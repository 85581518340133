import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { AccessGroupService } from '../../../shared/access-api/access-group.service';
import { AccessPipelineRunService } from '../../../shared/access-api/access-pipeline-run.service';
import * as CodeCoverageInfoActions from './code-coverage-info.actions';

@Injectable()
export class CodeCoverageInfoEffects {
  public onLoadCodeCoverageGroupSummary$ = createEffect(() => {
    return this.action$.pipe(
      ofType(CodeCoverageInfoActions.loadCodeCoverageGroupSummary),
      switchMap((action) => {
        return this.accessGroupService.getCodeCoverageForGroup(action.deliveryId, action.groupComplianceMode).pipe(
          map((coverageInfo) => CodeCoverageInfoActions.setCodeCoverageGroupSummary({ groupSummary: coverageInfo })),
          catchError((error: HttpErrorResponse) => {
            const deliveryId = action.deliveryId;
            if (error.status === 404) {
              return of(CodeCoverageInfoActions.loadCodeCoverageGroupSummaryNotFound({ deliveryId }));
            }
            return of(CodeCoverageInfoActions.loadCodeCoverageGroupSummaryError({ deliveryId }));
          }),
        );
      }),
    );
  });

  public onLoadCodeCoverageGroupDetails$ = createEffect(() => {
    return this.action$.pipe(
      ofType(CodeCoverageInfoActions.loadCodeCoverageGroupDetails),
      switchMap((action) => {
        return this.accessGroupService.getCodeCoverageDetailsForGroup(action.deliveryId, action.groupComplianceMode).pipe(
          map((coverageInfo) => CodeCoverageInfoActions.setCodeCoverageGroupDetails({ groupDetails: coverageInfo })),
          catchError((error: HttpErrorResponse) => {
            const deliveryId = action.deliveryId;
            if (error.status === 404) {
              return of(CodeCoverageInfoActions.loadCodeCoverageGroupDetailsNotFound({ deliveryId }));
            }
            return of(CodeCoverageInfoActions.loadCodeCoverageGroupDetailsError({ deliveryId }));
          }),
        );
      }),
    );
  });

  // Pipeline Run Coverage Info
  public onLoadCodeCoverageForPipelineRun$ = createEffect(() => {
    return this.action$.pipe(
      ofType(CodeCoverageInfoActions.loadCodeCoverageForPipelineRun),
      mergeMap((action) => {
        return this.accessPipelineRunService.getPipelineRunCodeCoverage(action.pipelineRunId).pipe(
          map((coverageInfo) =>
            CodeCoverageInfoActions.setCodeCoverageForPipelineRun({
              coverageInfo,
              pipelineRunId: action.pipelineRunId,
            }),
          ),
          catchError((error) => {
            if (error.status === 404) {
              return of(CodeCoverageInfoActions.loadCodeCoverageForPipelineRunNotFound({ pipelineRunId: action.pipelineRunId }));
            }
            return of(CodeCoverageInfoActions.loadCodeCoverageForPipelineRunError({ pipelineRunId: action.pipelineRunId }));
          }),
          takeUntil(
            this.action$.pipe(
              ofType(CodeCoverageInfoActions.loadCodeCoverageForPipelineRun),
              filter((newAction) => newAction.pipelineRunId === action.pipelineRunId),
            ),
          ),
        );
      }),
    );
  });

  constructor(
    private readonly action$: Actions,
    private readonly accessPipelineRunService: AccessPipelineRunService,
    private readonly accessGroupService: AccessGroupService,
  ) {}
}
