import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { catchError, first, map, mergeMap, switchMap } from 'rxjs/operators';
import { combineLatest, EMPTY, Observable, of, throwError, zip } from 'rxjs';
import {
  AccessApiService,
  JiraComponentPipelineMapping,
  ReleaseDecisionEntity,
  ReleaseDecisionInformation,
} from '../../../shared/access-api/access-api.service';
import { JiraService } from '../../../shared/jira-service/jira.service';
import {
  addMicrodeliveriesFromJira,
  cacheRequestFailed,
  jiraRequestFailed,
  loadMicrodeliveriesFromCache,
  loadReleaseDecisionInformation,
  loadReleaseEntriesFromJira,
  loadReleaseIssuesFromJira,
  setMicrodeliveriesFromCache,
  updateCache,
  updateCacheFailed,
  updateCacheSuccessful,
} from './microdeliveries.actions';
import { Action, Store } from '@ngrx/store';
import { selectMicrodeliveriesLoading } from '../loading-state/loading-state.selectors';
import { selectActiveMicrodeliveries, selectDisplayedMicrodeliveries } from './microdeliveries.selectors';
import { GroupTypeMicrodelivery, ReleaseDecisionEntityTypes } from '../../model/group-type-microdelivery';
import { JiraRelease } from '../../../model/jira/release-item';
import { Component } from '../../../model/jira/jira-issue-fields';
import { Delivery } from '../../../model/group/group';
import { selectDeliveryFixVersions, selectSelectedDelivery } from '../deliveries/deliveries.selectors';
import { AccessGroupService } from '../../../shared/access-api/access-group.service';
import { GroupService } from '../../../shared/group/group.service';
import { DeliveryFixVersion } from '../../../model/jira/delivery-fix-version.model';
import { setConfigurationForPipelinesOfDelivery } from '../configuration/configuration.actions';

@Injectable()
export class MicrodeliveriesEffects {
  public onLoadReleaseIssuesFromJira = createEffect(() => {
    return this.action$.pipe(
      ofType(loadReleaseIssuesFromJira),
      concatLatestFrom(() => [this.store$.select(selectSelectedDelivery), this.store$.select(selectDeliveryFixVersions)]),
      mergeMap(([action, delivery, deliveryFixVersions]) =>
        this.loadReleaseIssuesFromJira(delivery, action.releaseConfig, deliveryFixVersions).pipe(
          switchMap((microdeliveries) => [addMicrodeliveriesFromJira({ microdeliveries }), updateCache()]),
          catchError((err) => {
            console.error(err);
            return of(jiraRequestFailed(), updateCache());
          }),
        ),
      ),
    );
  });

  public onLoadReleaseEntriesFromJira = createEffect(() => {
    return this.action$.pipe(
      ofType(loadReleaseEntriesFromJira),
      mergeMap((action) =>
        this.loadReleaseEntriesFromJira(action.releaseConfig).pipe(
          switchMap((microdeliveries) => [addMicrodeliveriesFromJira({ microdeliveries }), updateCache()]),
          catchError((err) => {
            console.error(err);
            return of(jiraRequestFailed(), updateCache());
          }),
        ),
      ),
    );
  });

  public onLoadMicrodeliveriesFromCache = createEffect(() => {
    return this.action$.pipe(
      ofType(loadMicrodeliveriesFromCache),
      switchMap((action) =>
        this.groupService.getMicrodeliveriesByGroupId(action.groupId).pipe(
          map((microdeliveries) => setMicrodeliveriesFromCache({ microdeliveries })),
          catchError(() => of(cacheRequestFailed())),
        ),
      ),
    );
  });

  public onLoadReleaseDecisionInformation = createEffect(() => {
    return this.action$.pipe(
      ofType(loadReleaseDecisionInformation),
      switchMap((action) => zip(of(action), this.accessApiGroupService.getPipelinesReleaseDecisionInformationByGroup(action.delivery?.id))),
      switchMap(([action, releaseDecisionInformation]) => {
        // store configuration in ngrx store
        const actionsToDispatch: Action[] = [setConfigurationForPipelinesOfDelivery({ config: releaseDecisionInformation })];

        if (action.delivery.isMicrodelivery) {
          return EMPTY;
        }

        const alreadyHandledReleaseDecisionInformation = new Set<string>();
        const releaseConfigurations = Object.values(releaseDecisionInformation);
        for (const releaseConfig of releaseConfigurations) {
          // Prevent loading the same Release Decision Entities multiple times
          const copyOfReleaseDecisionInformation = this.getGenericCopyOfReleaseDecisionInformation(releaseConfig);
          if (alreadyHandledReleaseDecisionInformation.has(copyOfReleaseDecisionInformation)) {
            continue;
          }
          alreadyHandledReleaseDecisionInformation.add(copyOfReleaseDecisionInformation);

          // check for custom release configuration
          if (releaseConfig.releaseDecisionEntity && releaseConfig.backlogIds.length) {
            // show dropdown of microdeliveries
            switch (releaseConfig.releaseDecisionEntity) {
              case ReleaseDecisionEntity.Release:
                actionsToDispatch.push(loadReleaseEntriesFromJira({ releaseConfig }));
                break;
              case ReleaseDecisionEntity.Issue:
                actionsToDispatch.push(loadReleaseIssuesFromJira({ releaseConfig }));
                break;
            }
          } else {
            actionsToDispatch.push(loadReleaseIssuesFromJira({ releaseConfig }));
          }
        }
        return actionsToDispatch;
      }),
    );
  });

  public onUpdateCache = createEffect(() => {
    return this.action$.pipe(
      ofType(updateCache),
      concatLatestFrom(() => [
        this.store$.select(selectMicrodeliveriesLoading),
        this.store$.select(selectActiveMicrodeliveries),
        this.store$.select(selectDisplayedMicrodeliveries),
      ]),
      switchMap(([action, loading, activeMicrodeliveries, displayedMicrodeliveries]) => {
        if (!loading) {
          // Get ids from already cached microdeliveries in order to not destroy cumulus db relations/constraints
          activeMicrodeliveries = this.mergeWithCached(activeMicrodeliveries, displayedMicrodeliveries);
          const microdeliveriesChunked = this.chunkMicrodeliveries([...activeMicrodeliveries.values()]);
          if (microdeliveriesChunked.length > 1) {
            const addRequests = this.createAndCombineAddRequests(microdeliveriesChunked.slice(1));
            return this.groupService.updateMicrodeliveriesInGroup(microdeliveriesChunked[0]).pipe(
              switchMap((updatedMicrodeliveries) => {
                return combineLatest([of(updatedMicrodeliveries), addRequests]).pipe(
                  map((results) => {
                    return results[0].concat(results[1]);
                  }),
                );
              }),
              map((allMicrodeliveries) => updateCacheSuccessful({ microdeliveries: allMicrodeliveries })),
              catchError(() => of(updateCacheFailed())),
            );
          }
          return this.groupService.updateMicrodeliveriesInGroup(microdeliveriesChunked[0]).pipe(
            map((updatedMicrodeliveries) => updateCacheSuccessful({ microdeliveries: updatedMicrodeliveries })),
            catchError(() => of(updateCacheFailed())),
          );
        }
        return EMPTY;
      }),
    );
  });

  constructor(
    private readonly action$: Actions,
    private readonly jiraService: JiraService,
    private readonly accessApiService: AccessApiService,
    private readonly accessApiGroupService: AccessGroupService,
    private readonly groupService: GroupService,
    private readonly store$: Store,
  ) {}

  private loadReleaseIssuesFromJira(
    delivery: Delivery,
    releaseDecisionInformation: ReleaseDecisionInformation,
    deliveryFixVersions: DeliveryFixVersion[],
  ): Observable<GroupTypeMicrodelivery[]> {
    const releaseJiraCall$ = this.getJiraIssueMicrodeliveries(delivery, releaseDecisionInformation, deliveryFixVersions);
    return releaseJiraCall$.pipe(
      // get pipelineKeys from linked Jira Components for each Jira Issue
      map((issues) => {
        return issues.map((issue) => {
          const pipelineKeysForIssue = this.getPipelineKeysFromComponents(
            issue.fields.components,
            releaseDecisionInformation.jiraComponentPipelineMappings,
          );
          return {
            issue,
            pipelineKeys: pipelineKeysForIssue,
          };
        });
      }),
      // Translate all found pipelineKeys into PipelineIds at once
      mergeMap((issuesAndKeys) => {
        // get all pipeline keys
        const allPipelineKeysDistinct = issuesAndKeys
          .map((issueAndKeys) => issueAndKeys.pipelineKeys)
          .reduceRight((acc, curr) => acc.concat(curr), [])
          .filter((pipelineKey: string) => !!pipelineKey)
          .filter((value, index, self) => self.indexOf(value) === index); // get unique pipelineKeys

        if (!allPipelineKeysDistinct.length) {
          return zip(of(issuesAndKeys), of([]));
        }

        // Get pipeline key to pipeline id mapping from access api
        const pipelineIdsByKey = this.accessApiService.getPipelineIdsByKeys(allPipelineKeysDistinct).pipe(first());

        return zip(of(issuesAndKeys), pipelineIdsByKey);
      }),
      // Unzip the zipped issues, keys and pipelineIdByKey mapping
      map((issuesAndKeysWithTranslationZipped) => {
        return {
          issuesAndKeys: issuesAndKeysWithTranslationZipped[0],
          pipelineIdsByKeys: issuesAndKeysWithTranslationZipped[1],
        };
      }),
      // Connect the pipelines (bucketId) to the jira issues by finding the right entry in the translation result
      map((issuesAndKeysWithTranslation) => {
        return issuesAndKeysWithTranslation.issuesAndKeys.map((issueAndKeys) => {
          let linkedPipelineIds;
          if (Array.isArray(issueAndKeys.pipelineKeys)) {
            if (issueAndKeys.pipelineKeys.length === 0) {
              // if there are no keys, we dont have to translate them
              linkedPipelineIds = [];
            } else {
              // find the pipeline(/bucket) ids of the linked pipeline keys in the translation result and link them to the jira issue
              linkedPipelineIds = issuesAndKeysWithTranslation.pipelineIdsByKeys
                .filter((pipelineIdByKey) => issueAndKeys.pipelineKeys.includes(pipelineIdByKey.key))
                .map((pipelineIdByKey) => pipelineIdByKey.bucketId);
            }
          } else {
            // No Pipeline ids found for given Pipeline keys (via explicit or implicit mapping)
            // Provide null so that we can handle both in the same way.
            linkedPipelineIds = null;
          }
          return { issue: issueAndKeys.issue, linkedPipelineIds };
        });
      }),
      // Convert the collected values to real GroupTypeMicrodelivery entities with linkedPipelineIds
      map((issuesWithLinkedPipelineIds) => {
        return issuesWithLinkedPipelineIds.map((issueWithLinkedPipelineIds) => {
          const microdelivery = new GroupTypeMicrodelivery(issueWithLinkedPipelineIds.issue, ReleaseDecisionEntityTypes.JIRA_ISSUE);
          if (Array.isArray(issueWithLinkedPipelineIds.linkedPipelineIds) && !microdelivery.group.isLocked) {
            microdelivery.linkedPipelineIds = issueWithLinkedPipelineIds.linkedPipelineIds;
          }
          return microdelivery;
        });
      }),
    );
  }

  private getJiraIssueMicrodeliveries(
    delivery: Delivery,
    releaseDecisionInformation: ReleaseDecisionInformation,
    deliveryFixVersions: DeliveryFixVersion[],
  ) {
    if (!releaseDecisionInformation.releaseDecisionEntity) {
      return this.jiraService.getDefaultReleasesForMicroDeliveries(delivery, deliveryFixVersions);
    }
    if (releaseDecisionInformation.releaseIssueFilterId) {
      return this.jiraService.getReleasesForJiraFilter(releaseDecisionInformation);
    } else if (releaseDecisionInformation.releaseIssueProjectId && releaseDecisionInformation.releaseIssueType) {
      return this.jiraService.getReleasesForEpicProject(releaseDecisionInformation);
    } else {
      const message =
        'cumulus-configuration.json configuration error: releaseDecision Entity was set, ' +
        'but neither releaseIssueFilterId nor releaseIssueProjectId and ' +
        'releaseIssueType have been set; see https://wiki.wdf.sap.corp/wiki/x/X9rKj';
      return throwError(() => new Error(message));
    }
  }

  private getPipelineKeysFromComponents(components: Component[], jiraComponentPipelineMappings: JiraComponentPipelineMapping[]): string[] {
    // No components set on Microdelivery or no mapping configured => exit
    if (!jiraComponentPipelineMappings) {
      return null;
    }

    let allPipelineKeys: string[] = [];
    for (const component of components) {
      // get pipelineKeys of component from jiraComponentPipelineMapping (cumulus config)
      const componentPipelineKeys = jiraComponentPipelineMappings?.find((mapping) => mapping.component === component.name)?.pipelineKeys;
      allPipelineKeys = allPipelineKeys.concat(componentPipelineKeys);
    }

    return allPipelineKeys;
  }

  private loadReleaseEntriesFromJira(releaseDecisionInformation: ReleaseDecisionInformation): Observable<GroupTypeMicrodelivery[]> {
    return this.jiraService.getReleasesForProjects(releaseDecisionInformation).pipe(
      map((releases: JiraRelease[]) => {
        return releases.map((release) => {
          return new GroupTypeMicrodelivery(release, ReleaseDecisionEntityTypes.JIRA_RELEASE);
        });
      }),
    );
  }

  private chunkMicrodeliveries(microdeliveries: GroupTypeMicrodelivery[], chunkSize = 100): GroupTypeMicrodelivery[][] {
    const microdeliveriesChunked = [];
    if (microdeliveries.length) {
      while (microdeliveries.length) {
        microdeliveriesChunked.push(microdeliveries.splice(0, chunkSize));
      }
    } else {
      microdeliveriesChunked.push([]);
    }
    return microdeliveriesChunked;
  }

  private createAndCombineAddRequests(microdeliveriesChunked: GroupTypeMicrodelivery[][]): Observable<GroupTypeMicrodelivery[]> {
    const addRequests = microdeliveriesChunked.map((microdeliveries) => this.groupService.addMicrodeliveriesToGroup(microdeliveries));
    return combineLatest(addRequests).pipe(
      map((microdeliveries) => {
        return microdeliveries.reduce((prev, curr) => prev.concat(curr), []);
      }),
    );
  }

  private mergeWithCached(
    activeMicrodeliveries: Map<number, GroupTypeMicrodelivery>,
    displayedMicrodeliveries: Map<number, GroupTypeMicrodelivery>,
  ) {
    activeMicrodeliveries.forEach((microdelivery, issueId) => {
      const alreadyCachedMicrodelivery = displayedMicrodeliveries.get(issueId);
      if (alreadyCachedMicrodelivery) {
        activeMicrodeliveries.set(issueId, {
          ...microdelivery,
          id: alreadyCachedMicrodelivery.id,
          group: { ...microdelivery.group, id: alreadyCachedMicrodelivery.group.id },
        } as GroupTypeMicrodelivery);
      }
    });
    return activeMicrodeliveries;
  }

  private getGenericCopyOfReleaseDecisionInformation(releaseDecisionInformation: ReleaseDecisionInformation) {
    const copyOfReleaseDecisionInformation = { ...releaseDecisionInformation };
    delete copyOfReleaseDecisionInformation.pipelineId;
    delete copyOfReleaseDecisionInformation.configurationGroupId;
    delete copyOfReleaseDecisionInformation.coverageThresholds;
    return JSON.stringify(copyOfReleaseDecisionInformation);
  }
}
