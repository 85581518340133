<div
  *ngIf="
    (coverageInfo?.lineRate === undefined || coverageInfo?.lineRate === null) &&
      (coverageInfo?.branchRate === undefined || coverageInfo?.branchRate === null);
    else coverageDataInfo
  "
>
  No data available {{ coverageInfo?.lineRate }}
</div>
<ng-template #coverageDataInfo>
  <div>
    <ngx-gauge
      [size]="size"
      [type]="gaugeType"
      [value]="coverageInfo?.lineRate"
      [min]="min"
      [max]="max"
      [thresholds]="threshold?.lineRate || thresholds"
      [append]="'%'"
    >
      <ngx-gauge-label>
        <strong class="chart-label" *ngIf="!(coverageInfo?.lineRate === undefined || coverageInfo?.lineRate === null)">Line Rate</strong>
        <strong
          class="chart-label"
          *ngIf="coverageInfo?.lineRate === undefined || coverageInfo?.lineRate === null"
          fusionTooltip="No Data available"
          >Line Rate*</strong
        ></ngx-gauge-label
      >
    </ngx-gauge>
    <ngx-gauge
      [size]="size"
      [type]="gaugeType"
      [value]="coverageInfo?.branchRate"
      [thresholds]="threshold?.branchRate || thresholds"
      [append]="'%'"
    >
      <ngx-gauge-label>
        <strong class="chart-label" *ngIf="!(coverageInfo?.branchRate === undefined || coverageInfo?.branchRate === null)"
          >Branch Rate</strong
        >
        <strong
          class="chart-label"
          *ngIf="coverageInfo?.branchRate === undefined || coverageInfo?.branchRate === null"
          fusionTooltip="No Data available"
          >Branch Rate*</strong
        ></ngx-gauge-label
      >
    </ngx-gauge>
  </div>
</ng-template>
