import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { AccessApiPolicyService } from '../../../shared/access-api/policy/access-api-policy.service';
import * as CustomPolicyResultsActions from './custom-policy-results.actions';

@Injectable()
export class CustomPolicyResultsEffects {
  public onLoadCustomPolicyResultsSingleSummary$ = createEffect(() => {
    return this.action$.pipe(
      ofType(CustomPolicyResultsActions.loadCustomPolicyResultsSingleSummary),
      mergeMap((action) => {
        return this.accessApiPolicyService.getPipelineRunCustomPolicyResultsSummary(action.pipelineRunId).pipe(
          map((singleSummary) =>
            CustomPolicyResultsActions.setCustomPolicyResultsSingleSummary({
              pipelineRunId: action.pipelineRunId,
              singleSummary,
            }),
          ),
          catchError((error) => {
            if (error.status === 404) {
              return of(CustomPolicyResultsActions.loadCustomPolicyResultsSingleSummaryNotFound({ pipelineRunId: action.pipelineRunId }));
            }
            return of(CustomPolicyResultsActions.loadCustomPolicyResultsSingleSummaryError({ pipelineRunId: action.pipelineRunId }));
          }),
          takeUntil(
            this.action$.pipe(
              ofType(CustomPolicyResultsActions.loadCustomPolicyResultsSingleSummary),
              filter((newAction) => newAction.pipelineRunId === action.pipelineRunId),
            ),
          ),
        );
      }),
    );
  });

  public onLoadCustomPolicyResultsSingleDetails$ = createEffect(() => {
    return this.action$.pipe(
      ofType(CustomPolicyResultsActions.loadCustomPolicyResultsSingleDetails),
      mergeMap((action) => {
        return this.accessApiPolicyService.getPipelineRunCustomPolicyResultsDetails(action.pipelineRunId).pipe(
          map((singleDetails) =>
            CustomPolicyResultsActions.setCustomPolicyResultsSingleDetails({
              singleDetails,
              pipelineRunId: action.pipelineRunId,
            }),
          ),
          catchError(() => {
            return of(CustomPolicyResultsActions.loadCustomPolicyResultsSingleDetailsError({ pipelineRunId: action.pipelineRunId }));
          }),
          takeUntil(
            this.action$.pipe(
              ofType(CustomPolicyResultsActions.loadCustomPolicyResultsSingleDetails),
              filter((newAction) => newAction.pipelineRunId === action.pipelineRunId),
            ),
          ),
        );
      }),
    );
  });

  public onLoadCustomPolicyResultsGroupSummary$ = createEffect(() => {
    return this.action$.pipe(
      ofType(CustomPolicyResultsActions.loadCustomPolicyResultsGroupSummary),
      switchMap((action) =>
        this.accessApiPolicyService.getGroupCustomPolicyResultsSummary(action.groupId, action.groupComplianceMode).pipe(
          map((groupSummary) =>
            CustomPolicyResultsActions.setCustomPolicyResultsGroupSummary({
              groupSummary,
            }),
          ),
          catchError((error: HttpErrorResponse) => {
            const groupId = action.groupId;
            if (error.status == 404) {
              return of(CustomPolicyResultsActions.loadCustomPolicyResultsGroupSummaryNotFound({ groupId }));
            }
            return of(CustomPolicyResultsActions.loadCustomPolicyResultsGroupSummaryError({ groupId }));
          }),
        ),
      ),
    );
  });

  public onLoadCustomPolicyResultsGroupDetails$ = createEffect(() => {
    return this.action$.pipe(
      ofType(CustomPolicyResultsActions.loadCustomPolicyResultsGroupDetails),
      switchMap((action) =>
        this.accessApiPolicyService.getGroupCustomPolicyResultsDetails(action.groupId, action.groupComplianceMode).pipe(
          map((groupDetails) =>
            CustomPolicyResultsActions.setCustomPolicyResultsGroupDetails({
              groupDetails,
            }),
          ),
          catchError(() => {
            return of(CustomPolicyResultsActions.loadCustomPolicyResultsGroupDetailsError({ groupId: action.groupId }));
          }),
        ),
      ),
    );
  });

  constructor(
    private readonly action$: Actions,
    private readonly accessApiPolicyService: AccessApiPolicyService,
  ) {}
}
