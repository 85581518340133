import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ComplianceInfoDetails } from '../../../ngrx/model/compliance-information';
import {
  selectIsSLC25GroupDetailsLoading,
  selectIsSLC25GroupDetailsLoadingError,
} from '../../../ngrx/store/loading-state/loading-state.selectors';
import { loadSlc25GroupDetails } from '../../../ngrx/store/slc-25/slc-25.actions';
import { selectSlc25GroupDetails } from '../../../ngrx/store/slc-25/slc-25.selectors';
import { GridDataSortService } from '../../../shared/grid-data-sort-service/grid-data-sort.service';
import { KpiDialogsData } from '../../kpis.module';

@Component({
  selector: 'app-slc25-details-dialog',
  templateUrl: './slc25-details-dialog.component.html',
  styleUrls: ['./slc25-details-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Slc25DetailsDialogComponent implements OnInit {
  public dataSource$!: Observable<GridDataResult>;
  public isLoading$!: Observable<boolean>;
  public isLoadingError$!: Observable<boolean>;

  slc25InfoSorted: ComplianceInfoDetails[];
  dataSource: GridDataResult;

  gridState: State = {
    filter: {
      logic: 'and',
      filters: [],
    },
    sort: [{ field: 'complianceStatus.displayKey', dir: 'asc' }],
  };

  constructor(
    private readonly dialogRef: MatDialogRef<Slc25DetailsDialogComponent>,
    private readonly store$: Store,
    private readonly gridDataSortService: GridDataSortService,
    @Inject(MAT_DIALOG_DATA) private readonly dialogData: KpiDialogsData,
  ) {
    this.dataSource$ = this.store$.select(selectSlc25GroupDetails).pipe(
      map((data) => {
        this.slc25InfoSorted = data;
        this.dataStateChanged(this.gridState);
        return this.dataSource;
      }),
    );
    this.isLoading$ = this.store$.select(selectIsSLC25GroupDetailsLoading);
    this.isLoadingError$ = this.store$.select(selectIsSLC25GroupDetailsLoadingError);
  }

  ngOnInit() {
    this.dialogRef.updateSize('60vw', 'auto');
  }

  reload() {
    this.store$.dispatch(
      loadSlc25GroupDetails({
        deliveryId: this.dialogData?.deliveryId,
        groupComplianceMode: this.dialogData?.groupComplianceMode,
      }),
    );
  }

  closeDialog(data?: any) {
    this.dialogRef.close(data);
  }

  dataStateChanged(state) {
    this.gridState = state;
    this.dataSource = process(this.slc25InfoSorted, this.gridState);
    this.dataSource.data = this.gridDataSortService.sortComplianceStateByStatusSortOrder(this.gridState.sort, this.dataSource.data);
  }
}
