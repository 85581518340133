import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store, Action } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { GroupService } from '../../../shared/group/group.service';
import { JiraService } from '../../../shared/jira-service/jira.service';
import { loadApiMetadataValidatorGroupSummary } from '../api-metadata-validator/api-metadata-validator.actions';
import { loadAtcGroupSummary } from '../atc/atc.actions';
import { loadCentralPolicyResultsGroupSummary } from '../central-policy-results/central-policy-results.actions';
import { loadCentralPolicyUsageGroupSummary } from '../central-policy-usage/central-policy-usage.actions';
import { loadCodeCoverageGroupSummary } from '../code-coverage-info/code-coverage-info.actions';
import { loadCustomPolicyResultsGroupSummary } from '../custom-policy-results/custom-policy-results.actions';
import { loadCustomPolicyUsageGroupSummary } from '../custom-policy-usage/custom-policy-usage.actions';
import { loadFc1GroupSummary } from '../fc-1/fc-1.actions';
import { loadDeliveryRequirementTestcases, loadDeliveryRequirements, loadExternalDeliveryRequirements } from '../fc-2/fc-2.actions';
import { selectDeliveryRequirements } from '../fc-2/fc-2.selectors';
import { loadFc3GroupSummary } from '../fc-3/fc-3.actions';
import { loadFosstarsGroupSummary } from '../fosstars/fosstars.actions';
import { loadMalwarescanGroupSummary } from '../malwarescan/malwarescan.actions';
import {
  clearMicrodeliveriesSlice,
  loadMicrodeliveriesFromCache,
  loadReleaseDecisionInformation,
} from '../microdeliveries/microdeliveries.actions';
import { loadPipelinesForDelivery } from '../pipeline/pipeline.actions';
import { loadPsl1GroupSummary } from '../psl-1/psl-1.actions';
import { loadSlc25GroupSummary } from '../slc-25/slc-25.actions';
import { loadSonarQubeCoverageGroupSummary } from '../sonar-qube-coverage/sonar-qube-coverage.actions';
import {
  loadDeliveryFixVersions,
  loadDeliveryFixVersionsEmpty,
  loadDeliveryFixVersionsFailed,
  loadDeliveryFixVersionsSuccessful,
  loadSiriusDelivery,
  loadSiriusDeliveryFailed,
  loadSiriusDeliverySuccessful,
  reloadGroup,
  reloadGroupCodeCompliance,
  setSelectedComplianceShowMode,
  setSelectedDelivery,
} from './deliveries.actions';
import { selectDeliveryFixVersions, selectSelectedDelivery, selectSelectedGroupComplianceMode } from './deliveries.selectors';

@Injectable()
export class DeliveriesEffects {
  public onLoadSiriusDelivery = createEffect(() => {
    return this.action$.pipe(
      ofType(loadSiriusDelivery),
      switchMap((action) =>
        this.groupService.getGroupOfDelivery(action.deliveryGuid).pipe(
          map((delivery) => {
            if (!delivery) {
              // this is not an error!
              // it means that no pipeline has been added yet
              // => cumulus does not yet know the delivery and the start screen is displayed
              return loadSiriusDeliverySuccessful({ delivery: undefined });
            }
            return loadSiriusDeliverySuccessful({
              delivery: {
                ...delivery,
                isMicrodelivery: false,
              },
            });
          }),
          catchError((e: HttpErrorResponse) => of(loadSiriusDeliveryFailed({ error: e }))),
        ),
      ),
    );
  });

  public onReloadGroup = createEffect(() => {
    return this.action$.pipe(
      ofType(reloadGroup),
      concatLatestFrom(() => [this.store$.select(selectSelectedDelivery)]),
      switchMap(([_, delivery]) => {
        if (delivery.isMicrodelivery) {
          return [loadPipelinesForDelivery({ delivery: delivery }), reloadGroupCodeCompliance()];
        } else {
          return [loadSiriusDelivery({ deliveryGuid: delivery.key })];
        }
      }),
    );
  });

  public reloadGroupComplianceData = createEffect(() => {
    return this.action$.pipe(
      ofType(reloadGroupCodeCompliance),
      concatLatestFrom(() => [
        this.store$.select(selectSelectedDelivery),
        this.store$.select(selectDeliveryRequirements),
        this.store$.select(selectSelectedGroupComplianceMode),
      ]),
      switchMap(([action, delivery, deliveryRequirements, groupComplianceMode]) => {
        const deliveryId = delivery.id;
        return [
          loadSlc25GroupSummary({ deliveryId, groupComplianceMode }),
          loadPsl1GroupSummary({ deliveryId, groupComplianceMode }),
          loadFc3GroupSummary({ deliveryId, groupComplianceMode }),
          loadFc1GroupSummary({ deliveryId, groupComplianceMode }),
          loadAtcGroupSummary({ deliveryId, groupComplianceMode }),
          loadCodeCoverageGroupSummary({ deliveryId, groupComplianceMode }),
          loadMalwarescanGroupSummary({ deliveryId, groupComplianceMode }),
          loadApiMetadataValidatorGroupSummary({ deliveryId, groupComplianceMode }),
          loadSonarQubeCoverageGroupSummary({ deliveryId, groupComplianceMode }),
          loadDeliveryRequirementTestcases(),
          loadFosstarsGroupSummary({ deliveryId, groupComplianceMode }),
          loadCentralPolicyResultsGroupSummary({ groupId: deliveryId, groupComplianceMode }),
          loadCustomPolicyResultsGroupSummary({ groupId: deliveryId, groupComplianceMode }),
          loadCentralPolicyUsageGroupSummary({ groupId: deliveryId, groupComplianceMode }),
          loadCustomPolicyUsageGroupSummary({ groupId: deliveryId, groupComplianceMode }),
        ] as Action<string>[];
      }),
    );
  });

  public onSetSelectedComplianceShowModeToLoadComplianceData = createEffect(() => {
    return this.action$.pipe(
      ofType(setSelectedComplianceShowMode, setSelectedDelivery),
      map((_) => {
        return reloadGroupCodeCompliance();
      }),
    );
  });

  public onSetSelectedComplianceShowModeToLoadPipelineList = createEffect(() => {
    return this.action$.pipe(
      ofType(setSelectedComplianceShowMode),
      concatLatestFrom(() => [this.store$.select(selectSelectedDelivery)]),
      map(([_, delivery]) => {
        return loadPipelinesForDelivery({ delivery });
      }),
    );
  });

  public onSetSelectedComplianceShowModeToLoadTestCasesOrRequirements = createEffect(() => {
    return this.action$.pipe(
      ofType(setSelectedComplianceShowMode),
      concatLatestFrom(() => [this.store$.select(selectDeliveryFixVersions), this.store$.select(selectSelectedDelivery)]),
      switchMap(([action, deliveryFixVersions, delivery]) => {
        if (Array.isArray(deliveryFixVersions) && !deliveryFixVersions.length) {
          return of(loadExternalDeliveryRequirements({ delivery }));
        } else {
          return of(loadDeliveryRequirementTestcases());
        }
      }),
    );
  });

  /**
   * Side effect to load Requirements of delivery with sap-jira link
   */
  public onSelectedDeliveryToLoadDeliveryRequirements = createEffect(() => {
    return this.action$.pipe(
      ofType(setSelectedDelivery),
      concatLatestFrom(() => this.store$.select(selectDeliveryFixVersions)),
      filter(([action, deliveryFixVersions]) => !!deliveryFixVersions?.length),
      switchMap(([action]) => [
        loadReleaseDecisionInformation({ delivery: action.delivery }),
        loadDeliveryRequirements({ delivery: action.delivery }),
      ]),
    );
  });

  /**
   * Fallback Side effect to load Requirements of delivery without sap-jira link
   */
  public onSelectedDeliveryToLoadExternalRequirements = createEffect(() => {
    return this.action$.pipe(
      ofType(setSelectedDelivery),
      concatLatestFrom(() => this.store$.select(selectDeliveryFixVersions)),
      filter(([action, deliveryFixVersions]) => Array.isArray(deliveryFixVersions) && !deliveryFixVersions.length),
      switchMap(([action]) => [loadExternalDeliveryRequirements({ delivery: action.delivery })]),
    );
  });

  public onLoadSiriusDeliverySuccessfulToLoadDeliveryFixVersions = createEffect(() => {
    return this.action$.pipe(
      ofType(loadSiriusDeliverySuccessful),
      switchMap((action) => {
        if (action.delivery) {
          return [loadDeliveryFixVersions({ deliveryGuid: action.delivery.key })];
        }
        return EMPTY;
      }),
    );
  });

  public onLoadDeliveryFixVersions = createEffect(() => {
    return this.action$.pipe(
      ofType(loadDeliveryFixVersions),
      switchMap((action) => {
        return this.jiraService.getDeliveryFixVersions(action.deliveryGuid).pipe(
          map((deliveryFixVersions) => {
            if (deliveryFixVersions.length) {
              return loadDeliveryFixVersionsSuccessful({ deliveryFixVersions });
            } else {
              return loadDeliveryFixVersionsEmpty();
            }
          }),
          catchError((e: HttpErrorResponse) => of(loadDeliveryFixVersionsFailed({ error: e }))),
        );
      }),
    );
  });

  public onLoadSiriusDeliveryFixVersionsSuccessFulToLoadMicrodeliveryInformation = createEffect(() => {
    return this.action$.pipe(
      ofType(loadDeliveryFixVersionsSuccessful),
      concatLatestFrom(() => this.store$.select(selectSelectedDelivery)),
      switchMap(([action, delivery]) => {
        if (!delivery) {
          return [];
        }

        return [
          loadDeliveryRequirements({ delivery }),

          // Rely on clearMicroDeliveriesSlice is 'faster' than the other ones
          clearMicrodeliveriesSlice(),
          loadMicrodeliveriesFromCache({ groupId: delivery.id }),
          loadReleaseDecisionInformation({ delivery }),
        ];
      }),
    );
  });

  public onLoadSiriusDeliveryFixVersionsFailed = createEffect(() => {
    return this.action$.pipe(
      ofType(loadDeliveryFixVersionsEmpty),
      concatLatestFrom(() => this.store$.select(selectSelectedDelivery)),
      switchMap(([action, delivery]) => {
        if (!delivery) {
          return [];
        }

        return [
          loadExternalDeliveryRequirements({ delivery }),

          // Rely on clearMicroDeliveriesSlice is 'faster' than the other ones
          clearMicrodeliveriesSlice(),
          loadMicrodeliveriesFromCache({ groupId: delivery.id }),
        ];
      }),
    );
  });

  constructor(
    private readonly store$: Store,
    private readonly action$: Actions,
    private readonly groupService: GroupService,
    private readonly jiraService: JiraService,
  ) {}
}
