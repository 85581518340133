import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { PipelineRunDocument } from '../../../model/documents/pipeline-run-document.model';
import { AccessPipelineRunService } from '../../../shared/access-api/access-pipeline-run.service';
import * as ComplianceCertificatesActions from './compliance-certificates.actions';
import * as DocumentsActions from '../documents/documents.actions';

@Injectable()
export class ComplianceCertificatesEffects {
  public onLoadPipelineRunDocuments$ = createEffect(() => {
    return this.action$.pipe(
      ofType(DocumentsActions.loadPipelineRunDocuments),
      map((action) => {
        return ComplianceCertificatesActions.loadPipelineRunComplianceCertificates({ pipelineRunId: action.pipelineRunId });
      }),
    );
  });

  public onLoadPipelineRunComplianceCertificates$ = createEffect(() => {
    return this.action$.pipe(
      ofType(ComplianceCertificatesActions.loadPipelineRunComplianceCertificates),
      mergeMap((action) =>
        this.accessPipelineRunService.getPipelineRunComplianceCertificates(action.pipelineRunId).pipe(
          map((documents: PipelineRunDocument[]) =>
            ComplianceCertificatesActions.setPipelineRunComplianceCertificates({
              pipelineRunId: action.pipelineRunId,
              documents: documents.map((document: PipelineRunDocument) => ({
                ...document,
                isUnprocessed: false,
              })),
            }),
          ),
          catchError((error) => {
            if (error.status === 404) {
              return of(
                ComplianceCertificatesActions.loadPipelineRunComplianceCertificatesNotFound({ pipelineRunId: action.pipelineRunId }),
              );
            }
            return of(ComplianceCertificatesActions.loadPipelineRunComplianceCertificatesError({ pipelineRunId: action.pipelineRunId }));
          }),
          takeUntil(
            this.action$.pipe(
              ofType(ComplianceCertificatesActions.loadPipelineRunComplianceCertificates),
              filter((newAction) => newAction.pipelineRunId === action.pipelineRunId),
            ),
          ),
        ),
      ),
    );
  });

  constructor(
    private readonly action$: Actions,
    private readonly accessPipelineRunService: AccessPipelineRunService,
  ) {}
}
