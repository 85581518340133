export enum FosstarRatingType {
  BAD = 'Bad',
  UNCLEAR = 'Unclear',
  MODERATE = 'Moderate',
  GOOD = 'Good',
  'NOT-AVAILABLE' = 'Not Available',
}

export class FosstarRating {
  type: string;
  label: string;
  amount: number;

  constructor(type: string, amount: number) {
    this.type = type;
    this.label = this.getLabel(this.type);
    this.amount = amount;
  }

  getLabel(type: string): string {
    return FosstarRatingType[type];
  }
}
