<div class="warning-box flex flex-row gap-2.5">
  <div>
    <fusion-icon name="exclamation-triangle-outline" class="icon-warn"></fusion-icon>
  </div>
  <span>
    <ng-content select="[warningMessage]"></ng-content>
    <span *ngIf="showTheAdditionalInfo"
      >More information can be found
      <a href="{{ additionalInfoLink }}" target="_blank" rel="noopener noreferrer" class="clickable">
        <span>here</span> <fusion-icon name="link-external" size="S"></fusion-icon>
      </a>
    </span>
  </span>
</div>
