import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ReleaseStatusKey } from '../../model/release-status/release-status';
import {
  ChangePipelineRunReleaseStatusDialogService,
  PipelineRunReleaseStatusInGroup,
} from '../change-pipeline-run-release-status-dialog.service';

@Component({
  selector: 'app-change-current-state-dialog',
  templateUrl: './change-current-state-dialog.component.html',
  styleUrls: ['./change-current-state-dialog.component.scss'],
})
export class ChangeCurrentStateDialogComponent implements OnChanges {
  @Input() public data: PipelineRunReleaseStatusInGroup;
  @Output() public revertReleasedStatusSelect = new EventEmitter<PipelineRunReleaseStatusInGroup>();
  @Output() public closeDialog = new EventEmitter<boolean>();

  ReleaseStatus = ReleaseStatusKey;
  latestReleaseStatusKey: ReleaseStatusKey;

  constructor(public changePipelineRunReleaseStatusService: ChangePipelineRunReleaseStatusDialogService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      this.latestReleaseStatusKey = this.data?.latestReleaseStatusKeyInGroup.key;
    }
  }

  handleSetReleaseStatus(newReleaseStatus: ReleaseStatusKey) {
    this.changePipelineRunReleaseStatusService.setNewReleaseState(
      this.data?.pipelineRunId,
      this.data?.pipelineRunKey,
      this.data?.pipelineId,
      this.data?.group,
      newReleaseStatus,
    );
    this.closeDialog.emit(true);
  }

  handleRevertReleaseStatus(revertedReleaseStatus: ReleaseStatusKey) {
    if (revertedReleaseStatus === ReleaseStatusKey.RELEASED) {
      this.revertReleasedStatusSelect.emit(this.data);
    } else {
      this.changePipelineRunReleaseStatusService.revertReleaseState(
        this.data?.pipelineRunId,
        this.data?.pipelineRunKey,
        this.data?.pipelineId,
        this.data?.group,
        revertedReleaseStatus,
      );
      this.closeDialog.emit(true);
    }
  }
}
