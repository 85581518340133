<div
  *ngIf="{
    isLoading: isLoading$ | async,
    isLoadingError: isLoadingError$ | async,
    dataSource: dataSource$ | async,
  } as data"
  class="flex flex-col"
  mat-dialog-content
>
  <div class="flex flex-col h-full w-full">
    <app-dialog-error-information (reloadClicked)="reload()" *ngIf="data?.isLoadingError"></app-dialog-error-information>
    <kendo-grid
      *ngIf="!data?.isLoadingError"
      [filter]="gridState.filter"
      [filterable]="true"
      [loading]="data.isLoading"
      [resizable]="true"
      [sort]="gridState.sort"
      [sortable]="sortSettings"
      data-test-id="policy-results-detail-popup-grid"
      class="flex h-full w-full"
      id="policy-results-detail-grid"
      [data]="dataSource"
      (dataStateChange)="dataStateChanged($event)"
      [trackBy]="trackByResultId"
    >
      <kendo-grid-column field="pipelineKey" title="Pipeline" width="160">
        <ng-template kendoGridCellTemplate kendoTooltip let-dataItem>
          <app-kpi-column-pipeline
            [item]="dataItem"
            fusionFeatureUsageTracker="open-policy-results-group-pipeline-url"
          ></app-kpi-column-pipeline>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        *ngIf="showToolNameColumn"
        [minResizableWidth]="100"
        field="toolName"
        title="Measurement Tool"
        [width]="140"
        class="vertical-align-initial td-padding-top-20"
      >
        <ng-template kendoGridCellTemplate kendoTooltip let-dataItem>
          <div class="align-middle">
            <span data-test-id="policy-result-tool-name" [innerHTML]="getToolName(dataItem?.toolName, dataItem?.isIgnored)"></span>
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [filterable]="false"
        [minResizableWidth]="235"
        field="validationErrorMessages"
        title="Validation Error Messages"
        [width]="300"
        class="vertical-align-initial td-padding-top-20"
      >
        <ng-template kendoGridCellTemplate kendoTooltip let-dataItem let-rowIndex="rowIndex">
          <app-validation-error-message-list
            [messages]="dataItem?.validationErrorMessages"
            messagesTestId="policy-result-validation-error-messages"
            id="textTruncationBlock{{ rowIndex }}"
            data-test-id="policy-result-validation-error-messages"
            [isIgnored]="dataItem?.isIgnored"
          >
          </app-validation-error-message-list>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [minResizableWidth]="77"
        [maxResizableWidth]="200"
        field="status.displayKey"
        title="Status"
        [width]="160"
        class="vertical-align-initial td-padding-top-20"
      >
        <ng-template kendoGridCellTemplate kendoTooltip let-dataItem>
          <div class="truncate align-middle" appTextTruncateTitleTooltip>
            <span *ngIf="dataItem?.status?.key" class="status-circle status-circle-{{ dataItem?.status?.key }}"> </span>
            <span data-test-id="policy-result-compliance-status">{{ dataItem?.status?.displayKey || '&mdash;' }}</span>
            <fusion-icon
              *ngIf="dataItem?.isIgnored && dataItem?.ignoreReason"
              [fusionTooltip]="ignoreReason"
              name="info-circle-outline"
              class="align-middle ml-1"
              data-test-id="info-icon"
            ></fusion-icon>
            <ng-template #ignoreReason>
              <div class="action-tooltip-text" data-test-id="central-policy-ignore-reason">
                <b>Provided reason: </b>{{ dataItem?.ignoreReason }}
              </div>
            </ng-template>
            <fusion-icon
              *ngIf="dataItem?.isIgnored && dataItem?.policyComplianceStatus?.key"
              [fusionTooltip]="statusWarningTooltip"
              name="exclamation-triangle-outline"
              class="warning align-middle ml-1"
              data-test-id="status-warning-tooltip-icon"
            ></fusion-icon>
            <ng-template #statusWarningTooltip>
              <ng-container *ngIf="dataItem?.policyComplianceStatus?.key">
                <div class="action-tooltip-header">Policy compliance result found but was configured to be ignored.</div>
                <div class="action-tooltip-text" data-test-id="status-warning-tooltip-result-status">
                  Result status:
                  <span class="status-circle status-circle-small status-circle-{{ dataItem?.policyComplianceStatus?.key }}"> </span>
                  <span>{{ dataItem?.policyComplianceStatus?.displayKey }}</span>
                </div>
                <div class="action-tooltip-text" *ngIf="dataItem?.toolName" data-test-id="status-warning-tooltip-measurement-tool">
                  Measurement tool: {{ dataItem?.toolName }}
                </div>
                <div
                  class="action-tooltip-text"
                  *ngIf="dataItem?.validationErrorMessages?.length"
                  data-test-id="status-warning-tooltip-validation-error-messages"
                >
                  Validation error messages:
                  <ng-container>
                    <app-validation-error-message-list [messages]="dataItem?.validationErrorMessages"> </app-validation-error-message-list>
                  </ng-container>
                </div>
              </ng-container>
            </ng-template>
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [minResizableWidth]="100"
        [maxResizableWidth]="100"
        [filterable]="false"
        [sortable]="false"
        title=""
        [width]="100"
        class="vertical-align-initial td-padding-top-20"
      >
        <ng-template kendoGridCellTemplate kendoTooltip let-dataItem>
          <div>
            <div class="flex flex-row gap-2.5 justify-center items-baseline">
              <button
                fusionIconButton
                fusionIcon="mail"
                fusionIconSize="L"
                (click)="mailTo(dataItem)"
                [fusionTooltip]="mailToTooltip"
                class="align-middle"
                data-test-id="mailToPolicyResponsiblesButton"
                fusionFeatureUsageTracker="policy-results-mail-to-btn"
                [disabled]="!dataItem?.policyOwner"
                [fusionTooltipDisabled]="!dataItem?.policyOwner"
              ></button>
              <ng-template #mailToTooltip>
                <div class="action-tooltip-header">Contact Central Policy Responsibles</div>
                <div class="action-tooltip-text">Owner: {{ dataItem?.policyOwner }}</div>
                <div class="action-tooltip-text" *ngIf="dataItem?.policyOwnerSubstitute">
                  Substitute: {{ dataItem?.policyOwnerSubstitute }}
                </div>
              </ng-template>
              <button
                fusionIconButton
                fusionIcon="download"
                fusionIconSize="L"
                (click)="downloadFile(dataItem)"
                [fusionTooltip]="downloadPolicyResultFileButton"
                class="align-middle"
                data-test-id="downloadPolicyResultFileButton"
                fusionFeatureUsageTracker="download-policy-results-file-btn"
                [disabled]="!dataItem?.fileName"
                [fusionTooltipDisabled]="!dataItem?.fileName"
              ></button>
              <ng-template #downloadPolicyResultFileButton>
                <div class="action-tooltip-header">Download Policy Result File</div>
                <div class="action-tooltip-text">{{ getDownloadTooltipText(dataItem?.fileName, dataItem?.subPath) }}</div>
              </ng-template>
            </div>
          </div>
        </ng-template>
      </kendo-grid-column>
      <ng-template kendoGridLoadingTemplate>
        <app-table-loading-indicator></app-table-loading-indicator>
      </ng-template>
    </kendo-grid>
  </div>
</div>
