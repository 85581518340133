import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { NgxGaugeType } from 'ngx-gauge/gauge/gauge';
import { Observable } from 'rxjs';
import { CodeCoverageInfo } from '../../model/code-coverage/code-coverage.model';
import {
  loadCodeCoverageForPipelineRun,
  loadCodeCoverageGroupDetails,
  loadCodeCoverageGroupSummary,
} from '../../ngrx/store/code-coverage-info/code-coverage-info.actions';
import {
  selectCodeCoverageGroupSummary,
  selectCoverageInfoForPipelineRun,
} from '../../ngrx/store/code-coverage-info/code-coverage-info.selectors';
import {
  selectIsCodeCoverageSummaryLoading,
  selectIsCodeCoverageSummaryLoadingError,
  selectIsCodeCoverageSummaryLoadingNotFound,
  selectIsPipelineRunCodeCoverageLoadingError,
  selectIsPipelineRunCodeCoverageLoadingNotFound,
  selectIsPipelineRunCoverageInfoLoading,
} from '../../ngrx/store/loading-state/loading-state.selectors';
import { GroupComplianceMode } from '../../shared/model/group-compliance-mode';
import { thresholds } from '../../shared/components/coverage-info/coverage-info.component';
import { CoverageDetailsDialogComponent } from './coverage-details-dialog/coverage-details-dialog.component';

@Component({
  selector: 'app-list-item-kpis-coverage',
  templateUrl: './list-item-kpis-coverage.component.html',
  styleUrls: ['./list-item-kpis-coverage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemKpisCoverageComponent implements OnInit, OnChanges {
  public isLoading$!: Observable<boolean>;
  public isLoadingError$!: Observable<boolean>;
  public isLoadingNotFound$!: Observable<boolean>;
  public coverageInfo$: Observable<CodeCoverageInfo>;

  @Input() pipelineRunId: number;
  @Input() groupId: string;
  @Input() groupComplianceMode: GroupComplianceMode;

  @Input()
  threshold;

  @Input()
  text;
  gaugeType = 'arch' as NgxGaugeType;
  thresholds = thresholds;

  constructor(
    private readonly store$: Store,
    private readonly dialog: MatDialog,
  ) {}

  ngOnInit() {
    if (!this.pipelineRunId) {
      this.isLoading$ = this.store$.select(selectIsCodeCoverageSummaryLoading);
      this.isLoadingError$ = this.store$.select(selectIsCodeCoverageSummaryLoadingError);
      this.isLoadingNotFound$ = this.store$.select(selectIsCodeCoverageSummaryLoadingNotFound);
      this.coverageInfo$ = this.store$.select(selectCodeCoverageGroupSummary);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.pipelineRunId) {
      this.loadPipelineRunInfo();
    }
  }

  loadPipelineRunInfo() {
    this.coverageInfo$ = this.store$.select(selectCoverageInfoForPipelineRun(this.pipelineRunId));
    this.isLoading$ = this.store$.select(selectIsPipelineRunCoverageInfoLoading(this.pipelineRunId));
    this.isLoadingError$ = this.store$.select(selectIsPipelineRunCodeCoverageLoadingError(this.pipelineRunId));
    this.isLoadingNotFound$ = this.store$.select(selectIsPipelineRunCodeCoverageLoadingNotFound(this.pipelineRunId));
  }

  reloadInfo() {
    if (!this.pipelineRunId) {
      this.store$.dispatch(loadCodeCoverageGroupSummary({ deliveryId: this.groupId, groupComplianceMode: this.groupComplianceMode }));
    } else {
      this.store$.dispatch(loadCodeCoverageForPipelineRun({ pipelineRunId: this.pipelineRunId }));
    }
  }

  public onViewDetailsClicked(): void {
    if (!this.pipelineRunId) {
      const data = { deliveryId: this.groupId, groupComplianceMode: this.groupComplianceMode };
      this.store$.dispatch(loadCodeCoverageGroupDetails(data));
      this.dialog.open(CoverageDetailsDialogComponent, { data });
    }
  }
}
