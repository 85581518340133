<div class="dialogHeader">
  <p class="dialogTitle">Regression Tests (FC-3)</p>
  <mat-icon aria-hidden="false" aria-label="cancel" class="closeButton" (click)="closeDialog()" data-test-id="fc-3-detail-popup-close-btn"
    >cancel
  </mat-icon>
</div>

<div
  *ngIf="{
    isLoading: isLoading$ | async,
    isLoadingError: isLoadingError$ | async,
    dataSource: dataSource$ | async,
  } as data"
  class="flex flex-col"
  mat-dialog-content
>
  <div class="flex h-full w-full">
    <app-dialog-error-information (reloadClicked)="reload()" *ngIf="data?.isLoadingError"></app-dialog-error-information>
    <kendo-grid
      class="flex h-full w-full"
      id="fc3-detail-grid"
      data-test-id="fc-3-detail-popup-grid"
      *ngIf="!data?.isLoadingError"
      [data]="dataSource"
      [loading]="data.isLoading"
      [sortable]="true"
      [sort]="gridState.sort"
      [filterable]="true"
      [filter]="gridState.filter"
      (dataStateChange)="dataStateChanged($event)"
    >
      <kendo-grid-column field="pipelineKey" title="Pipeline" width="44%">
        <ng-template kendoGridCellTemplate kendoTooltip let-dataItem>
          <app-kpi-column-pipeline [item]="dataItem" fusionFeatureUsageTracker="open-fc3-pipeline-url"></app-kpi-column-pipeline>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="success"
        title="Success"
        width="12%"
        class="text-align-right"
        headerClass="text-align-right"
        [filterable]="false"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <span *ngIf="dataItem.complianceInformationAvailable">{{ dataItem.success }}</span>
          <span *ngIf="!dataItem.complianceInformationAvailable">&mdash;</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="skipped"
        title="Skipped"
        width="12%"
        class="text-align-right"
        headerClass="text-align-right"
        [filterable]="false"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <span *ngIf="dataItem.complianceInformationAvailable">{{ dataItem.skipped }}</span>
          <span *ngIf="!dataItem.complianceInformationAvailable">&mdash;</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="error"
        title="Failed"
        width="12%"
        class="text-align-right"
        headerClass="text-align-right"
        [filterable]="false"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <span *ngIf="dataItem.complianceInformationAvailable">{{ dataItem.error }}</span>
          <span *ngIf="!dataItem.complianceInformationAvailable">&mdash;</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="complianceStatus.displayKey" title="Status" width="20%">
        <ng-template kendoGridCellTemplate kendoTooltip let-dataItem>
          <app-kpi-column-compliance-status [item]="dataItem" dataTestId="fc-3-detail-popup-grid"></app-kpi-column-compliance-status>
        </ng-template>
      </kendo-grid-column>

      <ng-template kendoGridLoadingTemplate>
        <app-table-loading-indicator></app-table-loading-indicator>
      </ng-template>
    </kendo-grid>
  </div>
</div>
