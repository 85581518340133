import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Delivery } from '../../../model/group/group';
import { DeliveryFixVersion } from '../../../model/jira/delivery-fix-version.model';
import { GroupComplianceMode } from '../../../shared/model/group-compliance-mode';

export const loadSiriusDelivery = createAction(`[Deliveries] Trigger loading of Sirius Delivery Group`, props<{ deliveryGuid: string }>());
export const loadSiriusDeliverySuccessful = createAction(
  `[Deliveries] Loading Sirius Delivery Group was successful`,
  props<{ delivery: Delivery }>(),
);
// Complete reload of the current group, use e.g. if pipeline is added / removed
export const reloadGroup = createAction(`[Deliveries] Loading Sirius Delivery Group was successful and reload group code compliance`);

// Only use 'reloadGroupCodeCompliance' if the list of pipelines is not modified
export const reloadGroupCodeCompliance = createAction(`[Deliveries] Reload group code compliance`);

export const loadSiriusDeliveryFailed = createAction(
  `[Deliveries] Loading Sirius Delivery Group failed`,
  props<{ error: HttpErrorResponse }>(),
);

export const setSelectedDelivery = createAction(`[Deliveries] Set selected Delivery`, props<{ delivery: Delivery }>());

export const setSelectedComplianceShowMode = createAction(
  `[Deliveries] Set selected compliance show mode`,
  props<{ groupComplianceMode: GroupComplianceMode }>(),
);

export const loadDeliveryFixVersions = createAction(
  `[Deliveries] Trigger loading of Delivery FixVersions`,
  props<{ deliveryGuid: string }>(),
);
export const loadDeliveryFixVersionsSuccessful = createAction(
  `[Deliveries] Loading Delivery FixVersions was successful`,
  props<{ deliveryFixVersions: DeliveryFixVersion[] }>(),
);

export const loadDeliveryFixVersionsEmpty = createAction(`[Deliveries] Loading Delivery FixVersions with empty result`);

export const loadDeliveryFixVersionsFailed = createAction(
  `[Deliveries] Loading Delivery FixVersions failed`,
  props<{ error: HttpErrorResponse }>(),
);
