import { Component, OnInit } from '@angular/core';
import { BannerService } from '../shared/banner-service/banner.service';

@Component({
  selector: 'app-announcement-banner',
  templateUrl: './announcement-banner.component.html',
  styleUrls: ['./announcement-banner.component.scss'],
})
export class AnnouncementBannerComponent implements OnInit {
  surveyUrl: string;
  deactivationDate: Date;
  maintenanceStartDate: Date;
  maintenanceEndDate: Date;
  constructor(private readonly bannerService: BannerService) {}

  ngOnInit(): void {
    this.surveyUrl = this.bannerService.getSurveyUrl();
    this.deactivationDate = this.bannerService.getDeactivationDate();
    this.maintenanceStartDate = this.bannerService.getMaintenanceStartDate();
    this.maintenanceEndDate = this.bannerService.getMaintenanceEndDate();
  }

  closeBanner(): void {
    this.bannerService.closeBanner();
  }
}
