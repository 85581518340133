import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ConfigurationSlice } from '../app-state';

export const selectConfigurationSlice = createFeatureSelector<ConfigurationSlice>('configurationSlice');

export const selectConfigurationForPipeline = (pipelineId: string) =>
  createSelector(selectConfigurationSlice, (configurationSlice) => {
    return configurationSlice.configurations[pipelineId];
  });

export const selectJiraComponentPipelineMappingForPipeline = (pipelineId: string) =>
  createSelector(selectConfigurationForPipeline(pipelineId), (pipelineConfiguration) => {
    return pipelineConfiguration?.jiraComponentPipelineMappings;
  });

export const selectJiraComponentMappingsForPipelines = createSelector(selectConfigurationSlice, (configurationSlice) => {
  return configurationSlice.jiraComponentMappingsForPipelines;
});

export const selectIsComponentMappingActive = (pipelineId: string) =>
  createSelector(selectJiraComponentPipelineMappingForPipeline(pipelineId), (jiraComponentPipelineMappings) => {
    return !!jiraComponentPipelineMappings;
  });

export const selectCoverageThresholdConfigurationForPipeline = (pipelineId: string) =>
  createSelector(selectConfigurationForPipeline(pipelineId), (pipelineConfiguration) => {
    return mapConfiguration(pipelineConfiguration);
  });

const mapConfiguration = (configuration) => {
  return configuration?.coverageThresholds
    ? Object.keys(configuration.coverageThresholds).map((value) => {
        return mapCoverageConfiguration({
          coverageType: +value,
          threshold: JSON.parse(configuration.coverageThresholds[value]),
        });
      })
    : [];
};

/**
 * Coverage configuration retrieves as {'0': 'color', '50': 'color2', '100': 'color3'}.
 * This method maps the given config param thresholds branch and line rate property to work with the gauges chart
 * mapping to format:  {'0': {color: 'color'}, '50': {color: 'color2'}, '100': {color: 'color3'}}
 * @param config which contains the branch and line rate threshold. Property will be mapped and config param will be returned
 */
const mapCoverageConfiguration = (config) => {
  for (const root of Object.keys(config.threshold)) {
    for (const rates of Object.keys(config.threshold[root])) {
      config.threshold[root][rates] = { color: config.threshold[root][rates] };
    }
  }
  return config;
};
