import { createReducer, on } from '@ngrx/store';
import { PipelineRunSlice } from '../app-state';
import { initialPipelineRunSlice } from '../initial-slices';
import {
  deletePipelineRunSuccessful,
  setPipelineRunsForPipeline,
  setSelectedPipelineRun,
  setSelectedPipelineRunOnlyInStore,
} from './pipeline-run.actions';

export const pipelineRunReducer = createReducer(
  initialPipelineRunSlice,
  on(setPipelineRunsForPipeline, (state, props): PipelineRunSlice => {
    const pipelineRunsState = { ...state.pipelineRuns };
    pipelineRunsState[props.pipelineId] = props.pipelineRuns;
    return { ...state, pipelineRuns: pipelineRunsState };
  }),
  on(setSelectedPipelineRun, setSelectedPipelineRunOnlyInStore, (state, props): PipelineRunSlice => {
    const selectedPipelineRunState = { ...state.selectedPipelineRun };
    selectedPipelineRunState[props.pipelineId] = props.selectedPipelineRunId;
    return { ...state, selectedPipelineRun: selectedPipelineRunState };
  }),
  on(deletePipelineRunSuccessful, (state, props): PipelineRunSlice => {
    const pipelineRunsState = { ...state.pipelineRuns };
    const selectedPipelineRunState = { ...state.selectedPipelineRun };
    const pipelineId = props.pipelineId;
    const pipelineRunId = props.pipelineRunId;
    pipelineRunsState[pipelineId] = pipelineRunsState[pipelineId].filter((pipelineRun) => pipelineRun.id !== pipelineRunId);
    selectedPipelineRunState[pipelineId] = pipelineRunsState[pipelineId][0]?.id;
    return { ...state, pipelineRuns: pipelineRunsState, selectedPipelineRun: selectedPipelineRunState };
  }),
);
