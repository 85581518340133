import { createAction, props } from '@ngrx/store';
import { PipelineRunDocument } from '../../../model/documents/pipeline-run-document.model';

export const loadPipelineRunDocuments = createAction(`[Documents] Load Documents for pipeline run`, props<{ pipelineRunId: number }>());

export const setPipelineRunDocuments = createAction(
  `[Documents] Set Documents for pipeline run`,
  props<{ pipelineRunId: number; documents: PipelineRunDocument[] }>(),
);

// Errors & NOT FOUND
export const loadPipelineRunDocumentsError = createAction(
  `[Documents] Load Documents for pipeline run failed`,
  props<{ pipelineRunId: number }>(),
);

export const loadPipelineRunDocumentsNotFound = createAction(
  `[Documents] Load Documents for pipeline run not found`,
  props<{ pipelineRunId: number }>(),
);
