import { createAction, props } from '@ngrx/store';
import { ReleaseStatusKey } from '../../../model/release-status/release-status';
import { PipelineRun } from '../../../shared/access-api/access-api.service';

export const pipelineRunKey = 'pipeline-run';

export const loadPipelineRunsForPipeline = createAction(
  `[${pipelineRunKey}] Trigger loading of pipeline runs for pipeline from backend`,
  (pipelineId: string, groupId: string, updateSelectedPipelineRun = true) => ({
    pipelineId,
    groupId,
    updateSelectedPipelineRun,
  }),
);

export const setPipelineRunsForPipeline = createAction(
  `[${pipelineRunKey}] Set pipeline runs for pipeline`,
  (pipelineId: string, pipelineRuns: PipelineRun[], updateSelectedPipelineRun = true) => ({
    pipelineId,
    pipelineRuns,
    updateSelectedPipelineRun,
  }),
);

export const setSelectedPipelineRun = createAction(
  `[${pipelineRunKey}] Set selected pipeline run for pipeline`,
  (pipelineId: string, selectedPipelineRunId: number) => ({ pipelineId, selectedPipelineRunId }),
);

export const setSelectedPipelineRunOnlyInStore = createAction(
  `[${pipelineRunKey}] Set selected pipeline run for pipeline only in store`,
  (pipelineId: string, selectedPipelineRunId: number) => ({ pipelineId, selectedPipelineRunId }),
);

export const loadPipelineRunsForPipelineFailed = createAction(
  `[${pipelineRunKey}] Loading of pipeline runs for pipeline from backend failed`,
  props<{ pipelineId: string }>(),
);

export const deletePipelineRun = createAction(
  `[${pipelineRunKey}] Deleting pipeline run for pipeline`,
  props<{ pipelineRunId: number; pipelineId: string; pipelineRunKey: string; isLatestPipelineRun: boolean }>(),
);

export const deletePipelineRunSuccessful = createAction(
  `[${pipelineRunKey}] Deleted pipeline run for pipeline`,
  props<{ pipelineRunId: number; pipelineId: string; isLatestPipelineRun: boolean }>(),
);

export const deletePipelineRunFailed = createAction(
  `[${pipelineRunKey}] Deletion of pipeline run for pipeline failed`,
  props<{ pipelineRunId: number; pipelineId: string }>(),
);

export const setNewReleaseStatus = createAction(
  `[${pipelineRunKey}] Set new release status of pipeline run for group`,
  props<{
    pipelineId: string;
    pipelineRunId: number;
    pipelineRunKey: string;
    groupId: string;
    groupKey: string;
    groupName: string;
    isMicrodelivery: boolean;
    releaseStatus: ReleaseStatusKey;
  }>(),
);

export const setNewReleaseStatusIfPossible = createAction(
  `[${pipelineRunKey}] Set new release status of pipeline run for group if possible`,
  props<{
    pipelineId: string;
    pipelineRunId: number;
    pipelineRunKey: string;
    groupId: string;
    groupKey: string;
    groupName: string;
    isMicrodelivery: boolean;
    releaseStatus: ReleaseStatusKey;
  }>(),
);

export const setNewReleaseStatusSuccessful = createAction(
  `[${pipelineRunKey}] Set new release status of pipeline run for group successfully`,
  props<{ pipelineId: string; pipelineRunId: number; groupId: string; releaseStatus: ReleaseStatusKey }>(),
);

export const setNewReleaseStatusFailed = createAction(
  `[${pipelineRunKey}] Set new release status of pipeline run for group failed`,
  props<{ pipelineId: string; pipelineRunId: number; groupId: string; releaseStatus: ReleaseStatusKey }>(),
);

export const revertReleaseStatusIfPossible = createAction(
  `[${pipelineRunKey}] Revert release status of pipeline run for group if possible`,
  props<{
    pipelineId: string;
    pipelineRunId: number;
    pipelineRunKey: string;
    groupId: string;
    groupKey: string;
    groupName: string;
    isMicrodelivery: boolean;
    microdeliveryIds: string[];
    releaseStatus: ReleaseStatusKey;
    justification?: string;
  }>(),
);

export const revertReleaseStatus = createAction(
  `[${pipelineRunKey}] Revert release status of pipeline run for group`,
  props<{
    pipelineId: string;
    pipelineRunId: number;
    pipelineRunKey: string;
    groupId: string;
    groupKey: string;
    groupName: string;
    isMicrodelivery: boolean;
    releaseStatus: ReleaseStatusKey;
    justification?: string;
  }>(),
);

export const revertReleaseStatusSuccessfulId = `[${pipelineRunKey}] Revert release status of pipeline run for group was successfully`;

export const revertReleaseStatusSuccessful = createAction(
  revertReleaseStatusSuccessfulId,
  props<{ pipelineId: string; pipelineRunId: number; groupId: string; releaseStatus: ReleaseStatusKey; justification?: string }>(),
);

export const revertReleaseCandidateStatusFailed = createAction(
  `[${pipelineRunKey}] Revert release status of pipeline run for group failed`,
  props<{ pipelineId: string; pipelineRunId: number; groupId: string; releaseStatus: ReleaseStatusKey; justification?: string }>(),
);
