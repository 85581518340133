import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SonarQubeCoverageSlice } from '../app-state';

export const selectSonarQubeCoverageSlice = createFeatureSelector<SonarQubeCoverageSlice>('sonarQubeCoverageSlice');

export const selectSonarQubeCoverageGroupSummary = createSelector(selectSonarQubeCoverageSlice, (sonarQubeCoverageSlice) => {
  return sonarQubeCoverageSlice.groupSummary;
});

export const selectSonarQubeCoverageGroupDetails = createSelector(selectSonarQubeCoverageSlice, (sonarQubeCoverageSlice) => {
  return sonarQubeCoverageSlice.groupDetails;
});

export const selectSonarQubeCoverageForPipelineRun = (pipelineRunId: number) =>
  createSelector(selectSonarQubeCoverageSlice, (sonarQubeCoverageSlice) => {
    return sonarQubeCoverageSlice.selectedPipelineRuns[pipelineRunId];
  });

export const selectHasPipelineRunSonarQubeCoverage = (pipelineRunId: number) =>
  createSelector(selectSonarQubeCoverageForPipelineRun(pipelineRunId), (coverageDataForPipelineRun) => {
    return !(
      (coverageDataForPipelineRun?.lineCoverage === null || coverageDataForPipelineRun?.lineCoverage === undefined) &&
      (coverageDataForPipelineRun?.branchCoverage === null || coverageDataForPipelineRun?.branchCoverage === undefined)
    );
  });
