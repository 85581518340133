<div class="flex flex-col h-full w-full overflow-hidden">
  <div class="dialogHeader space-between">
    <div class="flex">
      <fusion-icon
        *ngIf="isSelected"
        class="backButton"
        name="chevron-left"
        size="XL"
        (click)="revertReleasedStatusDeselected()"
        fusionFeatureUsageTracker="revert-confirmation-dialog-back-icon"
      >
      </fusion-icon>
      <span class="dialogTitle">{{ !isSelected ? 'Change Current State' : 'Change Current State | Revert State Released' }}</span>
    </div>
    <mat-icon
      aria-hidden="false"
      aria-label="cancel"
      class="closeButton"
      fusionFeatureUsageTracker="change-pipeline-run-release-status-dialog-close-btn"
      (click)="closeDialog()"
      >cancel</mat-icon
    >
  </div>
  <div class="tabContainer flex flex-row" [@tabSwitch]="viewMode">
    <app-change-current-state-dialog
      id="one"
      [data]="dialogData"
      class="w-full flex flex-col"
      (revertReleasedStatusSelect)="revertReleasedStatusSelected()"
      (closeDialog)="closeDialog()"
    ></app-change-current-state-dialog>
    <app-revert-confirmation-dialog
      id="two"
      class="w-full flex flex-col"
      [data]="dialogData"
      (closeDialog)="closeDialog()"
      (cancelDialog)="revertReleasedStatusDeselected()"
    ></app-revert-confirmation-dialog>
  </div>
</div>
