import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { ProductStandard, ProductStandards } from '../model/product-standard';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ProductStandardDeprecationService {
  // TODO should we use german date format
  private readonly DATE_FORMAT = 'dd.MM.yyyy';

  constructor(@Inject(LOCALE_ID) private locale: string) {}

  isProductStandardDeprecated(productStandard: ProductStandard): boolean {
    return productStandard.isDeprecated();
  }

  isProductStandardEndOfVisualisation(productStandardKey: ProductStandard): boolean {
    return productStandardKey.isEndOfVisualisation();
  }

  getFormattedDeprecationAndVisualisationDates(): Record<string, string> {
    const deprecationDates = ProductStandards.filter((ps) => !!ps.deprecationDate).map(
      (ps) => [ps.key + '-Deprecation', this.getFormattedDeprecationDate(ps)] as const,
    );
    const endOfVisualisationDates = ProductStandards.filter((ps) => !!ps.endOfVisualisationDate).map(
      (ps) => [ps.key + '-EndOfVisualisation', this.getFormattedEndOfVisualisationDate(ps)] as const,
    );
    const dates = [...deprecationDates, ...endOfVisualisationDates];
    return this.objectFromEntries(dates);
  }

  private getFormattedDeprecationDate(productStandard: ProductStandard): string {
    return formatDate(productStandard.deprecationDate, this.DATE_FORMAT, this.locale);
  }

  private getFormattedEndOfVisualisationDate(productStandard: ProductStandard): string {
    return formatDate(productStandard.endOfVisualisationDate, this.DATE_FORMAT, this.locale);
  }

  // Object.fromEntries is not supported with target library es2018
  private objectFromEntries<T>(entries: (readonly [string, T])[]): Record<string, T> {
    return entries.reduce((obj, [key, val]) => {
      obj[key] = val;
      return obj;
    }, {});
  }
}
