export interface EnvironmentData {
  parentId?: string;
  production: boolean;
  deliveryGuid?: string;
  deliveryName?: string;
  activationDate?: Date;
  isLocked?: boolean;
  baseUrl?: string;
  readOnly?: boolean;
  hyperspace?: string;
  stage?: string;
  matomoApplicationId: number;
  jiraUrl?: string;
  xRayStatusFieldKey?: string;
  issueTypeIdMicroDelivery?: number;
  microDeliveryServiceBaseUrl?: string;
  gcpStorageBaseUrl?: string;
  deliveryProcessingStatus?: DeliveryProcessingStatus;
}

export enum DeliveryProcessingStatus {
  NEW = 'New',
  ACTIVE = 'Active',
  FINISHED = 'Finished',
}

export interface ParentMessage {
  baseUrl: string;
  deliveryGuid: string;
  readOnly: boolean;
  deliveryName: string;
  isLocked: boolean;
  activationDate: Date;
}
