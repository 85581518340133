import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CodeCoverageInfo, CodeCoverageSummary } from '../../model/code-coverage/code-coverage.model';
import { PipelineRunDocument } from '../../model/documents/pipeline-run-document.model';
import { PipelineRunRequirement } from '../../model/pipeline-run/pipeline-run';
import { RequirementTestcaseResult } from '../../model/requirement-detail/requirement-detail-testcase';
import { ApiMetadataValidatorDetails } from '../../ngrx/model/api-metadata-validator';
import { AtcInfoSummary } from '../../ngrx/model/atc';
import { FosstarsDetails, FosstarsSummaryDetail } from '../../ngrx/model/fosstars';
import { MalwarescanDetails } from '../../ngrx/model/malwarescan';
import { EnvironmentService } from '../environment.service';

@Injectable({
  providedIn: 'root',
})
export class AccessPipelineRunService {
  constructor(
    private readonly http: HttpClient,
    private readonly envService: EnvironmentService,
  ) {}

  getPipelineRunRequirements(pipelineRunId: number): Observable<PipelineRunRequirement[]> {
    return this.http.get<PipelineRunRequirement[]>(`${this.pipelineRunUrl(pipelineRunId, '')}/requirements`);
  }

  getPipelineRunTestcases(pipelineRunId: number, requirementKeys: string[]): Observable<RequirementTestcaseResult[]> {
    return this.http
      .post<RequirementTestcaseResult[]>(`${this.pipelineRunUrl(pipelineRunId)}/requirements/testcases`, { requirementKeys })
      .pipe(
        map((testcases) =>
          testcases.map((testcase) => ({
            ...testcase,
            // FIXME: Backend (access-api) should provide the gitSearchUrl
            gitSearchUrl: `${testcase.gitUrl}/find/${testcase.gitCommit}`,
          })),
        ),
      );
  }

  getPipelineRunFosstarRatingSummary(pipelineRunId: number) {
    return this.http.get<FosstarsSummaryDetail[]>(`${this.pipelineRunUrl(pipelineRunId)}/fosstars-ratings-summary`);
  }

  getPipelineRunFosstarRatingDetails(pipelineRunId: number) {
    return this.http.get<FosstarsDetails[]>(`${this.pipelineRunUrl(pipelineRunId)}/fosstars-ratings-details`);
  }

  getPipelineRunCodeCoverage(pipelineRunId: number): Observable<CodeCoverageInfo | null> {
    return this.http.get<CodeCoverageSummary>(`${this.pipelineRunUrl(pipelineRunId)}/code-coverage-summary`).pipe(
      map((summary: CodeCoverageSummary) => {
        if (summary) {
          return {
            lineRate: summary.aggregatedLineRate,
            branchRate: summary.aggregatedBranchRate,
          };
        }
        return undefined;
      }),
    );
  }

  getPipelineRunMalwarescanInfo(pipelineRunId: number): Observable<MalwarescanDetails> {
    return this.http.get<MalwarescanDetails>(`${this.pipelineRunUrl(pipelineRunId)}/malwarescan`);
  }

  getPipelineRunApiMetadataValidatorInfo(pipelineRunId: number): Observable<ApiMetadataValidatorDetails> {
    return this.http.get<ApiMetadataValidatorDetails>(`${this.pipelineRunUrl(pipelineRunId)}/api-metadata-validator-summary`);
  }

  getPipelineRunDocuments(pipelineRunId: number): Observable<PipelineRunDocument[]> {
    return this.http.get<PipelineRunDocument[]>(`${this.pipelineRunUrl(pipelineRunId)}/documents`);
  }

  getPipelineRunComplianceCertificates(pipelineRunId: number): Observable<PipelineRunDocument[]> {
    return this.http.get<PipelineRunDocument[]>(`${this.pipelineRunUrl(pipelineRunId)}/compliance-certificates`);
  }

  getPipelineRunAtc(pipelineRunId: number): Observable<AtcInfoSummary> {
    return this.http.get<AtcInfoSummary[]>(`${this.pipelineRunUrl(pipelineRunId)}/atc`).pipe(
      map((res) => {
        return res[0];
      }),
    );
  }

  private pipelineRunUrl(pipelineRunId?: number, version = 'v3/'): string {
    return `https://${this.envService.getBaseUrl()}/zprs/gcp/access-api/api/${version}pipelineRuns/${pipelineRunId}`;
  }
}
