<div class="dialogHeader">
  <p class="dialogTitle">{{ 'kpi.tile.documents.title' | translate }}</p>
  <mat-icon
    aria-hidden="false"
    aria-label="cancel"
    class="closeButton"
    (click)="closeDialog()"
    data-test-id="documents-detail-popup-close-btn"
    >cancel
  </mat-icon>
</div>
<div
  mat-dialog-content
  class="flex flex-col"
  *ngIf="{
    gridData: gridData$ | async,
    isLoading: isPipelineRunCumulusDocumentsLoading$ | async,
    isLoadingError: isPipelineRunCumulusDocumentsLoadingError$ | async,
  } as data"
>
  <div class="flex h-full w-full">
    <app-dialog-error-information (reloadClicked)="reload()" *ngIf="data?.isLoadingError"></app-dialog-error-information>
    <kendo-grid
      *ngIf="!data.isLoadingError"
      (dataStateChange)="gridDataStateChange($event)"
      [data]="data.gridData"
      [filter]="gridState.filter"
      [filterable]="true"
      [selectable]="true"
      [resizable]="true"
      [sort]="gridState.sort"
      [sortable]="true"
      [loading]="data.isLoading"
      class="flex h-full w-full"
      [trackBy]="trackByStepResultId"
      data-test-id="pipeline-documents-detail-list"
    >
      <kendo-grid-column field="stepResultTypeName" title="File Type">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="flex flex-row justify-start items-center gap-1">
            <fusion-icon *ngIf="dataItem.isUnprocessed" name="exclamation-triangle-outline" class="warning"></fusion-icon>
            <span data-test-id="document-type-names">{{ dataItem.stepResultTypeName || '&mdash;' }}</span>
          </div>
        </ng-template>
      </kendo-grid-column>
      <!-- To be removed together with document-coverage service -->
      <kendo-grid-column [filterable]="false" title="Coverage" field="isCoverageType">
        <ng-template kendoGridCellTemplate let-dataItem>
          <!-- size property to make the gauges responsive: (column.width - <50 = column padding>) / 2 -->
          <app-coverage-info
            *ngIf="dataItem.isCoverageType"
            [coverageInfo]="dataItem.getInfo | async"
            [size]="80"
            [fusionFeatureUsageTrackerEvents]="['click']"
            fusionFeatureUsageTracker="click-document-file-coverage"
          >
          </app-coverage-info>
          <span *ngIf="!dataItem.isCoverageType">&mdash;</span>
          <p *ngIf="dataItem.isCoverageType && dataItem.error">Error loading result of file</p>
          <app-loading *ngIf="dataItem.isCoverageType && !dataItem.error && dataItem.loading"></app-loading>
        </ng-template>
      </kendo-grid-column>
      <!-- End coverage stuff -->
      <kendo-grid-column title="Sub-Path" field="subPath">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{ dataItem.subPath ? dataItem.subPath + '/' : '&mdash;' }}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Last Modified" field="fileUploadedAt" [filterable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span *ngIf="dataItem.fileUploadedAt">{{ dataItem.fileUploadedAt | date: 'MMM d, y hh:mm:ss a' }}</span>
          <span *ngIf="!dataItem.fileUploadedAt">&mdash;</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="File State" field="isImmutable" [filterable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div>
            <div *ngIf="dataItem.isImmutable === undefined">&mdash;</div>
            <div *ngIf="dataItem.isImmutable">
              <div class="flex flex-row justify-start items-center gap-1">
                <fusion-icon name="lock-close" class="primary" size="M"></fusion-icon>
                <span fusionTooltip="File can't be overwritten.">immutable </span>
                <fusion-icon
                  *ngIf="dataItem.setImmutableAt"
                  name="clock-outline"
                  [fusionTooltip]="'since ' + (dataItem.setImmutableAt | date: 'MMM d, y hh:mm:ss a')"
                  size="S"
                  fusionFeatureUsageTracker="immutable-date-icon"
                  [fusionFeatureUsageTrackerEvents]="['mouseover']"
                ></fusion-icon>
              </div>
              <div *ngIf="dataItem.setImmutableByReleaseStatus" class="text-small text-neutral">
                <span
                  >since status <span class="text-bold">{{ dataItem.setImmutableByReleaseStatus }}</span></span
                >
              </div>
              <div
                *ngIf="dataItem.setImmutableByProductStandards && dataItem.setImmutableByProductStandards.length"
                class="text-small text-neutral"
              >
                <span
                  >because of relevance for <span class="text-bold">{{ dataItem.setImmutableByProductStandards.join(', ') }}</span></span
                >
              </div>
            </div>
            <div *ngIf="!dataItem.isImmutable && dataItem.isImmutable !== undefined" class="flex flex-row justify-start items-center gap-1">
              <fusion-icon name="lock-open-outline" class="primary" size="M"></fusion-icon>
              <span fusionTooltip="File can be overwritten.">mutable</span>
            </div>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="File name" field="fileName">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="flex flex-row justify-start items-center gap-1">
            <a
              *ngIf="!dataItem.isUnprocessed && (dataItem.stepResultId || dataItem.stepResultTypeId); else fileName"
              [fusionTooltip]="'kpi.tile.documents.details.tooltip.download' | translate"
              [download]="dataItem.fileName"
              [href]="buildFileLinkUrl(dataItem)"
              target="_blank"
              [fusionFeatureUsageTrackerEvents]="['click']"
              fusionFeatureUsageTracker="download-pipeline-run-document"
              rel="noopener noreferrer"
            >
              <ng-template [ngTemplateOutlet]="fileName"></ng-template>
              <fusion-icon name="download" size="S"></fusion-icon>
            </a>
            <ng-template #fileName>
              <span class="document-filename">
                {{ dataItem.fileName || '&mdash;' }}
              </span>
            </ng-template>
            <span class="warning" *ngIf="dataItem.message" [fusionTooltip]="dataItem.message" fusionTooltipPosition="bottom-left">
              <fusion-icon name="exclamation-circle-outline" size="M"></fusion-icon>
            </span>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column *ngIf="isOptimizedPipeline" title="Pipeline Run" field="pipelineRunKey">
        <ng-template kendoGridCellTemplate let-dataItem>
          <app-optimized-pipeline-run-info
            *ngIf="!dataItem.isUnprocessed"
            [optimizedPipelineRun]="dataItem"
          ></app-optimized-pipeline-run-info>
          <div *ngIf="dataItem.isUnprocessed">&mdash;</div>
        </ng-template>
      </kendo-grid-column>
      <ng-template kendoGridLoadingTemplate>
        <app-table-loading-indicator></app-table-loading-indicator>
      </ng-template>
    </kendo-grid>
  </div>
</div>
