<div class="dialogHeader">
  <span class="dialogTitle">{{ data.title }}</span>
</div>

<div class="dialogContent">
  <ng-container *ngTemplateOutlet="data.content"></ng-container>
</div>
<div class="dialogActions flex gap-2.5 justify-end items-center">
  <button [mat-dialog-close]="result.NEGATIVE" fusionButton data-test-id="confirmation-dialog-cancel-btn" fusionButtonKind="outline">
    {{ data.negativeButtonText }}
  </button>
  <button [mat-dialog-close]="result.POSITIVE" fusionButton data-test-id="confirmation-dialog-confirm-btn" fusionButtonKind="filled">
    {{ data.positiveButtonText }}
  </button>
</div>
