import { createSelector } from '@ngrx/store';
import { AppState } from '../app-state';
import { LoadingStateSlice } from './loading-state.model';

export const selectLoadingState = (state: AppState): LoadingStateSlice => state.loadingStateSlice;
// SLC-25
export const selectSLC25Loading = createSelector(selectLoadingState, (loadingState) => loadingState.slc25);
export const selectIsSLC25GroupSummaryLoading = createSelector(
  selectSLC25Loading,
  (loadingState) => loadingState.groupSummary === 'loading',
);
export const selectIsSLC25SingleLoading = (pipelineRunId: number) =>
  createSelector(selectSLC25Loading, (loadingState) => loadingState.singleDetails[pipelineRunId] === 'loading');
export const selectIsSLC25GroupDetailsLoading = createSelector(
  selectSLC25Loading,
  (loadingState) => loadingState.groupDetails === 'loading',
);
export const selectIsSLC25GroupDetailsLoadingError = createSelector(
  selectSLC25Loading,
  (loadingState) => loadingState.groupDetails === 'error',
);
export const selectIsSLC25GroupSummaryLoadingError = createSelector(
  selectSLC25Loading,
  (loadingState) => loadingState.groupSummary === 'error',
);
export const selectIsSLC25GroupSummaryLoadingNotFound = createSelector(
  selectSLC25Loading,
  (loadingState) => loadingState.groupSummary === 'not-found',
);
export const selectIsSLC25SingleLoadingError = (pipelineRunId: number) =>
  createSelector(selectSLC25Loading, (loadingState) => loadingState.singleDetails[pipelineRunId] === 'error');
export const selectIsSLC25SingleLoadingNotFound = (pipelineRunId: number) =>
  createSelector(selectSLC25Loading, (loadingState) => loadingState.singleDetails[pipelineRunId] === 'not-found');

// PSL-1
export const selectPsl1Loading = createSelector(selectLoadingState, (loadingState) => loadingState.psl1);
export const selectIsPsl1SingleLoading = (pipelineRunId: number) =>
  createSelector(selectPsl1Loading, (loadingState) => loadingState.singleDetails[pipelineRunId] === 'loading');
export const selectIsPsl1SummaryLoading = createSelector(selectPsl1Loading, (loadingState) => loadingState.groupSummary === 'loading');
export const selectIsPsl1DetailsLoading = createSelector(selectPsl1Loading, (loadingState) => loadingState.groupDetails === 'loading');
export const selectIsPsl1DetailsLoadingError = createSelector(selectPsl1Loading, (loadingState) => loadingState.groupDetails === 'error');
export const selectIsPsl1SummaryLoadingError = createSelector(selectPsl1Loading, (loadingState) => loadingState.groupSummary === 'error');
export const selectIsPsl1SummaryLoadingNotFound = createSelector(selectPsl1Loading, (loadingState) => {
  return loadingState.groupSummary === 'not-found';
});
export const selectIsPsl1SingleLoadingError = (pipelineRunId: number) =>
  createSelector(selectPsl1Loading, (loadingState) => loadingState.singleDetails[pipelineRunId] === 'error');

export const selectIsPsl1SingleLoadingNotFound = (pipelineRunId: number) =>
  createSelector(selectPsl1Loading, (loadingState) => loadingState.singleDetails[pipelineRunId] === 'not-found');

// FC-1
export const selectFc1Loading = createSelector(selectLoadingState, (loadingState) => loadingState.fc1);
export const selectFc1SummaryLoading = createSelector(selectFc1Loading, (loadingState) => loadingState.groupSummary);

export const selectIsFc1SingleLoading = (pipelineRunId: number) =>
  createSelector(selectFc1Loading, (loadingState) => loadingState.singleDetails[pipelineRunId] === 'loading');
export const selectIsFc1SummaryLoading = createSelector(selectFc1Loading, (loadingState) => loadingState.groupSummary === 'loading');
export const selectIsFc1DetailsLoading = createSelector(selectFc1Loading, (loadingState) => loadingState.groupDetails === 'loading');
export const selectIsFc1DetailsLoadingError = createSelector(selectFc1Loading, (loadingState) => loadingState.groupDetails === 'error');
export const selectIsFc1SummaryLoadingError = createSelector(selectFc1Loading, (loadingState) => loadingState.groupSummary === 'error');
export const selectIsFc1GroupSummaryLoadingNotFound = createSelector(selectFc1Loading, (loadingState) => {
  return loadingState.groupSummary === 'not-found';
});
export const selectIsFc1SingleLoadingError = (pipelineRunId: number) =>
  createSelector(selectFc1Loading, (loadingState) => loadingState.singleDetails[pipelineRunId] === 'error');
export const selectIsFc1SingleLoadingNotFound = (pipelineRunId: number) =>
  createSelector(selectFc1Loading, (loadingState) => loadingState.singleDetails[pipelineRunId] === 'not-found');

// FC-2
export const selectFC2LoadingState = createSelector(selectLoadingState, (loadingState) => loadingState.fc2);
export const selectFC2DeliveryOverallLoading = createSelector(
  selectFC2LoadingState,
  (loadingState) => loadingState.deliveryTestcases === 'loading' || loadingState.deliveryRequirements === 'loading',
);

export const selectFc2PipelineRunRequirementsIsLoading = (pipelineRunId: number) =>
  createSelector(selectFC2LoadingState, (loadingState) => {
    return loadingState.pipelineRunRequirements[pipelineRunId] === 'loading';
  });

export const selectFC2PipelineRunOverallLoading = (pipelineRunId: number) =>
  createSelector(selectFC2LoadingState, (loadingState) => {
    const pipelineRunTestcasesLoading = loadingState.pipelineRunTestcases[pipelineRunId] === 'loading';
    return pipelineRunTestcasesLoading || loadingState.deliveryRequirements === 'loading';
  });

export const selectFc2DeliveryLoadingError = createSelector(selectFC2LoadingState, (loadingState) => {
  return loadingState.deliveryTestcases === 'error' || loadingState.deliveryRequirements === 'error';
});

export const selectFc2PipelineRunLoadingError = (pipelineRunId: number) =>
  createSelector(selectFC2LoadingState, (loadingState) => {
    const loadingStateTestcases = loadingState.pipelineRunTestcases[pipelineRunId];
    return loadingStateTestcases === 'error' || loadingState.deliveryRequirements === 'error';
  });

export const selectFc2PipelineRunRequirementsLoadingError = (pipelineRunId: number) =>
  createSelector(selectFC2LoadingState, (loadingState) => {
    return loadingState.pipelineRunRequirements[pipelineRunId] === 'error';
  });
export const selectFc2PipelineRunRequirementsLoadingNotFound = (pipelineRunId: number) =>
  createSelector(selectFC2LoadingState, (loadingState) => {
    return loadingState.pipelineRunRequirements[pipelineRunId] === 'not-found';
  });

// FC-3
export const selectFc3Loading = createSelector(selectLoadingState, (loadingState) => loadingState.fc3);
export const selectIsFc3SingleLoading = (pipelineRunId: number) =>
  createSelector(selectFc3Loading, (loadingState) => loadingState.singleDetails[pipelineRunId] === 'loading');
export const selectIsFc3SummaryLoading = createSelector(selectFc3Loading, (loadingState) => loadingState.groupSummary === 'loading');
export const selectIsFc3DetailsLoading = createSelector(selectFc3Loading, (loadingState) => loadingState.groupDetails === 'loading');
export const selectIsFc3DetailsLoadingError = createSelector(selectFc3Loading, (loadingState) => loadingState.groupDetails === 'error');
export const selectIsFc3SummaryLoadingError = createSelector(selectFc3Loading, (loadingState) => loadingState.groupSummary === 'error');
export const selectIsFc3SummaryLoadingNotFound = createSelector(
  selectFc3Loading,
  (loadingState) => loadingState.groupSummary === 'not-found',
);
export const selectIsFc3SingleLoadingError = (pipelineRunId: number) =>
  createSelector(selectFc3Loading, (loadingState) => loadingState.singleDetails[pipelineRunId] === 'error');
export const selectIsFc3SingleLoadingNotFound = (pipelineRunId: number) =>
  createSelector(selectFc3Loading, (loadingState) => loadingState.singleDetails[pipelineRunId] === 'not-found');
// ATC
export const selectAtcLoading = createSelector(selectLoadingState, (loadingState) => loadingState.atc);
export const selectIsAtcSummaryLoading = createSelector(
  selectAtcLoading,
  (loadingState) => loadingState.deliveryAtcInformation === 'loading',
);
export const selectIsAtcPipelineRunLoading = (pipelineRunId: number) =>
  createSelector(selectAtcLoading, (loadingState) => {
    return loadingState.allPipelineRunAtcInformation[pipelineRunId] === 'loading';
  });

export const selectIsAtcPipelineRunLoadingError = (pipelineRunId: number) =>
  createSelector(selectAtcLoading, (loadingState) => {
    return loadingState.allPipelineRunAtcInformation[pipelineRunId] === 'error';
  });

export const selectIsAtcSummaryLoadingError = createSelector(
  selectAtcLoading,
  (loadingState) => loadingState.deliveryAtcInformation === 'error',
);

export const selectIsAtcPipelineRunLoadingNotFound = (pipelineRunId: number) =>
  createSelector(selectAtcLoading, (loadingState) => {
    return loadingState.allPipelineRunAtcInformation[pipelineRunId] === 'not-found';
  });

export const selectIsAtcSummaryLoadingNotFound = createSelector(
  selectAtcLoading,
  (loadingState) => loadingState.deliveryAtcInformation === 'not-found',
);

// Microdeliveries
export const selectMicrodeliveriesLoading = createSelector(
  selectLoadingState,
  (loadingState) => loadingState.microdeliveries.jiraRequestsLoading !== 0,
);
export const selectMicrodeliveriesErrorCount = createSelector(
  selectLoadingState,
  (loadingState) => loadingState.microdeliveries.errorCount,
);
export const selectMicrodeliveriesRequestCount = createSelector(
  selectLoadingState,
  (loadingState) => loadingState.microdeliveries.triggeredRequests,
);

export const selectSiriusDeliveryLoadingState = createSelector(selectLoadingState, (loadingState) => loadingState.siriusDelivery);

// Pipeline Runs
export const selectIsPipelineRunsForPipelineLoading = (pipelineId: string) =>
  createSelector(selectLoadingState, (loadingState) => loadingState.pipelineRuns[pipelineId] === 'loading');

export const selectIsPipelineRunDeleting = (pipelineRunId: number) =>
  createSelector(selectLoadingState, (loadingState) => loadingState.pipelineRunDeletion[pipelineRunId] === 'loading');

export const selectIsPipelineRunDeletionSuccessful = (pipelineRunId: number) =>
  createSelector(selectLoadingState, (loadingState) => loadingState.pipelineRunDeletion[pipelineRunId] === 'completed');

export const selectPipelineRunReleaseStatusOperationLoading = (pipelineRunId: number) =>
  createSelector(selectLoadingState, (loadingState) => loadingState.pipelineRunReleaseStatusOperation[pipelineRunId]);

export const selectIsPipelineRunReleaseStatusOperationLoading = (pipelineRunId: number) =>
  createSelector(selectLoadingState, (loadingState) => loadingState.pipelineRunReleaseStatusOperation[pipelineRunId] === 'loading');

export const selectIsPipelineRunProcessingStatusChanging = (pipelineRunId: number) =>
  createSelector(selectLoadingState, (loadingState) => loadingState.pipelineRunProcessingStatusChanging[pipelineRunId] === 'loading');

export const selectIsPipelineRunProcessingStatusChangingSuccessful = (pipelineRunId: number) =>
  createSelector(selectLoadingState, (loadingState) => loadingState.pipelineRunProcessingStatusChanging[pipelineRunId] === 'completed');

export const selectIsPipelineRunReleaseStatusOperationLoadingCompleted = (pipelineRunId: number) =>
  createSelector(selectLoadingState, (loadingState) => loadingState.pipelineRunReleaseStatusOperation[pipelineRunId] === 'completed');

export const selectIsPipelineRunReleaseStatusOperationLoadingError = (pipelineRunId: number) =>
  createSelector(selectLoadingState, (loadingState) => loadingState.pipelineRunReleaseStatusOperation[pipelineRunId] === 'loading');

// Pipelines
export const selectIsPipelinesForDeliveryLoading = createSelector(
  selectLoadingState,
  (loadingState) => loadingState.pipelines === 'loading',
);

// Pipelines Search
export const selectIsPipelineSearchLoading = createSelector(
  selectLoadingState,
  (loadingState) => loadingState.pipelinesSearch === 'loading',
);

export const selectIsPipelineSearchLoadingError = createSelector(
  selectLoadingState,
  (loadingState) => loadingState.pipelinesSearch === 'error',
);

// Coverage Info
export const selectCodeCoverageLoadingState = createSelector(selectLoadingState, (loadingState) => loadingState.coverageInfo);
export const selectIsCodeCoverageSummaryLoading = createSelector(
  selectCodeCoverageLoadingState,
  (loadingState) => loadingState.groupSummary === 'loading',
);

export const selectIsCodeCoverageSummaryLoadingError = createSelector(
  selectCodeCoverageLoadingState,
  (loadingState) => loadingState.groupSummary === 'error',
);
export const selectIsCodeCoverageSummaryLoadingNotFound = createSelector(
  selectCodeCoverageLoadingState,
  (loadingState) => loadingState.groupSummary === 'not-found',
);

export const selectIsCodeCoverageGroupDetailsLoading = createSelector(
  selectCodeCoverageLoadingState,
  (loadingState) => loadingState.groupDetails === 'loading',
);

export const selectIsCodeCoverageGroupDetailsLoadingError = createSelector(
  selectCodeCoverageLoadingState,
  (loadingState) => loadingState.groupDetails === 'error',
);
export const selectIsCodeCoverageGroupDetailsLoadingNotFound = createSelector(
  selectCodeCoverageLoadingState,
  (loadingState) => loadingState.groupDetails === 'not-found',
);

export const selectIsPipelineRunCoverageInfoLoading = (pipelineRunId: number) =>
  createSelector(selectCodeCoverageLoadingState, (loadingState) => loadingState.pipelineRunLoadingState[pipelineRunId] === 'loading');

export const selectIsPipelineRunCodeCoverageLoadingError = (pipelineRunId: number) =>
  createSelector(selectCodeCoverageLoadingState, (loadingState) => loadingState.pipelineRunLoadingState[pipelineRunId] === 'error');

export const selectIsPipelineRunCodeCoverageLoadingNotFound = (pipelineRunId: number) =>
  createSelector(selectCodeCoverageLoadingState, (loadingState) => loadingState.pipelineRunLoadingState[pipelineRunId] === 'not-found');

export const selectSonarqubeCoverageLoadingState = createSelector(selectLoadingState, (loadingState) => loadingState.sonarQubeCoverage);

// SonarQube Coverage
export const selectIsSonarQubeCoverageGroupSummaryLoading = createSelector(
  selectLoadingState,
  (loadingState) => loadingState.sonarQubeCoverage.groupSummary === 'loading',
);

export const selectIsSonarQubeCoverageGroupSummaryLoadingError = createSelector(
  selectLoadingState,
  (loadingState) => loadingState.sonarQubeCoverage.groupSummary === 'error',
);

export const selectIsSonarQubeCoverageGroupSummaryLoadingNotFound = createSelector(
  selectLoadingState,
  (loadingState) => loadingState.sonarQubeCoverage.groupSummary === 'not-found',
);

export const selectIsSonarQubeCoverageGroupDetailsLoading = createSelector(
  selectLoadingState,
  (loadingState) => loadingState.sonarQubeCoverage.groupDetails === 'loading',
);

export const selectIsSonarQubeCoverageGroupDetailsLoadingError = createSelector(
  selectLoadingState,
  (loadingState) => loadingState.sonarQubeCoverage.groupDetails === 'error',
);

export const selectIsSonarQubeCoverageGroupDetailsLoadingNotFound = createSelector(
  selectLoadingState,
  (loadingState) => loadingState.sonarQubeCoverage.groupDetails === 'not-found',
);

export const selectIsPipelineRunSonarQubeCoverageLoading = (pipelineRunId: number) =>
  createSelector(selectSonarqubeCoverageLoadingState, (loadingState) => loadingState.pipelineRunLoadingState[pipelineRunId] === 'loading');

export const selectIsPipelineRunSonarQubeCoverageLoadingError = (pipelineRunId: number) =>
  createSelector(selectSonarqubeCoverageLoadingState, (loadingState) => loadingState.pipelineRunLoadingState[pipelineRunId] === 'error');

export const selectIsPipelineRunSonarQubeCoverageLoadingNotFound = (pipelineRunId: number) =>
  createSelector(
    selectSonarqubeCoverageLoadingState,
    (loadingState) => loadingState.pipelineRunLoadingState[pipelineRunId] === 'not-found',
  );

// Regression Tests

export const selectTestRegressionLoadingState = createSelector(selectLoadingState, (loadingState) => {
  return loadingState.testRegression;
});

export const selectTestRegressionPipelineRequirementsLoadingState = (pipelineId: string) =>
  createSelector(selectTestRegressionLoadingState, (testRegressionLoadingState) => {
    return testRegressionLoadingState.pipelineRequirements[pipelineId];
  });

export const selectTestRegressionPipelineRunTestCasesLoadingState = (pipelineRunId: number) =>
  createSelector(selectTestRegressionLoadingState, (testRegressionLoadingState) => {
    return testRegressionLoadingState.pipelineRunTestcasesForRegression[pipelineRunId];
  });

export const selectIsPipelineRunRegressionTestsLoading = (pipelineRunId: number, pipelineId: string) =>
  createSelector(selectTestRegressionLoadingState, (testRegressionLoadingState) => {
    return (
      testRegressionLoadingState.pipelineRunTestcasesForRegression[pipelineRunId] === 'loading' ||
      testRegressionLoadingState.pipelineRequirements[pipelineId] === 'loading'
    );
  });

export const selectIsPipelineRunRegressionTestsLoadingError = (pipelineId: string, pipelineRunId: number) =>
  createSelector(selectTestRegressionLoadingState, (testRegressionLoadingState) => {
    return (
      testRegressionLoadingState.pipelineRunTestcasesForRegression[pipelineRunId] === 'error' ||
      testRegressionLoadingState.pipelineRequirements[pipelineId] === 'error'
    );
  });

// Malwarescan

export const selectIsDeliveryMalwarescanSummaryLoading = createSelector(
  selectLoadingState,
  (loadingState) => loadingState.malwarescan.groupSummary === 'loading',
);

export const selectIsDeliveryMalwarescanSummaryLoadingError = createSelector(
  selectLoadingState,
  (loadingState) => loadingState.malwarescan.groupSummary === 'error',
);

export const selectIsDeliveryMalwarescanSummaryLoadingNotFound = createSelector(
  selectLoadingState,
  (loadingState) => loadingState.malwarescan.groupSummary === 'not-found',
);

export const selectIsDeliveryMalwarescanDetailsLoading = createSelector(
  selectLoadingState,
  (loadingState) => loadingState.malwarescan.groupDetails === 'loading',
);

export const selectIsDeliveryMalwarescanDetailsLoadingError = createSelector(
  selectLoadingState,
  (loadingState) => loadingState.malwarescan.groupDetails === 'error',
);

export const selectIsPipelineRunMalwarescanDetailsLoading = (pipelineRunId: number) =>
  createSelector(selectLoadingState, (loadingState) => loadingState.malwarescan.pipelineRunLoadingState[pipelineRunId] === 'loading');

export const selectIsPipelineRunMalwarescanDetailsLoadingError = (pipelineRunId: number) =>
  createSelector(selectLoadingState, (loadingState) => loadingState.malwarescan.pipelineRunLoadingState[pipelineRunId] === 'error');
export const selectIsPipelineRunMalwarescanDetailsLoadingNotFound = (pipelineRunId: number) =>
  createSelector(selectLoadingState, (loadingState) => loadingState.malwarescan.pipelineRunLoadingState[pipelineRunId] === 'not-found');

// Documents

export const selectIsPipelineRunDocumentsLoading = (pipelineRunId: number) => {
  return createSelector(
    selectLoadingState,
    (loadingState) => loadingState.documents.pipelineRunDetailsLoadingState[pipelineRunId] === 'loading',
  );
};

export const selectIsPipelineRunDocumentsLoadingError = (pipelineRunId: number) => {
  return createSelector(
    selectLoadingState,
    (loadingState) => loadingState.documents.pipelineRunDetailsLoadingState[pipelineRunId] === 'error',
  );
};

export const selectIsPipelineRunDocumentsLoadingNotFound = (pipelineRunId: number) => {
  return createSelector(
    selectLoadingState,
    (loadingState) => loadingState.documents.pipelineRunDetailsLoadingState[pipelineRunId] === 'not-found',
  );
};

// ComplianceCertificates

export const selectIsPipelineRunComplianceCertificatesLoading = (pipelineRunId: number) => {
  return createSelector(
    selectLoadingState,
    (loadingState) => loadingState.complianceCertificates.pipelineRunDetailsLoadingState[pipelineRunId] === 'loading',
  );
};

export const selectIsPipelineRunComplianceCertificatesLoadingError = (pipelineRunId: number) => {
  return createSelector(
    selectLoadingState,
    (loadingState) => loadingState.complianceCertificates.pipelineRunDetailsLoadingState[pipelineRunId] === 'error',
  );
};

export const selectIsPipelineRunComplianceCertificatesLoadingNotFound = (pipelineRunId: number) => {
  return createSelector(
    selectLoadingState,
    (loadingState) => loadingState.complianceCertificates.pipelineRunDetailsLoadingState[pipelineRunId] === 'not-found',
  );
};

export const selectIsPipelineRunProcessingStatusLoading = (pipelineRunId: number) =>
  createSelector(selectLoadingState, (loadingState) => loadingState.processingStatus.pipelineRunLoadingState[pipelineRunId] === 'loading');

export const selectIsPipelineRunProcessingStatusLoadingError = (pipelineRunId: number) =>
  createSelector(selectLoadingState, (loadingState) => loadingState.processingStatus.pipelineRunLoadingState[pipelineRunId] === 'error');

export const selectIsPipelineRunProcessingStatusLoadingNotFound = (pipelineRunId: number) =>
  createSelector(
    selectLoadingState,
    (loadingState) => loadingState.processingStatus.pipelineRunLoadingState[pipelineRunId] === 'not-found',
  );

export const selectIsPipelienRunCumulusDocumentsLoading = (pipelineRunId: number) =>
  createSelector(
    selectIsPipelineRunDocumentsLoading(pipelineRunId),
    selectIsPipelineRunProcessingStatusLoading(pipelineRunId),
    selectIsPipelineRunComplianceCertificatesLoading(pipelineRunId),
    (documentsLoading, processingStateLoading, complianceCertificatesLoading) => {
      return documentsLoading || processingStateLoading || complianceCertificatesLoading;
    },
  );

export const selectIsPipelienRunCumulusDocumentsLoadingError = (pipelineRunId: number) =>
  createSelector(
    selectIsPipelineRunDocumentsLoadingError(pipelineRunId),
    selectIsPipelineRunProcessingStatusLoadingError(pipelineRunId),
    selectIsPipelineRunComplianceCertificatesLoadingError(pipelineRunId),
    (documentsLoadingError, processingStateLoadingError, complianceCertificatesLoadingError) => {
      return documentsLoadingError || processingStateLoadingError || complianceCertificatesLoadingError;
    },
  );

// Api Metadata Validator
export const selectApiMetadataValidatorLoadingState = createSelector(
  selectLoadingState,
  (loadingState) => loadingState.apiMetadataValidator,
);
// - Group Summary
export const selectIsApiMetadataValidatorGroupSummaryLoading = createSelector(
  selectApiMetadataValidatorLoadingState,
  (loadingState) => loadingState.groupSummary === 'loading',
);
export const selectIsApiMetadataValidatorGroupSummaryLoadingNotFound = createSelector(
  selectApiMetadataValidatorLoadingState,
  (loadingState) => {
    return loadingState.groupSummary === 'not-found';
  },
);
export const selectIsApiMetadataValidatorGroupSummaryLoadingError = createSelector(
  selectApiMetadataValidatorLoadingState,
  (loadingState) => loadingState.groupSummary === 'error',
);

// - Group Details
export const selectIsApiMetadataValidatorGroupDetailsLoading = createSelector(
  selectApiMetadataValidatorLoadingState,
  (loadingState) => loadingState.groupDetails === 'loading',
);
export const selectIsApiMetadataValidatorGroupDetailsLoadingError = createSelector(
  selectApiMetadataValidatorLoadingState,
  (loadingState) => loadingState.groupDetails === 'error',
);

// - Pipeline Run Summary
export const selectIsApiMetadataValidatorPipelineRunLoading = (pipelineRunId: number) =>
  createSelector(
    selectApiMetadataValidatorLoadingState,
    (loadingState) => loadingState.pipelineRunLoadingState[pipelineRunId] === 'loading',
  );
export const selectIsApiMetadataValidatorPipelineRunLoadingNotFound = (pipelineRunId: number) =>
  createSelector(
    selectApiMetadataValidatorLoadingState,
    (loadingState) => loadingState.pipelineRunLoadingState[pipelineRunId] === 'not-found',
  );
export const selectIsApiMetadataValidatorPipelineRunLoadingError = (pipelineRunId: number) =>
  createSelector(selectApiMetadataValidatorLoadingState, (loadingState) => loadingState.pipelineRunLoadingState[pipelineRunId] === 'error');

// Fosstars
export const selectFosstarsLoading = createSelector(selectLoadingState, (loadingState) => loadingState.fosstars);
export const selectIsFosstarsPipelineRunLoading = (pipelineRunId: number) =>
  createSelector(selectFosstarsLoading, (loadingState) => loadingState.pipelineRunLoadingState[pipelineRunId] === 'loading');

export const selectIsFosstarsGroupSummaryLoading = createSelector(
  selectFosstarsLoading,
  (loadingState) => loadingState.groupSummary === 'loading',
);

export const selectIsFosstarsGroupDetailsLoading = createSelector(
  selectFosstarsLoading,
  (loadingState) => loadingState.groupDetails === 'loading',
);

export const selectIsFosstarsPipelineRunDetailsLoading = (pipelineRunId: number) =>
  createSelector(selectFosstarsLoading, (loadingState) => loadingState.pipelineRunDetailsLoadingState[pipelineRunId] === 'loading');

export const selectIsFosstarsGroupDetailsLoadingError = createSelector(
  selectFosstarsLoading,
  (loadingState) => loadingState.groupDetails === 'error',
);

export const selectIsFosstarsGroupSummaryLoadingError = createSelector(
  selectFosstarsLoading,
  (loadingState) => loadingState.groupSummary === 'error',
);

export const selectIsFosstarsGroupSummaryLoadingNotFound = createSelector(
  selectFosstarsLoading,
  (loadingState) => loadingState.groupSummary === 'not-found',
);

export const selectIsFosstarsPipelineRunDetailsLoadingError = (pipelineRunId: number) =>
  createSelector(selectFosstarsLoading, (loadingState) => {
    const pipelineRunDetailsLoadingState = loadingState.pipelineRunDetailsLoadingState;
    return pipelineRunDetailsLoadingState ? pipelineRunDetailsLoadingState[pipelineRunId] === 'error' : false;
  });

export const selectIsFosstarsPipelineRunSummaryLoadingError = (pipelineRunId: number) =>
  createSelector(selectFosstarsLoading, (loadingState) => {
    const pipelineRunLoadingState = loadingState.pipelineRunLoadingState;
    return pipelineRunLoadingState ? pipelineRunLoadingState[pipelineRunId] === 'error' : false;
  });

export const selectIsFosstarsPipelineRunSummaryLoadingNotFound = (pipelineRunId: number) =>
  createSelector(selectFosstarsLoading, (loadingState) => {
    const pipelineRunLoadingState = loadingState.pipelineRunLoadingState;
    return pipelineRunLoadingState ? pipelineRunLoadingState[pipelineRunId] === 'not-found' : false;
  });

// Central Policy Results
export const selectCentralPolicyResultsLoading = createSelector(selectLoadingState, (loadingState) => loadingState.centralPolicyResults);

export const selectIsCentralPolicyResultsPipelineRunSummaryLoading = (pipelineRunId: number) =>
  createSelector(selectCentralPolicyResultsLoading, (loadingState) => loadingState.pipelineRunLoadingState[pipelineRunId] === 'loading');

export const selectIsCentralPolicyResultsPipelineRunSummaryLoadingError = (pipelineRunId: number) =>
  createSelector(selectCentralPolicyResultsLoading, (loadingState) => {
    const pipelineRunLoadingState = loadingState.pipelineRunLoadingState;
    return pipelineRunLoadingState ? pipelineRunLoadingState[pipelineRunId] === 'error' : false;
  });

export const selectIsCentralPolicyResultsPipelineRunSummaryLoadingNotFound = (pipelineRunId: number) =>
  createSelector(selectCentralPolicyResultsLoading, (loadingState) => {
    const pipelineRunLoadingState = loadingState.pipelineRunLoadingState;
    return pipelineRunLoadingState ? pipelineRunLoadingState[pipelineRunId] === 'not-found' : false;
  });

export const selectIsCentralPolicyResultsPipelineRunDetailsLoading = (pipelineRunId: number) =>
  createSelector(
    selectCentralPolicyResultsLoading,
    (loadingState) => loadingState.pipelineRunDetailsLoadingState[pipelineRunId] === 'loading',
  );

export const selectIsCentralPolicyResultsPipelineRunDetailsLoadingError = (pipelineRunId: number) =>
  createSelector(selectCentralPolicyResultsLoading, (loadingState) => {
    const pipelineRunDetailsLoadingState = loadingState.pipelineRunDetailsLoadingState;
    return pipelineRunDetailsLoadingState ? pipelineRunDetailsLoadingState[pipelineRunId] === 'error' : false;
  });

export const selectIsCentralPolicyResultsGroupSummaryLoading = createSelector(
  selectCentralPolicyResultsLoading,
  (loadingState) => loadingState.groupSummary === 'loading',
);

export const selectIsCentralPolicyResultsGroupSummaryLoadingError = createSelector(
  selectCentralPolicyResultsLoading,
  (loadingState) => loadingState.groupSummary === 'error',
);

export const selectIsCentralPolicyResultsGroupSummaryLoadingNotFound = createSelector(
  selectCentralPolicyResultsLoading,
  (loadingState) => loadingState.groupSummary === 'not-found',
);

export const selectIsCentralPolicyResultsGroupDetailsLoading = createSelector(
  selectCentralPolicyResultsLoading,
  (loadingState) => loadingState.groupDetails === 'loading',
);

export const selectIsCentralPolicyResultsGroupDetailsLoadingError = createSelector(
  selectCentralPolicyResultsLoading,
  (loadingState) => loadingState.groupDetails === 'error',
);

// Custom Policy Results
export const selectCustomPolicyResultsLoading = createSelector(selectLoadingState, (loadingState) => loadingState.customPolicyResults);

export const selectIsCustomPolicyResultsPipelineRunSummaryLoading = (pipelineRunId: number) =>
  createSelector(selectCustomPolicyResultsLoading, (loadingState) => loadingState.pipelineRunLoadingState[pipelineRunId] === 'loading');

export const selectIsCustomPolicyResultsPipelineRunSummaryLoadingError = (pipelineRunId: number) =>
  createSelector(selectCustomPolicyResultsLoading, (loadingState) => {
    const pipelineRunLoadingState = loadingState.pipelineRunLoadingState;
    return pipelineRunLoadingState ? pipelineRunLoadingState[pipelineRunId] === 'error' : false;
  });

export const selectIsCustomPolicyResultsPipelineRunSummaryLoadingNotFound = (pipelineRunId: number) =>
  createSelector(selectCustomPolicyResultsLoading, (loadingState) => {
    const pipelineRunLoadingState = loadingState.pipelineRunLoadingState;
    return pipelineRunLoadingState ? pipelineRunLoadingState[pipelineRunId] === 'not-found' : false;
  });

export const selectIsCustomPolicyResultsPipelineRunDetailsLoading = (pipelineRunId: number) =>
  createSelector(
    selectCustomPolicyResultsLoading,
    (loadingState) => loadingState.pipelineRunDetailsLoadingState[pipelineRunId] === 'loading',
  );

export const selectIsCustomPolicyResultsPipelineRunDetailsLoadingError = (pipelineRunId: number) =>
  createSelector(selectCustomPolicyResultsLoading, (loadingState) => {
    const pipelineRunDetailsLoadingState = loadingState.pipelineRunDetailsLoadingState;
    return pipelineRunDetailsLoadingState ? pipelineRunDetailsLoadingState[pipelineRunId] === 'error' : false;
  });

export const selectIsCustomPolicyResultsGroupSummaryLoading = createSelector(
  selectCustomPolicyResultsLoading,
  (loadingState) => loadingState.groupSummary === 'loading',
);

export const selectIsCustomPolicyResultsGroupSummaryLoadingError = createSelector(
  selectCustomPolicyResultsLoading,
  (loadingState) => loadingState.groupSummary === 'error',
);

export const selectIsCustomPolicyResultsGroupSummaryLoadingNotFound = createSelector(
  selectCustomPolicyResultsLoading,
  (loadingState) => loadingState.groupSummary === 'not-found',
);

export const selectIsCustomPolicyResultsGroupDetailsLoading = createSelector(
  selectCustomPolicyResultsLoading,
  (loadingState) => loadingState.groupDetails === 'loading',
);

export const selectIsCustomPolicyResultsGroupDetailsLoadingError = createSelector(
  selectCustomPolicyResultsLoading,
  (loadingState) => loadingState.groupDetails === 'error',
);

// Central Policy Usage
export const selectCentralPolicyUsageLoading = createSelector(selectLoadingState, (loadingState) => loadingState.centralPolicyUsage);

export const selectIsCentralPolicyUsageGroupSummaryLoading = createSelector(
  selectCentralPolicyUsageLoading,
  (loadingState) => loadingState.groupSummary === 'loading',
);

export const selectIsCentralPolicyUsageGroupSummaryLoadingError = createSelector(
  selectCentralPolicyUsageLoading,
  (loadingState) => loadingState.groupSummary === 'error',
);

export const selectIsCentralPolicyUsageGroupSummaryLoadingNotFound = createSelector(
  selectCentralPolicyUsageLoading,
  (loadingState) => loadingState.groupSummary === 'not-found',
);

export const selectIsCentralPolicyUsageGroupDetailsLoading = createSelector(
  selectCentralPolicyUsageLoading,
  (loadingState) => loadingState.groupDetails === 'loading',
);

export const selectIsCentralPolicyUsageGroupDetailsLoadingError = createSelector(
  selectCentralPolicyUsageLoading,
  (loadingState) => loadingState.groupDetails === 'error',
);

export const selectIsCentralPolicyKeyGroupDetailsLoading = createSelector(
  selectCentralPolicyUsageLoading,
  (loadingState) => loadingState.groupPolicyKeySummary === 'loading',
);

export const selectIsCentralPolicyKeyGroupDetailsLoadingError = createSelector(
  selectCentralPolicyUsageLoading,
  (loadingState) => loadingState.groupPolicyKeySummary === 'error',
);

export const selectIsCentralPolicyKeyGroupDetailsLoadingNotFound = createSelector(
  selectCentralPolicyUsageLoading,
  (loadingState) => loadingState.groupPolicyKeySummary === 'not-found',
);

// Custom Policy Usage
export const selectCustomPolicyUsageLoading = createSelector(selectLoadingState, (loadingState) => loadingState.customPolicyUsage);

export const selectIsCustomPolicyUsageGroupSummaryLoading = createSelector(
  selectCustomPolicyUsageLoading,
  (loadingState) => loadingState.groupSummary === 'loading',
);

export const selectIsCustomPolicyUsageGroupSummaryLoadingError = createSelector(
  selectCustomPolicyUsageLoading,
  (loadingState) => loadingState.groupSummary === 'error',
);

export const selectIsCustomPolicyUsageGroupSummaryLoadingNotFound = createSelector(
  selectCustomPolicyUsageLoading,
  (loadingState) => loadingState.groupSummary === 'not-found',
);

export const selectIsCustomPolicyUsageGroupDetailsLoading = createSelector(
  selectCustomPolicyUsageLoading,
  (loadingState) => loadingState.groupDetails === 'loading',
);

export const selectIsCustomPolicyUsageGroupDetailsLoadingError = createSelector(
  selectCustomPolicyUsageLoading,
  (loadingState) => loadingState.groupDetails === 'error',
);

export const selectIsCustomPolicyKeyGroupDetailsLoading = createSelector(
  selectCustomPolicyUsageLoading,
  (loadingState) => loadingState.groupPolicyKeySummary === 'loading',
);

export const selectIsCustomPolicyKeyGroupDetailsLoadingError = createSelector(
  selectCustomPolicyUsageLoading,
  (loadingState) => loadingState.groupPolicyKeySummary === 'error',
);

export const selectIsCustomPolicyKeyGroupDetailsLoadingNotFound = createSelector(
  selectCustomPolicyUsageLoading,
  (loadingState) => loadingState.groupPolicyKeySummary === 'not-found',
);
