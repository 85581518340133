import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appTextTruncateTitleTooltip]',
})
export class ShowTitleTooltipIfTextIsTruncatedDirective {
  domElement: any;
  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
  ) {
    this.domElement = this.elementRef.nativeElement; // to get DOM element and store it in global variable
  }

  @HostListener('mouseover', ['$event'])
  isTitleAttribute() {
    // to add or remove title attribute on the element when it is hovered
    return this.domElement.offsetWidth < this.domElement.scrollWidth
      ? this.renderer.setAttribute(this.domElement, 'title', this.domElement.textContent)
      : this.renderer.removeAttribute(this.domElement, 'title');
  }
}
