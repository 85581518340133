import { createAction, props } from '@ngrx/store';
import { GroupComplianceMode } from '../../../shared/model/group-compliance-mode';
import { ProductStandard } from '../../../shared/model/product-standard';
import { ApiMetadataValidatorDetails, ApiMetadataValidatorSummary } from '../../model/api-metadata-validator';

// Group Summary
export const loadApiMetadataValidatorGroupSummary = createAction(
  `[${ProductStandard.apiMetadataValidator}] Trigger loading of ${ProductStandard.apiMetadataValidator} compliance information for group-summary from backend`,
  props<{ deliveryId: string; groupComplianceMode: GroupComplianceMode }>(),
);
export const setApiMetadataValidatorGroupSummary = createAction(
  `[${ProductStandard.apiMetadataValidator}] Set ${ProductStandard.apiMetadataValidator} compliance information for group-summary from backend in store`,
  props<{ groupSummary: ApiMetadataValidatorSummary }>(),
);
export const loadApiMetadataValidatorGroupSummaryNotFound = createAction(
  `[${ProductStandard.apiMetadataValidator}] Loading of ${ProductStandard.apiMetadataValidator} Group Summary from backend not found`,
  props<{ deliveryId: string }>(),
);
export const loadApiMetadataValidatorGroupSummaryError = createAction(
  `[${ProductStandard.apiMetadataValidator}] Loading of ${ProductStandard.apiMetadataValidator} Group Summary from backend error`,
  props<{ deliveryId: string }>(),
);

// Group Details
export const loadApiMetadataValidatorGroupDetails = createAction(
  `[${ProductStandard.apiMetadataValidator}] Trigger loading of ${ProductStandard.apiMetadataValidator} compliance information for group-details from backend`,
  props<{ deliveryId: string; groupComplianceMode: GroupComplianceMode }>(),
);
export const setApiMetadataValidatorGroupDetails = createAction(
  `[${ProductStandard.apiMetadataValidator}] Set ${ProductStandard.apiMetadataValidator} compliance information for group-details from backend in store`,
  props<{ groupDetails: ApiMetadataValidatorDetails[] }>(),
);
export const loadApiMetadataValidatorGroupDetailsError = createAction(
  `[${ProductStandard.apiMetadataValidator}] Loading of ${ProductStandard.apiMetadataValidator} Group Details from backend error`,
  props<{ deliveryId: string }>(),
);

// Pipeline Run Summary
export const loadApiMetadataPipelineRunSummary = createAction(
  `[${ProductStandard.apiMetadataValidator}] Trigger loading of ${ProductStandard.apiMetadataValidator} compliance information for single-details from backend`,
  props<{ pipelineRunId: number }>(),
);
export const setApiMetadataValidatorPipelineRunSummary = createAction(
  `[${ProductStandard.apiMetadataValidator}] Set ${ProductStandard.apiMetadataValidator} compliance information for single-details from backend in store`,
  props<{ pipelineRunId: number; pipelineRunSummary: ApiMetadataValidatorDetails }>(),
);
export const loadApiMetadataValidatorPipelineRunSummaryNotFound = createAction(
  `[${ProductStandard.apiMetadataValidator}] Loading of ${ProductStandard.apiMetadataValidator} Single Details from backend not found`,
  props<{ pipelineRunId: number }>(),
);
export const loadApiMetadataValidatorPipelineRunSummaryError = createAction(
  `[${ProductStandard.apiMetadataValidator}] Loading of ${ProductStandard.apiMetadataValidator} Single Details from backend error`,
  props<{ pipelineRunId: number }>(),
);
