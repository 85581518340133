import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PipelineRunProcessingStatus } from '../../../shared/processing-status/processing-status.service';
import { AggregatedProcessingState } from '../../model/processing-state';
import { PipelineSlice } from '../app-state';
import { selectProcessingStatusSlice } from '../processing-status/processing-status.selectors';
import { ProcessingStatus } from '../../../shared/model/processing-status';

export const selectPipelineSlice = createFeatureSelector<PipelineSlice>('pipelineSlice');

export const selectPipelinesRaw = createSelector(selectPipelineSlice, (pipelineSlice) => {
  return pipelineSlice.pipelines;
});

export const selectPipelinesSorted = createSelector(selectPipelinesRaw, (pipelines) => {
  return pipelines
    .map((pipeline) => ({ ...pipeline }))
    .sort((a, b) => {
      return a.pipelineKey.localeCompare(b.pipelineKey);
    });
});

export const selectAggregatedProcessingState = createSelector(
  selectPipelinesSorted,
  selectProcessingStatusSlice,
  (sortedPipelines, processingStatus) => {
    const pipelineRunIds = sortedPipelines.map((pipeline) => pipeline.pipelineRunId);
    const tuple: [number, PipelineRunProcessingStatus][] = pipelineRunIds
      .map((pipelineRunId) => {
        return [pipelineRunId, processingStatus.pipelineRunProcessingStatus[pipelineRunId]] as [number, PipelineRunProcessingStatus];
      })
      .filter(([_, status]) => !!status);

    return {
      hasLatestRunsUnprocessedFiles: tuple.some(([_, status]) => status.unprocessedFiles.length > 0),
      numberOfArchivingPipelineRuns: tuple.filter(([_, status]) => status.processingStatusName === ProcessingStatus.ARCHIVING).length,
      numberOfArchivedPipelineRuns: tuple.filter(([_, status]) => status.processingStatusName === ProcessingStatus.ARCHIVED).length,
      numberOfRestoringPipelinesRuns: tuple.filter(([_, status]) => status.processingStatusName === ProcessingStatus.RESTORING).length,
      archivedPipelineRunIds: tuple
        .filter(([_, status]) => status.processingStatusName === ProcessingStatus.ARCHIVED)
        .map(([pipelineRunId]) => pipelineRunId),
    } as AggregatedProcessingState;
  },
);

export const selectPipelineById = (pipelineId: string) =>
  createSelector(selectPipelinesRaw, (pipelines) => {
    return pipelines.find((pipeline) => pipeline.pipelineId === pipelineId);
  });

export const selectSearchedPipelines = createSelector(selectPipelineSlice, (pipelineSlice) => {
  return pipelineSlice.searchedPipelines;
});

export const selectHasPipelinesSearchStringNoData = createSelector(selectPipelineSlice, (pipelineSlice) => {
  return pipelineSlice.searchString.length > 3 && !pipelineSlice.searchedPipelines.length;
});
