import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { selectIsPsl1DetailsLoading, selectIsPsl1DetailsLoadingError } from '../../../ngrx/store/loading-state/loading-state.selectors';

import { Store } from '@ngrx/store';

import { process, State } from '@progress/kendo-data-query';
import { Psl1ComplianceInfoDetails } from '../../../ngrx/model/compliance-information';
import { loadPsl1GroupDetails } from '../../../ngrx/store/psl-1/psl-1.actions';
import { selectPsl1GroupDetails } from '../../../ngrx/store/psl-1/psl-1.selectors';
import { AccessApiService } from '../../../shared/access-api/access-api.service';
import { GridDataSortService } from '../../../shared/grid-data-sort-service/grid-data-sort.service';
import { KpiDialogsData } from '../../kpis.module';

@Component({
  selector: 'app-ip-scan-details-dialog',
  templateUrl: './ip-scan-details-dialog.component.html',
  styleUrls: ['./ip-scan-details-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IPScanDetailsDialogComponent implements OnInit {
  public dataSource$!: Observable<GridDataResult>;
  public isLoading$!: Observable<boolean>;
  public isLoadingError$!: Observable<boolean>;

  ipScanInfoSorted: Psl1ComplianceInfoDetails[];
  dataSource: GridDataResult;

  gridState: State = {
    filter: {
      logic: 'and',
      filters: [],
    },
    sort: [{ field: 'complianceStatus.displayKey', dir: 'asc' }],
  };

  constructor(
    private readonly dialogRef: MatDialogRef<IPScanDetailsDialogComponent>,
    private readonly store$: Store,
    private readonly accessApiService: AccessApiService,
    private readonly gridDataSortService: GridDataSortService,
    @Inject(MAT_DIALOG_DATA) private readonly dialogData: KpiDialogsData,
  ) {
    this.dataSource$ = this.store$.select(selectPsl1GroupDetails).pipe(
      map((data) => {
        this.ipScanInfoSorted = data;
        this.dataStateChanged(this.gridState);
        return this.dataSource;
      }),
    );
    this.isLoading$ = this.store$.select(selectIsPsl1DetailsLoading);
    this.isLoadingError$ = this.store$.select(selectIsPsl1DetailsLoadingError);
  }

  ngOnInit() {
    this.dialogRef.updateSize('60vw', 'auto');
  }

  reload() {
    this.store$.dispatch(
      loadPsl1GroupDetails({
        deliveryId: this.dialogData?.deliveryId,
        groupComplianceMode: this.dialogData?.groupComplianceMode,
      }),
    );
  }

  closeDialog(data?: any) {
    this.dialogRef.close(data);
  }

  buildFileLinkUrlForStepResult(stepResultId: number): string {
    return this.accessApiService.buildFileLinkUrlForStepResult(stepResultId);
  }

  dataStateChanged(state) {
    this.gridState = state;
    this.dataSource = process(this.ipScanInfoSorted, this.gridState);
    this.dataSource.data = this.gridDataSortService.sortComplianceStateByStatusSortOrder(this.gridState.sort, this.dataSource.data);
  }
}
