<div class="dialogHeader">
  <p class="dialogTitle">SonarQube Coverage</p>
  <mat-icon
    aria-hidden="false"
    aria-label="cancel"
    class="closeButton"
    (click)="closeDialog()"
    data-test-id="sonarqube-coverage-detail-popup-close-btn"
    >cancel
  </mat-icon>
</div>

<div
  *ngIf="{
    isLoading: isLoading$ | async,
    isLoadingError: isLoadingError$ | async,
    dataSource: dataSource$ | async,
  } as data"
  class="flex flex-col"
  mat-dialog-content
>
  <div class="flex h-full w-full">
    <app-dialog-error-information (reloadClicked)="reload()" *ngIf="data?.isLoadingError"></app-dialog-error-information>
    <kendo-grid
      class="flex h-full w-full"
      id="sonarqube-coverage-detail-grid"
      data-test-id="sonarqube-coverage-detail-popup-grid"
      *ngIf="!data?.isLoadingError"
      [data]="dataSource"
      [loading]="data.isLoading"
      [sortable]="true"
      [sort]="gridState.sort"
      [filterable]="true"
      [filter]="gridState.filter"
      (dataStateChange)="dataStateChanged($event)"
    >
      <kendo-grid-column field="pipelineKey" title="Pipeline" width="44px">
        <ng-template kendoGridCellTemplate kendoTooltip let-dataItem>
          <app-kpi-column-pipeline
            [item]="dataItem"
            fusionFeatureUsageTracker="open-sonarqube-coverage-pipeline-url"
          ></app-kpi-column-pipeline>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="lineCoverage" title="Line Rate" width="20px">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-numeric-filter-cell
            [column]="column"
            [filter]="filter"
            [spinners]="false"
            min="0"
            max="100"
            format="##.#"
            decimals="1"
          ></kendo-grid-numeric-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="flex justify-end items-center">
            <span *ngIf="isNullOrUndefined(dataItem.lineCoverage)">&mdash;</span>
            <a
              *ngIf="dataItem.lineCoverageUrl && !isNullOrUndefined(dataItem.lineCoverage)"
              href="{{ dataItem.lineCoverageUrl }}"
              target="_blank"
              rel="noopener noreferrer"
              fusionTooltip="Open in SonarQube"
              class="gap-1"
            >
              <span *ngIf="!isNullOrUndefined(dataItem.lineCoverage)" data-test-id="sonarqube-coverage-lineRate-column"
                >{{ dataItem.lineCoverage | number: '1.1' }}%</span
              >
              <fusion-icon name="link-external" size="S"></fusion-icon>
            </a>
            <span *ngIf="!dataItem.lineCoverageUrl && !isNullOrUndefined(dataItem.lineCoverage)"
              >{{ dataItem.lineCoverage | number: '1.1' }}%</span
            >
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="branchCoverage" title="Branch Rate" width="20px">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-numeric-filter-cell
            [column]="column"
            [filter]="filter"
            [spinners]="false"
            min="0"
            max="100"
            format="##.#"
            decimals="1"
          ></kendo-grid-numeric-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="flex justify-end items-center">
            <span *ngIf="isNullOrUndefined(dataItem.branchCoverage)">&mdash;</span>
            <a
              *ngIf="dataItem.branchCoverageUrl && !isNullOrUndefined(dataItem.branchCoverage)"
              href="{{ dataItem.branchCoverageUrl }}"
              target="_blank"
              rel="noopener noreferrer"
              fusionTooltip="Open in SonarQube"
              class="flex justify-items-center gap-1"
            >
              <span *ngIf="!isNullOrUndefined(dataItem.branchCoverage)" data-test-id="sonarqube-coverage-branchRate-column"
                >{{ dataItem.branchCoverage | number: '1.1' }}%</span
              >
              <fusion-icon name="link-external" size="S"></fusion-icon>
            </a>
            <span *ngIf="!dataItem.branchCoverageUrl && !isNullOrUndefined(dataItem.branchCoverage)"
              >{{ dataItem.branchCoverage | number: '1.1' }}%</span
            >
          </div>
        </ng-template>
      </kendo-grid-column>
      <ng-template kendoGridLoadingTemplate>
        <app-table-loading-indicator></app-table-loading-indicator>
      </ng-template>
    </kendo-grid>
  </div>
</div>
