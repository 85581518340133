import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { Observable, map } from 'rxjs';
import { FosstarsDetails } from '../../../ngrx/model/fosstars';
import { selectFosstarsGroupDetails, selectFosstarsSingleDetailByPipelineRunId } from '../../../ngrx/store/fosstars/fosstars.selectors';
import {
  selectIsFosstarsGroupDetailsLoading,
  selectIsFosstarsGroupDetailsLoadingError,
  selectIsFosstarsPipelineRunDetailsLoading,
  selectIsFosstarsPipelineRunDetailsLoadingError,
} from '../../../ngrx/store/loading-state/loading-state.selectors';
import { GridDataSortService } from '../../../shared/grid-data-sort-service/grid-data-sort.service';
import { KpiDialogsData } from '../../kpis.module';

@Component({
  selector: 'app-fosstar-details-dialog',
  templateUrl: './fosstar-details-dialog.component.html',
  styleUrls: ['./fosstar-details-dialog.component.scss'],
})
export class FosstarDetailsDialogComponent implements OnInit {
  fosstarsDetails$!: Observable<GridDataResult>;
  fosstarsDetailsSorted: FosstarsDetails[];
  isLoading$!: Observable<boolean>;
  isLoadingError$!: Observable<boolean>;
  dataSource$!: Observable<GridDataResult>;
  dataSource: GridDataResult;

  gridState: State = {
    skip: 0,
    take: 100000,
    filter: {
      logic: 'and',
      filters: [],
    },
    sort: [{ field: 'rating', dir: 'asc' }],
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly dialogData: KpiDialogsData,
    private readonly dialogRef: MatDialogRef<FosstarDetailsDialogComponent>,
    private readonly gridDataSortService: GridDataSortService,
    private readonly store$: Store,
  ) {}

  ngOnInit() {
    this.dialogRef.updateSize('60vw', 'auto');
    // Depending on a given pipeline run we have to choose between pipeline run data or delivery data
    let dataSelector;
    let loadingSelector;
    let loadingErrorSelector;

    if (this.dialogData?.pipelineRunId) {
      dataSelector = selectFosstarsSingleDetailByPipelineRunId(this.dialogData?.pipelineRunId);
      loadingSelector = selectIsFosstarsPipelineRunDetailsLoading(this.dialogData?.pipelineRunId);
      loadingErrorSelector = selectIsFosstarsPipelineRunDetailsLoadingError(this.dialogData?.pipelineRunId);
    } else {
      dataSelector = selectFosstarsGroupDetails;
      loadingSelector = selectIsFosstarsGroupDetailsLoading;
      loadingErrorSelector = selectIsFosstarsGroupDetailsLoadingError;
    }

    this.dataSource$ = this.store$.select(dataSelector).pipe(
      map((data: FosstarsDetails[]) => {
        this.fosstarsDetailsSorted = [];
        data?.forEach((obj) => {
          this.fosstarsDetailsSorted.push({
            ...obj,
            sort: this.getRanking(obj.rating),
          });
        });
        this.dataStateChanged(this.gridState);
        return this.dataSource;
      }),
    );
    this.isLoading$ = this.store$.select(loadingSelector);
    this.isLoadingError$ = this.store$.select(loadingErrorSelector);
  }

  getRanking(label: string): number {
    let rankingNumber: number;
    switch (label) {
      case 'NOTAVAILABLE':
        rankingNumber = 1;
        break;
      case 'BAD':
        rankingNumber = 2;
        break;
      case 'UNCLEAR':
        rankingNumber = 3;
        break;
      case 'MODERATE':
        rankingNumber = 4;
        break;
      case 'GOOD':
        rankingNumber = 5;
        break;
    }
    return rankingNumber;
  }

  // TO DO Reload in error case

  dataStateChanged(state) {
    this.gridState = state;
    this.dataSource = process(this.fosstarsDetailsSorted, this.gridState);
    this.dataSource.data = this.gridDataSortService.sortByReplacedNumberField(this.gridState.sort, 'rating', 'sort', this.dataSource.data);
  }

  closeDialog(data?: any) {
    this.dialogRef.close(data);
  }
}
