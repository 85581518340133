import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CodeCoverageDetail, CodeCoverageInfo, CodeCoverageSummary } from '../../model/code-coverage/code-coverage.model';
import { Requirement } from '../../model/jira/release-item';
import { PipelineRunExecutionStatus } from '../../model/pipeline/execution-status/pipeline-run-execution-status';
import { Pipeline, PipelineRaw } from '../../model/pipeline/pipeline';
import { ReleaseStatus } from '../../model/release-status/release-status';
import { RequirementDetailRequestBody } from '../../model/requirement-detail/requirement-detail-request-body';
import { RequirementTestcaseResult } from '../../model/requirement-detail/requirement-detail-testcase';
import { SonarQubeCoverage, SonarQubeCoverageGroupDetails, SonarQubeCoverageGroupSummary } from '../../model/sonarqube/sonar-qube.model';
import { ApiMetadataValidatorDetails, ApiMetadataValidatorSummary } from '../../ngrx/model/api-metadata-validator';
import { AtcInfoSummary } from '../../ngrx/model/atc';
import { FosstarsDetails, FosstarsSummaryDetail } from '../../ngrx/model/fosstars';
import { MalwarescanDetails, MalwarescanSummary } from '../../ngrx/model/malwarescan';
import { EnvironmentService } from '../environment.service';
import { GroupComplianceMode } from '../model/group-compliance-mode';
import { ReleaseDecisionInformation } from './access-api.service';
import { buildRequestOptions } from './request-utils';

@Injectable({
  providedIn: 'root',
})
export class AccessGroupService {
  constructor(
    private readonly http: HttpClient,
    private readonly envService: EnvironmentService,
  ) {}

  getPipelinesForDelivery(groupId: string, mode: GroupComplianceMode = GroupComplianceMode.LATEST): Observable<Pipeline[]> {
    return this.http.get<PipelineRaw[]>(`${this.groupUrl(groupId)}/pipelines`, buildRequestOptions(mode)).pipe(
      map((pipelines) => {
        return pipelines.map((pipeline) => {
          const leadingLatestStartDateTime = new Date(pipeline.pipelineRunStartDateTime);
          const relatedLatestStartDateTime = pipeline.relatedPipelineRunStartDateTime
            ? new Date(pipeline.relatedPipelineRunStartDateTime)
            : null;
          return {
            ...pipeline,
            pipelineRunReleaseStatusKeyInGroup: ReleaseStatus.getByKey(pipeline.pipelineRunReleaseStatusKeyInGroup),
            isRelatedPipelineRunDeprecated: relatedLatestStartDateTime
              ? leadingLatestStartDateTime.getTime() - relatedLatestStartDateTime.getTime() > 24 * 3600 * 1000
              : false,
            pipelineRunExecutionStatus: PipelineRunExecutionStatus.getByKey(pipeline.pipelineRunExecutionStatusKey),
          };
        });
      }),
    );
  }

  getGroupFosstarRatingSummary(groupId: string, mode: GroupComplianceMode) {
    return this.http.get<FosstarsSummaryDetail>(this.buildRequestUrl(groupId, 'fosstars-ratings-summary'), buildRequestOptions(mode));
  }

  getGroupFosstarRatingDetails(groupId: string, mode: GroupComplianceMode) {
    return this.http.get<FosstarsDetails[]>(this.buildRequestUrl(groupId, 'fosstars-ratings'), buildRequestOptions(mode));
  }

  getCodeCoverageForGroup(groupId: string, mode: GroupComplianceMode): Observable<CodeCoverageInfo> {
    return this.http.get<CodeCoverageSummary>(this.buildRequestUrl(groupId, 'code-coverage-summary'), buildRequestOptions(mode)).pipe(
      map((summary: CodeCoverageSummary) => {
        if (summary) {
          return {
            lineRate: summary.aggregatedLineRate,
            branchRate: summary.aggregatedBranchRate,
          };
        }
        return undefined;
      }),
    );
  }

  getCodeCoverageDetailsForGroup(groupId: string, mode: GroupComplianceMode): Observable<CodeCoverageDetail[]> {
    return this.http.get<CodeCoverageSummary[]>(this.buildRequestUrl(groupId, 'code-coverages'), buildRequestOptions(mode)).pipe(
      map((codeCoverageSummaries) => {
        return codeCoverageSummaries.map((codeCoverageSummary) => {
          return {
            pipelineKey: codeCoverageSummary.pipelineKey,
            buildUrl: codeCoverageSummary.buildUrl,
            pipelineUrl: codeCoverageSummary.pipelineUrl,
            lineRate: codeCoverageSummary.aggregatedLineRate,
            branchRate: codeCoverageSummary.aggregatedBranchRate,
          };
        });
      }),
    );
  }

  getGroupSummarySonarQubeCoverageInformation(groupId: string, mode: GroupComplianceMode): Observable<SonarQubeCoverageGroupSummary> {
    return this.http.get<SonarQubeCoverageGroupSummary>(
      this.buildRequestUrl(groupId, 'sonarqube-coverage-summary'),
      buildRequestOptions(mode),
    );
  }

  getGroupDetailsSonarQubeCoverageInformation(groupId: string, mode: GroupComplianceMode): Observable<SonarQubeCoverageGroupDetails[]> {
    return this.http.get<SonarQubeCoverage[]>(this.buildRequestUrl(groupId, 'sonarqube-code-coverages'), buildRequestOptions(mode));
  }

  getGroupRequirements(groupId: string, requestBody: RequirementDetailRequestBody, mode: GroupComplianceMode) {
    return this.http.post<RequirementTestcaseResult[]>(
      this.buildRequestUrl(groupId, 'requirements/testcases'),
      requestBody,
      buildRequestOptions(mode),
    );
  }

  getPipelinesReleaseDecisionInformationByGroup(groupId: string): Observable<Record<string, ReleaseDecisionInformation>> {
    return this.http.get<Record<string, ReleaseDecisionInformation>>(`${this.groupUrl(groupId)}/pipelines/release/configuration`);
  }

  getGroupSummaryAtcInformation(groupId: string, mode: GroupComplianceMode): Observable<AtcInfoSummary> {
    return this.http.get<AtcInfoSummary>(this.buildRequestUrl(groupId, 'atc-summary'), buildRequestOptions(mode));
  }

  getGroupSummaryMalwarescanInformation(groupId: string, mode: GroupComplianceMode): Observable<MalwarescanSummary> {
    return this.http.get<MalwarescanSummary>(this.buildRequestUrl(groupId, 'malwarescan-summary'), buildRequestOptions(mode));
  }

  getGroupDetailsMalwarescanInformation(groupId: string, mode: GroupComplianceMode): Observable<MalwarescanDetails[]> {
    return this.http.get<MalwarescanDetails[]>(this.buildRequestUrl(groupId, 'malwarescans'), buildRequestOptions(mode));
  }

  getApiMetadataValidatorGroupSummary(groupId: string, mode: GroupComplianceMode): Observable<ApiMetadataValidatorSummary> {
    return this.http.get<ApiMetadataValidatorSummary>(
      this.buildRequestUrl(groupId, 'api-metadata-validator-summary'),
      buildRequestOptions(mode),
    );
  }

  getApiMetadataValidatorGroupDetails(groupId: string, mode: GroupComplianceMode): Observable<ApiMetadataValidatorDetails[]> {
    return this.http.get<ApiMetadataValidatorDetails[]>(this.buildRequestUrl(groupId, 'api-metadata-validator'), buildRequestOptions(mode));
  }

  buildRequestUrl(groupId: string, endpoint: string) {
    return `${this.groupUrl(groupId)}/${endpoint}`;
  }

  getExternalRequirements(groupId: string, mode: GroupComplianceMode): Observable<Requirement[]> {
    return this.http.get<Requirement[]>(this.buildRequestUrl(groupId, 'externalRequirements'), buildRequestOptions(mode));
  }

  private groupUrl(groupId: string): string {
    return `https://${this.envService.getBaseUrl()}/zprs/gcp/access-api/api/v3/groups/${groupId}`;
  }
}
