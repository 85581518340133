import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ComplianceInfoSummary, ComplianceStatus, Fc3ComplianceInfoDetails } from '../../ngrx/model/compliance-information';
import { loadFc3GroupDetails, loadFc3GroupSummary, loadFc3SingleDetails } from '../../ngrx/store/fc-3/fc-3.actions';
import { selectFc3GroupSummary, selectFc3SingleDetailById } from '../../ngrx/store/fc-3/fc-3.selectors';
import {
  selectIsFc3SingleLoading,
  selectIsFc3SingleLoadingError,
  selectIsFc3SingleLoadingNotFound,
  selectIsFc3SummaryLoading,
  selectIsFc3SummaryLoadingError,
  selectIsFc3SummaryLoadingNotFound,
} from '../../ngrx/store/loading-state/loading-state.selectors';
import { GroupComplianceMode } from '../../shared/model/group-compliance-mode';
import { Fc3DetailsDialogComponent } from './fc3-details-dialog/fc3-details-dialog.component';

@Component({
  selector: 'app-list-item-kpis-fc3',
  templateUrl: './list-item-kpis-fc3.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemKpisFc3Component implements OnInit, OnChanges {
  public ComplianceStatus = ComplianceStatus;

  @Input() groupId: string;
  @Input() groupComplianceMode: GroupComplianceMode;
  @Input() currentPipelineRunId: number;

  public fc3complianceInfo$!: Observable<Fc3ComplianceInfoDetails | ComplianceInfoSummary>;
  public isLoading$!: Observable<boolean>;
  public isLoadingError$!: Observable<boolean>;
  public isLoadingNotFound$!: Observable<boolean>;

  constructor(
    private readonly store$: Store,
    private readonly dialog: MatDialog,
  ) {}

  ngOnInit() {
    if (!this.currentPipelineRunId) {
      this.isLoading$ = this.store$.select(selectIsFc3SummaryLoading);
      this.isLoadingError$ = this.store$.select(selectIsFc3SummaryLoadingError);
      this.isLoadingNotFound$ = this.store$.select(selectIsFc3SummaryLoadingNotFound);
      this.fc3complianceInfo$ = this.store$.select(selectFc3GroupSummary);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentPipelineRunId) {
      this.loadPipelineRunInfo();
    }
  }

  loadPipelineRunInfo() {
    const pipelineRunId = this.currentPipelineRunId;
    this.fc3complianceInfo$ = this.store$.select(selectFc3SingleDetailById(pipelineRunId));
    this.isLoading$ = this.store$.select(selectIsFc3SingleLoading(pipelineRunId));
    this.isLoadingError$ = this.store$.select(selectIsFc3SingleLoadingError(pipelineRunId));
    this.isLoadingNotFound$ = this.store$.select(selectIsFc3SingleLoadingNotFound(pipelineRunId));
  }

  reloadInfo() {
    if (!this.currentPipelineRunId) {
      this.store$.dispatch(loadFc3GroupSummary({ deliveryId: this.groupId, groupComplianceMode: this.groupComplianceMode }));
    } else {
      this.store$.dispatch(loadFc3SingleDetails({ pipelineRunId: this.currentPipelineRunId }));
    }
  }

  public onSummaryStatusClicked(): void {
    if (!this.currentPipelineRunId) {
      const data = { deliveryId: this.groupId, groupComplianceMode: this.groupComplianceMode };
      this.store$.dispatch(loadFc3GroupDetails(data));
      this.dialog.open(Fc3DetailsDialogComponent, { data });
    }
  }
}
