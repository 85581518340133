import { ComplianceInfoSummary, Psl1ComplianceInfoDetails } from '../../model/compliance-information';
import { createAction, props } from '@ngrx/store';
import { ProductStandard } from '../../../shared/model/product-standard';
import { GroupComplianceMode } from '../../../shared/model/group-compliance-mode';

export const loadPsl1GroupSummary = createAction(
  `[${ProductStandard.psl1}] Trigger loading of ${ProductStandard.psl1} compliance information for group-summary from backend`,
  props<{ deliveryId: string; groupComplianceMode: GroupComplianceMode }>(),
);
export const setPsl1GroupSummary = createAction(
  `[${ProductStandard.psl1}] Set ${ProductStandard.psl1} compliance information for group-summary from backend in store`,
  props<{ groupSummary: ComplianceInfoSummary }>(),
);

export const loadPsl1GroupDetails = createAction(
  `[${ProductStandard.psl1}] Trigger loading of ${ProductStandard.psl1} compliance information for group-details from backend`,
  props<{ deliveryId: string; groupComplianceMode: GroupComplianceMode }>(),
);
export const setPsl1GroupDetails = createAction(
  `[${ProductStandard.psl1}] Set ${ProductStandard.psl1} compliance information for group-details from backend in store`,
  props<{ groupDetails: Psl1ComplianceInfoDetails[] }>(),
);

export const loadPsl1SingleDetails = createAction(
  `[${ProductStandard.psl1}] Trigger loading of ${ProductStandard.psl1} compliance information for single-details from backend`,
  props<{ pipelineRunId: number }>(),
);
export const setPsl1SingleDetails = createAction(
  `[${ProductStandard.psl1}] Set ${ProductStandard.psl1} compliance information for single-details from backend in store`,
  props<{ pipelineRunId: number; singleDetails: Psl1ComplianceInfoDetails }>(),
);

export const loadPsl1GroupSummaryError = createAction(
  `[${ProductStandard.psl1}] Loading of ${ProductStandard.psl1} Group Summary from backend error`,
  props<{ deliveryId: string }>(),
);

export const loadPsl1GroupSummaryNotFound = createAction(
  `[${ProductStandard.psl1}] Loading of ${ProductStandard.psl1} Group Summary from backend not found`,
  props<{ deliveryId: string }>(),
);

export const loadPsl1SingleDetailsError = createAction(
  `[${ProductStandard.psl1}] Loading of ${ProductStandard.psl1} Single Details from backend error`,
  props<{ pipelineRunId: number }>(),
);

export const loadPsl1SingleDetailsNotFound = createAction(
  `[${ProductStandard.psl1}] Loading of ${ProductStandard.psl1} Single Details from backend not found`,
  props<{ pipelineRunId: number }>(),
);

export const loadPsl1GroupDetailsError = createAction(
  `[${ProductStandard.psl1}] Loading of ${ProductStandard.psl1} Group Details from backend error`,
  props<{ deliveryId: string }>(),
);
