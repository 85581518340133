<ng-container
  *ngIf="{
    coverageInfo: coverageInfo$ | async,
    loadingNotFound: isLoadingNotFound$ | async,
    loadingError: isLoadingError$ | async,
    isLoading: isLoading$ | async,
  } as data"
>
  <app-list-item-kpis [isLoading]="data.isLoading" [title]="'kpi.tile.coverage.title' | translate">
    <div kpi-tile-content>
      <app-error-information (reloadClicked)="reloadInfo()" *ngIf="data.loadingError"></app-error-information>
      <span *ngIf="!data.loadingError && !data.coverageInfo" class="flex justify-start items-center">
        <span class="status-text-not-available">No information available</span>
      </span>
      <div *ngIf="data.coverageInfo && !data.loadingError && !data.loadingNotFound" class="flex flex-col gap-3.5">
        <div class="flex flex-row justify-items-center gap-2.5" data-test-id="cobertura-jacoco-coverage-check-result-text">
          <div class="flex flex-col">
            <ngx-gauge
              [size]="120"
              [type]="gaugeType"
              [value]="data.coverageInfo?.lineRate | number: '1.0-1'"
              [thresholds]="threshold?.lineRate || thresholds"
              [append]="'%'"
            >
              <ngx-gauge-label>
                <strong
                  class="chart-label"
                  *ngIf="!(data.coverageInfo?.lineRate === undefined || data.coverageInfo?.lineRate === null)"
                  data-test-id="cobertura-jacoco-coverage-chart-line-rate"
                  >Line Rate</strong
                >
                <strong
                  class="chart-label"
                  *ngIf="data.coverageInfo?.lineRate === undefined || data.coverageInfo?.lineRate === null"
                  fusionTooltip="No Data available"
                  >Line Rate*</strong
                >
              </ngx-gauge-label>
            </ngx-gauge>
          </div>
          <div class="flex flex-col">
            <ngx-gauge
              [size]="120"
              [type]="gaugeType"
              [value]="data.coverageInfo?.branchRate | number: '1.0-1'"
              [thresholds]="threshold?.branchRate || thresholds"
              [append]="'%'"
            >
              <ngx-gauge-label>
                <strong
                  class="chart-label"
                  *ngIf="!(data.coverageInfo?.branchRate === undefined || data.coverageInfo?.branchRate === null)"
                  data-test-id="cobertura-jacoco-coverage-chart-branch-rate"
                  >Branch Rate</strong
                >
                <strong
                  class="chart-label"
                  *ngIf="data.coverageInfo?.branchRate === undefined || data.coverageInfo?.branchRate === null"
                  fusionTooltip="No Data available"
                  >Branch Rate*</strong
                >
              </ngx-gauge-label>
            </ngx-gauge>
          </div>
        </div>
        <div
          *ngIf="text && data.coverageInfo?.lineRate && data.coverageInfo?.branchRate"
          class="flex flex-row justify-normal items-center gap-2.5"
        >
          <fusion-icon name="info-circle-outline"></fusion-icon>
          <span>{{ text }}</span>
        </div>
        <div class="flex flex-row justify-end items-center" *ngIf="!pipelineRunId">
          <span
            data-test-id="code-coverage-popup-view-details-link"
            class="open-details"
            (click)="onViewDetailsClicked()"
            fusionFeatureUsageTracker="kpi-group-coverage-view-details"
            [fusionFeatureUsageTrackerEvents]="['mouseover', 'click']"
            >View Details</span
          >
        </div>
      </div>
    </div>
  </app-list-item-kpis>
</ng-container>
