import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CentralPolicyResultsSlice } from '../app-state';

// First selector should be a featureSelector
export const selectCentralPolicyResultsSlice = createFeatureSelector<CentralPolicyResultsSlice>('centralPolicyResultsSlice');

export const selectCentralPolicyResultsSingleDetailById = (pipelineRunId: number) =>
  createSelector(selectCentralPolicyResultsSlice, (centralPolicyResultsSlice: CentralPolicyResultsSlice) => {
    return centralPolicyResultsSlice.pipelineRunsDetails[pipelineRunId] ?? [];
  });

export const selectCentralPolicyResultsSingleSummaryById = (pipelineRunId: number) =>
  createSelector(selectCentralPolicyResultsSlice, (centralPolicyResultsSlice: CentralPolicyResultsSlice) => {
    return centralPolicyResultsSlice.pipelineRunsSummary[pipelineRunId];
  });

export const selectCentralPolicyResultsGroupSummary = createSelector(selectCentralPolicyResultsSlice, (CentralPolicyResultsSlice) => {
  return CentralPolicyResultsSlice.groupSummary;
});

export const selectCentralPolicyResultsGroupDetails = createSelector(selectCentralPolicyResultsSlice, (CentralPolicyResultsSlice) => {
  return CentralPolicyResultsSlice.groupDetails;
});
