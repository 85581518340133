<ng-container
  *ngIf="{
    loading: isLoading$ | async,
    policyUsage: policyUsageGroupInfo$ | async,
    loadingError: isLoadingError$ | async,
  } as data"
>
  <app-list-item-kpis [isLoading]="data.loading" [title]="policyUsageTileTitleRef | translate" minimized="true">
    <div class="flex flex-row justify-between items-end flex-[1_1_0%]" kpi-tile-content>
      <div class="flex flex-col justify-between items-start">
        <span
          *ngIf="!data.loadingError"
          class="status-text status-text-{{ data.policyUsage?.groupComplianceStatus }}"
          data-test-id="policy-usage-summary-text-aggregated"
        >
          {{ data.policyUsage?.policyCount | i18nPlural: { '=1': '# Policy', other: '# Policies' } }}
        </span>
        <span>
          {{ 'policy.usage.group.summary.description' | translate }}
        </span>
      </div>
      <span
        (click)="openDetailsDialog()"
        [fusionFeatureUsageTrackerEvents]="['click']"
        class="open-details clickable justify-items-end"
        attr.data-test-id="{{ dataTestIdForDetailsButton }}"
        attr.fusionFeatureUsageTracker="{{ fusionFeatureTrackerForOpenDetailsDialog }}"
        fusionTooltip="Open Details"
        fusionTooltipPosition="bottom-right"
        >View Details</span
      >
    </div>
  </app-list-item-kpis>
</ng-container>
