import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Fc2Slice } from '../app-state';

// First selector should be a featureSelector
export const selectFc2Slice = createFeatureSelector<Fc2Slice>('fc2Slice');

export const selectDeliveryRequirements = createSelector(selectFc2Slice, (fc2Slice) => {
  return fc2Slice.deliveryRequirements;
});

export const selectDeliveryRequirementsTestcases = createSelector(selectFc2Slice, (fc2Slice) => {
  return fc2Slice.deliveryTestcases;
});

export const selectPipelineRunRequirements = (pipelineRunId: number) =>
  createSelector(selectFc2Slice, (fc2Slice) => {
    return fc2Slice.pipelineRunRequirements[pipelineRunId];
  });

export const selectPipelineRunRequirementsTestcases = (pipelineRunId: number) =>
  createSelector(selectFc2Slice, (fc2Slice) => {
    return fc2Slice.pipelineRunRequirementsTestcases[pipelineRunId];
  });
