import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PipelineRunReleaseStatusInGroup } from './change-pipeline-run-release-status-dialog.service';

@Component({
  selector: 'app-change-pipeline-run-release-status-dialog',
  templateUrl: './change-pipeline-run-release-status-dialog.component.html',
  styleUrls: ['./change-pipeline-run-release-status-dialog.component.scss'],
  animations: [
    trigger('tabSwitch', [
      state('one', style({ transform: 'translateX(0%)' })),
      state('two', style({ transform: 'translateX(-50%)' })),

      transition('one => two', [animate('300ms ease-in-out')]),
      transition('two => one', [animate('300ms ease-in-out')]),
    ]),
  ],
})
export class ChangePipelineRunReleaseStatusDialogComponent implements OnInit {
  // business logic vars
  dialogData: PipelineRunReleaseStatusInGroup;
  isSelected = false;
  viewMode: 'one' | 'two' = 'one';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PipelineRunReleaseStatusInGroup,
    private readonly dialogRef: MatDialogRef<ChangePipelineRunReleaseStatusDialogComponent>,
  ) {
    this.dialogData = data;
  }

  ngOnInit() {
    this.dialogRef.updateSize('70vw', 'auto');
  }

  closeDialog(data?: any) {
    this.dialogRef.close(data);
  }

  revertReleasedStatusSelected() {
    this.isSelected = true;
    this.viewMode = 'two';
  }

  revertReleasedStatusDeselected() {
    this.isSelected = false;
    this.viewMode = 'one';
  }
}
