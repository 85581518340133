import { Component, Input } from '@angular/core';
import { PipelineRunModeIds } from '../../../model/pipeline-run/pipeline-run';

export interface OptimizedPipelineRunInfo {
  pipelineRunModeId: PipelineRunModeIds;
  pipelineRunKey: string;
  pipelineRunStartDateTime: string;
}

@Component({
  selector: 'app-optimized-pipeline-run-info',
  templateUrl: './optimized-pipeline-run-info.component.html',
})
export class OptimizedPipelineRunInfoComponent {
  @Input() optimizedPipelineRun: OptimizedPipelineRunInfo;
}
