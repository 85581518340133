<app-announcement-banner *ngIf="isBannerVisible()"></app-announcement-banner>
<ng-container [ngSwitch]="siriusDeliveryLoadingState$ | async">
  <app-loading *ngSwitchCase="'loading'"></app-loading>
  <div *ngSwitchCase="'unauthorized'" class="error-message">
    <p>You need to be staffed in the program to see Pipeline information.</p>
  </div>
  <div *ngSwitchCase="'error'" class="error-message">
    <ng-container *ngIf="!isInvalidBrowser(); else invalidBrowser">
      <p>An unexpected error occurred. Please try it again later.</p>
      <p [innerHTML]="'home.screen.contact.summary' | translate"></p>
    </ng-container>
  </div>
  <div *ngSwitchCase="'completed'">
    <ng-container *ngIf="{ siriusDelivery: siriusDelivery$ | async } as siriusDeliveryData">
      <app-alert></app-alert>
      <app-start-screen *ngIf="!siriusDeliveryData.siriusDelivery"></app-start-screen>
      <app-pipeline-list *ngIf="siriusDeliveryData.siriusDelivery"></app-pipeline-list>
    </ng-container>
  </div>
</ng-container>

<ng-template #invalidBrowser>
  <p>Unsupported Browser detected. Firefox is not supported.</p>
  <p>
    Please use Google Chrome or Microsoft Edge (also see
    <a href="https://wiki.one.int.sap/wiki/display/DevFw/The+Sirius+Project" target="_blank" rel="noopener noreferrer">here</a>).
  </p>
</ng-template>
