import { createAction, props } from '@ngrx/store';
import { CodeCoverageDetail, CodeCoverageInfo } from '../../../model/code-coverage/code-coverage.model';
import { GroupComplianceMode } from '../../../shared/model/group-compliance-mode';

const CODE_COVERAGE_INFO_IDENTIFIER = 'Code Coverage Info';

export const loadCodeCoverageGroupSummary = createAction(
  `[${CODE_COVERAGE_INFO_IDENTIFIER}] Loading Delivery coverage info`,
  props<{ deliveryId: string; groupComplianceMode: GroupComplianceMode }>(),
);

export const loadCodeCoverageGroupSummaryNotFound = createAction(
  `[${CODE_COVERAGE_INFO_IDENTIFIER}] Trigger loading of ${CODE_COVERAGE_INFO_IDENTIFIER} information for group-summary from backend not found`,
  props<{ deliveryId: string }>(),
);

export const loadCodeCoverageGroupSummaryError = createAction(
  `[${CODE_COVERAGE_INFO_IDENTIFIER}] Trigger loading of ${CODE_COVERAGE_INFO_IDENTIFIER} information for group-summary from backend error`,
  props<{ deliveryId: string }>(),
);

export const setCodeCoverageGroupSummary = createAction(
  `[${CODE_COVERAGE_INFO_IDENTIFIER}] Set Delivery coverage info`,
  props<{ groupSummary: CodeCoverageInfo }>(),
);

export const loadCodeCoverageGroupDetails = createAction(
  `[${CODE_COVERAGE_INFO_IDENTIFIER}] Loading Delivery coverage details`,
  props<{ deliveryId: string; groupComplianceMode: GroupComplianceMode }>(),
);

export const loadCodeCoverageGroupDetailsNotFound = createAction(
  `[${CODE_COVERAGE_INFO_IDENTIFIER}] Trigger loading of ${CODE_COVERAGE_INFO_IDENTIFIER} information for group-details from backend not found`,
  props<{ deliveryId: string }>(),
);

export const loadCodeCoverageGroupDetailsError = createAction(
  `[${CODE_COVERAGE_INFO_IDENTIFIER}] Trigger loading of ${CODE_COVERAGE_INFO_IDENTIFIER} information for group-details from backend error`,
  props<{ deliveryId: string }>(),
);

export const setCodeCoverageGroupDetails = createAction(
  `[${CODE_COVERAGE_INFO_IDENTIFIER}] Set Delivery coverage details`,
  props<{ groupDetails: CodeCoverageDetail[] }>(),
);

export const loadCodeCoverageForPipelineRun = createAction(
  `[${CODE_COVERAGE_INFO_IDENTIFIER}] Trigger loading of code coverage for pipeline run`,
  props<{ pipelineRunId: number }>(),
);

export const setCodeCoverageForPipelineRun = createAction(
  `[${CODE_COVERAGE_INFO_IDENTIFIER}] Set code coverage for pipeline run`,
  props<{ pipelineRunId: number; coverageInfo: CodeCoverageInfo }>(),
);

export const loadCodeCoverageForPipelineRunError = createAction(
  `[${CODE_COVERAGE_INFO_IDENTIFIER}] Loading of code coverage for pipeline run failed`,
  props<{ pipelineRunId: number }>(),
);

export const loadCodeCoverageForPipelineRunNotFound = createAction(
  `[${CODE_COVERAGE_INFO_IDENTIFIER}] Loading of code coverage for pipeline run not found`,
  props<{ pipelineRunId: number }>(),
);
