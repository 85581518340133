<div
  *ngIf="releaseSelectionListItems$ | async as releaseSelectionItems"
  class="flex flex-row gap-5 justify-start items-center h-full w-full"
>
  <div><strong>Microdelivery:</strong></div>
  <div *ngIf="siriusDelivery$ | async as siriusDelivery" class="flex flex-row justify-start items-center gap-5">
    <kendo-dropdownlist
      (selectionChange)="releaseChanged($event.value, siriusDelivery)"
      *ngIf="releaseSelectionItems"
      [defaultItem]="defaultReleaseSelectionListItem"
      [(ngModel)]="selectedReleaseItem"
      [valuePrimitive]="false"
      [data]="releaseSelectionItems"
      [disabled]="readOnly"
      [listHeight]="400"
      textField="text"
      valueField="value"
      fusionFeatureUsageTracker="release-selection"
      data-test-id="fc-2-release-selection-dropdown"
      [popupSettings]="{ width: 'auto' }"
    >
      <ng-template kendoDropDownListFooterTemplate>
        <div
          *ngIf="loadingReleases$ | async"
          [fusionTooltip]="
            releaseSelectionItems.length
              ? 'we are loading entries from Jira. Meanwhile you can use the already loaded entries below'
              : undefined
          "
          class="loading-releases flex flex-row justify-start items-center gap-2.5"
          fusionTooltipPosition="bottom-right"
        >
          <app-loading [showLoadingText]="false"></app-loading>
          <span>Loading...</span>
        </div>
      </ng-template>
    </kendo-dropdownlist>
    <a
      *ngIf="releaseSelectionItems && selectedReleaseItem?.value"
      [fusionFeatureUsageTrackerEvents]="['click', 'mouseover']"
      [href]="buildPipelineLink(selectedReleaseItem)"
      class="link flex flex-row justify-start items-center"
      fusionFeatureUsageTracker="open-jira-requirement"
      target="_blank"
      rel="noopener noreferrer"
      ><span> {{ getOpenMicrodeliveryLinkText(selectedReleaseItem.value) | translate }}</span>
      <fusion-icon id="jiraLinkIcon" name="link-external" size="M"></fusion-icon>
    </a>
  </div>
  <div class="info-bottom">
    <span *ngIf="(loadingReleases$ | async) === false && releaseSelectionItems && !selectedReleaseItem"
      >Select a Microdelivery to see details.</span
    >
    <span *ngIf="errorCount$ | async" class="error">
      {{ errorCount$ | async }} of {{ requestCount$ | async }} Jira requests failed because of connection issues or bad configuration.
    </span>
  </div>
</div>
