import { initialConfigurationSlice } from '../initial-slices';
import { createReducer, on } from '@ngrx/store';
import { setConfigurationForPipelinesOfDelivery, setJiraComponentMappingsForPipelines } from './configuration.actions';
import { ConfigurationSlice } from '../app-state';

export const configurationReducer = createReducer(
  initialConfigurationSlice,
  on(setConfigurationForPipelinesOfDelivery, (state, props): ConfigurationSlice => {
    return { ...state, configurations: props.config };
  }),
  on(setJiraComponentMappingsForPipelines, (state, props): ConfigurationSlice => {
    return { ...state, jiraComponentMappingsForPipelines: props.jiraComponentMapping };
  }),
);
