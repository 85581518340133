import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getProcessingStatus } from '../../../shared/group/group.utils';
import { DeliveriesSlice } from '../app-state';

export const selectDeliveriesSlice = createFeatureSelector<DeliveriesSlice>('deliveriesSlice');

export const selectSelectedDelivery = createSelector(selectDeliveriesSlice, (deliveriesSlice) => {
  return deliveriesSlice.selectedDelivery;
});

export const selectSiriusDelivery = createSelector(selectDeliveriesSlice, (deliveriesSlice) => {
  return deliveriesSlice.siriusDelivery;
});

export const selectSelectedGroupComplianceMode = createSelector(selectDeliveriesSlice, (deliveriesSlice) => {
  return deliveriesSlice.groupComplianceMode;
});

export const selectDeliveryFixVersions = createSelector(selectDeliveriesSlice, (deliveriesSlice) => {
  return deliveriesSlice.deliveryFixVersions;
});

export const selectCalculatedDeliveryProcessingStatus = createSelector(selectSelectedDelivery, (delivery) => {
  return getProcessingStatus(delivery);
});
