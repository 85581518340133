import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { GridDataResult, GridItem } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { Observable, map } from 'rxjs';
import { CustomPolicyResultsPipelineRunDetail } from '../../../ngrx/model/policy-results';
import { loadCustomPolicyResultsSingleDetails } from '../../../ngrx/store/custom-policy-results/custom-policy-results.actions';
import { selectCustomPolicyResultsSingleDetailById } from '../../../ngrx/store/custom-policy-results/custom-policy-results.selectors';
import {
  selectIsCustomPolicyResultsPipelineRunDetailsLoading,
  selectIsCustomPolicyResultsPipelineRunDetailsLoadingError,
} from '../../../ngrx/store/loading-state/loading-state.selectors';
import { AccessApiPolicyService } from '../../../shared/access-api/policy/access-api-policy.service';
@Component({
  selector: 'app-custom-policy-results-details',
  templateUrl: './custom-policy-results-details.component.html',
  styleUrls: ['./custom-policy-results-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomPolicyResultsDetailsComponent implements OnChanges {
  @Input() pipelineRunId: number;
  @Output() policyAmount = new EventEmitter<number>();

  public dataSource$!: Observable<GridDataResult>;
  public isLoading$!: Observable<boolean>;
  public isLoadingError$!: Observable<boolean>;

  policyResultsInfo: CustomPolicyResultsPipelineRunDetail[];
  dataSource: GridDataResult;

  gridState: State = {
    filter: {
      logic: 'and',
      filters: [],
    },
    sort: [
      {
        field: 'customPolicyResultStatus.displayKey',
        dir: 'desc',
      },
    ],
  };

  constructor(
    private readonly store$: Store,
    private readonly accessApiPolicy: AccessApiPolicyService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pipelineRunId) {
      this.pipelineRunChanged();
    }
  }

  reload() {
    this.store$.dispatch(
      loadCustomPolicyResultsSingleDetails({
        pipelineRunId: this.pipelineRunId,
      }),
    );
  }

  isSubPathInformationAvailable(): boolean {
    return this.policyResultsInfo.filter((result) => !!result?.subPath).length > 0;
  }

  trackByResultId(index: number, item: GridItem) {
    const row = item.data as CustomPolicyResultsPipelineRunDetail;
    return row?.customPolicyResultId;
  }

  getPolicyDisplayText(policy: any): string {
    return `${policy.customPolicyKey}: ${policy.customPolicyLabel}`;
  }

  mailTo(data: CustomPolicyResultsPipelineRunDetail): void {
    const subject = encodeURIComponent(`Question Regarding CustomPolicy: "${data.customPolicyText}"`);
    const lines = [];
    lines.push(`Hello colleague,`);
    lines.push(``);
    lines.push(`I have a question regarding the following custom policy: "${data.customPolicyText}".`);
    lines.push(``);
    if (data.validationErrorMessages.length > 0) {
      lines.push(`The following error messages are shown:`);
      data.validationErrorMessages.forEach((message) => {
        lines.push(`- ${message}`);
      });
      lines.push(``);
    }
    lines.push(`Thank you and best regards,`);
    lines.push(``);

    const body = encodeURIComponent(lines.join('\n'));

    const mailTo = `mailTo:${data.customPolicyAuthor}?subject=${subject}&body=${body}`;
    window.open(mailTo, '_blank', 'noopener,noreferrer');
  }

  downloadFile(data: CustomPolicyResultsPipelineRunDetail): void {
    window.open(this.accessApiPolicy.buildFileLinkUrlForCustomPolicyResult(data.customPolicyResultId), '_blank', 'noopener,noreferrer');
  }

  openToolUrl(data: CustomPolicyResultsPipelineRunDetail): void {
    window.open(data.toolUrl, '_blank', 'noopener,noreferrer');
  }

  getDownloadTooltipText(fileName: string, uploadPath: string): string {
    return uploadPath ? `${uploadPath}/${fileName}` : `${fileName}`;
  }

  private pipelineRunChanged() {
    this.dataSource$ = this.store$.select(selectCustomPolicyResultsSingleDetailById(this.pipelineRunId)).pipe(
      map((data) => {
        this.policyResultsInfo = data;
        this.dataSource = process(this.policyResultsInfo, this.gridState);
        this.policyAmount.emit(this.dataSource.total);
        return this.dataSource;
      }),
    );
    this.isLoading$ = this.store$.select(selectIsCustomPolicyResultsPipelineRunDetailsLoading(this.pipelineRunId));
    this.isLoadingError$ = this.store$.select(selectIsCustomPolicyResultsPipelineRunDetailsLoadingError(this.pipelineRunId));
  }
}
