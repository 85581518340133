import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Slc25Slice } from '../app-state';

// First selector should be a featureSelector
export const selectSlc25Slice = createFeatureSelector<Slc25Slice>('slc25Slice');

export const selectSlc25GroupSummary = createSelector(selectSlc25Slice, (slc25Slice) => {
  return slc25Slice.aggregationSummary;
});
export const selectSlc25GroupDetails = createSelector(selectSlc25Slice, (slc25Slice) => {
  return slc25Slice.aggregationDetails;
});
export const selectSlc25SingleDetailById = (pipelineRunId: number) =>
  createSelector(selectSlc25Slice, (slc25Slice: Slc25Slice) => {
    return slc25Slice.selectedPipelineRuns[pipelineRunId];
  });
