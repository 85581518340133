import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { GridDataResult, GridItem, MultipleSortSettings } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { Observable, map } from 'rxjs';
import { CustomPolicyKeyGroupDetails, CustomPolicyResultsPipelineRunDetail } from '../../../../ngrx/model/policy-results';
import { loadCustomPolicyKeyGroupDetails } from '../../../../ngrx/store/custom-policy-usage/custom-policy-usage.actions';
import { selectCustomPolicyKeyGroupDetails } from '../../../../ngrx/store/custom-policy-usage/custom-policy-usage.selectors';
import {
  selectIsCustomPolicyKeyGroupDetailsLoading,
  selectIsCustomPolicyKeyGroupDetailsLoadingError,
} from '../../../../ngrx/store/loading-state/loading-state.selectors';
import { AccessApiPolicyService } from '../../../../shared/access-api/policy/access-api-policy.service';
import { GridDataSortService } from '../../../../shared/grid-data-sort-service/grid-data-sort.service';
import { GroupComplianceMode } from '../../../../shared/model/group-compliance-mode';

@Component({
  selector: 'app-custom-policy-results',
  templateUrl: './custom-policy-results.component.html',
  styleUrls: ['./custom-policy-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomPolicyResultsComponent {
  @Input() selectedPolicyKey: string;
  @Input() groupId: string;
  @Input() groupComplianceMode: GroupComplianceMode;
  @Output() pipelineAmount = new EventEmitter<number>();

  public dataSource$!: Observable<GridDataResult>;
  public isLoading$!: Observable<boolean>;
  public isLoadingError$!: Observable<boolean>;

  policyResultsInfo: CustomPolicyKeyGroupDetails[];
  dataSource: GridDataResult;

  gridState: State = {
    filter: {
      logic: 'and',
      filters: [],
    },
    sort: [
      {
        field: 'customPolicyComplianceStatus.displayKey',
        dir: 'asc',
      },
      {
        field: 'pipelineKey',
        dir: 'asc',
      },
    ],
  };

  public sortSettings: MultipleSortSettings = {
    mode: 'multiple',
    initialDirection: 'asc',
    allowUnsort: true,
    showIndexes: true,
  };

  constructor(
    private readonly store$: Store,
    private readonly accessApiPolicy: AccessApiPolicyService,
    private readonly gridDataSortService: GridDataSortService,
  ) {
    this.dataSource$ = this.store$.select(selectCustomPolicyKeyGroupDetails).pipe(
      map((data) => {
        this.policyResultsInfo = data;
        this.dataStateChanged(this.gridState);
        return this.dataSource;
      }),
    );
    this.isLoading$ = this.store$.select(selectIsCustomPolicyKeyGroupDetailsLoading);
    this.isLoadingError$ = this.store$.select(selectIsCustomPolicyKeyGroupDetailsLoadingError);
  }

  reload() {
    this.store$.dispatch(
      loadCustomPolicyKeyGroupDetails({
        groupId: this.groupId,
        policyKey: this.selectedPolicyKey,
        groupComplianceMode: this.groupComplianceMode,
      }),
    );
  }

  trackByResultId(index: number, item: GridItem) {
    const row = item.data as CustomPolicyKeyGroupDetails;
    return row?.customPolicyResultId;
  }

  mailTo(data: CustomPolicyResultsPipelineRunDetail): void {
    const subject = encodeURIComponent(`Question Regarding CustomPolicy: "${data.customPolicyText}"`);
    const lines = [];
    lines.push(`Hello colleague,`);
    lines.push(``);
    lines.push(`I have a question regarding the following custom policy: "${data.customPolicyText}".`);
    lines.push(``);
    if (data?.validationErrorMessages && data?.validationErrorMessages.length > 0) {
      lines.push(`The following error messages are shown:`);
      data.validationErrorMessages.forEach((message) => {
        lines.push(`- ${message}`);
      });
      lines.push(``);
    }
    lines.push(`Thank you and best regards,`);
    lines.push(``);

    const body = encodeURIComponent(lines.join('\n'));

    const mailTo = `mailTo:${data.customPolicyAuthor}?subject=${subject}&body=${body}`;
    window.open(mailTo, '_blank', 'noopener,noreferrer');
  }

  downloadFile(data: CustomPolicyResultsPipelineRunDetail): void {
    window.open(this.accessApiPolicy.buildFileLinkUrlForCustomPolicyResult(data.customPolicyResultId), '_blank', 'noopener,noreferrer');
  }

  openToolUrl(data: CustomPolicyResultsPipelineRunDetail): void {
    window.open(data.toolUrl, '_blank', 'noopener,noreferrer');
  }

  getDownloadTooltipText(fileName: string, uploadPath: string): string {
    return uploadPath ? `${uploadPath}/${fileName}` : `${fileName}`;
  }

  dataStateChanged(state) {
    this.gridState = state;
    this.dataSource = process(this.policyResultsInfo, this.gridState);
    this.dataSource.data = this.gridDataSortService.sortPolicyComplianceStateByStatusSortOrder(
      this.gridState.sort,
      this.dataSource.data,
      'customPolicyComplianceStatus.displayKey',
      'customPolicyComplianceStatus',
    );
  }
}
