import { JiraIssueFields, JiraVersion } from './jira-issue-fields';

export interface JiraRelease {
  id: number;
  name: string;
  key?: string;
  description: string;
  released: boolean;
  projectId: number;
  archived: boolean;
  projectKey: string;
  startDate: string;
  releaseDate: string;
}

interface JiraIssueChangelogItem {
  field: string;
  filedType: string;
  from: number | string;
  fromString: string;
  to: number | string;
  toString: string;
}

interface JiraIssueChangelog {
  id: number;
  author: any;
  created: string;
  items: JiraIssueChangelogItem[];
}

export interface JiraIssue {
  key: string;
  id: number;
  self: string;
  fields: JiraIssueFields;
  changelog?: JiraIssueChangelog[];
}

export class Requirement {
  id: string;
  key: string;
  summary: string;
  link?: string;
  projectKey?: string;
  statusName?: string;
  fixVersions?: JiraVersion[];
  self?: string;

  static fromJiraIssue(jiraElement: JiraIssue) {
    const requirement = new Requirement();
    requirement.id = jiraElement.id.toString();
    requirement.key = jiraElement.key;
    requirement.summary = jiraElement.fields?.summary;
    requirement.self = jiraElement.self;
    requirement.projectKey = jiraElement.fields?.project?.key;
    requirement.statusName = jiraElement.fields?.status?.name;
    requirement.fixVersions = jiraElement.fields?.fixVersions;
    return requirement;
  }
}
