import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { effects, metaReducers, reducers } from './provide-store';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictActionWithinNgZone: true,
        // We want to have "overrides" (which are own actions/instances) in uiStage actions, hence this cannot be true
        strictActionTypeUniqueness: false,
        // We want to use Maps in the uiStateSlice, hence this cannot be true
        strictStateSerializability: false,
        // We want to use a function as property/parameter in the uiState actions, hence this cannot be true
        strictActionSerializability: false,
      },
    }),
    EffectsModule.forRoot(effects),
    StoreDevtoolsModule.instrument({
      // maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
  ],
  providers: [],
})
export class NgrxModule {}
