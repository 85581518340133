import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { SonarQubeCoverage, SonarQubeCoverageGroupSummary } from '../../../model/sonarqube/sonar-qube.model';
import { AccessApiService } from '../../../shared/access-api/access-api.service';
import { AccessGroupService } from '../../../shared/access-api/access-group.service';
import * as SonarQubeCoverageActions from './sonar-qube-coverage.actions';

@Injectable()
export class SonarQubeCoverageEffects {
  public onLoadSonarQubeCoverageGroupSummary$ = createEffect(() => {
    return this.action$.pipe(
      ofType(SonarQubeCoverageActions.loadSonarQubeCoverageGroupSummary),
      switchMap((action) =>
        this.accessGroupService.getGroupSummarySonarQubeCoverageInformation(action.deliveryId, action.groupComplianceMode).pipe(
          map((groupSummary: SonarQubeCoverageGroupSummary) => SonarQubeCoverageActions.setSonarQubeCoverageGroupSummary({ groupSummary })),
          catchError((error) => {
            if (error.status === 404) {
              return of(SonarQubeCoverageActions.loadSonarQubeCoverageGroupSummaryNotFound({ deliveryId: action.deliveryId }));
            }
            return of(SonarQubeCoverageActions.loadSonarQubeCoverageGroupSummaryError({ deliveryId: action.deliveryId }));
          }),
        ),
      ),
    );
  });

  public onLoadSonarQubeCoverageGroupDetails$ = createEffect(() => {
    return this.action$.pipe(
      ofType(SonarQubeCoverageActions.loadSonarQubeCoverageGroupDetails),
      switchMap((action) =>
        this.accessGroupService.getGroupDetailsSonarQubeCoverageInformation(action.deliveryId, action.groupComplianceMode).pipe(
          map((groupDetails: SonarQubeCoverage[]) => SonarQubeCoverageActions.setSonarQubeCoverageGroupDetails({ groupDetails })),
          catchError((error) => {
            if (error.status === 404) {
              return of(SonarQubeCoverageActions.loadSonarQubeCoverageGroupDetailsNotFound({ deliveryId: action.deliveryId }));
            }
            return of(SonarQubeCoverageActions.loadSonarQubeCoverageGroupDetailsError({ deliveryId: action.deliveryId }));
          }),
        ),
      ),
    );
  });

  public onLoadPipelineRunSonarQubeCoverage$ = createEffect(() => {
    return this.action$.pipe(
      ofType(SonarQubeCoverageActions.loadPipelineRunSonarQubeCoverage),
      mergeMap((action) => {
        return this.accessApiService.getPipelineRunSonarQubeCoverage(action.pipelineRunId).pipe(
          map((coverage) =>
            SonarQubeCoverageActions.setPipelineRunSonarQubeCoverage({
              pipelineRunId: action.pipelineRunId,
              coverage: coverage,
            }),
          ),
          takeUntil(
            this.action$.pipe(
              ofType(SonarQubeCoverageActions.loadPipelineRunSonarQubeCoverage),
              filter((newAction) => newAction.pipelineRunId === action.pipelineRunId),
            ),
          ),
          catchError((error) => {
            if (error.status === 404) {
              return [SonarQubeCoverageActions.loadPipelineRunSonarQubeCoverageNotFound({ pipelineRunId: action.pipelineRunId })];
            }
            return [SonarQubeCoverageActions.loadPipelineRunSonarQubeCoverageError({ pipelineRunId: action.pipelineRunId })];
          }),
        );
      }),
    );
  });

  constructor(
    private readonly action$: Actions,
    private readonly accessApiService: AccessApiService,
    private readonly accessGroupService: AccessGroupService,
  ) {}
}
