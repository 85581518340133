import { createReducer, on } from '@ngrx/store';
import { Fc3Slice } from '../app-state';
import { initialFc3Slice } from '../initial-slices';
import * as Fc3Actions from './fc-3.actions';

export const fc3Reducer = createReducer(
  initialFc3Slice,
  on(Fc3Actions.loadFc3GroupSummary, (state, _): Fc3Slice => {
    return { ...state, aggregationSummary: initialFc3Slice.aggregationSummary };
  }),
  on(Fc3Actions.setFc3GroupSummary, (state, props): Fc3Slice => {
    return { ...state, aggregationSummary: props.groupSummary };
  }),
  on(Fc3Actions.setFc3GroupDetails, (state, props): Fc3Slice => {
    return { ...state, aggregationDetails: [...props.groupDetails] };
  }),
  on(Fc3Actions.setFc3SingleDetails, (state, props): Fc3Slice => {
    const selectedPipelineRuns = { ...state.selectedPipelineRuns };
    selectedPipelineRuns[props.pipelineRunId] = props.singleDetails;
    return { ...state, selectedPipelineRuns };
  }),
);
