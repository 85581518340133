import { createReducer, on } from '@ngrx/store';
import * as Fc2Actions from './fc-2.actions';
import { initialFc2Slice } from '../initial-slices';
import { Fc2Slice } from '../app-state';

export const fc2Reducer = createReducer(
  initialFc2Slice,
  on(Fc2Actions.setDeliveryRequirements, (state, props): Fc2Slice => {
    return { ...state, deliveryRequirements: props.deliveryRequirements };
  }),
  on(Fc2Actions.loadDeliveryRequirements, Fc2Actions.loadExternalDeliveryRequirements, (state): Fc2Slice => {
    return { ...state, deliveryTestcases: initialFc2Slice.deliveryTestcases };
  }),
  on(Fc2Actions.setDeliveryRequirementTestcases, (state, props): Fc2Slice => {
    return { ...state, deliveryTestcases: props.deliveryTestcases };
  }),
  on(Fc2Actions.setPipelineRunRequirements, (state, props): Fc2Slice => {
    const pipelineRunRequirements = { ...state.pipelineRunRequirements };
    pipelineRunRequirements[props.pipelineRunId] = props.pipelineRunRequirements;
    return { ...state, pipelineRunRequirements };
  }),
  on(Fc2Actions.setPipelineRunRequirementTestcases, (state, props): Fc2Slice => {
    const pipelineRunRequirementsTestcases = { ...state.pipelineRunRequirementsTestcases };
    pipelineRunRequirementsTestcases[props.pipelineRunId] = props.pipelineRunRequirementsTestcases;
    return { ...state, pipelineRunRequirementsTestcases };
  }),
);
