import { DeliveryProcessingStatus } from '../../model/environment/environment-data';
import { ReleaseStatus } from '../../model/release-status/release-status';

export class GroupComplianceMode {
  public static LATEST = new GroupComplianceMode(1, 'latest');
  public static LATEST_PROMOTED = new GroupComplianceMode(2, 'latest-promoted');
  public static LATEST_DEPLOYED = new GroupComplianceMode(3, 'latest-deployed-to-production');
  public static LATEST_RELEASE_CANDIDATE = new GroupComplianceMode(4, 'latest-release-candidate');
  public static LATEST_RELEASED = new GroupComplianceMode(5, 'latest-released');

  private constructor(
    readonly sortOrder: number,
    readonly key: string,
  ) {}

  static getByKey(key: string): GroupComplianceMode {
    return GroupComplianceModes.find((productStandard) => productStandard.key === key);
  }

  toString() {
    return this.key;
  }

  getReleaseStatus(): ReleaseStatus {
    switch (this.key) {
      case GroupComplianceMode.LATEST.key:
        return ReleaseStatus.NOT_AVAILABLE;
      case GroupComplianceMode.LATEST_PROMOTED.key:
        return ReleaseStatus.PROMOTED;
      case GroupComplianceMode.LATEST_DEPLOYED.key:
        return ReleaseStatus.DEPLOYED_TO_PRODUCTION;
      case GroupComplianceMode.LATEST_RELEASE_CANDIDATE.key:
        return ReleaseStatus.RELEASE_CANDIDATE;
      case GroupComplianceMode.LATEST_RELEASED.key:
        return ReleaseStatus.RELEASED;
      default:
        throw new Error(`Unknown mode ${this.key} - implementation required`);
    }
  }

  isActive(deliveryStatus: DeliveryProcessingStatus): boolean {
    switch (this.key) {
      case GroupComplianceMode.LATEST.key:
        return deliveryStatus !== DeliveryProcessingStatus.FINISHED;
      case GroupComplianceMode.LATEST_PROMOTED.key:
        return deliveryStatus === DeliveryProcessingStatus.ACTIVE;
      case GroupComplianceMode.LATEST_DEPLOYED.key:
        return deliveryStatus === DeliveryProcessingStatus.ACTIVE;
      case GroupComplianceMode.LATEST_RELEASE_CANDIDATE.key:
        return deliveryStatus === DeliveryProcessingStatus.ACTIVE;
      case GroupComplianceMode.LATEST_RELEASED.key:
        return deliveryStatus !== DeliveryProcessingStatus.NEW;
      default:
        throw new Error(`Unknown mode ${this.key} - implementation required`);
    }
  }
}

export const GroupComplianceModes: GroupComplianceMode[] = Object.keys(GroupComplianceMode)
  .filter((key) => GroupComplianceMode[key] instanceof GroupComplianceMode)
  .map((key) => GroupComplianceMode[key] as GroupComplianceMode)
  .sort((m1: GroupComplianceMode, m2: GroupComplianceMode) => m1.sortOrder - m2.sortOrder);
