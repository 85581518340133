import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-warning-box',
  templateUrl: './warning-box.component.html',
  styleUrl: './warning-box.component.scss',
})
export class WarningBoxComponent {
  @Input() showTheAdditionalInfo: boolean;
  @Input() additionalInfoLink: string;
}
