import { createReducer, on } from '@ngrx/store';
import * as ApiMetadataValidatorActions from '../api-metadata-validator/api-metadata-validator.actions';
import * as CentralPolicyResultsActions from '../central-policy-results/central-policy-results.actions';
import * as CentralPolicyUsageActions from '../central-policy-usage/central-policy-usage.actions';
import * as ComplianceCertificatesActions from '../compliance-certificates/compliance-certificates.actions';
import * as CustomPolicyResultsActions from '../custom-policy-results/custom-policy-results.actions';
import * as CustomPolicyUsageActions from '../custom-policy-usage/custom-policy-usage.actions';
import * as DocumentsActions from '../documents/documents.actions';
import * as MalwarescanActions from '../malwarescan/malwarescan.actions';
import * as PipelineRunActions from '../pipeline-run/pipeline-run.actions';
import * as PipelineActions from '../pipeline/pipeline.actions';
import * as ProcessingStatusActions from '../processing-status/processing-status.actions';
import * as Psl1Actions from '../psl-1/psl-1.actions';
import * as TestRegressionActions from '../test-regression/test-regression.actions';
import * as AtcActions from './../atc/atc.actions';
import * as CodeCoverageInfoActions from './../code-coverage-info/code-coverage-info.actions';
import * as DeliveriesActions from './../deliveries/deliveries.actions';
import * as Fc1Actions from './../fc-1/fc-1.actions';
import * as Fc2Actions from './../fc-2/fc-2.actions';
import * as Fc3Actions from './../fc-3/fc-3.actions';
import * as FosstarsActions from './../fosstars/fosstars.actions';
import * as MicrodeliveryActions from './../microdeliveries/microdeliveries.actions';
import * as Slc25Actions from './../slc-25/slc-25.actions';
import * as SonarQubeCoverageActions from './../sonar-qube-coverage/sonar-qube-coverage.actions';
import { LoadingState, LoadingStateSlice } from './loading-state.model';

export const initialLoadingState: LoadingStateSlice = {
  slc25: { groupSummary: 'completed', groupDetails: 'completed', singleDetails: {} },
  psl1: { groupSummary: 'completed', groupDetails: 'completed', singleDetails: {} },
  fc1: { groupSummary: 'completed', groupDetails: 'completed', singleDetails: {} },
  fc2: {
    deliveryRequirements: 'loading',
    deliveryTestcases: 'initial',
    pipelineRunTestcases: {},
    pipelineRunRequirements: {},
  },
  fc3: { groupSummary: 'completed', groupDetails: 'completed', singleDetails: {} },
  atc: { deliveryAtcInformation: 'loading', allPipelineRunAtcInformation: {} },
  microdeliveries: {
    triggeredRequests: 0,
    jiraRequestsLoading: 0,
    errorCount: 0,
  },
  siriusDelivery: 'initial',
  pipelineRuns: {},
  pipelineRunDeletion: {},
  pipelineRunProcessingStatusChanging: {},
  pipelineRunReleaseStatusOperation: {},
  pipelines: 'initial',
  pipelinesSearch: 'initial',
  coverageInfo: { groupSummary: 'initial', pipelineRunLoadingState: {} },
  sonarQubeCoverage: { groupSummary: 'initial', groupDetails: 'initial', pipelineRunLoadingState: {} },
  testRegression: {
    pipelineRequirements: {},
    pipelineRunTestcasesForRegression: {},
  },
  malwarescan: {
    groupSummary: 'initial',
    groupDetails: 'initial',
    pipelineRunLoadingState: {},
  },
  documents: {
    groupSummary: 'initial',
    pipelineRunLoadingState: {},
    pipelineRunDetailsLoadingState: {},
  },
  complianceCertificates: {
    groupSummary: 'initial',
    pipelineRunLoadingState: {},
    pipelineRunDetailsLoadingState: {},
  },
  processingStatus: {
    groupSummary: 'initial',
    pipelineRunLoadingState: {},
  },
  apiMetadataValidator: {
    groupSummary: 'initial',
    groupDetails: 'initial',
    pipelineRunLoadingState: {},
  },
  fosstars: {
    groupSummary: 'initial',
    groupDetails: 'initial',
    pipelineRunDetailsLoadingState: {},
    pipelineRunLoadingState: {},
  },
  centralPolicyResults: {
    groupSummary: 'initial',
    groupDetails: 'initial',
    pipelineRunDetailsLoadingState: {},
    pipelineRunLoadingState: {},
  },
  customPolicyResults: {
    groupSummary: 'initial',
    groupDetails: 'initial',
    pipelineRunDetailsLoadingState: {},
    pipelineRunLoadingState: {},
  },
  centralPolicyUsage: {
    groupPolicyKeySummary: 'initial',
    groupDetails: 'initial',
    groupSummary: 'initial',
  },
  customPolicyUsage: {
    groupPolicyKeySummary: 'initial',
    groupDetails: 'initial',
    groupSummary: 'initial',
  },
};

export const loadingStateReducer = createReducer(
  initialLoadingState,
  on(PipelineRunActions.setNewReleaseStatus, PipelineRunActions.revertReleaseStatus, (state, action): LoadingStateSlice => {
    const pipelineRunReleaseStatusOperationLoadingState = { ...state.pipelineRunReleaseStatusOperation };
    pipelineRunReleaseStatusOperationLoadingState[action.pipelineRunId] = 'loading';
    return {
      ...state,
      pipelineRunReleaseStatusOperation: pipelineRunReleaseStatusOperationLoadingState,
    };
  }),
  on(
    PipelineRunActions.setNewReleaseStatusSuccessful,
    PipelineRunActions.revertReleaseStatusSuccessful,
    (state, action): LoadingStateSlice => {
      const pipelineRunReleaseStatusOperationLoadingState = { ...state.pipelineRunReleaseStatusOperation };
      pipelineRunReleaseStatusOperationLoadingState[action.pipelineRunId] = 'completed';
      return {
        ...state,
        pipelineRunReleaseStatusOperation: pipelineRunReleaseStatusOperationLoadingState,
      };
    },
  ),
  on(
    PipelineRunActions.setNewReleaseStatusFailed,
    PipelineRunActions.revertReleaseCandidateStatusFailed,
    (state, action): LoadingStateSlice => {
      const pipelineRunReleaseStatusOperationLoadingState = { ...state.pipelineRunReleaseStatusOperation };
      pipelineRunReleaseStatusOperationLoadingState[action.pipelineRunId] = 'error';
      return {
        ...state,
        pipelineRunReleaseStatusOperation: pipelineRunReleaseStatusOperationLoadingState,
      };
    },
  ),
  // SLC-25
  on(
    Slc25Actions.loadSlc25GroupSummary,
    (state): LoadingStateSlice => ({
      ...state,
      slc25: { ...state.slc25, groupSummary: 'loading' },
    }),
  ),
  on(
    Slc25Actions.setSlc25GroupSummary,
    (state): LoadingStateSlice => ({
      ...state,
      slc25: { ...state.slc25, groupSummary: 'completed' },
    }),
  ),
  on(
    Slc25Actions.loadSlc25GroupDetails,
    (state): LoadingStateSlice => ({
      ...state,
      slc25: { ...state.slc25, groupDetails: 'loading' },
    }),
  ),
  on(
    Slc25Actions.setSlc25GroupDetails,
    (state): LoadingStateSlice => ({
      ...state,
      slc25: { ...state.slc25, groupDetails: 'completed' },
    }),
  ),
  on(
    Slc25Actions.loadSlc25SingleDetails,
    (state, action): LoadingStateSlice => setSlc25SinglePipelineRunLoadingState(state, action, 'loading'),
  ),
  on(
    Slc25Actions.setSlc25SingleDetails,
    (state, action): LoadingStateSlice => setSlc25SinglePipelineRunLoadingState(state, action, 'completed'),
  ),
  on(
    Slc25Actions.loadSlc25GroupSummaryError,
    (state): LoadingStateSlice => ({
      ...state,
      slc25: { ...state.slc25, groupSummary: 'error' },
    }),
  ),
  on(
    Slc25Actions.loadSlc25GroupSummaryNotFound,
    (state): LoadingStateSlice => ({
      ...state,
      slc25: { ...state.slc25, groupSummary: 'not-found' },
    }),
  ),
  on(
    Slc25Actions.loadSlc25GroupDetailsError,
    (state): LoadingStateSlice => ({
      ...state,
      slc25: { ...state.slc25, groupDetails: 'error' },
    }),
  ),
  on(
    Slc25Actions.loadSlc25SingleDetailsError,
    (state, action): LoadingStateSlice => setSlc25SinglePipelineRunLoadingState(state, action, 'error'),
  ),
  on(
    Slc25Actions.loadSlc25SingleDetailsNotFound,
    (state, action): LoadingStateSlice => setSlc25SinglePipelineRunLoadingState(state, action, 'not-found'),
  ),
  // PSL-1
  on(
    Psl1Actions.loadPsl1GroupSummary,
    (state): LoadingStateSlice => ({
      ...state,
      psl1: { ...state.psl1, groupSummary: 'loading' },
    }),
  ),
  on(
    Psl1Actions.setPsl1GroupSummary,
    (state): LoadingStateSlice => ({
      ...state,
      psl1: { ...state.psl1, groupSummary: 'completed' },
    }),
  ),
  on(
    Psl1Actions.loadPsl1GroupDetails,
    (state): LoadingStateSlice => ({
      ...state,
      psl1: { ...state.psl1, groupDetails: 'loading' },
    }),
  ),
  on(
    Psl1Actions.setPsl1GroupDetails,
    (state): LoadingStateSlice => ({
      ...state,
      psl1: { ...state.psl1, groupDetails: 'completed' },
    }),
  ),
  on(
    Psl1Actions.loadPsl1SingleDetails,
    (state, action): LoadingStateSlice => setPsl1SinglePipelineRunLoadingState(state, action, 'loading'),
  ),
  on(
    Psl1Actions.setPsl1SingleDetails,
    (state, action): LoadingStateSlice => setPsl1SinglePipelineRunLoadingState(state, action, 'completed'),
  ),
  on(
    Psl1Actions.loadPsl1GroupSummaryNotFound,
    (state): LoadingStateSlice => ({
      ...state,
      psl1: { ...state.psl1, groupSummary: 'not-found' },
    }),
  ),
  on(
    Psl1Actions.loadPsl1GroupSummaryError,
    (state): LoadingStateSlice => ({
      ...state,
      psl1: { ...state.psl1, groupSummary: 'error' },
    }),
  ),
  on(
    Psl1Actions.loadPsl1GroupDetailsError,
    (state): LoadingStateSlice => ({
      ...state,
      psl1: { ...state.psl1, groupDetails: 'error' },
    }),
  ),
  on(
    Psl1Actions.loadPsl1SingleDetailsError,
    (state, action): LoadingStateSlice => setPsl1SinglePipelineRunLoadingState(state, action, 'error'),
  ),
  on(
    Psl1Actions.loadPsl1SingleDetailsNotFound,
    (state, action): LoadingStateSlice => setPsl1SinglePipelineRunLoadingState(state, action, 'not-found'),
  ),
  // FC-1
  on(
    Fc1Actions.loadFc1GroupSummary,
    (state): LoadingStateSlice => ({
      ...state,
      fc1: { ...state.fc1, groupSummary: 'loading' },
    }),
  ),
  on(
    Fc1Actions.setFc1GroupSummary,
    (state): LoadingStateSlice => ({
      ...state,
      fc1: { ...state.fc1, groupSummary: 'completed' },
    }),
  ),
  on(
    Fc1Actions.loadFc1GroupDetails,
    (state): LoadingStateSlice => ({
      ...state,
      fc1: { ...state.fc1, groupDetails: 'loading' },
    }),
  ),
  on(
    Fc1Actions.setFc1GroupDetails,
    (state): LoadingStateSlice => ({
      ...state,
      fc1: { ...state.fc1, groupDetails: 'completed' },
    }),
  ),
  on(Fc1Actions.loadFc1SingleDetails, (state, action): LoadingStateSlice => setFc1SinglePipelineRunLoadingState(state, action, 'loading')),
  on(Fc1Actions.setFc1SingleDetails, (state, action): LoadingStateSlice => setFc1SinglePipelineRunLoadingState(state, action, 'completed')),
  on(
    Fc1Actions.loadFc1GroupSummaryNotFound,
    (state): LoadingStateSlice => ({
      ...state,
      fc1: { ...state.fc1, groupSummary: 'not-found' },
    }),
  ),
  on(
    Fc1Actions.loadFc1GroupSummaryError,
    (state): LoadingStateSlice => ({
      ...state,
      fc1: { ...state.fc1, groupSummary: 'error' },
    }),
  ),
  on(
    Fc1Actions.loadFc1GroupDetailsError,
    (state): LoadingStateSlice => ({
      ...state,
      fc1: { ...state.fc1, groupDetails: 'error' },
    }),
  ),
  on(
    Fc1Actions.loadFc1SingleDetailsError,
    (state, action): LoadingStateSlice => setFc1SinglePipelineRunLoadingState(state, action, 'error'),
  ),
  on(
    Fc1Actions.loadFc1SingleDetailsNotFound,
    (state, action): LoadingStateSlice => setFc1SinglePipelineRunLoadingState(state, action, 'not-found'),
  ),
  // FC-3
  on(
    Fc3Actions.loadFc3GroupSummary,
    (state): LoadingStateSlice => ({
      ...state,
      fc3: { ...state.fc3, groupSummary: 'loading' },
    }),
  ),
  on(
    Fc3Actions.setFc3GroupSummary,
    (state): LoadingStateSlice => ({
      ...state,
      fc3: { ...state.fc3, groupSummary: 'completed' },
    }),
  ),
  on(
    Fc3Actions.loadFc3GroupDetails,
    (state): LoadingStateSlice => ({
      ...state,
      fc3: { ...state.fc3, groupDetails: 'loading' },
    }),
  ),
  on(
    Fc3Actions.setFc3GroupDetails,
    (state): LoadingStateSlice => ({
      ...state,
      fc3: { ...state.fc3, groupDetails: 'completed' },
    }),
  ),
  on(Fc3Actions.loadFc3SingleDetails, (state, action): LoadingStateSlice => setFc3SinglePipelineRunLoadingState(state, action, 'loading')),
  on(Fc3Actions.setFc3SingleDetails, (state, action): LoadingStateSlice => setFc3SinglePipelineRunLoadingState(state, action, 'completed')),
  on(
    Fc3Actions.loadFc3GroupSummaryError,
    (state): LoadingStateSlice => ({
      ...state,
      fc3: { ...state.fc3, groupSummary: 'error' },
    }),
  ),
  on(
    Fc3Actions.loadFc3GroupSummaryNotFound,
    (state): LoadingStateSlice => ({
      ...state,
      fc3: { ...state.fc3, groupSummary: 'not-found' },
    }),
  ),
  on(
    Fc3Actions.loadFc3GroupDetailsError,
    (state): LoadingStateSlice => ({
      ...state,
      fc3: { ...state.fc3, groupDetails: 'error' },
    }),
  ),
  on(
    Fc3Actions.loadFc3SingleDetailsError,
    (state, action): LoadingStateSlice => setFc3SinglePipelineRunLoadingState(state, action, 'error'),
  ),
  on(
    Fc3Actions.loadFc3SingleDetailsNotFound,
    (state, action): LoadingStateSlice => setFc3SinglePipelineRunLoadingState(state, action, 'not-found'),
  ),
  // Atc
  on(
    AtcActions.loadAtcGroupSummary,
    (state): LoadingStateSlice => ({
      ...state,
      atc: { ...state.atc, deliveryAtcInformation: 'loading' },
    }),
  ),
  on(
    AtcActions.setAtcGroupSummary,
    (state): LoadingStateSlice => ({
      ...state,
      atc: { ...state.atc, deliveryAtcInformation: 'completed' },
    }),
  ),
  on(
    AtcActions.loadPipelineRunATCInformation,
    (state, action): LoadingStateSlice => setPipelineRunATCLoadingState(state, action, 'loading'),
  ),
  on(
    AtcActions.setPipelineRunATCInformation,
    (state, action): LoadingStateSlice => setPipelineRunATCLoadingState(state, action, 'completed'),
  ),
  on(
    AtcActions.loadAtcGroupSummaryError,
    (state): LoadingStateSlice => ({
      ...state,
      atc: { ...state.atc, deliveryAtcInformation: 'error' },
    }),
  ),
  on(AtcActions.loadAtcPipelineRunError, (state, action): LoadingStateSlice => setPipelineRunATCLoadingState(state, action, 'error')),
  on(
    AtcActions.loadAtcGroupSummaryNotFound,
    (state): LoadingStateSlice => ({
      ...state,
      atc: { ...state.atc, deliveryAtcInformation: 'not-found' },
    }),
  ),
  on(
    AtcActions.loadAtcPipelineRunNotFound,
    (state, action): LoadingStateSlice => setPipelineRunATCLoadingState(state, action, 'not-found'),
  ),
  // Microdeliveries
  on(
    MicrodeliveryActions.clearMicrodeliveriesSlice,
    (state): LoadingStateSlice => ({
      ...state,
      microdeliveries: initialLoadingState.microdeliveries,
    }),
  ),
  on(
    MicrodeliveryActions.loadReleaseIssuesFromJira,
    MicrodeliveryActions.loadReleaseEntriesFromJira,
    (state): LoadingStateSlice => ({
      ...state,
      microdeliveries: {
        ...state.microdeliveries,
        jiraRequestsLoading: state.microdeliveries.jiraRequestsLoading + 1,
        triggeredRequests: state.microdeliveries.triggeredRequests + 1,
      },
    }),
  ),
  on(
    MicrodeliveryActions.addMicrodeliveriesFromJira,
    (state): LoadingStateSlice => ({
      ...state,
      microdeliveries: { ...state.microdeliveries, jiraRequestsLoading: state.microdeliveries.jiraRequestsLoading - 1 },
    }),
  ),
  on(
    MicrodeliveryActions.jiraRequestFailed,
    (state): LoadingStateSlice => ({
      ...state,
      microdeliveries: {
        ...state.microdeliveries,
        errorCount: state.microdeliveries.errorCount + 1,
        jiraRequestsLoading: state.microdeliveries.jiraRequestsLoading - 1,
      },
    }),
  ),

  // Deliveries
  on(
    DeliveriesActions.loadSiriusDelivery,
    (state): LoadingStateSlice => ({
      ...state,
      siriusDelivery: 'loading',
    }),
  ),
  on(
    DeliveriesActions.loadSiriusDeliverySuccessful,
    (state): LoadingStateSlice => ({
      ...state,
      siriusDelivery: 'completed',
    }),
  ),
  on(DeliveriesActions.loadSiriusDeliveryFailed, (state, props): LoadingStateSlice => {
    const loadingState: LoadingState = props.error.status === 403 ? 'unauthorized' : 'error';
    return {
      ...state,
      siriusDelivery: loadingState,
    };
  }),
  on(DeliveriesActions.loadDeliveryFixVersionsFailed, (state, _): LoadingStateSlice => {
    return {
      ...state,
      fc2: { ...state.fc2, deliveryTestcases: 'error' },
    };
  }),
  // FC-2
  on(
    Fc2Actions.loadDeliveryRequirements,
    Fc2Actions.loadExternalDeliveryRequirements,
    (state): LoadingStateSlice => ({
      ...state,
      fc2: {
        ...state.fc2,
        deliveryRequirements: 'loading',
        deliveryTestcases: 'initial',
      },
    }),
  ),
  on(
    Fc2Actions.loadDeliveryRequirementTestcases,
    (state): LoadingStateSlice => ({
      ...state,
      fc2: {
        ...state.fc2,
        deliveryTestcases: 'loading',
      },
    }),
  ),

  on(
    Fc2Actions.loadDeliveryRequirementsError,
    (state): LoadingStateSlice => ({
      ...state,
      fc2: { ...state.fc2, deliveryRequirements: 'error', deliveryTestcases: 'error' },
    }),
  ),

  on(
    Fc2Actions.loadDeliveryRequirementTestcasesError,
    (state): LoadingStateSlice => ({
      ...state,
      fc2: { ...state.fc2, deliveryTestcases: 'error' },
    }),
  ),

  on(
    Fc2Actions.setDeliveryRequirements,
    (state): LoadingStateSlice => ({
      ...state,
      fc2: {
        ...state.fc2,
        deliveryRequirements: 'completed',
      },
    }),
  ),
  on(
    Fc2Actions.setDeliveryRequirementTestcases,
    (state): LoadingStateSlice => ({
      ...state,
      fc2: {
        ...state.fc2,
        deliveryTestcases: 'completed',
      },
    }),
  ),

  // FC2 Pipeline run level loading state
  on(Fc2Actions.loadPipelineRunRequirements, (state, action): LoadingStateSlice => {
    return setPipelineRunRequirementLoadingState(state, action, 'loading');
  }),
  on(Fc2Actions.loadPipelineRunRequirementTestcases, (state, action): LoadingStateSlice => {
    return setPipelineRunTestcasesLoadingState(state, action, 'loading');
  }),

  on(Fc2Actions.setPipelineRunRequirements, (state, action): LoadingStateSlice => {
    return setPipelineRunRequirementLoadingState(state, action, 'completed');
  }),
  on(Fc2Actions.setPipelineRunRequirementTestcases, (state, action): LoadingStateSlice => {
    return setPipelineRunTestcasesLoadingState(state, action, 'completed');
  }),

  on(Fc2Actions.loadPipelineRunRequirementTestcasesError, (state, action): LoadingStateSlice => {
    return setPipelineRunTestcasesLoadingState(state, action, 'error');
  }),
  on(Fc2Actions.loadPipelineRunRequirementsError, (state, action): LoadingStateSlice => {
    return setPipelineRunRequirementLoadingState(state, action, 'error');
  }),
  on(Fc2Actions.loadPipelineRunRequirementsNotFound, (state, action): LoadingStateSlice => {
    return setPipelineRunRequirementLoadingState(state, action, 'not-found');
  }),

  // Pipeline Runs
  on(PipelineRunActions.loadPipelineRunsForPipeline, (state, action): LoadingStateSlice => {
    return setPipelineRunLoadingState(state, action, 'loading');
  }),
  on(PipelineRunActions.loadPipelineRunsForPipelineFailed, (state, action): LoadingStateSlice => {
    return setPipelineRunLoadingState(state, action, 'error');
  }),
  on(PipelineRunActions.setPipelineRunsForPipeline, (state, action): LoadingStateSlice => {
    return setPipelineRunLoadingState(state, action, 'completed');
  }),
  on(PipelineRunActions.deletePipelineRun, (state, action): LoadingStateSlice => {
    return setPipelineRunDeletionState(state, action, 'loading');
  }),
  on(PipelineRunActions.deletePipelineRunSuccessful, (state, action): LoadingStateSlice => {
    return setPipelineRunDeletionState(state, action, 'completed');
  }),
  on(PipelineRunActions.deletePipelineRunFailed, (state, action): LoadingStateSlice => {
    return setPipelineRunDeletionState(state, action, 'error');
  }),
  // restore
  on(ProcessingStatusActions.restorePipelineRun, (state, action): LoadingStateSlice => {
    return setPipelineRunProcessingStatusChangingState(state, action, 'loading');
  }),
  on(ProcessingStatusActions.restorePipelineRunSuccessful, (state, action): LoadingStateSlice => {
    return setPipelineRunProcessingStatusChangingState(state, action, 'completed');
  }),
  on(ProcessingStatusActions.restorePipelineRunError, (state, action): LoadingStateSlice => {
    return setPipelineRunProcessingStatusChangingState(state, action, 'error');
  }),
  // Pipelines
  on(PipelineActions.loadPipelinesForDelivery, (state): LoadingStateSlice => {
    return { ...state, pipelines: 'loading' };
  }),
  on(PipelineActions.setPipelinesForDelivery, (state): LoadingStateSlice => {
    return { ...state, pipelines: 'completed' };
  }),

  // Pipeline Search
  on(PipelineActions.loadPipelinesByQuery, (state): LoadingStateSlice => {
    return { ...state, pipelinesSearch: 'loading' };
  }),
  on(PipelineActions.setPipelinesByQuery, (state): LoadingStateSlice => {
    return { ...state, pipelinesSearch: 'completed' };
  }),
  on(PipelineActions.setPipelinesByQueryError, (state): LoadingStateSlice => {
    return { ...state, pipelinesSearch: 'error' };
  }),

  // Coverage Info
  on(CodeCoverageInfoActions.loadCodeCoverageGroupSummary, (state): LoadingStateSlice => {
    return { ...state, coverageInfo: { ...state.coverageInfo, groupSummary: 'loading' } };
  }),
  on(CodeCoverageInfoActions.setCodeCoverageGroupSummary, (state): LoadingStateSlice => {
    return { ...state, coverageInfo: { ...state.coverageInfo, groupSummary: 'completed' } };
  }),
  on(CodeCoverageInfoActions.loadCodeCoverageGroupSummaryError, (state): LoadingStateSlice => {
    return { ...state, coverageInfo: { ...state.coverageInfo, groupSummary: 'error' } };
  }),
  on(CodeCoverageInfoActions.loadCodeCoverageGroupSummaryNotFound, (state): LoadingStateSlice => {
    return { ...state, coverageInfo: { ...state.coverageInfo, groupSummary: 'not-found' } };
  }),
  on(CodeCoverageInfoActions.loadCodeCoverageGroupDetails, (state): LoadingStateSlice => {
    return { ...state, coverageInfo: { ...state.coverageInfo, groupDetails: 'loading' } };
  }),
  on(CodeCoverageInfoActions.setCodeCoverageGroupDetails, (state): LoadingStateSlice => {
    return { ...state, coverageInfo: { ...state.coverageInfo, groupDetails: 'completed' } };
  }),
  on(CodeCoverageInfoActions.loadCodeCoverageGroupDetailsError, (state): LoadingStateSlice => {
    return { ...state, coverageInfo: { ...state.coverageInfo, groupDetails: 'error' } };
  }),
  on(CodeCoverageInfoActions.loadCodeCoverageGroupDetailsNotFound, (state): LoadingStateSlice => {
    return { ...state, coverageInfo: { ...state.coverageInfo, groupDetails: 'not-found' } };
  }),
  on(CodeCoverageInfoActions.loadCodeCoverageForPipelineRun, (state, action): LoadingStateSlice => {
    return setPipelineRunCoverageInfoLoadingState(state, action, 'loading');
  }),
  on(CodeCoverageInfoActions.setCodeCoverageForPipelineRun, (state, action): LoadingStateSlice => {
    return setPipelineRunCoverageInfoLoadingState(state, action, 'completed');
  }),
  on(CodeCoverageInfoActions.loadCodeCoverageForPipelineRunError, (state, action): LoadingStateSlice => {
    return setPipelineRunCoverageInfoLoadingState(state, action, 'error');
  }),
  on(CodeCoverageInfoActions.loadCodeCoverageForPipelineRunNotFound, (state, action): LoadingStateSlice => {
    return setPipelineRunCoverageInfoLoadingState(state, action, 'not-found');
  }),

  // Sonarqube Coverage
  on(
    SonarQubeCoverageActions.loadSonarQubeCoverageGroupSummary,
    (state): LoadingStateSlice => ({
      ...state,
      sonarQubeCoverage: { ...state.sonarQubeCoverage, groupSummary: 'loading' },
    }),
  ),
  on(
    SonarQubeCoverageActions.setSonarQubeCoverageGroupSummary,
    (state): LoadingStateSlice => ({
      ...state,
      sonarQubeCoverage: { ...state.sonarQubeCoverage, groupSummary: 'completed' },
    }),
  ),
  on(
    SonarQubeCoverageActions.loadSonarQubeCoverageGroupSummaryError,
    (state): LoadingStateSlice => ({
      ...state,
      sonarQubeCoverage: { ...state.sonarQubeCoverage, groupSummary: 'error' },
    }),
  ),
  on(
    SonarQubeCoverageActions.loadSonarQubeCoverageGroupSummaryNotFound,
    (state): LoadingStateSlice => ({
      ...state,
      sonarQubeCoverage: { ...state.sonarQubeCoverage, groupSummary: 'not-found' },
    }),
  ),
  on(
    SonarQubeCoverageActions.loadSonarQubeCoverageGroupDetails,
    (state): LoadingStateSlice => ({
      ...state,
      sonarQubeCoverage: { ...state.sonarQubeCoverage, groupDetails: 'loading' },
    }),
  ),
  on(
    SonarQubeCoverageActions.setSonarQubeCoverageGroupDetails,
    (state): LoadingStateSlice => ({
      ...state,
      sonarQubeCoverage: { ...state.sonarQubeCoverage, groupDetails: 'completed' },
    }),
  ),
  on(
    SonarQubeCoverageActions.loadSonarQubeCoverageGroupDetailsError,
    (state): LoadingStateSlice => ({
      ...state,
      sonarQubeCoverage: { ...state.sonarQubeCoverage, groupDetails: 'error' },
    }),
  ),
  on(
    SonarQubeCoverageActions.loadSonarQubeCoverageGroupDetailsNotFound,
    (state): LoadingStateSlice => ({
      ...state,
      sonarQubeCoverage: { ...state.sonarQubeCoverage, groupDetails: 'not-found' },
    }),
  ),
  on(
    SonarQubeCoverageActions.loadPipelineRunSonarQubeCoverage,
    (state, action): LoadingStateSlice => setPipelineRunSonarQubeCoverageLoadingState(state, action, 'loading'),
  ),
  on(
    SonarQubeCoverageActions.setPipelineRunSonarQubeCoverage,
    (state, action): LoadingStateSlice => setPipelineRunSonarQubeCoverageLoadingState(state, action, 'completed'),
  ),
  on(
    SonarQubeCoverageActions.loadPipelineRunSonarQubeCoverageError,
    (state, action): LoadingStateSlice => setPipelineRunSonarQubeCoverageLoadingState(state, action, 'error'),
  ),
  on(
    SonarQubeCoverageActions.loadPipelineRunSonarQubeCoverageNotFound,
    (state, action): LoadingStateSlice => setPipelineRunSonarQubeCoverageLoadingState(state, action, 'not-found'),
  ),

  // Regression Tests

  on(TestRegressionActions.loadPipelineRequirements, (state, action): LoadingStateSlice => {
    return setPipelineRequirementsLoadingState(state, action, 'loading');
  }),
  on(TestRegressionActions.setPipelineRequirements, (state, action): LoadingStateSlice => {
    return setPipelineRequirementsLoadingState(state, action, 'completed');
  }),
  on(
    TestRegressionActions.loadPipelineRequirementsError,
    TestRegressionActions.missingDeliveryFixVersionError,
    (state, action): LoadingStateSlice => {
      return setPipelineRequirementsLoadingState(state, action, 'error');
    },
  ),

  on(TestRegressionActions.loadPipelineRunTestCasesForRegression, (state, action): LoadingStateSlice => {
    return setPipelineRegressionTestsLoadingState(state, action, 'loading');
  }),
  on(TestRegressionActions.setPipelineRunTestCasesForRegression, (state, action): LoadingStateSlice => {
    return setPipelineRegressionTestsLoadingState(state, action, 'completed');
  }),
  on(TestRegressionActions.loadPipelineRunTestCasesForRegressionError, (state, action): LoadingStateSlice => {
    return setPipelineRegressionTestsLoadingState(state, action, 'error');
  }),

  // Fosstars
  on(FosstarsActions.loadFosstarsSingleSummary, (state, action): LoadingStateSlice => {
    return setPipelineRunFosstarsLoadingState(state, action, 'loading');
  }),
  on(FosstarsActions.setFosstarsSingleSummary, (state, action): LoadingStateSlice => {
    return setPipelineRunFosstarsLoadingState(state, action, 'completed');
  }),
  on(FosstarsActions.loadFosstarsSingleSummaryError, (state, action): LoadingStateSlice => {
    return setPipelineRunFosstarsLoadingState(state, action, 'error');
  }),
  on(FosstarsActions.loadFosstarsSingleSummaryNotFound, (state, action): LoadingStateSlice => {
    return setPipelineRunFosstarsLoadingState(state, action, 'not-found');
  }),
  on(FosstarsActions.loadFosstarsSingleDetails, (state, action): LoadingStateSlice => {
    return setPipelineRunDetailsFosstarsLoadingState(state, action, 'loading');
  }),
  on(FosstarsActions.setFosstarsSingleDetails, (state, action): LoadingStateSlice => {
    return setPipelineRunDetailsFosstarsLoadingState(state, action, 'completed');
  }),
  on(FosstarsActions.loadFosstarsSingleDetailsError, (state, action): LoadingStateSlice => {
    return setPipelineRunDetailsFosstarsLoadingState(state, action, 'error');
  }),
  on(FosstarsActions.loadFosstarsGroupSummary, (state): LoadingStateSlice => {
    return { ...state, fosstars: { ...state.fosstars, groupSummary: 'loading' } };
  }),
  on(FosstarsActions.setFosstarsGroupSummary, (state): LoadingStateSlice => {
    return { ...state, fosstars: { ...state.fosstars, groupSummary: 'completed' } };
  }),
  on(FosstarsActions.loadFosstarsGroupDetails, (state): LoadingStateSlice => {
    return { ...state, fosstars: { ...state.fosstars, groupDetails: 'loading' } };
  }),
  on(FosstarsActions.setFosstarsGroupDetails, (state): LoadingStateSlice => {
    return { ...state, fosstars: { ...state.fosstars, groupDetails: 'completed' } };
  }),
  on(FosstarsActions.loadFosstarsGroupSummaryNotFound, (state): LoadingStateSlice => {
    return { ...state, fosstars: { ...state.fosstars, groupSummary: 'not-found' } };
  }),
  on(FosstarsActions.loadFosstarsGroupSummaryError, (state): LoadingStateSlice => {
    return { ...state, fosstars: { ...state.fosstars, groupSummary: 'error' } };
  }),
  on(FosstarsActions.loadFosstarsGroupDetailsError, (state): LoadingStateSlice => {
    return { ...state, fosstars: { ...state.fosstars, groupDetails: 'error' } };
  }),

  // Malwarescan
  on(
    MalwarescanActions.loadMalwarescanGroupSummary,
    (state): LoadingStateSlice => ({
      ...state,
      malwarescan: { ...state.malwarescan, groupSummary: 'loading' },
    }),
  ),
  on(
    MalwarescanActions.setMalwarescanGroupSummary,
    (state): LoadingStateSlice => ({
      ...state,
      malwarescan: { ...state.malwarescan, groupSummary: 'completed' },
    }),
  ),
  on(
    MalwarescanActions.loadMalwarescanGroupSummaryError,
    (state): LoadingStateSlice => ({
      ...state,
      malwarescan: { ...state.malwarescan, groupSummary: 'error' },
    }),
  ),
  on(
    MalwarescanActions.loadMalwarescanGroupSummaryNotFound,
    (state): LoadingStateSlice => ({
      ...state,
      malwarescan: { ...state.malwarescan, groupSummary: 'not-found' },
    }),
  ),
  on(
    MalwarescanActions.loadMalwarescanGroupDetails,
    (state): LoadingStateSlice => ({
      ...state,
      malwarescan: { ...state.malwarescan, groupDetails: 'loading' },
    }),
  ),
  on(
    MalwarescanActions.setMalwarescanGroupDetails,
    (state): LoadingStateSlice => ({
      ...state,
      malwarescan: { ...state.malwarescan, groupDetails: 'completed' },
    }),
  ),
  on(
    MalwarescanActions.loadMalwarescanGroupDetailsError,
    (state): LoadingStateSlice => ({
      ...state,
      malwarescan: { ...state.malwarescan, groupDetails: 'error' },
    }),
  ),
  on(
    MalwarescanActions.loadPipelineRunMalwarescanInformation,
    (state, action): LoadingStateSlice => setPipelineRunMalwarescanLoadingState(state, action, 'loading'),
  ),
  on(
    MalwarescanActions.setPipelineRunMalwarescanInformation,
    (state, action): LoadingStateSlice => setPipelineRunMalwarescanLoadingState(state, action, 'completed'),
  ),
  on(
    MalwarescanActions.loadMalwarescanPipelineRunError,
    (state, action): LoadingStateSlice => setPipelineRunMalwarescanLoadingState(state, action, 'error'),
  ),
  on(
    MalwarescanActions.loadMalwarescanPipelineRunNotFound,
    (state, action): LoadingStateSlice => setPipelineRunMalwarescanLoadingState(state, action, 'not-found'),
  ),

  // Documents
  on(
    DocumentsActions.loadPipelineRunDocuments,
    (state, action): LoadingStateSlice => setPipelineRunDocumentsDetailsLoadingState(state, action.pipelineRunId, 'loading'),
  ),
  on(
    DocumentsActions.setPipelineRunDocuments,
    (state, action): LoadingStateSlice => setPipelineRunDocumentsDetailsLoadingState(state, action.pipelineRunId, 'completed'),
  ),
  on(
    DocumentsActions.loadPipelineRunDocumentsNotFound,
    (state, action): LoadingStateSlice => setPipelineRunDocumentsDetailsLoadingState(state, action.pipelineRunId, 'not-found'),
  ),
  on(
    DocumentsActions.loadPipelineRunDocumentsError,
    (state, action): LoadingStateSlice => setPipelineRunDocumentsDetailsLoadingState(state, action.pipelineRunId, 'error'),
  ),

  // Compliance Certificates
  on(
    ComplianceCertificatesActions.loadPipelineRunComplianceCertificates,
    (state, action): LoadingStateSlice => setPipelineRunComplianceCertificatesLoadingState(state, action.pipelineRunId, 'loading'),
  ),
  on(
    ComplianceCertificatesActions.setPipelineRunComplianceCertificates,
    (state, action): LoadingStateSlice => setPipelineRunComplianceCertificatesLoadingState(state, action.pipelineRunId, 'completed'),
  ),
  on(
    ComplianceCertificatesActions.loadPipelineRunComplianceCertificatesNotFound,
    (state, action): LoadingStateSlice => setPipelineRunComplianceCertificatesLoadingState(state, action.pipelineRunId, 'not-found'),
  ),
  on(
    ComplianceCertificatesActions.loadPipelineRunComplianceCertificatesError,
    (state, action): LoadingStateSlice => setPipelineRunComplianceCertificatesLoadingState(state, action.pipelineRunId, 'error'),
  ),

  // Processing Status
  on(
    ProcessingStatusActions.loadPipelineRunProcessingStatus,
    (state, action): LoadingStateSlice => setPipelineRunProcessingStatusSummaryLoadingState(state, action.pipelineRunId, 'loading'),
  ),
  on(
    ProcessingStatusActions.setPipelineRunProcessingStatus,
    (state, action): LoadingStateSlice => setPipelineRunProcessingStatusSummaryLoadingState(state, action.pipelineRunId, 'completed'),
  ),
  on(
    ProcessingStatusActions.loadPipelineRunProcessingStatusNotFound,
    (state, action): LoadingStateSlice => setPipelineRunProcessingStatusSummaryLoadingState(state, action.pipelineRunId, 'not-found'),
  ),
  on(
    ProcessingStatusActions.loadPipelineRunProcessingStatusError,
    (state, action): LoadingStateSlice => setPipelineRunProcessingStatusSummaryLoadingState(state, action.pipelineRunId, 'error'),
  ),
  // Api Metadata Validator
  // - Group Summary
  on(
    ApiMetadataValidatorActions.loadApiMetadataValidatorGroupSummary,
    (state): LoadingStateSlice => ({
      ...state,
      apiMetadataValidator: { ...state.apiMetadataValidator, groupSummary: 'loading' },
    }),
  ),
  on(
    ApiMetadataValidatorActions.setApiMetadataValidatorGroupSummary,
    (state): LoadingStateSlice => ({
      ...state,
      apiMetadataValidator: { ...state.apiMetadataValidator, groupSummary: 'completed' },
    }),
  ),
  on(
    ApiMetadataValidatorActions.loadApiMetadataValidatorGroupSummaryNotFound,
    (state): LoadingStateSlice => ({
      ...state,
      apiMetadataValidator: { ...state.apiMetadataValidator, groupSummary: 'not-found' },
    }),
  ),
  on(
    ApiMetadataValidatorActions.loadApiMetadataValidatorGroupSummaryError,
    (state): LoadingStateSlice => ({
      ...state,
      apiMetadataValidator: { ...state.apiMetadataValidator, groupSummary: 'error' },
    }),
  ),
  // - Group Details
  on(
    ApiMetadataValidatorActions.loadApiMetadataValidatorGroupDetails,
    (state): LoadingStateSlice => ({
      ...state,
      apiMetadataValidator: { ...state.apiMetadataValidator, groupDetails: 'loading' },
    }),
  ),
  on(
    ApiMetadataValidatorActions.setApiMetadataValidatorGroupDetails,
    (state): LoadingStateSlice => ({
      ...state,
      apiMetadataValidator: { ...state.apiMetadataValidator, groupDetails: 'completed' },
    }),
  ),
  on(
    ApiMetadataValidatorActions.loadApiMetadataValidatorGroupDetailsError,
    (state): LoadingStateSlice => ({
      ...state,
      apiMetadataValidator: { ...state.apiMetadataValidator, groupDetails: 'error' },
    }),
  ),
  // Pipeline Run Summary
  on(
    ApiMetadataValidatorActions.loadApiMetadataPipelineRunSummary,
    (state, action): LoadingStateSlice => setApiMetadataValidatorPipelineRunSummaryLoadingState(state, action.pipelineRunId, 'loading'),
  ),
  on(
    ApiMetadataValidatorActions.setApiMetadataValidatorPipelineRunSummary,
    (state, action): LoadingStateSlice => setApiMetadataValidatorPipelineRunSummaryLoadingState(state, action.pipelineRunId, 'completed'),
  ),
  on(
    ApiMetadataValidatorActions.loadApiMetadataValidatorPipelineRunSummaryNotFound,
    (state, action): LoadingStateSlice => setApiMetadataValidatorPipelineRunSummaryLoadingState(state, action.pipelineRunId, 'not-found'),
  ),
  on(
    ApiMetadataValidatorActions.loadApiMetadataValidatorPipelineRunSummaryError,
    (state, action): LoadingStateSlice => setApiMetadataValidatorPipelineRunSummaryLoadingState(state, action.pipelineRunId, 'error'),
  ),

  // Central Policy Results
  on(CentralPolicyResultsActions.loadCentralPolicyResultsSingleSummary, (state, action): LoadingStateSlice => {
    return setPipelineRunCentralPolicyResultsLoadingState(state, action, 'loading');
  }),
  on(CentralPolicyResultsActions.setCentralPolicyResultsSingleSummary, (state, action): LoadingStateSlice => {
    return setPipelineRunCentralPolicyResultsLoadingState(state, action, 'completed');
  }),
  on(CentralPolicyResultsActions.loadCentralPolicyResultsSingleSummaryError, (state, action): LoadingStateSlice => {
    return setPipelineRunCentralPolicyResultsLoadingState(state, action, 'error');
  }),
  on(CentralPolicyResultsActions.loadCentralPolicyResultsSingleSummaryNotFound, (state, action): LoadingStateSlice => {
    return setPipelineRunCentralPolicyResultsLoadingState(state, action, 'not-found');
  }),
  on(CentralPolicyResultsActions.loadCentralPolicyResultsSingleDetails, (state, action): LoadingStateSlice => {
    return setPipelineRunDetailsCentralPolicyResultsLoadingState(state, action, 'loading');
  }),
  on(CentralPolicyResultsActions.setCentralPolicyResultsSingleDetails, (state, action): LoadingStateSlice => {
    return setPipelineRunDetailsCentralPolicyResultsLoadingState(state, action, 'completed');
  }),
  on(CentralPolicyResultsActions.loadCentralPolicyResultsSingleDetailsError, (state, action): LoadingStateSlice => {
    return setPipelineRunDetailsCentralPolicyResultsLoadingState(state, action, 'error');
  }),

  on(
    CentralPolicyResultsActions.loadCentralPolicyResultsGroupSummary,
    (state): LoadingStateSlice => ({
      ...state,
      centralPolicyResults: { ...state.centralPolicyResults, groupSummary: 'loading' },
    }),
  ),
  on(
    CentralPolicyResultsActions.setCentralPolicyResultsGroupSummary,
    (state): LoadingStateSlice => ({
      ...state,
      centralPolicyResults: { ...state.centralPolicyResults, groupSummary: 'completed' },
    }),
  ),
  on(
    CentralPolicyResultsActions.loadCentralPolicyResultsGroupDetails,
    (state): LoadingStateSlice => ({
      ...state,
      centralPolicyResults: { ...state.centralPolicyResults, groupDetails: 'loading' },
    }),
  ),
  on(
    CentralPolicyResultsActions.setCentralPolicyResultsGroupDetails,
    (state): LoadingStateSlice => ({
      ...state,
      centralPolicyResults: { ...state.centralPolicyResults, groupDetails: 'completed' },
    }),
  ),
  on(
    CentralPolicyResultsActions.loadCentralPolicyResultsGroupSummaryError,
    (state): LoadingStateSlice => ({
      ...state,
      centralPolicyResults: { ...state.centralPolicyResults, groupSummary: 'error' },
    }),
  ),
  on(
    CentralPolicyResultsActions.loadCentralPolicyResultsGroupSummaryNotFound,
    (state): LoadingStateSlice => ({
      ...state,
      centralPolicyResults: { ...state.centralPolicyResults, groupSummary: 'not-found' },
    }),
  ),
  on(
    CentralPolicyResultsActions.loadCentralPolicyResultsGroupDetailsError,
    (state): LoadingStateSlice => ({
      ...state,
      centralPolicyResults: { ...state.centralPolicyResults, groupDetails: 'error' },
    }),
  ),

  // Custom Policy Results
  on(CustomPolicyResultsActions.loadCustomPolicyResultsSingleSummary, (state, action): LoadingStateSlice => {
    return setPipelineRunCustomPolicyResultsLoadingState(state, action, 'loading');
  }),
  on(CustomPolicyResultsActions.setCustomPolicyResultsSingleSummary, (state, action): LoadingStateSlice => {
    return setPipelineRunCustomPolicyResultsLoadingState(state, action, 'completed');
  }),
  on(CustomPolicyResultsActions.loadCustomPolicyResultsSingleSummaryError, (state, action): LoadingStateSlice => {
    return setPipelineRunCustomPolicyResultsLoadingState(state, action, 'error');
  }),
  on(CustomPolicyResultsActions.loadCustomPolicyResultsSingleSummaryNotFound, (state, action): LoadingStateSlice => {
    return setPipelineRunCustomPolicyResultsLoadingState(state, action, 'not-found');
  }),
  on(CustomPolicyResultsActions.loadCustomPolicyResultsSingleDetails, (state, action): LoadingStateSlice => {
    return setPipelineRunDetailsCustomPolicyResultsLoadingState(state, action, 'loading');
  }),
  on(CustomPolicyResultsActions.setCustomPolicyResultsSingleDetails, (state, action): LoadingStateSlice => {
    return setPipelineRunDetailsCustomPolicyResultsLoadingState(state, action, 'completed');
  }),
  on(CustomPolicyResultsActions.loadCustomPolicyResultsSingleDetailsError, (state, action): LoadingStateSlice => {
    return setPipelineRunDetailsCustomPolicyResultsLoadingState(state, action, 'error');
  }),

  on(
    CustomPolicyResultsActions.loadCustomPolicyResultsGroupSummary,
    (state): LoadingStateSlice => ({
      ...state,
      customPolicyResults: { ...state.customPolicyResults, groupSummary: 'loading' },
    }),
  ),
  on(
    CustomPolicyResultsActions.setCustomPolicyResultsGroupSummary,
    (state): LoadingStateSlice => ({
      ...state,
      customPolicyResults: { ...state.customPolicyResults, groupSummary: 'completed' },
    }),
  ),
  on(
    CustomPolicyResultsActions.loadCustomPolicyResultsGroupDetails,
    (state): LoadingStateSlice => ({
      ...state,
      customPolicyResults: { ...state.customPolicyResults, groupDetails: 'loading' },
    }),
  ),
  on(
    CustomPolicyResultsActions.setCustomPolicyResultsGroupDetails,
    (state): LoadingStateSlice => ({
      ...state,
      customPolicyResults: { ...state.customPolicyResults, groupDetails: 'completed' },
    }),
  ),
  on(
    CustomPolicyResultsActions.loadCustomPolicyResultsGroupSummaryError,
    (state): LoadingStateSlice => ({
      ...state,
      customPolicyResults: { ...state.customPolicyResults, groupSummary: 'error' },
    }),
  ),
  on(
    CustomPolicyResultsActions.loadCustomPolicyResultsGroupSummaryNotFound,
    (state): LoadingStateSlice => ({
      ...state,
      customPolicyResults: { ...state.customPolicyResults, groupSummary: 'not-found' },
    }),
  ),
  on(
    CustomPolicyResultsActions.loadCustomPolicyResultsGroupDetailsError,
    (state): LoadingStateSlice => ({
      ...state,
      customPolicyResults: { ...state.customPolicyResults, groupDetails: 'error' },
    }),
  ),

  // Central Policy Usage
  on(
    CentralPolicyUsageActions.loadCentralPolicyUsageGroupSummary,
    (state): LoadingStateSlice => ({
      ...state,
      centralPolicyUsage: { ...state.centralPolicyUsage, groupSummary: 'loading' },
    }),
  ),
  on(
    CentralPolicyUsageActions.setCentralPolicyUsageGroupSummary,
    (state): LoadingStateSlice => ({
      ...state,
      centralPolicyUsage: { ...state.centralPolicyUsage, groupSummary: 'completed' },
    }),
  ),
  on(
    CentralPolicyUsageActions.loadCentralPolicyUsageGroupSummaryError,
    (state): LoadingStateSlice => ({
      ...state,
      centralPolicyUsage: { ...state.centralPolicyUsage, groupSummary: 'error' },
    }),
  ),
  on(
    CentralPolicyUsageActions.loadCentralPolicyUsageGroupSummaryNotFound,
    (state): LoadingStateSlice => ({
      ...state,
      centralPolicyUsage: { ...state.centralPolicyUsage, groupSummary: 'not-found' },
    }),
  ),
  on(
    CentralPolicyUsageActions.loadCentralPolicyUsageGroupDetails,
    (state): LoadingStateSlice => ({
      ...state,
      centralPolicyUsage: { ...state.centralPolicyUsage, groupDetails: 'loading' },
    }),
  ),
  on(
    CentralPolicyUsageActions.setCentralPolicyUsageGroupDetails,
    (state): LoadingStateSlice => ({
      ...state,
      centralPolicyUsage: { ...state.centralPolicyUsage, groupDetails: 'completed' },
    }),
  ),
  on(
    CentralPolicyUsageActions.loadCentralPolicyUsageGroupDetailsError,
    (state): LoadingStateSlice => ({
      ...state,
      centralPolicyUsage: { ...state.centralPolicyUsage, groupDetails: 'error' },
    }),
  ),
  on(
    CentralPolicyUsageActions.loadCentralPolicyKeyGroupDetails,
    (state): LoadingStateSlice => ({
      ...state,
      centralPolicyUsage: { ...state.centralPolicyUsage, groupPolicyKeySummary: 'loading' },
    }),
  ),
  on(
    CentralPolicyUsageActions.setCentralPolicyKeyGroupDetails,
    (state): LoadingStateSlice => ({
      ...state,
      centralPolicyUsage: { ...state.centralPolicyUsage, groupPolicyKeySummary: 'completed' },
    }),
  ),
  on(
    CentralPolicyUsageActions.loadCentralPolicyKeyGroupDetailsError,
    (state): LoadingStateSlice => ({
      ...state,
      centralPolicyUsage: { ...state.centralPolicyUsage, groupPolicyKeySummary: 'error' },
    }),
  ),
  on(
    CentralPolicyUsageActions.loadCentralPolicyKeyGroupDetailsNotFound,
    (state): LoadingStateSlice => ({
      ...state,
      centralPolicyUsage: { ...state.centralPolicyUsage, groupPolicyKeySummary: 'not-found' },
    }),
  ),
  // Custom Policy Usage
  on(
    CustomPolicyUsageActions.loadCustomPolicyUsageGroupSummary,
    (state): LoadingStateSlice => ({
      ...state,
      customPolicyUsage: { ...state.customPolicyUsage, groupSummary: 'loading' },
    }),
  ),
  on(
    CustomPolicyUsageActions.setCustomPolicyUsageGroupSummary,
    (state): LoadingStateSlice => ({
      ...state,
      customPolicyUsage: { ...state.customPolicyUsage, groupSummary: 'completed' },
    }),
  ),
  on(
    CustomPolicyUsageActions.loadCustomPolicyUsageGroupSummaryError,
    (state): LoadingStateSlice => ({
      ...state,
      customPolicyUsage: { ...state.customPolicyUsage, groupSummary: 'error' },
    }),
  ),
  on(
    CustomPolicyUsageActions.loadCustomPolicyUsageGroupSummaryNotFound,
    (state): LoadingStateSlice => ({
      ...state,
      customPolicyUsage: { ...state.customPolicyUsage, groupSummary: 'not-found' },
    }),
  ),
  on(
    CustomPolicyUsageActions.loadCustomPolicyUsageGroupDetails,
    (state): LoadingStateSlice => ({
      ...state,
      customPolicyUsage: { ...state.customPolicyUsage, groupDetails: 'loading' },
    }),
  ),
  on(
    CustomPolicyUsageActions.setCustomPolicyUsageGroupDetails,
    (state): LoadingStateSlice => ({
      ...state,
      customPolicyUsage: { ...state.customPolicyUsage, groupDetails: 'completed' },
    }),
  ),
  on(
    CustomPolicyUsageActions.loadCustomPolicyUsageGroupDetailsError,
    (state): LoadingStateSlice => ({
      ...state,
      customPolicyUsage: { ...state.customPolicyUsage, groupDetails: 'error' },
    }),
  ),
  on(
    CustomPolicyUsageActions.loadCustomPolicyKeyGroupDetails,
    (state): LoadingStateSlice => ({
      ...state,
      customPolicyUsage: { ...state.customPolicyUsage, groupPolicyKeySummary: 'loading' },
    }),
  ),
  on(
    CustomPolicyUsageActions.setCustomPolicyKeyGroupDetails,
    (state): LoadingStateSlice => ({
      ...state,
      customPolicyUsage: { ...state.customPolicyUsage, groupPolicyKeySummary: 'completed' },
    }),
  ),
  on(
    CustomPolicyUsageActions.loadCustomPolicyKeyGroupDetailsError,
    (state): LoadingStateSlice => ({
      ...state,
      customPolicyUsage: { ...state.customPolicyUsage, groupPolicyKeySummary: 'error' },
    }),
  ),
  on(
    CustomPolicyUsageActions.loadCustomPolicyKeyGroupDetailsNotFound,
    (state): LoadingStateSlice => ({
      ...state,
      customPolicyUsage: { ...state.customPolicyUsage, groupPolicyKeySummary: 'not-found' },
    }),
  ),
);

const setPipelineRequirementsLoadingState = (state: LoadingStateSlice, action, loading: LoadingState) => {
  const pipelineRequirementsLoadingState = { ...state.testRegression.pipelineRequirements };
  pipelineRequirementsLoadingState[action.pipelineId] = loading;

  return {
    ...state,
    testRegression: {
      ...state.testRegression,
      pipelineRequirements: pipelineRequirementsLoadingState,
    },
  };
};

const setPipelineRegressionTestsLoadingState = (state: LoadingStateSlice, action, loading: LoadingState) => {
  const pipelineRunTestcasesLoadingState = { ...state.testRegression.pipelineRunTestcasesForRegression };
  pipelineRunTestcasesLoadingState[action.pipelineRunId] = loading;

  return {
    ...state,
    testRegression: {
      ...state.testRegression,
      pipelineRunTestcasesForRegression: pipelineRunTestcasesLoadingState,
    },
  };
};

const setPipelineRunDetailsFosstarsLoadingState = (state: LoadingStateSlice, action, loading: LoadingState) => {
  const pipelineRunId = action.pipelineRunId;
  const allPipelineRuns = { ...state.fosstars.pipelineRunDetailsLoadingState };
  allPipelineRuns[pipelineRunId] = loading;
  return {
    ...state,
    fosstars: { ...state.fosstars, pipelineRunDetailsLoadingState: allPipelineRuns },
  };
};

const setPipelineRunFosstarsLoadingState = (state: LoadingStateSlice, action, loading: LoadingState) => {
  const pipelineRunId = action.pipelineRunId;
  const allPipelineRuns = { ...state.fosstars.pipelineRunLoadingState };
  allPipelineRuns[pipelineRunId] = loading;
  return {
    ...state,
    fosstars: { ...state.fosstars, pipelineRunLoadingState: allPipelineRuns },
  };
};

const setPipelineRunCoverageInfoLoadingState = (state: LoadingStateSlice, action, loading: LoadingState) => {
  const pipelineRunId = action.pipelineRunId;
  const allPipelineRuns = { ...state.coverageInfo.pipelineRunLoadingState };
  allPipelineRuns[pipelineRunId] = loading;
  return {
    ...state,
    coverageInfo: { ...state.coverageInfo, pipelineRunLoadingState: allPipelineRuns },
  };
};

const setPipelineRunSonarQubeCoverageLoadingState = (state: LoadingStateSlice, action, loading: LoadingState) => {
  const pipelineRunId = action.pipelineRunId;
  const pipelineRunLoadingState = { ...state.sonarQubeCoverage.pipelineRunLoadingState };
  pipelineRunLoadingState[pipelineRunId] = loading;
  return {
    ...state,
    sonarQubeCoverage: {
      ...state.sonarQubeCoverage,
      pipelineRunLoadingState,
    },
  };
};

const setPipelineRunDeletionState = (state: LoadingStateSlice, action, loading: LoadingState) => {
  const pipelineRunId = action.pipelineRunId;
  const allPipelineRuns = { ...state.pipelineRunDeletion };
  allPipelineRuns[pipelineRunId] = loading;
  return {
    ...state,
    pipelineRunDeletion: { ...allPipelineRuns },
  };
};

const setPipelineRunProcessingStatusChangingState = (state: LoadingStateSlice, action, loading: LoadingState) => {
  const pipelineRunId = action.pipelineRunId;
  const allPipelineRuns = { ...state.pipelineRunProcessingStatusChanging };
  allPipelineRuns[pipelineRunId] = loading;
  return {
    ...state,
    pipelineRunProcessingStatusChanging: { ...allPipelineRuns },
  };
};

const setPipelineRunLoadingState = (state: LoadingStateSlice, action, loading: LoadingState) => {
  const pipelineId = action.pipelineId;
  const allPipelineRuns = { ...state.pipelineRuns };
  allPipelineRuns[pipelineId] = loading;
  return {
    ...state,
    pipelineRuns: { ...allPipelineRuns },
  };
};

const setPipelineRunATCLoadingState = (state: LoadingStateSlice, action, loading: LoadingState) => {
  const pipelineRunId = action.pipelineRunId;
  const allPipelineRunAtcInformation = { ...state.atc.allPipelineRunAtcInformation };
  allPipelineRunAtcInformation[pipelineRunId] = loading;
  return {
    ...state,
    atc: {
      ...state.atc,
      allPipelineRunAtcInformation,
    },
  };
};

const setPipelineRunMalwarescanLoadingState = (state: LoadingStateSlice, action, loading: LoadingState) => {
  const pipelineRunId = action.pipelineRunId;
  const pipelineRunLoadingState = { ...state.malwarescan.pipelineRunLoadingState };
  pipelineRunLoadingState[pipelineRunId] = loading;
  return {
    ...state,
    malwarescan: {
      ...state.malwarescan,
      pipelineRunLoadingState,
    },
  };
};

const setPipelineRunRequirementLoadingState = (state: LoadingStateSlice, action, loading: LoadingState) => {
  const pipelineRunId = action.pipelineRunId;
  const pipelineRunLoading = { ...state.fc2.pipelineRunRequirements };
  pipelineRunLoading[pipelineRunId] = loading;
  return {
    ...state,
    fc2: {
      ...state.fc2,
      pipelineRunRequirements: pipelineRunLoading,
    },
  };
};

const setPipelineRunTestcasesLoadingState = (state: LoadingStateSlice, action, loading: LoadingState) => {
  const pipelineRunId = action.pipelineRunId;
  const pipelineRunLoading = { ...state.fc2.pipelineRunTestcases };
  pipelineRunLoading[pipelineRunId] = loading;

  return {
    ...state,
    fc2: {
      ...state.fc2,
      pipelineRunTestcases: pipelineRunLoading,
    },
  };
};

const setFc1SinglePipelineRunLoadingState = (state: LoadingStateSlice, action, loading: LoadingState) => {
  const pipelineRunId = action.pipelineRunId;
  const pipelineRunLoading = { ...state.fc1.singleDetails };
  pipelineRunLoading[pipelineRunId] = loading;

  return {
    ...state,
    fc1: {
      ...state.fc1,
      singleDetails: pipelineRunLoading,
    },
  };
};

const setPsl1SinglePipelineRunLoadingState = (state: LoadingStateSlice, action, loading: LoadingState) => {
  const pipelineRunId = action.pipelineRunId;
  const pipelineRunLoading = { ...state.psl1.singleDetails };
  pipelineRunLoading[pipelineRunId] = loading;
  return {
    ...state,
    psl1: {
      ...state.psl1,
      singleDetails: pipelineRunLoading,
    },
  };
};

const setSlc25SinglePipelineRunLoadingState = (state: LoadingStateSlice, action, loading: LoadingState) => {
  const pipelineRunId = action.pipelineRunId;
  const pipelineRunLoading = { ...state.slc25.singleDetails };
  pipelineRunLoading[pipelineRunId] = loading;

  return {
    ...state,
    slc25: {
      ...state.slc25,
      singleDetails: pipelineRunLoading,
    },
  };
};

const setFc3SinglePipelineRunLoadingState = (state: LoadingStateSlice, action, loading: LoadingState) => {
  const pipelineRunId = action.pipelineRunId;
  const pipelineRunLoading = { ...state.fc3.singleDetails };
  pipelineRunLoading[pipelineRunId] = loading;

  return {
    ...state,
    fc3: {
      ...state.fc3,
      singleDetails: pipelineRunLoading,
    },
  };
};

const setPipelineRunDocumentsDetailsLoadingState = (state: LoadingStateSlice, pipelineRunId: number, loading: LoadingState) => {
  const pipelineRunLoading = { ...state.documents.pipelineRunDetailsLoadingState };
  pipelineRunLoading[pipelineRunId] = loading;

  return {
    ...state,
    documents: {
      ...state.documents,
      pipelineRunDetailsLoadingState: pipelineRunLoading,
    },
  };
};

const setPipelineRunComplianceCertificatesLoadingState = (state: LoadingStateSlice, pipelineRunId: number, loading: LoadingState) => {
  const pipelineRunLoading = { ...state.complianceCertificates.pipelineRunDetailsLoadingState };
  pipelineRunLoading[pipelineRunId] = loading;

  return {
    ...state,
    complianceCertificates: {
      ...state.complianceCertificates,
      pipelineRunDetailsLoadingState: pipelineRunLoading,
    },
  };
};

const setPipelineRunProcessingStatusSummaryLoadingState = (state: LoadingStateSlice, pipelineRunId: number, loading: LoadingState) => {
  const pipelineRunLoading = { ...state.processingStatus.pipelineRunLoadingState };
  pipelineRunLoading[pipelineRunId] = loading;

  return {
    ...state,
    processingStatus: {
      ...state.processingStatus,
      pipelineRunLoadingState: pipelineRunLoading,
    },
  };
};

const setApiMetadataValidatorPipelineRunSummaryLoadingState = (state: LoadingStateSlice, pipelineRunId: number, loading: LoadingState) => {
  const pipelineRunLoading = { ...state.apiMetadataValidator.pipelineRunLoadingState };
  pipelineRunLoading[pipelineRunId] = loading;

  return {
    ...state,
    apiMetadataValidator: {
      ...state.apiMetadataValidator,
      pipelineRunLoadingState: pipelineRunLoading,
    },
  };
};

// Central Policy Results
const setPipelineRunDetailsCentralPolicyResultsLoadingState = (state: LoadingStateSlice, action, loading: LoadingState) => {
  const pipelineRunId = action.pipelineRunId;
  const allPipelineRuns = { ...state.centralPolicyResults.pipelineRunDetailsLoadingState };
  allPipelineRuns[pipelineRunId] = loading;
  return {
    ...state,
    centralPolicyResults: { ...state.centralPolicyResults, pipelineRunDetailsLoadingState: allPipelineRuns },
  };
};

const setPipelineRunCentralPolicyResultsLoadingState = (state: LoadingStateSlice, action, loading: LoadingState) => {
  const pipelineRunId = action.pipelineRunId;
  const allPipelineRuns = { ...state.centralPolicyResults.pipelineRunLoadingState };
  allPipelineRuns[pipelineRunId] = loading;
  return {
    ...state,
    centralPolicyResults: { ...state.centralPolicyResults, pipelineRunLoadingState: allPipelineRuns },
  };
};

// Custom Policy Results
const setPipelineRunDetailsCustomPolicyResultsLoadingState = (state: LoadingStateSlice, action, loading: LoadingState) => {
  const pipelineRunId = action.pipelineRunId;
  const allPipelineRuns = { ...state.customPolicyResults.pipelineRunDetailsLoadingState };
  allPipelineRuns[pipelineRunId] = loading;
  return {
    ...state,
    customPolicyResults: { ...state.customPolicyResults, pipelineRunDetailsLoadingState: allPipelineRuns },
  };
};

const setPipelineRunCustomPolicyResultsLoadingState = (state: LoadingStateSlice, action, loading: LoadingState) => {
  const pipelineRunId = action.pipelineRunId;
  const allPipelineRuns = { ...state.customPolicyResults.pipelineRunLoadingState };
  allPipelineRuns[pipelineRunId] = loading;
  return {
    ...state,
    customPolicyResults: { ...state.customPolicyResults, pipelineRunLoadingState: allPipelineRuns },
  };
};
