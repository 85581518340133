import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CustomPolicyComplianceStatus,
  CustomPolicyKeyGroupDetails,
  CustomPolicyResultsPipelineRunDetail,
  GroupCustomPolicyUsageByPolicyKey,
  GroupCustomPolicyUsageSummary,
  GroupPolicyUsageByPolicyKey,
  GroupPolicyUsageSummary,
  PolicyComplianceStatus,
  PolicyComplianceStatusKey,
  PolicyKeyGroupDetails,
  PolicyResultsGroupDetails,
  PolicyResultsGroupSummary,
  PolicyResultsPipelineRunDetail,
  PolicyResultsPipelineRunSummary,
} from '../../../ngrx/model/policy-results';
import { EnvironmentService } from '../../environment.service';
import { GroupComplianceMode } from '../../model/group-compliance-mode';
import { buildRequestOptions } from '../request-utils';

@Injectable({
  providedIn: 'root',
})
export class AccessApiPolicyService {
  constructor(
    private readonly http: HttpClient,
    private readonly envService: EnvironmentService,
  ) {}

  _baseUrl: string;

  get baseUrl() {
    if (!this._baseUrl) {
      this._baseUrl = `https://${this.envService.getBaseUrl()}/zprs/gcp/access-api/api`;
    }
    return this._baseUrl;
  }

  getPipelineRunCentralPolicyResultsDetails(pipelineRunId: number): Observable<PolicyResultsPipelineRunDetail[]> {
    return this.http.get<PolicyResultsPipelineRunDetail[]>(`${this.pipelineRunUrl(pipelineRunId)}/details`).pipe(
      map((responses) => {
        return responses.map((response) => {
          return {
            ...response,
            policyComplianceStatus: PolicyComplianceStatus.getByKey(response.policyComplianceStatus as any),
            policyText: `${response.policyKey}: ${response.policyLabel}`,
          };
        });
      }),
    );
  }

  getPipelineRunCentralPolicyResultsSummary(pipelineRunId: number): Observable<PolicyResultsPipelineRunSummary> {
    return this.http.get<PolicyResultsPipelineRunSummary>(`${this.pipelineRunUrl(pipelineRunId)}/summary`);
  }

  getGroupCentralPolicyResultsSummary(groupId: string, mode: GroupComplianceMode): Observable<PolicyResultsGroupSummary> {
    return this.http.get<PolicyResultsGroupSummary>(`${this.groupUrl(groupId)}/summary`, buildRequestOptions(mode));
  }

  getGroupCentralPolicyResultsDetails(groupId: string, mode: GroupComplianceMode): Observable<PolicyResultsGroupDetails[]> {
    return this.http.get<PolicyResultsGroupDetails[]>(`${this.groupUrl(groupId)}/details`, buildRequestOptions(mode));
  }

  getPipelineRunCustomPolicyResultsDetails(pipelineRunId: number): Observable<CustomPolicyResultsPipelineRunDetail[]> {
    return this.http.get<CustomPolicyResultsPipelineRunDetail[]>(`${this.pipelineRunUrlOfCustomPolicies(pipelineRunId)}/details`).pipe(
      map((responses) => {
        return responses.map((response) => {
          return {
            ...response,
            customPolicyResultStatus: PolicyComplianceStatus.getByKey(response.customPolicyResultStatus as any),
            customPolicyText: `${response.customPolicyKey}: ${response.customPolicyLabel}`,
          };
        });
      }),
    );
  }

  getPipelineRunCustomPolicyResultsSummary(pipelineRunId: number): Observable<PolicyResultsPipelineRunSummary> {
    return this.http.get<PolicyResultsPipelineRunSummary>(`${this.pipelineRunUrlOfCustomPolicies(pipelineRunId)}/summary`);
  }

  getGroupCustomPolicyResultsSummary(groupId: string, mode: GroupComplianceMode): Observable<PolicyResultsGroupSummary> {
    return this.http.get<PolicyResultsGroupSummary>(`${this.groupUrlOfCustomPolicies(groupId)}/summary`, buildRequestOptions(mode));
  }

  getGroupCustomPolicyResultsDetails(groupId: string, mode: GroupComplianceMode): Observable<PolicyResultsGroupDetails[]> {
    return this.http.get<PolicyResultsGroupDetails[]>(`${this.groupUrlOfCustomPolicies(groupId)}/details`, buildRequestOptions(mode));
  }

  private pipelineRunUrl(pipelineRunId?: number, version = 'v1'): string {
    return `${this.baseUrl}/${version}/policies/pipelineRun/${pipelineRunId}`;
  }

  private groupUrl(groupId: string, version = 'v1'): string {
    return `${this.baseUrl}/${version}/policies/group/${groupId}`;
  }

  buildFileLinkUrlForPolicyResult(policyResultId: number, version = 'v1'): string {
    return (
      `${this.baseUrl}/${version}/policies/result/${policyResultId}/download` + `?x-delivery-guid=${this.envService.getDeliveryGuid()}`
    );
  }

  private pipelineRunUrlOfCustomPolicies(pipelineRunId?: number, version = 'v1'): string {
    return `${this.baseUrl}/${version}/custom-policies/pipelineRun/${pipelineRunId}`;
  }

  private groupUrlOfCustomPolicies(groupId: string, version = 'v1'): string {
    return `${this.baseUrl}/${version}/custom-policies/group/${groupId}`;
  }

  buildFileLinkUrlForCustomPolicyResult(policyResultId: number, version = 'v1'): string {
    return (
      `${this.baseUrl}/${version}/custom-policies/result/${policyResultId}/download` +
      `?x-delivery-guid=${this.envService.getDeliveryGuid()}`
    );
  }

  getGroupCentralPolicyUsageSummary(groupId: string, mode: GroupComplianceMode): Observable<GroupPolicyUsageSummary> {
    return this.http.get<GroupPolicyUsageSummary>(`${this.groupUrl(groupId)}/usageSummary`, buildRequestOptions(mode));
  }

  getGroupCentralPolicyUsageDetails(groupId: string, mode: GroupComplianceMode): Observable<GroupPolicyUsageByPolicyKey[]> {
    return this.http.get<GroupPolicyUsageByPolicyKey[]>(`${this.groupUrl(groupId)}/usageDetails`, buildRequestOptions(mode)).pipe(
      map((responses) => {
        return responses.map((response) => {
          return {
            ...response,
            policyText: `${response.policyKey}: ${response.policyLabel}`,
          };
        });
      }),
    );
  }

  getPolicyKeyGroupDetails(groupId: string, policyKey: string, mode: GroupComplianceMode): Observable<PolicyKeyGroupDetails[]> {
    return this.http.get<PolicyKeyGroupDetails[]>(`${this.groupUrl(groupId)}/policy/${policyKey}/details`, buildRequestOptions(mode)).pipe(
      map((responses) => {
        return responses.map((response) => {
          return {
            ...response,
            policyComplianceStatus: PolicyComplianceStatus.getByKey(response.policyComplianceStatus as any),
            policyText: `${response.policyKey}: ${response.policyLabel}`,
            status: response.isIgnored
              ? PolicyComplianceStatus.getByKey(PolicyComplianceStatusKey.IGNORED)
              : PolicyComplianceStatus.getByKey(response.policyComplianceStatus as any),
          };
        });
      }),
    );
  }

  getGroupCustomPolicyUsageSummary(groupId: string, mode: GroupComplianceMode): Observable<GroupPolicyUsageSummary> {
    return this.http
      .get<GroupCustomPolicyUsageSummary>(`${this.groupUrlOfCustomPolicies(groupId)}/usageSummary`, buildRequestOptions(mode))
      .pipe(
        map((response) => {
          return {
            groupComplianceStatus: response.groupCustomPolicyResultStatus as any,
            policyCount: response.customPolicyCount as any,
          };
        }),
      );
  }
  getGroupCustomPolicyUsageDetails(groupId: string, mode: GroupComplianceMode): Observable<GroupCustomPolicyUsageByPolicyKey[]> {
    return this.http
      .get<GroupCustomPolicyUsageByPolicyKey[]>(`${this.groupUrlOfCustomPolicies(groupId)}/usageDetails`, buildRequestOptions(mode))
      .pipe(
        map((responses) => {
          return responses.map((response) => {
            return {
              ...response,
              customPolicyText: `${response.customPolicyKey}: ${response.customPolicyLabel}`,
            };
          });
        }),
      );
  }

  getCustomPolicyKeyGroupDetails(groupId: string, policyKey: string, mode: GroupComplianceMode): Observable<CustomPolicyKeyGroupDetails[]> {
    return this.http
      .get<
        CustomPolicyKeyGroupDetails[]
      >(`${this.groupUrlOfCustomPolicies(groupId)}/policy/${policyKey}/details`, buildRequestOptions(mode))
      .pipe(
        map((responses) => {
          return responses.map((response) => {
            return {
              ...response,
              customPolicyComplianceStatus: CustomPolicyComplianceStatus.getByKey(response.customPolicyResultStatus as any),
              customPolicyText: `${response.customPolicyKey}: ${response.customPolicyLabel}`,
            };
          });
        }),
      );
  }
}
