import { createReducer, on } from '@ngrx/store';
import { Pipeline } from '../../../model/pipeline/pipeline';
import { PipelineSlice } from '../app-state';
import { initialPipelineSlice } from '../initial-slices';
import {
  removePipelineFromDeliverySuccessful,
  resetPipelineResults,
  setPipeline,
  setPipelinesByQuery,
  setPipelinesForDelivery,
  setPipelinesWithProcessingStateForDelivery,
} from './pipeline.actions';

export const pipelineReducer = createReducer(
  initialPipelineSlice,
  on(setPipelinesForDelivery, setPipelinesWithProcessingStateForDelivery, (state, props): PipelineSlice => {
    return { ...state, pipelines: props.pipelines };
  }),
  on(removePipelineFromDeliverySuccessful, (state, props): PipelineSlice => {
    let pipelines: Pipeline[] = [...state.pipelines];
    pipelines = pipelines.filter((pipeline) => pipeline.pipelineId !== props.pipelineId);
    return { ...state, pipelines };
  }),
  on(setPipelinesByQuery, (state, props): PipelineSlice => {
    return { ...state, searchedPipelines: props.pipelines, searchString: props.searchString };
  }),
  on(resetPipelineResults, (state): PipelineSlice => {
    return { ...state, searchedPipelines: [], searchString: '' };
  }),
  on(setPipeline, (state, props): PipelineSlice => {
    let pipelines: Pipeline[] = [...state.pipelines];
    const otherPipelines = pipelines.filter((pipeline) => pipeline.pipelineId !== props.pipeline.pipelineId);
    pipelines = [...otherPipelines, props.pipeline];
    return { ...state, pipelines };
  }),
);
