import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ParentMessage } from './model/environment/environment-data';
import { Delivery } from './model/group/group';
import { loadSiriusDelivery, setSelectedDelivery } from './ngrx/store/deliveries/deliveries.actions';
import { selectSiriusDelivery } from './ngrx/store/deliveries/deliveries.selectors';
import { LoadingState } from './ngrx/store/loading-state/loading-state.model';
import { selectSiriusDeliveryLoadingState } from './ngrx/store/loading-state/loading-state.selectors';
import { EnvironmentService } from './shared/environment.service';
import { BannerService } from './shared/banner-service/banner.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private deliveryGuid;
  private baseUrl;

  siriusDelivery$: Observable<Delivery>;
  siriusDeliveryLoadingState$: Observable<LoadingState>;

  constructor(
    private environmentService: EnvironmentService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private readonly store$: Store,
    private readonly bannerService: BannerService,
  ) {
    this.siriusDelivery$ = this.store$.select(selectSiriusDelivery).pipe(
      map((delivery) => {
        if (delivery) {
          this.environmentService.groupId = delivery?.id;
          this.store$.dispatch(setSelectedDelivery({ delivery }));
        }
        return delivery;
      }),
    );

    this.siriusDeliveryLoadingState$ = this.store$.select(selectSiriusDeliveryLoadingState);

    window.addEventListener('message', this.handleIFrameMessage.bind(this), false);
    this.matIconRegistry.addSvgIcon('critical', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/whitesource/critical.svg'));
    this.matIconRegistry.addSvgIcon('high', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/whitesource/high.svg'));
    this.matIconRegistry.addSvgIcon('low', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/whitesource/low.svg'));
    this.matIconRegistry.addSvgIcon('medium', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/whitesource/medium.svg'));
    this.matIconRegistry.addSvgIcon('unknown', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/whitesource/unknown.svg'));
    this.matIconRegistry.addSvgIcon(
      'sonar_blocker',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/sonarqube/sonar_blocker_icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'sonar_critical',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/sonarqube/sonar_critical_icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'sonar_major',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/sonarqube/sonar_major_icon.svg'),
    );
    this.matIconRegistry.addSvgIcon('docker-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/docker-icon.svg'));
  }

  /**
   * Handles parent message of the iframe
   * @param message of the parent
   */
  async handleIFrameMessage(message: MessageEvent) {
    if (!message.data) {
      return;
    }
    await this.environmentService.loadEnvironmentData();

    if (message.data.id !== this.environmentService.getParentMessageId()) {
      return;
    }
    console.log('Got Sirius Parent Message ' + JSON.stringify(message.data));

    const parentMessage: ParentMessage = {
      baseUrl: message.data.base,
      deliveryGuid: message.data.guid,
      readOnly: message.data.readOnly,
      deliveryName: message.data.deliveryName,
      isLocked: message.data.isLocked,
      activationDate: message?.data?.activationDate ? new Date(message.data.activationDate) : null,
    };

    const oldDeliveryGuid: string = this.environmentService.getDeliveryGuid();

    this.environmentService.parentMessage = parentMessage;

    this.deliveryGuid = this.environmentService.getDeliveryGuid();
    this.baseUrl = this.environmentService.getBaseUrl();

    if (this.deliveryGuid && this.baseUrl && oldDeliveryGuid !== this.deliveryGuid) {
      this.store$.dispatch(loadSiriusDelivery({ deliveryGuid: this.deliveryGuid }));
    }
  }

  ngOnInit() {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.environmentService.loadEnvironmentData().then(() => {
      // Get delivery Guid from environment if not production to simulate inclusion in SIRIUS
      this.deliveryGuid = this.environmentService.getDeliveryGuid();

      this.baseUrl = this.environmentService.getBaseUrl();

      if (this.deliveryGuid && this.baseUrl) {
        this.store$.dispatch(loadSiriusDelivery({ deliveryGuid: this.deliveryGuid }));
      }
    });
  }

  isBannerVisible(): boolean {
    return this.bannerService.isBannerVisible();
  }

  isInvalidBrowser(): boolean {
    return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  }
}
