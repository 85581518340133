import { createReducer, on } from '@ngrx/store';
import { SonarQubeCoverageSlice } from '../app-state';
import { initialSonarQubeCoverageSlice } from '../initial-slices';
import * as SonarQubeCoverageActions from './sonar-qube-coverage.actions';

export const sonarQubeCoverageReducer = createReducer(
  initialSonarQubeCoverageSlice,
  on(SonarQubeCoverageActions.loadSonarQubeCoverageGroupSummary, (state): SonarQubeCoverageSlice => {
    return { ...state, groupSummary: initialSonarQubeCoverageSlice.groupSummary };
  }),
  on(SonarQubeCoverageActions.setSonarQubeCoverageGroupSummary, (state, props): SonarQubeCoverageSlice => {
    return { ...state, groupSummary: props.groupSummary };
  }),
  on(SonarQubeCoverageActions.setSonarQubeCoverageGroupDetails, (state, props): SonarQubeCoverageSlice => {
    return { ...state, groupDetails: [...props.groupDetails] };
  }),
  on(SonarQubeCoverageActions.setPipelineRunSonarQubeCoverage, (state, props): SonarQubeCoverageSlice => {
    const selectedPipelineRuns = { ...state.selectedPipelineRuns };
    selectedPipelineRuns[props.pipelineRunId] = props.coverage;
    return { ...state, selectedPipelineRuns };
  }),
);
