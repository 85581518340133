export enum PipelineRunExecutionStatusKey {
  INITIALIZED = 'initialized',
  SUCCESS = 'success',
  ERROR = 'error',
  FAILURE = 'failure',
  ABORTED = 'aborted',
  UNKNOWN = 'unknown',
}

export class PipelineRunExecutionStatus {
  // if an Execution Status has no displayKey, it is not displayed in the UI
  static INITIALIZED = new PipelineRunExecutionStatus(PipelineRunExecutionStatusKey.INITIALIZED, 'Running');
  static SUCCESS = new PipelineRunExecutionStatus(PipelineRunExecutionStatusKey.SUCCESS, 'Success');
  static FAILURE = new PipelineRunExecutionStatus(PipelineRunExecutionStatusKey.FAILURE, 'Failure');
  static ERROR = new PipelineRunExecutionStatus(PipelineRunExecutionStatusKey.ERROR, 'Error');
  static ABORTED = new PipelineRunExecutionStatus(PipelineRunExecutionStatusKey.ABORTED, 'Aborted');
  static UNKNOWN = new PipelineRunExecutionStatus(PipelineRunExecutionStatusKey.UNKNOWN, null);

  constructor(
    public key: PipelineRunExecutionStatusKey,
    public displayKey: string,
  ) {}

  static getByKey(key: string) {
    return Object.values(PipelineRunExecutionStatus).find((entry) => entry.key == key);
  }

  toString() {
    return this.key;
  }

  toHttpParam() {
    return this.key ? { executionsStatus: this.key } : {};
  }
}

export const ExecutionStatuses: PipelineRunExecutionStatus[] = Object.keys(PipelineRunExecutionStatus)
  .filter((key) => PipelineRunExecutionStatus[key] instanceof PipelineRunExecutionStatus)
  .map((key) => PipelineRunExecutionStatus[key]);
