import { createReducer, on } from '@ngrx/store';
import { initialMicrodeliveriesSlice } from '../initial-slices';
import * as MicrodeliveriesActions from '../microdeliveries/microdeliveries.actions';
import { GroupTypeMicrodelivery } from '../../model/group-type-microdelivery';
import { MicrodeliveriesSlice } from '../app-state';

export const microdeliveriesReducer = createReducer(
  initialMicrodeliveriesSlice,
  on(MicrodeliveriesActions.clearMicrodeliveriesSlice, (state, props): MicrodeliveriesSlice => {
    return initialMicrodeliveriesSlice;
  }),
  on(MicrodeliveriesActions.addMicrodeliveriesFromJira, (state, props): MicrodeliveriesSlice => {
    const activeMicrodeliveries = new Map<number, GroupTypeMicrodelivery>(state.activeMicrodeliveries);
    props.microdeliveries.forEach((md) => {
      activeMicrodeliveries.set(md.jiraIssueId, md);
    });
    return { ...state, activeMicrodeliveries };
  }),
  on(MicrodeliveriesActions.setMicrodeliveriesFromCache, (state, props): MicrodeliveriesSlice => {
    const displayedMicrodeliveries = new Map<number, GroupTypeMicrodelivery>(state.displayedMicrodeliveries);
    props.microdeliveries.forEach((md) => {
      displayedMicrodeliveries.set(md.jiraIssueId, md);
    });
    return { ...state, displayedMicrodeliveries };
  }),
  on(MicrodeliveriesActions.updateCacheSuccessful, (state, props): MicrodeliveriesSlice => {
    const updatedMicrodeliveries = new Map<number, GroupTypeMicrodelivery>();
    props.microdeliveries.forEach((md) => {
      // persist info if pipelines where set by components in order to disable add/delete pipeline functionality
      const microdelivery: GroupTypeMicrodelivery = {
        ...md,
        linkedPipelineIds: state.activeMicrodeliveries.get(md.jiraIssueId)?.linkedPipelineIds,
      } as GroupTypeMicrodelivery;
      updatedMicrodeliveries.set(md.jiraIssueId, microdelivery);
    });
    return { ...state, displayedMicrodeliveries: updatedMicrodeliveries, activeMicrodeliveries: updatedMicrodeliveries };
  }),
);
