import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ComplianceInfoSummary, ComplianceStatus, Fc1ComplianceInfoDetails } from '../../ngrx/model/compliance-information';
import { loadFc1GroupDetails, loadFc1GroupSummary, loadFc1SingleDetails } from '../../ngrx/store/fc-1/fc-1.actions';
import { selectFc1GroupSummary, selectFc1SingleDetailById } from '../../ngrx/store/fc-1/fc-1.selectors';
import {
  selectIsFc1GroupSummaryLoadingNotFound,
  selectIsFc1SingleLoading,
  selectIsFc1SingleLoadingError,
  selectIsFc1SingleLoadingNotFound,
  selectIsFc1SummaryLoading,
  selectIsFc1SummaryLoadingError,
} from '../../ngrx/store/loading-state/loading-state.selectors';
import { GroupComplianceMode } from '../../shared/model/group-compliance-mode';
import { Fc1DetailsDialogComponent } from './fc1-details-dialog/fc1-details-dialog.component';

@Component({
  selector: 'app-list-item-kpis-fc1',
  templateUrl: './list-item-kpis-fc1.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemKpisFc1Component implements OnInit, OnChanges {
  public ComplianceStatus = ComplianceStatus;

  @Input() groupId: string;
  @Input() groupComplianceMode: GroupComplianceMode;
  @Input() currentPipelineRunId: number;

  public fc1complianceInfo$!: Observable<Fc1ComplianceInfoDetails | ComplianceInfoSummary>;
  public isLoading$!: Observable<boolean>;
  public isLoadingError$!: Observable<boolean>;
  public isLoadingNotFound$!: Observable<boolean>;

  constructor(
    private readonly store$: Store,
    private readonly dialog: MatDialog,
  ) {}

  ngOnInit() {
    if (!this.currentPipelineRunId) {
      this.isLoading$ = this.store$.select(selectIsFc1SummaryLoading);
      this.isLoadingError$ = this.store$.select(selectIsFc1SummaryLoadingError);
      this.isLoadingNotFound$ = this.store$.select(selectIsFc1GroupSummaryLoadingNotFound);
      this.fc1complianceInfo$ = this.store$.select(selectFc1GroupSummary);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentPipelineRunId) {
      this.loadPipelineRunInfo();
    }
  }

  loadPipelineRunInfo() {
    const pipelineRunId = this.currentPipelineRunId;
    this.fc1complianceInfo$ = this.store$.select(selectFc1SingleDetailById(pipelineRunId));
    this.isLoading$ = this.store$.select(selectIsFc1SingleLoading(pipelineRunId));
    this.isLoadingError$ = this.store$.select(selectIsFc1SingleLoadingError(pipelineRunId));
    this.isLoadingNotFound$ = this.store$.select(selectIsFc1SingleLoadingNotFound(pipelineRunId));
  }

  reloadInfo() {
    if (!this.currentPipelineRunId) {
      this.store$.dispatch(loadFc1GroupSummary({ deliveryId: this.groupId, groupComplianceMode: this.groupComplianceMode }));
    } else {
      this.store$.dispatch(loadFc1SingleDetails({ pipelineRunId: this.currentPipelineRunId }));
    }
  }

  public onSummaryStatusClicked(): void {
    if (!this.currentPipelineRunId) {
      const data = { deliveryId: this.groupId, groupComplianceMode: this.groupComplianceMode };
      this.store$.dispatch(loadFc1GroupDetails(data));
      this.dialog.open(Fc1DetailsDialogComponent, { data });
    }
  }
}
