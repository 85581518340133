import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DocumentsSlice } from '../app-state';
import { selectPipelineRunUnprocessedFilesMapped } from '../processing-status/processing-status.selectors';
import { selectPipelineRunComplianceCertificates } from '../compliance-certificates/compliance-certificates.selectors';

export const selectDocumentsSlice = createFeatureSelector<DocumentsSlice>('documentsSlice');

export const selectPipelineRunDocuments = (pipelineRunId: number) =>
  createSelector(selectDocumentsSlice, (documentsSlice) => {
    return documentsSlice.pipelineRunsDetails[pipelineRunId];
  });

export const selectPipelineRunCumulusDocuments = (pipelineRunId) =>
  createSelector(
    selectPipelineRunDocuments(pipelineRunId),
    selectPipelineRunUnprocessedFilesMapped(pipelineRunId),
    selectPipelineRunComplianceCertificates(pipelineRunId),
    (pipelineRunDocuments, unprocessedFiles, complianceCertificates) => [
      ...unprocessedFiles,
      ...(pipelineRunDocuments || []),
      ...(complianceCertificates || []),
    ],
  );
