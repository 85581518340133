import { Component, EventEmitter, Output } from '@angular/core';
import { EnvironmentService } from '../shared/environment.service';
import { AddPipelineDialogService } from '../add-pipeline-dialog/add-pipeline-dialog.service';

@Component({
  selector: 'app-start-screen',
  templateUrl: './start-screen.component.html',
  styleUrls: ['./start-screen.component.scss'],
})
export class StartScreenComponent {
  @Output() groupCreated = new EventEmitter();
  readOnly: boolean;

  hyperSpaceUrl: string;

  constructor(
    private readonly addPipelineDialogService: AddPipelineDialogService,
    private readonly environmentService: EnvironmentService,
  ) {
    this.hyperSpaceUrl = environmentService.getHyperSpaceUrl();
    this.readOnly = this.environmentService.isReadOnly();
  }

  openPipelineDialog() {
    this.addPipelineDialogService.openDialog({ data: { groupCreated: this.groupCreated } });
  }
}
