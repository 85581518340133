import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CodeCoverageInfo } from '../../../model/code-coverage/code-coverage.model';

export const thresholds = {
  0: { color: '#D62E1A' },
  50: { color: '#f5a623' },
  81: { color: '#389c86' },
};

@Component({
  selector: 'app-coverage-info',
  templateUrl: './coverage-info.component.html',
  styleUrls: ['./coverage-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverageInfoComponent {
  @Input() coverageInfo: CodeCoverageInfo;

  @Input()
  threshold: { branchRate: any; lineRate: any };

  @Input() size = 100;

  thresholds = thresholds;

  gaugeType = 'semi';

  max = 100;
  min = 0;
}
