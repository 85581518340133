import { Component, Input } from '@angular/core';
import { RequirementDetail } from '../../../../model/requirement-detail/requirement-detail';
import { orderBy, process, SortDescriptor } from '@progress/kendo-data-query';
import { RequirementTestcaseResult } from '../../../../model/requirement-detail/requirement-detail-testcase';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { PipelineRunModeIds } from '../../../../model/pipeline-run/pipeline-run';
import { Clipboard } from '@angular/cdk/clipboard';
import { getStatusText, isRequirementsMappingFailed, testcaseWithName } from './test-utils';

@Component({
  selector: 'app-tests',
  templateUrl: './tests.component.html',
  styleUrls: ['./tests.component.scss'],
})
export class TestsComponent {
  testcases: RequirementTestcaseResult[];
  sort: SortDescriptor[] = [
    {
      field: 'sort',
      dir: 'asc',
    },
  ];
  dataSource: GridDataResult;
  requirementDetail: RequirementDetail;
  isOptimizedPipeline = false;

  getStatusText = getStatusText;
  isRequirementsMappingFailed = isRequirementsMappingFailed;

  @Input() isPipelineRunView = false;

  @Input() set requirement(requirement: RequirementDetail) {
    this.requirementDetail = requirement;
    this.testcases = [];
    if (requirement) {
      requirement.testcases.forEach((testcase) => {
        if (
          testcase.pipelineRunModeId === PipelineRunModeIds.Optimized_Commit ||
          testcase.pipelineRunModeId === PipelineRunModeIds.Optimized_Scheduled
        ) {
          this.isOptimizedPipeline = true;
        }

        if (testcase.name) {
          testcase.name.forEach((name) => this.testcases.push(testcaseWithName(testcase, name)));
        }
      });
    }
    this.testcases = orderBy(this.testcases, this.sort);
    this.dataSource = process(this.testcases, {});
  }

  constructor(private readonly clipboard: Clipboard) {}

  copyValue(sourcePathValue: string) {
    this.clipboard.copy(sourcePathValue);
  }

  highlightTestCaseInDetail(testCase: string, backlogKey: string) {
    const requirementRegExp = new RegExp(`${backlogKey.replace(/[-_]/g, '[_-]')}`, 'gi');
    return testCase.replace(requirementRegExp, `<b>${backlogKey}</b>`);
  }

  checkBuildUrl(url: string) {
    return url && url !== 'string';
  }

  getStatusColor(testcase: RequirementTestcaseResult): string {
    if (testcase.hasIncorrectVersion) {
      return '#edaa0a';
    } else if (testcase.untested) {
      return '#edaa0a';
    } else if (testcase.status) {
      if (testcase.skipped) {
        return '#edaa0a';
      }
      return '#389c86';
    } else {
      return '#D62E1A';
    }
  }
}
