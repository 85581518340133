import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, distinct, process, State } from '@progress/kendo-data-query';
import { Observable, Subscription } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { AddPipelineDialogService } from '../add-pipeline-dialog/add-pipeline-dialog.service';
import { DeliveryProcessingStatus } from '../model/environment/environment-data';
import { Delivery } from '../model/group/group';
import { Requirement } from '../model/jira/release-item';
import { PipelineRunModeIds } from '../model/pipeline-run/pipeline-run';
import { Pipeline } from '../model/pipeline/pipeline';
import { AggregatedProcessingState } from '../ngrx/model/processing-state';
import { setSelectedComplianceShowMode } from '../ngrx/store/deliveries/deliveries.actions';
import {
  selectCalculatedDeliveryProcessingStatus,
  selectSelectedDelivery,
  selectSelectedGroupComplianceMode,
} from '../ngrx/store/deliveries/deliveries.selectors';
import { selectDeliveryRequirements } from '../ngrx/store/fc-2/fc-2.selectors';
import { selectIsPipelinesForDeliveryLoading } from '../ngrx/store/loading-state/loading-state.selectors';
import { selectIsDisplayedMicrodeliveriesEmpty } from '../ngrx/store/microdeliveries/microdeliveries.selectors';
import { setSelectedPipelineRun } from '../ngrx/store/pipeline-run/pipeline-run.actions';
import { loadPipelinesForDelivery, removePipelineFromDelivery } from '../ngrx/store/pipeline/pipeline.actions';
import { selectAggregatedProcessingState, selectPipelinesSorted } from '../ngrx/store/pipeline/pipeline.selectors';
import { EnvironmentService } from '../shared/environment.service';
import { GroupComplianceMode, GroupComplianceModes } from '../shared/model/group-compliance-mode';
import { ProcessingStatus } from '../shared/model/processing-status';
import { ProcessingStatusService } from '../shared/processing-status/processing-status.service';
import { ShowRunDetailsEventData } from './run-header-info/run-header-info.component';
import { PipelineRunExecutionStatusKey } from '../model/pipeline/execution-status/pipeline-run-execution-status';

@Component({
  selector: 'app-pipeline-list',
  templateUrl: './pipeline-list.component.html',
  styleUrls: ['./pipeline-list.component.scss'],
})
export class PipelineListComponent implements OnDestroy, OnInit {
  selectedDelivery$!: Observable<Delivery>;
  pipelines$!: Observable<Pipeline[]>;
  pipelinesLoading$!: Observable<boolean>;
  deliveryProcessingStatus$!: Observable<DeliveryProcessingStatus>;
  deliveryRequirements$!: Observable<Requirement[]>;
  processingState$: Observable<AggregatedProcessingState>;

  isAnyPipelineJiraComponentMapped = false;
  public filter: CompositeFilterDescriptor;
  public gridData: GridDataResult;

  dataSource: GridDataResult;
  selectedGroupComplianceMode$: Observable<GroupComplianceMode>;

  pipelineRunModeIds = PipelineRunModeIds;

  // UI logic vars
  loading = false;
  readOnly: boolean;
  selectIsDisplayedMicrodeliveriesEmpty$: Observable<boolean>;
  expandedDetailKeys: any[] = [];

  // subscriptions
  subscriptions = new Subscription();

  groupComplianceModes = GroupComplianceModes;
  pipelineRunCountInMode: number;

  constructor(
    private readonly environmentService: EnvironmentService,
    private readonly addPipelineDialogService: AddPipelineDialogService,
    private readonly translateService: TranslateService,
    private readonly processingStatusService: ProcessingStatusService,
    private readonly store$: Store,
    private readonly snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    this.selectIsDisplayedMicrodeliveriesEmpty$ = this.store$.select(selectIsDisplayedMicrodeliveriesEmpty);

    this.pipelinesLoading$ = this.store$.select(selectIsPipelinesForDeliveryLoading);

    this.selectedGroupComplianceMode$ = this.store$.select(selectSelectedGroupComplianceMode);

    this.processingState$ = this.store$.select(selectAggregatedProcessingState);

    this.pipelines$ = this.store$.select(selectPipelinesSorted).pipe(
      tap((pipelines) => {
        this.isAnyPipelineJiraComponentMapped = !!pipelines.find((pipeline) => !!pipeline?.components?.length);
        this.gridData = process(pipelines, { filter: this.filter });
        this.pipelineRunCountInMode = this.gridData.data.filter((pipeline) => pipeline.latestPipelineRunId).length;
      }),
    );

    this.selectedDelivery$ = this.store$.select(selectSelectedDelivery).pipe(
      tap((delivery) => {
        if (delivery) {
          this.store$.dispatch(loadPipelinesForDelivery({ delivery }));
        }
      }),
    );

    this.deliveryProcessingStatus$ = this.store$.select(selectCalculatedDeliveryProcessingStatus).pipe(
      tap((deliveryProcessingStatus) => {
        this.setGroupComplianceShowMode(deliveryProcessingStatus);
        return deliveryProcessingStatus;
      }),
    );

    this.readOnly = this.environmentService.isReadOnly();
    this.deliveryRequirements$ = this.store$.select(selectDeliveryRequirements);
  }

  setGroupComplianceShowMode(deliveryProcessingStatus: DeliveryProcessingStatus) {
    for (const mode of GroupComplianceModes) {
      if (mode.isActive(deliveryProcessingStatus)) {
        this.dispatchGroupComplianceToggle(mode);
        return;
      }
    }
  }

  itemDisabled(status: DeliveryProcessingStatus) {
    return (item: { dataItem: GroupComplianceMode; index: number }) => {
      return !item.dataItem.isActive(status);
    };
  }

  openPipelineDialog(delivery?: Delivery) {
    this.addPipelineDialogService.openDialog({ data: { group: delivery } });
  }

  removePipeline(delivery: Delivery, pipelineInfo: { groupId: string; pipelineId: string }) {
    this.store$.dispatch(
      removePipelineFromDelivery({
        deliveryId: pipelineInfo.groupId,
        pipelineId: pipelineInfo.pipelineId,
      }),
    );
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  clearSubscriptions() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  onGroupComplianceToggle(delivery: Delivery, $event: GroupComplianceMode) {
    this.dispatchGroupComplianceToggle($event);
  }

  dispatchGroupComplianceToggle(mode: GroupComplianceMode) {
    this.store$.dispatch(setSelectedComplianceShowMode({ groupComplianceMode: mode }));
  }

  public expandDetailsBy = (dataItem: any): any => {
    return dataItem.pipelineId;
  };

  showPipelineRunDetails(pipelines: Pipeline[], pipelineRunToExpandData: ShowRunDetailsEventData) {
    this.store$.dispatch(setSelectedPipelineRun(pipelineRunToExpandData.pipelineId, pipelineRunToExpandData.pipelineRunId));
    if (!this.expandedDetailKeys.includes(pipelineRunToExpandData.pipelineId)) {
      this.expandedDetailKeys = [...this.expandedDetailKeys, pipelineRunToExpandData.pipelineId];
    }
  }

  isManagePipelinesDisabled(delivery: Delivery): boolean {
    return this.readOnly || delivery.isLocked || delivery.arePipelinesSetByComponents;
  }

  getAddButtonTooltip(delivery: Delivery): string {
    if (this.isManagePipelinesDisabled(delivery)) {
      // Explain the reason why the button is disabled
      return this.getDisabledButtonTooltip(delivery);
    } else {
      // Add pipeline button enabled -> No tooltip
      return null;
    }
  }

  getDeleteButtonTooltip(delivery: Delivery): string {
    if (this.isManagePipelinesDisabled(delivery)) {
      // Explain the reason why the button is disabled
      return this.getDisabledButtonTooltip(delivery);
    } else {
      // Delete pipeline buttons enabled -> Explain we only remove the link instead of deleting runs or the pipeline
      if (delivery.isMicrodelivery) {
        return this.translateService.instant('button-tooltip.manage-pipeline.enabled-microdelivery-delete-message');
      } else {
        return this.translateService.instant('button-tooltip.manage-pipeline.enabled-delivery-delete-message');
      }
    }
  }

  public filterChange(filter: CompositeFilterDescriptor, pipelines: Pipeline[]): void {
    this.filter = filter;
    const state: State = {
      filter,
    };
    this.gridData = process(pipelines, state);
    this.pipelineRunCountInMode = this.gridData?.data.filter((pipeline) => pipeline.latestPipelineRunId).length;
  }

  public distinctPrimitive(pipelines: Pipeline[]): string[] {
    const allComponents: string[] = pipelines.reduce((acc, pipeline) => acc.concat(pipeline.components), []).sort();
    return distinct(allComponents);
  }

  private getDisabledButtonTooltip(delivery: Delivery): string {
    if (delivery.isLocked) {
      return this.translateService.instant('button-tooltip.manage-pipeline.disabled-by-lock');
    }
    if (delivery.arePipelinesSetByComponents) {
      return this.translateService.instant('button-tooltip.manage-pipeline.disabled-by-mapping');
    }
    if (this.readOnly) {
      return this.translateService.instant('button-tooltip.manage-pipeline.disabled-by-readonly');
    }
    return undefined;
  }

  restorePipelineRuns(pipelineRunIds: number[]) {
    this.processingStatusService
      .restorePipelineRuns(pipelineRunIds)
      .pipe(first())
      .subscribe(() => {
        this.snackBar.open(
          `Restoring ${pipelineRunIds.length} pipeline runs in the Background,
      please refresh the page in a few minutes.`,
          'Dismiss',
          { panelClass: 'restore-snackbar-message' },
        );
      });
  }

  isProcessingStatusWarningVisible(aggregatedProcessingState: AggregatedProcessingState): boolean {
    return (
      aggregatedProcessingState.numberOfArchivedPipelineRuns > 0 ||
      aggregatedProcessingState.numberOfArchivingPipelineRuns > 0 ||
      aggregatedProcessingState.numberOfRestoringPipelinesRuns > 0
    );
  }

  getRunningPipelines(pipelines: Pipeline[]): Pipeline[] {
    return pipelines.filter((pipeline: Pipeline) => pipeline.pipelineRunExecutionStatus?.key === PipelineRunExecutionStatusKey.INITIALIZED);
  }

  isRestoreButtonVisible(aggregatedProcessingState: AggregatedProcessingState): boolean {
    return (
      aggregatedProcessingState.numberOfArchivedPipelineRuns > 0 &&
      aggregatedProcessingState.numberOfArchivingPipelineRuns <= 0 &&
      aggregatedProcessingState.numberOfRestoringPipelinesRuns <= 0
    );
  }

  getTextForNumberOfPipelineRuns(aggregatedProcessingState: AggregatedProcessingState): string {
    const values: [string, number][] = [
      [ProcessingStatus.ARCHIVING, aggregatedProcessingState.numberOfArchivingPipelineRuns],
      [ProcessingStatus.RESTORING, aggregatedProcessingState.numberOfRestoringPipelinesRuns],
      [ProcessingStatus.ARCHIVED, aggregatedProcessingState.numberOfArchivedPipelineRuns],
    ];
    return values
      .filter(([_, count]) => count > 0)
      .map(([text, count]) => `${count} ${text}`)
      .join(' and ');
  }

  getTextForPipelinesRunningWarning(pipelines: Pipeline[]): string {
    const runningPipelinesCount = this.getRunningPipelines(pipelines).length;
    if (runningPipelinesCount > 1) {
      return `${runningPipelinesCount} ${this.translateService.instant('pipeline-list.pipelines-running.warning-box.multiple')}`;
    }
    return `${runningPipelinesCount} ${this.translateService.instant('pipeline-list.pipelines-running.warning-box.single')}`;
  }
}
