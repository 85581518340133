import { createAction, props } from '@ngrx/store';
import { GroupComplianceMode } from '../../../shared/model/group-compliance-mode';
import { ProductStandard } from '../../../shared/model/product-standard';
import { ComplianceInfoSummary, Fc1ComplianceInfoDetails } from '../../model/compliance-information';

export const loadFc1GroupSummary = createAction(
  `[${ProductStandard.fc1}] Trigger loading of ${ProductStandard.fc1} compliance information for group-summary from backend`,
  props<{ deliveryId: string; groupComplianceMode: GroupComplianceMode }>(),
);
export const setFc1GroupSummary = createAction(
  `[${ProductStandard.fc1}] Set ${ProductStandard.fc1} compliance information for group-summary from backend in store`,
  props<{ groupSummary: ComplianceInfoSummary }>(),
);

export const loadFc1GroupDetails = createAction(
  `[${ProductStandard.fc1}] Trigger loading of ${ProductStandard.fc1} compliance information for group-details from backend`,
  props<{ deliveryId: string; groupComplianceMode: GroupComplianceMode }>(),
);
export const setFc1GroupDetails = createAction(
  `[${ProductStandard.fc1}] Set ${ProductStandard.fc1} compliance information for group-details from backend in store`,
  props<{ groupDetails: Fc1ComplianceInfoDetails[] }>(),
);

export const loadFc1SingleDetails = createAction(
  `[${ProductStandard.fc1}] Trigger loading of ${ProductStandard.fc1} compliance information for single-details from backend`,
  props<{ pipelineRunId: number }>(),
);
export const setFc1SingleDetails = createAction(
  `[${ProductStandard.fc1}] Set ${ProductStandard.fc1} compliance information for single-details from backend in store`,
  props<{ pipelineRunId: number; singleDetails: Fc1ComplianceInfoDetails }>(),
);

export const loadFc1GroupSummaryError = createAction(
  `[${ProductStandard.fc1}] Loading of ${ProductStandard.fc1} Group Summary from backend error`,
  props<{ deliveryId: string }>(),
);
export const loadFc1GroupSummaryNotFound = createAction(
  `[${ProductStandard.fc1}] Loading of ${ProductStandard.fc1} Group Summary from backend not found`,
  props<{ deliveryId: string }>(),
);

export const loadFc1SingleDetailsError = createAction(
  `[${ProductStandard.fc1}] Loading of ${ProductStandard.fc1} Single Details from backend error`,
  props<{ pipelineRunId: number }>(),
);

export const loadFc1SingleDetailsNotFound = createAction(
  `[${ProductStandard.fc1}] Loading of ${ProductStandard.fc1} Single Details from backend not found`,
  props<{ pipelineRunId: number }>(),
);

export const loadFc1GroupDetailsError = createAction(
  `[${ProductStandard.fc1}] Loading of ${ProductStandard.fc1} Group Details from backend error`,
  props<{ deliveryId: string }>(),
);
