import { Injectable } from '@angular/core';
import { Workbook, WorkbookSheet, WorkbookSheetRow, WorkbookSheetRowCell } from '@progress/kendo-angular-excel-export';
import { RequirementDetail } from '../../model/requirement-detail/requirement-detail';
import { RequirementTestcaseResult } from '../../model/requirement-detail/requirement-detail-testcase';
import { getStatusText, testcaseWithName } from '../../KPIs/list-item-kpis-fc2/dialog/tests/test-utils';

@Injectable({
  providedIn: 'root',
})
export class TraceabilityExcelService {
  createTraceabilityWorkbook(workbook: Workbook, sortedRequirements: RequirementDetail[]): Workbook {
    // Format first sheet a bit
    workbook.sheets[0].name = 'Summary';
    workbook.sheets[0].rows.forEach((row) => {
      row.cells[3].bold = true;
      row.cells[0].bold = true;
    });

    const headerStyles = workbook.sheets[0].rows[0].cells[0];

    const requirementExcelSheets = this.createAllRequirementSheets(sortedRequirements, headerStyles);
    workbook.sheets = workbook.sheets.concat(requirementExcelSheets);

    return workbook;
  }

  private createAllRequirementSheets(sortedRequirements: RequirementDetail[], headerStyles: WorkbookSheetRowCell): WorkbookSheet[] {
    // Create a Sheet with the testcases of each Requirement
    return sortedRequirements
      .filter((r) => !!r.testcases.length)
      .map((requirement) => {
        return this.createSheetForRequirementTestcases(requirement, headerStyles);
      });
  }

  private createSheetForRequirementTestcases(requirement: RequirementDetail, headerCell: WorkbookSheetRowCell): WorkbookSheet {
    const columnNames = ['Test Case', 'Status', 'Type', 'Source Path', 'Pipeline'];

    const headerRow = {
      cells: columnNames.map((columnName, index) => ({
        ...headerCell,
        value: columnName,
        bold: index === 1,
      })),
    } as WorkbookSheetRow;

    const allTestcaseCellValues = this.mapTestcasesToCellValues(requirement.testcases);

    const valueRows: WorkbookSheetRow[] = allTestcaseCellValues.map((cellValues) => {
      return {
        cells: cellValues.map((value, index) => ({
          value,
          bold: index === 1,
        })),
      } as WorkbookSheetRow;
    });

    return {
      name: requirement.key,
      rows: [headerRow].concat(valueRows),
    } as WorkbookSheet;
  }

  private mapTestcasesToCellValues(testcases: RequirementTestcaseResult[]): any[][] {
    const allTestcaseCellValues = [];

    testcases.forEach((tc) => {
      if (tc.name) {
        tc.name.forEach((name) => {
          const testcase = testcaseWithName(tc, name);
          const cellValues = [
            testcase.name[0],
            getStatusText(testcase),
            testcase.stepResultTypeName,
            testcase.source ? testcase.source : '',
            testcase.pipelineKey,
          ];
          allTestcaseCellValues.push(cellValues);
        });
      }
    });
    return allTestcaseCellValues;
  }
}
