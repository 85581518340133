import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { POPUP_CONTAINER, PopupService } from '@progress/kendo-angular-popup';
@Component({
  selector: 'app-list-item-kpis-policy-results-dialog',
  templateUrl: './list-item-kpis-policy-results-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      // Provide the current component element as Popup container.
      provide: POPUP_CONTAINER,
      useExisting: ElementRef,
    },
    // Create a new Popup Service that uses the provided container.
    PopupService,
  ],
})
export class ListItemKpisPolicyResultsDialogComponent implements OnInit {
  public pipelineRunId: number;
  public policyAmount: number;
  public isCustomPolicyResults: boolean;
  public policyDialogTitle: string;
  public dataTestIdForCloseButton: string;

  constructor(
    private readonly dialogRef: MatDialogRef<ListItemKpisPolicyResultsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly dialogData: { pipelineRunId: number; isCustomPolicyResults: boolean },
  ) {}

  ngOnInit() {
    this.dialogRef.updateSize('80vw', 'auto');
    this.pipelineRunId = this.dialogData?.pipelineRunId;
    this.isCustomPolicyResults = this.dialogData?.isCustomPolicyResults;
    if (this.isCustomPolicyResults) {
      this.dataTestIdForCloseButton = 'custom-policy-results-detail-popup-close-btn';
    } else {
      this.dataTestIdForCloseButton = 'policy-results-detail-popup-close-btn';
    }
  }

  closeDialog(data?: any) {
    this.dialogRef.close(data);
  }

  policyAmountChanged(policyAmount: number) {
    this.policyAmount = policyAmount;
    this.setDialogTitle();
  }

  private setDialogTitle() {
    if (this.isCustomPolicyResults) {
      this.policyDialogTitle = `Custom Policy Results (${this.policyAmount})`;
    } else {
      this.policyDialogTitle = `Central Policy Results (${this.policyAmount})`;
    }
  }
}
