import { SonarQube } from '../../model/sonarqube/sonar-qube.model';
import { ComplianceInfoDetailsRaw, ComplianceInfoSummaryRaw } from '../../shared/model/compliance-information';
import { AtcInfoSummary } from './atc';
import { StepResultFile } from './step-result-file';

export enum ComplianceStatusKey {
  NOT_COMPLIANT = 'NOT_COMPLIANT',
  COMPLIANT = 'COMPLIANT',
  UNKNOWN = 'UNKNOWN',
  IGNORED = 'IGNORED',
  DEPRECATED = 'DEPRECATED',
}

export class ComplianceStatus {
  static NOT_COMPLIANT = new ComplianceStatus(ComplianceStatusKey.NOT_COMPLIANT, 'Not Compliant');
  static COMPLIANT = new ComplianceStatus(ComplianceStatusKey.COMPLIANT, 'Compliant');
  static UNKNOWN = new ComplianceStatus(ComplianceStatusKey.UNKNOWN, 'Unknown');
  static IGNORED = new ComplianceStatus(ComplianceStatusKey.IGNORED, 'Ignored');
  static DEPRECATED = new ComplianceStatus(ComplianceStatusKey.DEPRECATED, 'Deprecated');
  // edge case if no compliance status is available
  static NOT_AVAILABLE = new ComplianceStatus(null, 'n/a');

  constructor(
    public key: ComplianceStatusKey,
    public displayKey: string,
  ) {}

  static getByKey(key: string) {
    return Object.values(ComplianceStatus).find((entry) => entry.key == key) ?? ComplianceStatus.NOT_AVAILABLE;
  }
}

export interface ComplianceInfoSummary extends Omit<ComplianceInfoSummaryRaw, 'complianceStatus'> {
  complianceStatus: ComplianceStatus;
}

export interface ComplianceInfoDetails
  extends Omit<ComplianceInfoDetailsRaw, 'complianceStatus' | 'leadingComplianceStatus' | 'relatedComplianceStatus'> {
  complianceStatus: ComplianceStatus;
  leadingComplianceStatus: ComplianceStatus;
  relatedComplianceStatus?: ComplianceStatus;
}

export type ComplianceInfoUrlDetails = Pick<ComplianceInfoDetails, 'buildUrl' | 'pipelineUrl'>;

export interface Psl1ComplianceInfoDetails extends ComplianceInfoDetails {
  links: StepResultFile[];
}

export interface Fc3ComplianceInfoDetails extends ComplianceInfoDetails {
  skipped: number;
  success: number;
  error: number;
  count: number;
}

export interface Fc1ComplianceInfoDetails extends ComplianceInfoDetails {
  sonarQube?: SonarQube;
  atc?: AtcInfoSummary;
}

export const emptyComplianceInfoDetails: ComplianceInfoDetails = {
  pipelineRunId: 0,
  pipelineRunModeId: 1,
  psrKey: null,
  pipelineId: '',
  pipelineUrl: '',
  buildUrl: '',
  pipelineKey: '',
  pipelineRunKey: '',
  groupId: '',
  statusSortOrder: 0,
  complianceStatus: null,
  leadingComplianceStatus: null,
  scmsUrl: '',
  complianceInformationAvailable: false,
};

export const emptyPsl1ComplianceInfoDetails: Psl1ComplianceInfoDetails = { ...emptyComplianceInfoDetails, links: [] };

export const emptyFc1ComplianceInfoDetails: Fc1ComplianceInfoDetails = {
  ...emptyComplianceInfoDetails,
};
export const emptyFc3ComplianceInfoDetails: Fc3ComplianceInfoDetails = {
  ...emptyComplianceInfoDetails,
  count: 0,
  skipped: 0,
  error: 0,
  success: 0,
};
