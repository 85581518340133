import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment.service';
import { GroupTypeMicrodelivery } from '../../ngrx/model/group-type-microdelivery';

@Injectable({
  providedIn: 'root',
})
export class GroupService {
  _baseUrl: string;

  get baseUrl() {
    if (!this._baseUrl) {
      this._baseUrl = this.environmentService.getGroupServiceBaseUrl('groups');
    }
    return this._baseUrl;
  }

  constructor(
    private readonly http: HttpClient,
    private readonly environmentService: EnvironmentService,
  ) {}

  addPipelinesToGroup(groupId: any, requestBody: string[]) {
    return this.http.post<any[]>(`${this.baseUrl}/${groupId}/pipelines`, requestBody);
  }

  getGroupOfDelivery(deliveryGuid?: string): Observable<any> {
    deliveryGuid = deliveryGuid || this.environmentService.getDeliveryGuid();
    return this.http.get<any>(`${this.baseUrl}?groupKey=${deliveryGuid}`);
  }

  createGroup(group: any) {
    return this.http.post(`${this.baseUrl}`, group);
  }

  removePipelinesFromGroup(groupId: string, pipelineIds: string[]) {
    return this.http.post(`${this.baseUrl}/${groupId}/pipelines/remove`, pipelineIds);
  }

  removeGroupFromGroup(parentGroupId: string, childGroupIds: string[]) {
    return this.http.post(`${this.baseUrl}/${parentGroupId}/children/remove`, childGroupIds);
  }

  addGroupsToDelivery(deliverGuid: string, groups: any[]) {
    return this.http.post(`${this.baseUrl}/${deliverGuid}/children`, groups);
  }

  addPipelinesFromGroupToDelivery(sourceGroupId: string, destinationGroupId: string) {
    return this.http.post(`${this.baseUrl}/${sourceGroupId}/copy/${destinationGroupId}/pipelines`, {});
  }

  getMicrodeliveriesByGroupId(groupId: string): Observable<GroupTypeMicrodelivery[]> {
    const requestUrl = `${this.baseUrl}/${groupId}/microdeliveries`;
    return this.http.get<GroupTypeMicrodelivery[]>(requestUrl);
  }

  updateMicrodeliveriesInGroup(microdeliveries: GroupTypeMicrodelivery[]): Observable<GroupTypeMicrodelivery[]> {
    const parentGroupId = this.environmentService.groupId;
    const requestUrl = `${this.baseUrl}/${parentGroupId}/microdeliveries/override`;
    return this.http.post<GroupTypeMicrodelivery[]>(requestUrl, microdeliveries);
  }

  addMicrodeliveriesToGroup(microdeliveries: GroupTypeMicrodelivery[]): Observable<GroupTypeMicrodelivery[]> {
    const parentGroupId = this.environmentService.groupId;
    const requestUrl = `${this.baseUrl}/${parentGroupId}/microdeliveries/add`;
    return this.http.post<GroupTypeMicrodelivery[]>(requestUrl, microdeliveries);
  }
}
