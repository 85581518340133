import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PipelineRunReleaseStatus } from '../../model/pipeline-run/pipeline-run';
import { ReleaseStatus, ReleaseStatusKey } from '../../model/release-status/release-status';
import { EnvironmentService } from '../environment.service';

@Injectable({
  providedIn: 'root',
})
export class FileParserService {
  get baseUrl() {
    return `https://${this.envService.getBaseUrl()}/zprs/gcp/file-parser/api`;
  }

  constructor(
    private readonly envService: EnvironmentService,
    private readonly httpClient: HttpClient,
  ) {}

  deletePipelineRun(pipelineRunId: number) {
    return this.httpClient.delete(`${this.baseUrl}/pipeline-run/${pipelineRunId}`);
  }

  setPipelineRunReleaseStatus(pipelineRunId: number, groupId: string, releaseStatus: ReleaseStatusKey) {
    return this.httpClient.post(`${this.baseUrl}/pipeline-run-release-status/${pipelineRunId}/${groupId}/${releaseStatus}`, null);
  }

  revertPipelineRunReleaseStatus(pipelineRunId: number, groupId: string, releaseStatus: ReleaseStatusKey, justification?: string) {
    const requestBody = {
      groupIds: [groupId],
      explanation: justification,
    };
    return this.httpClient.post(
      `${this.baseUrl}/pipeline-run-release-status/pipelineRun/${pipelineRunId}/releaseStatus/${releaseStatus}/revert`,
      requestBody,
    );
  }

  getPossibleReleaseStatus(pipelineRunId: number, groupId: string): Observable<ReleaseStatus[]> {
    return this.httpClient.get<ReleaseStatus[]>(`${this.baseUrl}/pipeline-run-release-status/${pipelineRunId}/${groupId}/transitions`);
  }

  getReleaseStatuses(pipelineRunId: number, groupIds: string[]): Observable<PipelineRunReleaseStatus[]> {
    return this.httpClient.post<PipelineRunReleaseStatus[]>(`${this.baseUrl}/pipeline-run-release-status/${pipelineRunId}/list`, {
      groupIds: groupIds,
    });
  }
}
