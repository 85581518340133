import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MicrodeliveriesSlice } from '../app-state';

export const selectMicrodeliveriesSlice = createFeatureSelector<MicrodeliveriesSlice>('microdeliveriesSlice');

export const selectActiveMicrodeliveries = createSelector(selectMicrodeliveriesSlice, (microdeliveriesSlice) => {
  return microdeliveriesSlice.activeMicrodeliveries;
});

export const selectDisplayedMicrodeliveries = createSelector(selectMicrodeliveriesSlice, (microdeliveriesSlice) => {
  return microdeliveriesSlice.displayedMicrodeliveries;
});

export const selectDisplayedMicrodeliveriesSorted = createSelector(selectDisplayedMicrodeliveries, (microdeliveries) => {
  return [...microdeliveries.values()].sort((a, b) => {
    const aActivationDate = a.group.activationDate ? new Date(a.group.activationDate) : undefined;
    const bActivationDate = b.group.activationDate ? new Date(b.group.activationDate) : undefined;
    // Sort order: If one of the dates is null it went down. If one of the dates isn't null it went up. If both are not null desc of dates
    if (!aActivationDate && !bActivationDate) {
      return 0;
    }
    if (!aActivationDate) {
      return 1;
    }
    if (!bActivationDate) {
      return -1;
    }
    return bActivationDate.getTime() - aActivationDate.getTime();
  });
});

export const selectIsDisplayedMicrodeliveriesEmpty = createSelector(
  selectDisplayedMicrodeliveries,
  (microdeliveries) => microdeliveries.size === 0,
);
