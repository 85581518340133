import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pipelineKeyWithoutTimestamp',
})
export class PipelineKeyWithoutTimestampPipe implements PipeTransform {
  transform(value: string): string {
    return PipelineKeyWithoutTimestampPipe.transform(value);
  }

  static transform(value: string): string {
    return value.replace(/-\d{13}$/, '');
  }
}
