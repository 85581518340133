import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { AccessPipelineRunService } from '../../../shared/access-api/access-pipeline-run.service';
import * as DocumentsActions from './documents.actions';
import { PipelineRunDocument } from '../../../model/documents/pipeline-run-document.model';

@Injectable()
export class DocumentsEffects {
  public onLoadPipelineRunDocuments$ = createEffect(() => {
    return this.action$.pipe(
      ofType(DocumentsActions.loadPipelineRunDocuments),
      mergeMap((action) =>
        this.accessPipelineRunService.getPipelineRunDocuments(action.pipelineRunId).pipe(
          map((documents: PipelineRunDocument[]) =>
            DocumentsActions.setPipelineRunDocuments({
              pipelineRunId: action.pipelineRunId,
              documents: documents,
            }),
          ),
          catchError((error) => {
            if (error.status === 404) {
              return of(DocumentsActions.loadPipelineRunDocumentsNotFound({ pipelineRunId: action.pipelineRunId }));
            }
            return of(DocumentsActions.loadPipelineRunDocumentsError({ pipelineRunId: action.pipelineRunId }));
          }),
          takeUntil(
            this.action$.pipe(
              ofType(DocumentsActions.loadPipelineRunDocuments),
              filter((newAction) => newAction.pipelineRunId === action.pipelineRunId),
            ),
          ),
        ),
      ),
    );
  });

  constructor(
    private readonly action$: Actions,
    private readonly accessPipelineRunService: AccessPipelineRunService,
  ) {}
}
