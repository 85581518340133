import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ComplianceInfoSummary, ComplianceStatus, Psl1ComplianceInfoDetails } from '../../ngrx/model/compliance-information';
import {
  selectIsPsl1SingleLoading,
  selectIsPsl1SingleLoadingError,
  selectIsPsl1SingleLoadingNotFound,
  selectIsPsl1SummaryLoading,
  selectIsPsl1SummaryLoadingError,
  selectIsPsl1SummaryLoadingNotFound,
} from '../../ngrx/store/loading-state/loading-state.selectors';
import { loadPsl1GroupDetails, loadPsl1GroupSummary, loadPsl1SingleDetails } from '../../ngrx/store/psl-1/psl-1.actions';
import { selectPsl1GroupSummary, selectPsl1SingleDetailById } from '../../ngrx/store/psl-1/psl-1.selectors';
import { GroupComplianceMode } from '../../shared/model/group-compliance-mode';
import { IPScanDetailsDialogComponent } from './ip-scan-details-dialog/ip-scan-details-dialog.component';

@Component({
  selector: 'app-list-item-kpis-ip-scan',
  templateUrl: './list-item-kpis-ip-scan.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemKpisIPScanComponent implements OnInit, OnChanges {
  public ComplianceStatus = ComplianceStatus;

  @Input() groupId: string;
  @Input() groupComplianceMode: GroupComplianceMode;
  @Input() currentPipelineRunId: number;

  public psl1ComplianceInfo$!: Observable<Psl1ComplianceInfoDetails | ComplianceInfoSummary>;
  public isLoading$!: Observable<boolean>;
  public isLoadingError$!: Observable<boolean>;
  public isLoadingNotFound$!: Observable<boolean>;

  constructor(
    private readonly store$: Store,
    private readonly dialog: MatDialog,
  ) {}

  ngOnInit() {
    if (!this.currentPipelineRunId) {
      this.isLoading$ = this.store$.select(selectIsPsl1SummaryLoading);
      this.isLoadingError$ = this.store$.select(selectIsPsl1SummaryLoadingError);
      this.isLoadingNotFound$ = this.store$.select(selectIsPsl1SummaryLoadingNotFound);
      this.psl1ComplianceInfo$ = this.store$.select(selectPsl1GroupSummary);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentPipelineRunId) {
      this.loadPipelineRunInfo();
    }
  }

  loadPipelineRunInfo() {
    const pipelineRunId = this.currentPipelineRunId;
    this.psl1ComplianceInfo$ = this.store$.select(selectPsl1SingleDetailById(pipelineRunId));
    this.isLoading$ = this.store$.select(selectIsPsl1SingleLoading(pipelineRunId));
    this.isLoadingError$ = this.store$.select(selectIsPsl1SingleLoadingError(pipelineRunId));
    this.isLoadingNotFound$ = this.store$.select(selectIsPsl1SingleLoadingNotFound(pipelineRunId));
  }

  reloadInfo() {
    if (!this.currentPipelineRunId) {
      this.store$.dispatch(loadPsl1GroupSummary({ deliveryId: this.groupId, groupComplianceMode: this.groupComplianceMode }));
    } else {
      this.store$.dispatch(loadPsl1SingleDetails({ pipelineRunId: this.currentPipelineRunId }));
    }
  }

  public onSummaryStatusClicked(): void {
    if (!this.currentPipelineRunId) {
      const data = { deliveryId: this.groupId, groupComplianceMode: this.groupComplianceMode };
      this.store$.dispatch(loadPsl1GroupDetails(data));
      this.dialog.open(IPScanDetailsDialogComponent, { data });
    }
  }
}
