import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { SortDescriptor, State, orderBy, process } from '@progress/kendo-data-query';
import { Observable, map } from 'rxjs';
import { PolicyResultsGroupDetails } from '../../../ngrx/model/policy-results';
import { loadCustomPolicyResultsGroupDetails } from '../../../ngrx/store/custom-policy-results/custom-policy-results.actions';
import { selectCustomPolicyResultsGroupDetails } from '../../../ngrx/store/custom-policy-results/custom-policy-results.selectors';
import {
  selectIsCustomPolicyResultsGroupDetailsLoading,
  selectIsCustomPolicyResultsGroupDetailsLoadingError,
} from '../../../ngrx/store/loading-state/loading-state.selectors';
import { GroupComplianceMode } from '../../../shared/model/group-compliance-mode';

@Component({
  selector: 'app-custom-policy-result-aggregation-details',
  templateUrl: './custom-policy-result-aggregation-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomPolicyResultAggregationDetailsComponent {
  @Input() groupId: string;
  @Input() groupComplianceMode: GroupComplianceMode;
  @Output() selectedPipelineRun = new EventEmitter<PolicyResultsGroupDetails>();

  public dataSource$!: Observable<GridDataResult>;
  public isLoading$!: Observable<boolean>;
  public isLoadingError$!: Observable<boolean>;

  policyResultsGroupSorted: PolicyResultsGroupDetails[];
  dataSource: GridDataResult;

  gridState: State = {
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  sort: SortDescriptor[] = [
    {
      field: 'pipelineKey',
      dir: 'asc',
    },
  ];

  constructor(private readonly store$: Store) {
    this.dataSource$ = this.store$.select(selectCustomPolicyResultsGroupDetails).pipe(
      map((data) => {
        this.policyResultsGroupSorted = orderBy(data, this.sort);
        this.dataSource = process(this.policyResultsGroupSorted, {});
        return this.dataSource;
      }),
    );
    this.isLoading$ = this.store$.select(selectIsCustomPolicyResultsGroupDetailsLoading);
    this.isLoadingError$ = this.store$.select(selectIsCustomPolicyResultsGroupDetailsLoadingError);
  }

  dataStateChanged(state) {
    this.gridState = state;
    this.dataSource = process(this.policyResultsGroupSorted, this.gridState);
  }

  reload() {
    this.store$.dispatch(
      loadCustomPolicyResultsGroupDetails({
        groupId: this.groupId,
        groupComplianceMode: this.groupComplianceMode,
      }),
    );
  }
}
