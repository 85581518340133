import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { EMPTY, forkJoin, from, Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { JiraList } from '../../../model/jira/jira-list';
import { RequirementTestcaseResult } from '../../../model/requirement-detail/requirement-detail-testcase';
import { AccessGroupService } from '../../../shared/access-api/access-group.service';
import { AccessPipelineRunService } from '../../../shared/access-api/access-pipeline-run.service';
import { JiraService } from '../../../shared/jira-service/jira.service';
import { GroupComplianceMode } from '../../../shared/model/group-compliance-mode';
import { ReleaseDecisionEntityTypes } from '../../model/group-type-microdelivery';
import { selectDeliveryFixVersions, selectSelectedDelivery, selectSelectedGroupComplianceMode } from '../deliveries/deliveries.selectors';
import {
  loadDeliveryRequirements,
  loadDeliveryRequirementsError,
  loadDeliveryRequirementTestcases,
  loadDeliveryRequirementTestcasesError,
  loadExternalDeliveryRequirements,
  loadPipelineRunRequirements,
  loadPipelineRunRequirementsError,
  loadPipelineRunRequirementsNotFound,
  loadPipelineRunRequirementTestcases,
  loadPipelineRunRequirementTestcasesError,
  setDeliveryRequirements,
  setDeliveryRequirementTestcases,
  setPipelineRunRequirements,
  setPipelineRunRequirementTestcases,
} from './fc-2.actions';
import { selectDeliveryRequirements } from './fc-2.selectors';
import { Requirement } from '../../../model/jira/release-item';

@Injectable()
export class Fc2Effects {
  public onLoadDeliveryRequirements = createEffect(() => {
    return this.action$.pipe(
      ofType(loadDeliveryRequirements),
      concatLatestFrom(() => this.store$.select(selectDeliveryFixVersions)),
      switchMap(([action, deliveryFixVersions]) => {
        let requirements$: Observable<JiraList>;
        if (action.delivery.isMicrodelivery) {
          switch (action.delivery.releaseDecisionEntityType) {
            case ReleaseDecisionEntityTypes.JIRA_ISSUE:
              requirements$ = this.jiraService.getRequirementsForParentIssue(action.delivery.key);
              break;
            case ReleaseDecisionEntityTypes.JIRA_RELEASE:
              requirements$ = this.jiraService.getRequirementsForRelease([action.delivery.jiraIssueId.toString()]);
              break;
          }
        } else {
          requirements$ = this.jiraService.getRequirementsForDelivery(deliveryFixVersions);
        }
        return requirements$.pipe(
          map((deliveryRequirements: JiraList) => {
            return deliveryRequirements.issues.map((req) => Requirement.fromJiraIssue(req));
          }),
          map((deliveryRequirements: Requirement[]) => setDeliveryRequirements({ deliveryRequirements })),
          catchError(() => {
            return of(loadDeliveryRequirementsError());
          }),
        );
      }),
    );
  });

  public onLoadExternalDeliveryRequirements = createEffect(() => {
    return this.action$.pipe(
      ofType(loadExternalDeliveryRequirements),
      concatLatestFrom(() => this.store$.select(selectSelectedGroupComplianceMode)),
      switchMap(([action, viewMode]) =>
        this.accessGroupService.getExternalRequirements(action.delivery.id, viewMode).pipe(
          map((deliveryRequirements) => setDeliveryRequirements({ deliveryRequirements })),
          catchError((e) => of(loadDeliveryRequirementsError())),
        ),
      ),
    );
  });

  public onSetDeliveryRequirementsLoadRequirementTestcases$ = createEffect(() => {
    return this.action$.pipe(
      ofType(setDeliveryRequirements),
      switchMap((_) => of(loadDeliveryRequirementTestcases())),
    );
  });

  // we need this for component requirements
  public onLoadPipelineRunRequirementsTestcases$ = createEffect(() => {
    return this.action$.pipe(
      ofType(loadPipelineRunRequirementTestcases),
      concatLatestFrom(() => this.store$.select(selectDeliveryRequirements)),
      mergeMap(([action, requirements]) => {
        return this.accessPipelineRunService
          .getPipelineRunTestcases(
            action.pipelineRunId,
            requirements.map((req) => req.key),
          )
          .pipe(
            map((pipelineRunRequirementsTestcases) =>
              setPipelineRunRequirementTestcases({
                pipelineRunId: action.pipelineRunId,
                pipelineRunRequirementsTestcases,
              }),
            ),
            catchError(() => {
              return of(loadPipelineRunRequirementTestcasesError({ pipelineRunId: action.pipelineRunId }));
            }),
            takeUntil(
              this.action$.pipe(
                ofType(loadPipelineRunRequirementTestcases),
                filter((newAction) => newAction.pipelineRunId === action.pipelineRunId),
              ),
            ),
          );
      }),
    );
  });

  public onLoadPipelineRunRequirements = createEffect(() => {
    return this.action$.pipe(
      ofType(loadPipelineRunRequirements),
      mergeMap((action) => {
        return this.accessPipelineRunService.getPipelineRunRequirements(action.pipelineRunId).pipe(
          map((pipelineRunRequirements) =>
            setPipelineRunRequirements({
              pipelineRunId: action.pipelineRunId,
              pipelineRunRequirements,
            }),
          ),
          catchError((error) => {
            if (error.status === 404) {
              return of(loadPipelineRunRequirementsNotFound({ pipelineRunId: action.pipelineRunId }));
            }
            return of(loadPipelineRunRequirementsError({ pipelineRunId: action.pipelineRunId }));
          }),
          takeUntil(
            this.action$.pipe(
              ofType(loadPipelineRunRequirements),
              filter((newAction) => newAction.pipelineRunId === action.pipelineRunId),
            ),
          ),
        );
      }),
    );
  });

  public onLoadDeliveryRequirementTestcases$ = createEffect(() => {
    return this.action$.pipe(
      ofType(loadDeliveryRequirementTestcases),
      concatLatestFrom(() => [
        this.store$.select(selectSelectedGroupComplianceMode),
        this.store$.select(selectDeliveryRequirements),
        this.store$.select(selectSelectedDelivery),
        this.store$.select(selectDeliveryFixVersions),
      ]),
      switchMap(([_, groupComplianceMode, requirements, delivery, deliveryFixVersions]) => {
        if (!requirements?.length) {
          return of(setDeliveryRequirementTestcases({ deliveryTestcases: [] }));
        }

        let dataToLoad = [];
        if (delivery) {
          dataToLoad.push(
            // Load traceability relevant tests from cumulus db
            this.loadTraceabilityResults(delivery.id, requirements, groupComplianceMode),
          );
        }

        if (deliveryFixVersions) {
          dataToLoad = [
            ...dataToLoad,
            // Load Manual Test Tasks (for Requirements) from Jira
            from(this.jiraService.getManualTestTasksForRequirements(requirements, delivery, deliveryFixVersions)),
            // Load Xray Test (for Requirements) from Jira
            from(this.jiraService.getXrayTestTasksForRequirements(requirements, delivery, deliveryFixVersions)),
          ];
        }

        if (dataToLoad.length) {
          return forkJoin(dataToLoad).pipe(
            map((testcases) => testcases.reduce((prev, curr) => prev.concat(curr))),
            map((deliveryTestcases) => setDeliveryRequirementTestcases({ deliveryTestcases })),
            catchError(() => {
              return of(loadDeliveryRequirementTestcasesError());
            }),
          );
        }
        return EMPTY;
      }),
    );
  });

  constructor(
    private readonly action$: Actions,
    private readonly store$: Store,
    private readonly jiraService: JiraService,
    private readonly accessGroupService: AccessGroupService,
    private readonly accessPipelineRunService: AccessPipelineRunService,
  ) {}

  private loadTraceabilityResults(
    groupId: string,
    requirements: Requirement[],
    groupComplianceMode: GroupComplianceMode,
  ): Observable<RequirementTestcaseResult[]> {
    if (requirements?.length) {
      const requestBody = { requirementKeys: Array<string>() };
      requestBody.requirementKeys = requirements.map((elem) => elem.key);
      return this.accessGroupService.getGroupRequirements(groupId, requestBody, groupComplianceMode).pipe(
        map((testcases) =>
          testcases.map((testcase) => ({
            ...testcase,
            // FIXME: Backend (access-api) should provide the gitSearchUrl
            gitSearchUrl: `${testcase.gitUrl}/find/${testcase.gitCommit}`,
          })),
        ),
      );
    }
    return of([]);
  }
}
