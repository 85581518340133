import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of, timer } from 'rxjs';
import { catchError, delayWhen, filter, map, mergeMap, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Delivery, GroupTypeKey } from '../../../model/group/group';
import { ReleaseStatusKey } from '../../../model/release-status/release-status';
import { AccessApiService } from '../../../shared/access-api/access-api.service';
import { AlertService, AlertType } from '../../../shared/alert/alert.service';
import { FileParserService } from '../../../shared/file-parser/file-parser.service';
import { loadApiMetadataPipelineRunSummary } from '../api-metadata-validator/api-metadata-validator.actions';
import { loadPipelineRunATCInformation } from '../atc/atc.actions';
import { loadCentralPolicyResultsSingleSummary } from '../central-policy-results/central-policy-results.actions';
import { loadCodeCoverageForPipelineRun } from '../code-coverage-info/code-coverage-info.actions';
import { loadCustomPolicyResultsSingleSummary } from '../custom-policy-results/custom-policy-results.actions';
import { reloadGroupCodeCompliance } from '../deliveries/deliveries.actions';
import { selectSelectedDelivery, selectSelectedGroupComplianceMode } from '../deliveries/deliveries.selectors';
import { loadFc1SingleDetails } from '../fc-1/fc-1.actions';
import { loadPipelineRunRequirementTestcases, loadPipelineRunRequirements } from '../fc-2/fc-2.actions';
import { loadFc3SingleDetails } from '../fc-3/fc-3.actions';
import { loadFosstarsSingleSummary } from '../fosstars/fosstars.actions';
import { loadPipelineRunMalwarescanInformation } from '../malwarescan/malwarescan.actions';
import { loadPipelinesForDelivery } from '../pipeline/pipeline.actions';
import { selectPipelineById } from '../pipeline/pipeline.selectors';
import { loadPipelineRunProcessingStatus } from '../processing-status/processing-status.actions';
import { loadPsl1SingleDetails } from '../psl-1/psl-1.actions';
import { loadSlc25SingleDetails } from '../slc-25/slc-25.actions';
import { loadPipelineRunSonarQubeCoverage } from '../sonar-qube-coverage/sonar-qube-coverage.actions';
import { loadPipelineRunTestCasesForRegression } from '../test-regression/test-regression.actions';
import {
  deletePipelineRun,
  deletePipelineRunFailed,
  deletePipelineRunSuccessful,
  loadPipelineRunsForPipeline,
  revertReleaseCandidateStatusFailed,
  revertReleaseStatus,
  revertReleaseStatusIfPossible,
  revertReleaseStatusSuccessful,
  revertReleaseStatusSuccessfulId,
  setNewReleaseStatus,
  setNewReleaseStatusFailed,
  setNewReleaseStatusIfPossible,
  setNewReleaseStatusSuccessful,
  setPipelineRunsForPipeline,
  setSelectedPipelineRun,
} from './pipeline-run.actions';
import { selectSelectedPipelineRunForPipeline } from './pipeline-run.selectors';

@Injectable()
export class PipelineRunEffects {
  public onLoadPipelineRunsForPipeline = createEffect(() => {
    return this.action$.pipe(
      ofType(loadPipelineRunsForPipeline),
      mergeMap((action) => {
        return this.accessApiService.getPipelineRunsForPipeline(action.pipelineId, action.groupId).pipe(
          map((pipelineRuns) => setPipelineRunsForPipeline(action.pipelineId, pipelineRuns, action.updateSelectedPipelineRun)),
          takeUntil(
            this.action$.pipe(
              ofType(loadPipelineRunsForPipeline),
              filter((newAction) => newAction.groupId === action.groupId && newAction.pipelineId === action.pipelineId),
            ),
          ),
        );
      }),
    );
  });

  public onDeletePipelineRunForPipeline = createEffect(() => {
    return this.action$.pipe(
      ofType(deletePipelineRun),
      switchMap((action) => {
        return this.fileParserService.deletePipelineRun(action.pipelineRunId).pipe(
          map(() =>
            deletePipelineRunSuccessful({
              pipelineId: action.pipelineId,
              pipelineRunId: action.pipelineRunId,
              isLatestPipelineRun: action.isLatestPipelineRun,
            }),
          ),
          catchError(() => {
            this.alertService.createAlert(
              `The pipeline run '${action.pipelineRunKey}' could not be deleted. Please try again later.`,
              AlertType.ERROR,
            );
            return of(deletePipelineRunFailed({ pipelineRunId: action.pipelineRunId, pipelineId: action.pipelineId }));
          }),
        );
      }),
    );
  });

  public onSetPipelineRunsForPipelineToSetSelectedPipelineRun = createEffect(() => {
    return this.action$.pipe(
      ofType(setPipelineRunsForPipeline),
      mergeMap((action) => of(action).pipe(withLatestFrom(this.store$.select(selectSelectedPipelineRunForPipeline(action.pipelineId))))),
      switchMap(([action, selectedPipelineRunId]) => {
        if (action.updateSelectedPipelineRun) {
          // use selected pipeline run from store or latest pipeline run from pipeline when pipeline gets expanded
          return [setSelectedPipelineRun(action.pipelineId, selectedPipelineRunId || action.pipelineRuns[0]?.id)];
        }
        return [];
      }),
    );
  });

  public onPipelineRunDeletionSuccessfulToLoadDeliverySuccessful = createEffect(() => {
    return this.action$.pipe(
      ofType(deletePipelineRunSuccessful),
      switchMap(() => [reloadGroupCodeCompliance()]),
    );
  });

  public onSetSelectedPipelineRunToLoadTileData = createEffect(() => {
    return this.action$.pipe(
      ofType(setSelectedPipelineRun),
      filter((action) => !!action.selectedPipelineRunId),
      switchMap((action) => [
        loadPipelineRunATCInformation({ pipelineRunId: action.selectedPipelineRunId }),
        loadPipelineRunRequirementTestcases({ pipelineRunId: action.selectedPipelineRunId }),
        loadPipelineRunTestCasesForRegression({
          pipelineRunId: action.selectedPipelineRunId,
          pipelineId: action.pipelineId,
        }),
        loadFc1SingleDetails({ pipelineRunId: action.selectedPipelineRunId }),
        loadFc3SingleDetails({ pipelineRunId: action.selectedPipelineRunId }),
        loadPsl1SingleDetails({ pipelineRunId: action.selectedPipelineRunId }),
        loadSlc25SingleDetails({ pipelineRunId: action.selectedPipelineRunId }),
        loadCodeCoverageForPipelineRun({ pipelineRunId: action.selectedPipelineRunId }),
        loadPipelineRunMalwarescanInformation({ pipelineRunId: action.selectedPipelineRunId }),
        loadApiMetadataPipelineRunSummary({ pipelineRunId: action.selectedPipelineRunId }),
        loadPipelineRunRequirements({ pipelineRunId: action.selectedPipelineRunId }),
        loadPipelineRunSonarQubeCoverage({ pipelineRunId: action.selectedPipelineRunId }),
        loadPipelineRunProcessingStatus({ pipelineRunId: action.selectedPipelineRunId }),
        loadFosstarsSingleSummary({ pipelineRunId: action.selectedPipelineRunId }),
        loadCentralPolicyResultsSingleSummary({ pipelineRunId: action.selectedPipelineRunId }),
        loadCustomPolicyResultsSingleSummary({ pipelineRunId: action.selectedPipelineRunId }),
      ]),
    );
  });

  public onSetReleaseStatusSuccessfulLoadRuns = createEffect(() => {
    return this.action$.pipe(
      ofType(setNewReleaseStatusSuccessful, revertReleaseStatusSuccessful),
      mergeMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store$.select(selectPipelineById(action.pipelineId)),
            this.store$.select(selectSelectedGroupComplianceMode),
            this.store$.select(selectSelectedDelivery),
          ),
        ),
      ),
      mergeMap(([action, pipeline, complianceMode, selectedDelivery]) => {
        const actionsToTrigger: Action[] = [
          loadPipelineRunsForPipeline(action.pipelineId, action.groupId, action.releaseStatus === ReleaseStatusKey.RELEASED),
        ];
        if (pipeline.pipelineRunId === action.pipelineRunId && selectedDelivery.id === action.groupId) {
          const delivery: Delivery = { id: action.groupId } as Delivery;
          actionsToTrigger.push(loadPipelinesForDelivery({ delivery: delivery }));
        }

        if (complianceMode.getReleaseStatus().key === action.releaseStatus || action.type === revertReleaseStatusSuccessfulId) {
          actionsToTrigger.push(reloadGroupCodeCompliance());
        }
        return actionsToTrigger;
      }),
    );
  });

  public onPipelineRunReleaseStatus = createEffect(() => {
    return this.action$.pipe(
      ofType(setNewReleaseStatus),
      mergeMap((action) => {
        return this.fileParserService.setPipelineRunReleaseStatus(action.pipelineRunId, action.groupId, action.releaseStatus).pipe(
          // delay reloading to increase the chances of receiving updated values for the documents tile
          delayWhen(() => (action.releaseStatus === ReleaseStatusKey.RELEASED ? timer(3000) : of(0))),
          map(() =>
            setNewReleaseStatusSuccessful({
              pipelineId: action.pipelineId,
              pipelineRunId: action.pipelineRunId,
              groupId: action.groupId,
              releaseStatus: action.releaseStatus,
            }),
          ),
          catchError(() => {
            const errorMsg = this.buildErrorMsg(
              action.pipelineRunKey,
              action.isMicrodelivery,
              action.groupName,
              action.groupKey,
              action.releaseStatus,
              'updated',
            );

            this.alertService.createAlert(errorMsg, AlertType.ERROR);
            return of(
              setNewReleaseStatusFailed({
                pipelineId: action.pipelineId,
                pipelineRunId: action.pipelineRunId,
                groupId: action.groupId,
                releaseStatus: action.releaseStatus,
              }),
            );
          }),
          takeUntil(
            this.action$.pipe(
              ofType(setNewReleaseStatus),
              filter((newAction) => newAction.groupId === action.groupId && newAction.pipelineRunId === action.pipelineRunId),
            ),
          ),
        );
      }),
    );
  });

  public onSetPipelineRunReleaseStatusIfPossible = createEffect(() => {
    return this.action$.pipe(
      ofType(setNewReleaseStatusIfPossible),
      switchMap((action) => {
        return this.fileParserService.getPossibleReleaseStatus(action.pipelineRunId, action.groupId).pipe(
          map((result) => {
            const statusIsPossible = !!result.find((entry) => entry.key === action.releaseStatus);
            if (statusIsPossible) {
              return setNewReleaseStatus({
                pipelineId: action.pipelineId,
                pipelineRunId: action.pipelineRunId,
                pipelineRunKey: action.pipelineRunKey,
                groupId: action.groupId,
                groupKey: action.groupKey,
                groupName: action.groupName,
                isMicrodelivery: action.isMicrodelivery,
                releaseStatus: action.releaseStatus,
              });
            }
          }),
          catchError(() => {
            const errorMsg = this.buildErrorMsg(
              action.pipelineRunKey,
              action.isMicrodelivery,
              action.groupName,
              action.groupKey,
              action.releaseStatus,
              'updated',
            );

            this.alertService.createAlert(errorMsg, AlertType.ERROR);
            return of(
              setNewReleaseStatusFailed({
                pipelineId: action.pipelineId,
                pipelineRunId: action.pipelineRunId,
                groupId: action.groupId,
                releaseStatus: action.releaseStatus,
              }),
            );
          }),
        );
      }),
    );
  });

  public onRevertPipelineRunReleaseStatusIfPossible = createEffect(() => {
    return this.action$.pipe(
      ofType(revertReleaseStatusIfPossible),
      switchMap((action) => {
        return this.fileParserService.getReleaseStatuses(action.pipelineRunId, [...action.microdeliveryIds, action.groupId]).pipe(
          map((result) => {
            const hasReleaseStatus = !!result.find(
              (entry) =>
                entry.releaseStatus.key === action.releaseStatus &&
                entry.groupKey === action.groupKey &&
                entry.groupTypeKey === GroupTypeKey.DELIVERY,
            );

            const hasReleaseStatusInMicrodelivery = !!result.find(
              (entry) => entry.releaseStatus.key === action.releaseStatus && entry.groupTypeKey !== GroupTypeKey.DELIVERY,
            );

            const revertIsPossible = hasReleaseStatus && !hasReleaseStatusInMicrodelivery;
            if (revertIsPossible) {
              return revertReleaseStatus({
                pipelineId: action.pipelineId,
                pipelineRunId: action.pipelineRunId,
                pipelineRunKey: action.pipelineRunKey,
                groupId: action.groupId,
                groupKey: action.groupKey,
                groupName: action.groupName,
                isMicrodelivery: action.isMicrodelivery,
                releaseStatus: action.releaseStatus,
                justification: action.justification,
              });
            }
          }),
          catchError(() => {
            const errorMsg = this.buildErrorMsg(
              action.pipelineRunKey,
              action.isMicrodelivery,
              action.groupName,
              action.groupKey,
              action.releaseStatus,
              'reverted',
            );

            this.alertService.createAlert(errorMsg, AlertType.ERROR);

            return of(
              revertReleaseCandidateStatusFailed({
                pipelineId: action.pipelineId,
                pipelineRunId: action.pipelineRunId,
                groupId: action.groupId,
                releaseStatus: action.releaseStatus,
                justification: action.justification,
              }),
            );
          }),
        );
      }),
    );
  });

  public onRevertPipelineRunReleaseStatus = createEffect(() => {
    return this.action$.pipe(
      ofType(revertReleaseStatus),
      mergeMap((action) => {
        return this.fileParserService
          .revertPipelineRunReleaseStatus(action.pipelineRunId, action.groupId, action.releaseStatus, action.justification)
          .pipe(
            map(() =>
              revertReleaseStatusSuccessful({
                pipelineId: action.pipelineId,
                pipelineRunId: action.pipelineRunId,
                groupId: action.groupId,
                releaseStatus: action.releaseStatus,
                justification: action.justification,
              }),
            ),
            catchError(() => {
              const errorMsg = this.buildErrorMsg(
                action.pipelineRunKey,
                action.isMicrodelivery,
                action.groupName,
                action.groupKey,
                action.releaseStatus,
                'reverted',
              );

              this.alertService.createAlert(errorMsg, AlertType.ERROR);
              return of(
                revertReleaseCandidateStatusFailed({
                  pipelineId: action.pipelineId,
                  pipelineRunId: action.pipelineRunId,
                  groupId: action.groupId,
                  releaseStatus: action.releaseStatus,
                  justification: action.justification,
                }),
              );
            }),
            takeUntil(
              this.action$.pipe(
                ofType(revertReleaseStatus),
                filter((newAction) => newAction.groupId === action.groupId && newAction.pipelineRunId === action.pipelineRunId),
              ),
            ),
          );
      }),
    );
  });

  constructor(
    private readonly action$: Actions,
    private readonly accessApiService: AccessApiService,
    private readonly store$: Store,
    private readonly fileParserService: FileParserService,
    private readonly alertService: AlertService,
  ) {}

  private buildErrorMsg(
    pipelineRunKey: string,
    isMicrodelivery: boolean,
    groupName: string,
    groupKey: string,
    releaseStatus: string,
    operation: string,
  ) {
    let errorMsg = `The pipeline run '${pipelineRunKey}' for `;
    errorMsg += isMicrodelivery ? `microdelivery '${groupName}' ` : `delivery '${groupKey}' `;
    errorMsg += `could not be ${operation} by release status '${releaseStatus}'. Please try again later.`;
    return errorMsg;
  }
}
