import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequirementDetail } from '../../../model/requirement-detail/requirement-detail';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Animations } from '../../../shared/animations';
import { POPUP_CONTAINER, PopupService } from '@progress/kendo-angular-popup';

export interface DialogData {
  requirementDetails: RequirementDetail[];
  showDetail: boolean;
  readOnly: boolean;
  isPipelineRunView: boolean;
  isRegression: boolean;
}

@Component({
  selector: 'app-requirement-details-dialog',
  templateUrl: './requirements-detail-dialog.component.html',
  styleUrls: ['./requirements-detail-dialog.component.scss'],
  animations: [
    trigger('tabSwitch', [
      state('left', style({})),
      state('right', style({ 'margin-left': '-100%' })),
      transition('left => right', animate(Animations.parameters.baseDurationMs + ' ease-out')),
      transition('right => left', animate(Animations.parameters.baseDurationMs + ' ease-out')),
    ]),
  ],
  providers: [
    {
      // Provide the current component element as Popup container.
      provide: POPUP_CONTAINER,
      useExisting: ElementRef,
    },
    // Create a new Popup Service that uses the provided container.
    PopupService,
  ],
})
export class RequirementsDetailDialogComponent implements OnInit {
  isSelected = false;
  selectedRequirement: RequirementDetail;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    private readonly dialogRef: MatDialogRef<RequirementsDetailDialogComponent>,
  ) {
    if (dialogData.showDetail) {
      // we need to select a requirement so that the component is always in the tree, ready to animate
      this.selectedRequirement = dialogData.requirementDetails[0];
    }
  }

  ngOnInit() {
    if (!this.dialogData.showDetail) {
      this.dialogRef.updateSize('25vw', 'auto');
    } else {
      this.dialogRef.updateSize('80vw', 'auto');
    }
  }

  closeDialog(data?: any) {
    this.dialogRef.close(data);
  }

  requirementSelected(requirementDetail: RequirementDetail) {
    this.isSelected = !!requirementDetail;
    if (requirementDetail) {
      this.selectedRequirement = requirementDetail;
    }
  }

  getDialogTitle(): string {
    if (!this.dialogData.showDetail) {
      return 'Tested Requirements';
    } else {
      if (this.dialogData.isRegression) {
        return 'Feature Regression Tests';
      }
      return 'Test of new Functionality (FC-2)';
    }
  }
}
