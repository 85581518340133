import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CustomPolicyUsageSlice } from '../app-state';

// First selector should be a featureSelector
export const selectCustomPolicyUsageSlice = createFeatureSelector<CustomPolicyUsageSlice>('customPolicyUsageSlice');

export const selectCustomPolicyKeyGroupDetails = createSelector(selectCustomPolicyUsageSlice, (CustomPolicyUsageSlice) => {
  return CustomPolicyUsageSlice.groupPolicyKeySummary;
});

export const selectCustomPolicyUsageGroupSummary = createSelector(selectCustomPolicyUsageSlice, (CustomPolicyUsageSlice) => {
  return CustomPolicyUsageSlice.groupSummary;
});

export const selectCustomPolicyUsageGroupDetails = createSelector(selectCustomPolicyUsageSlice, (CustomPolicyUsageSlice) => {
  return CustomPolicyUsageSlice.groupDetails;
});
