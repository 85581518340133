import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PipelineRunModeIds } from '../../model/pipeline-run/pipeline-run';
import { PipelineRunExecutionStatusKey } from '../../model/pipeline/execution-status/pipeline-run-execution-status';
import { Pipeline } from '../../model/pipeline/pipeline';
import { selectHasUnprocessedFiles } from '../../ngrx/store/processing-status/processing-status.selectors';

export interface ShowRunDetailsEventData {
  pipelineId: string;
  pipelineRunId: number;
}

@Component({
  selector: 'app-run-header-info',
  templateUrl: './run-header-info.component.html',
  styleUrls: ['./run-header-info.component.scss'],
})
export class RunHeaderInfoComponent {
  pipelineRunModeIds = PipelineRunModeIds;
  _dataTestIdRunKey: string;
  _dataTestIdRunReleaseStatus: string;
  _dataTestIdRunExecutionStatus: string;
  _dataTestIdRunScheduledInfo: string;
  showWarning$: Observable<boolean>;
  @Output() showDetails = new EventEmitter<ShowRunDetailsEventData>();
  @Input() warningTooltip: string;

  constructor(private readonly store$: Store) {}

  _pipeline: Pipeline;

  get pipeline() {
    return this._pipeline;
  }

  @Input()
  set pipeline(info: Pipeline) {
    if (!info) {
      return;
    }
    this._pipeline = info;
    this.setTestIds();
    this.showWarning$ = this.hasUnprocessedFiles();
  }

  setTestIds() {
    this._dataTestIdRunKey = `run-header-info-latest-pipeline-run-pipeline-${this.pipeline.pipelineId}`;
    this._dataTestIdRunReleaseStatus = `run-header-info-latest-pipeline-run-pipeline-release-status-${this.pipeline.pipelineId}`;
    this._dataTestIdRunExecutionStatus = `run-header-info-latest-pipeline-run-pipeline-execution-status-${this.pipeline.pipelineId}`;
    this._dataTestIdRunScheduledInfo = `run-header-info-latest-pipeline-run-pipeline-scheduled-info-${this.pipeline.pipelineId}`;
  }

  emitShowDetailsEvent() {
    this.showDetails.emit({
      pipelineId: this.pipeline.pipelineId,
      pipelineRunId: this.pipeline.pipelineRunId,
    });
  }

  disableTooltip(scheduledIsDeprecated: boolean, scheduledKey: string): boolean {
    // hide tooltip if run is not deprecated or there is no scheduled key available
    const hasNoScheduledKey = !scheduledKey;
    return scheduledIsDeprecated === false ? hasNoScheduledKey : false;
  }

  private hasUnprocessedFiles() {
    return this.store$.select(selectHasUnprocessedFiles(this.pipeline.pipelineRunId));
  }

  protected readonly PipelineRunExecutionStatusKey = PipelineRunExecutionStatusKey;
}
