import { createAction, props } from '@ngrx/store';
import { RequirementTestcaseResult } from '../../../model/requirement-detail/requirement-detail-testcase';
import { Requirement } from '../../../model/jira/release-item';

export const loadPipelineRequirements = createAction(
  `[Regression Test] Trigger loading of pipeline requirements from JIRA`,
  props<{ pipelineId: string }>(),
);

export const loadPipelineRequirementsError = createAction(
  `[Regression Test] Loading pipeline requirements from JIRA failed`,
  props<{ pipelineId: string }>(),
);

export const setPipelineRequirements = createAction(
  `[Regression Test] Set pipeline requirements from JIRA`,
  props<{ pipelineId: string; requirements: Requirement[] }>(),
);

export const loadPipelineRunTestCasesForRegression = createAction(
  `[Regression Test] Trigger loading of pipeline run testcases for regression from backend`,
  props<{ pipelineId: string; pipelineRunId: number }>(),
);

export const loadPipelineRunTestCasesForRegressionError = createAction(
  `[Regression Test] Loading pipeline run testcases for regression from backend failed`,
  props<{ pipelineRunId: number }>(),
);

export const setPipelineRunTestCasesForRegression = createAction(
  `[Regression Test] Set pipeline run testcases for regression from backend`,
  props<{ pipelineRunId: number; testcases: RequirementTestcaseResult[] }>(),
);

export const missingDeliveryFixVersionError = createAction(
  `[Regression Test] Missing DeliveryFixVersion Error`,
  props<{ pipelineId: string }>(),
);
