import { createReducer, on } from '@ngrx/store';
import { CentralPolicyUsageSlice } from '../app-state';
import { initialCentralPolicyUsageSlice } from '../initial-slices';
import * as CentralPolicyUsageActions from './central-policy-usage.actions';

export const CentralPolicyUsageReducer = createReducer(
  initialCentralPolicyUsageSlice,
  on(CentralPolicyUsageActions.loadCentralPolicyUsageGroupSummary, (state, _): CentralPolicyUsageSlice => {
    return { ...state, groupSummary: initialCentralPolicyUsageSlice.groupSummary };
  }),
  on(CentralPolicyUsageActions.setCentralPolicyKeyGroupDetails, (state, props): CentralPolicyUsageSlice => {
    return { ...state, groupPolicyKeySummary: props.groupPolicyKeySummary };
  }),
  on(CentralPolicyUsageActions.setCentralPolicyUsageGroupSummary, (state, props): CentralPolicyUsageSlice => {
    return { ...state, groupSummary: props.groupSummary };
  }),
  on(CentralPolicyUsageActions.setCentralPolicyUsageGroupDetails, (state, props): CentralPolicyUsageSlice => {
    return { ...state, groupDetails: props.groupDetails };
  }),
);
