import { Group } from '../../model/group/group';
import { JiraIssue, JiraRelease } from '../../model/jira/release-item';

export enum ReleaseDecisionEntityTypes {
  JIRA_RELEASE = 'jira-release',
  JIRA_ISSUE = 'jira-issue',
  REQUIREMENT_FILE = 'requirement-file',
}

export class GroupTypeMicrodelivery {
  id?: string;
  jiraIssueId: number;
  projectKey: string;
  releaseDecisionEntityType: ReleaseDecisionEntityTypes;
  group: Group;
  linkedPipelineIds: string[];

  constructor(jiraItem: JiraRelease | JiraIssue, type: ReleaseDecisionEntityTypes) {
    this.releaseDecisionEntityType = type;
    this.initializeGenericFields(jiraItem);
    switch (this.releaseDecisionEntityType) {
      case ReleaseDecisionEntityTypes.JIRA_ISSUE:
        this.setFieldsFromJiraIssue(jiraItem as JiraIssue);
        break;
      case ReleaseDecisionEntityTypes.JIRA_RELEASE:
        this.setFieldsFromJiraRelease(jiraItem as JiraRelease);
        break;
    }
  }

  private initializeGenericFields(jiraItem: JiraRelease | JiraIssue): void {
    this.jiraIssueId = +jiraItem.id;
    this.group = {
      key: `${jiraItem.key || jiraItem.id || ''}`,
      groupTypes: [{ groupTypeKey: 5 }],
      backlogLink: '',
      sourceCodeManagementLink: '',
    };
  }

  private setFieldsFromJiraIssue(jiraItem: JiraIssue): void {
    const activationDate: Date = jiraItem?.fields?.created ? new Date(jiraItem.fields.created) : null;
    const finishDate: Date = this.getLatestResolutionDateFromJiraIssue(jiraItem);

    // Set releaseDecisionEntityType specific fields
    this.projectKey = jiraItem.fields.project.key;
    this.group = {
      ...this.group,
      isLocked: !!jiraItem.fields.resolution,
      name: `${jiraItem.fields.summary} (${jiraItem.key})`,
      activationDate,
      finishDate,
      description: jiraItem.fields.description || '',
    };
  }

  getLatestResolutionDateFromJiraIssue(jiraIssue: JiraIssue): Date {
    const resolutionChangeLogs = jiraIssue.changelog.filter((changeLog) => {
      return changeLog.items.find((item) => item.field === 'resolution' && item.from === null && item.to !== null);
    });
    return resolutionChangeLogs
      .map((changeLogEntry) => new Date(changeLogEntry.created))
      .reduce((prev, current) => {
        if (!prev) {
          return current;
        }
        return current.getTime() > prev.getTime() ? current : prev;
      }, null);
  }

  private setFieldsFromJiraRelease(jiraItem: JiraRelease): void {
    // Set releaseDecisionEntityType specific fields
    this.projectKey = jiraItem.projectKey;
    this.group = {
      ...this.group,
      isLocked: !!jiraItem.released,
      name: jiraItem.name,
      description: jiraItem.description || '',
      activationDate: jiraItem.startDate ? new Date(jiraItem.startDate) : null,
      finishDate: jiraItem.releaseDate ? new Date(jiraItem.releaseDate) : null,
    };
  }
}
