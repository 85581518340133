import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isNullOrUndefined } from '../../../shared/utils';
import { ApiMetadataValidatorDetails, ApiMetadataValidatorStatus, ApiMetadataValidatorSummary } from '../../model/api-metadata-validator';
import { ApiMetadataValidatorSlice } from '../app-state';

// First selector should be a featureSelector
export const selectApiMetadataValidatorSlice = createFeatureSelector<ApiMetadataValidatorSlice>('apiMetadataValidatorSlice');

export const selectApiMetadataValidatorGroupSummary = createSelector(selectApiMetadataValidatorSlice, (apiMetadataValidatorSlice) => {
  return setDisplayStatus(apiMetadataValidatorSlice.groupSummary);
});
export const selectApiMetadataValidatorGroupDetails = createSelector(selectApiMetadataValidatorSlice, (apiMetadataValidatorSlice) => {
  return apiMetadataValidatorSlice.groupDetails.map((detail) => setDisplayStatus(detail));
});
export const selectApiMetadataValidatorPipelineRunSummary = (pipelineRunId: number) =>
  createSelector(selectApiMetadataValidatorSlice, (apiMetadataValidatorSlice: ApiMetadataValidatorSlice) => {
    return setDisplayStatus(apiMetadataValidatorSlice.pipelineRunsSummary[pipelineRunId]);
  });

function setDisplayStatus(
  data: ApiMetadataValidatorDetails | ApiMetadataValidatorSummary,
): ApiMetadataValidatorDetails | ApiMetadataValidatorSummary {
  if (isNullOrUndefined(data)) {
    return data;
  } else if (isNullOrUndefined(data.isApiMetadataValidatorFulfilled)) {
    return { ...data, displayStatus: null, statusSortOrder: 3 };
  } else if (data.isApiMetadataValidatorFulfilled) {
    return { ...data, displayStatus: ApiMetadataValidatorStatus.FULFILLED, statusSortOrder: 2 };
  } else {
    return { ...data, displayStatus: ApiMetadataValidatorStatus.NOT_FULFILLED, statusSortOrder: 1 };
  }
}
