import { JiraIssue } from './release-item';

export interface JiraXrayTest extends JiraIssue {
  reporter: string;
  precondition: [];
  type: string;
  status: string;
  definition: {
    steps: [];
  };
}

export enum XrayTestStatus {
  PASS = 0,
  TODO = 1,
  EXECUTING = 2,
  FAIL = 3,
  ABORTED = 4,
  BLOCKED = 1000,
}
