import { createAction, props } from '@ngrx/store';
import { GroupComplianceMode } from '../../../shared/model/group-compliance-mode';
import { MalwarescanDetails, MalwarescanSummary } from '../../model/malwarescan';

export const loadMalwarescanGroupSummary = createAction(
  `[Malwarescan] Trigger loading of Malwarescan information for group-summary from backend`,
  props<{ deliveryId: string; groupComplianceMode: GroupComplianceMode }>(),
);

export const setMalwarescanGroupSummary = createAction(
  `[Malwarescan] Set Malwarescan information for group-summary from backend in store`,
  props<{ groupSummary: MalwarescanSummary }>(),
);

export const loadMalwarescanGroupDetails = createAction(
  `[Malwarescan] Trigger loading of Malwarescan information for group-details from backend`,
  props<{ deliveryId: string; groupComplianceMode: GroupComplianceMode }>(),
);

export const setMalwarescanGroupDetails = createAction(
  `[Malwarescan] Set Malwarescan information for group-details from backend in store`,
  props<{ groupDetails: MalwarescanDetails[] }>(),
);

export const loadPipelineRunMalwarescanInformation = createAction(
  `[Malwarescan] Trigger loading of Malwarescan information for single-details from backend`,
  props<{ pipelineRunId: number }>(),
);
export const setPipelineRunMalwarescanInformation = createAction(
  `[Malwarescan] Set Malwarescan information for single-details from backend in store`,
  props<{ pipelineRunId: number; pipelineRunDetails: MalwarescanDetails }>(),
);

// errors - NOT FOUND
export const loadMalwarescanGroupSummaryError = createAction(
  `[Malwarescan] Loading of Malwarescan Group Summary error`,
  props<{ deliveryId: string }>(),
);

export const loadMalwarescanGroupSummaryNotFound = createAction(
  `[Malwarescan] Loading of Malwarescan Group Summary not found`,
  props<{ deliveryId: string }>(),
);
export const loadMalwarescanGroupDetailsError = createAction(
  `[Malwarescan] Loading of Malwarescan Group Details error`,
  props<{ deliveryId: string }>(),
);

export const loadMalwarescanPipelineRunError = createAction(
  `[Malwarescan] Loading of Malwarescan Pipeline Run Information error`,
  props<{ pipelineRunId: number }>(),
);

export const loadMalwarescanPipelineRunNotFound = createAction(
  `[Malwarescan] Loading of Malwarescan Pipeline Run Information not found`,
  props<{ pipelineRunId: number }>(),
);
