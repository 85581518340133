import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CodeCoverage } from '../../model/code-coverage/code-coverage.model';
import { PipelineRunRequirement } from '../../model/pipeline-run/pipeline-run';
import {
  PipelineRunExecutionStatus,
  PipelineRunExecutionStatusKey,
} from '../../model/pipeline/execution-status/pipeline-run-execution-status';
import { PipelineIdsByKey } from '../../model/pipeline/pipeline';
import { StepResult } from '../../model/processing-status/step-result';
import { ReleaseStatus, ReleaseStatusKey } from '../../model/release-status/release-status';
import { SonarQubeCoverage } from '../../model/sonarqube/sonar-qube.model';
import { EnvironmentService } from '../environment.service';

export interface ReleaseDecisionInformation {
  backlogIds?: string[];
  pipelineId?: string;
  releaseDecisionEntity?: ReleaseDecisionEntity;
  releaseFilterPrefix?: string;
  releaseIssueProjectId?: string;
  releaseIssueType?: ReleaseIssueType;
  releaseIssueFilterId?: number;
  configurationGroupId?: string;
  coverageThresholds?: any;
  jiraComponentPipelineMappings?: JiraComponentPipelineMapping[];
}

export interface JiraComponentPipelineMapping {
  component: string;
  pipelineKeys: string[];
}

export interface JiraAllComponentPipelineMapping {
  pipelineKey: string;
  component: string;
}

export interface PipelineRunRaw {
  createdAt: Date;
  gitUrl: string;
  id: number;
  isReleased: boolean;
  latestReleaseStatusKeyInGroup: ReleaseStatusKey;
  latestReleaseStatusCreatedAtInGroup: Date;
  pipelineBuildLink: string;
  buildUrl: string;
  pipelineId: string;
  pipelineRunKey: string;
  releaseFlag: boolean;
  sourceCodeManagementCommitId: number;
  startDateTime: Date;
  updatedAt: Date;
  pipelineRunMode: string;
  pipelineRunModeId: number;
  relatedPipelineRunId: number;
  relatedPipelineRunKey: string;
  relatedPipelineRunMode: string;
  relatedPipelineRunModeId: number;
  relatedStartDateTime: Date;
  isRelatedPipelineRunDeprecated: boolean;
  hasReleaseStatus: boolean;
  executionStatusKey: PipelineRunExecutionStatusKey;
  executionStatusDate: Date;
}

export interface PipelineRun extends Omit<PipelineRunRaw, 'latestReleaseStatusKeyInGroup'> {
  latestReleaseStatusKeyInGroup: ReleaseStatus;
  executionStatus?: PipelineRunExecutionStatus;
}

export enum ReleaseDecisionEntity {
  Release = 'release',
  Issue = 'issue',
}

export enum ReleaseIssueType {
  Epic = 'epic',
  UserStory = 'user-story',
}

export interface PipelineGroupSearch {
  entityType: 'group' | 'pipeline';
  id: string;
  key: string;
}

@Injectable({
  providedIn: 'root',
})
export class AccessApiService {
  constructor(
    private readonly http: HttpClient,
    private readonly envService: EnvironmentService,
  ) {}

  _baseUrl: string;

  get baseUrl() {
    if (!this._baseUrl) {
      this._baseUrl = `https://${this.envService.getBaseUrl()}/zprs/gcp/access-api/api`;
    }
    return this._baseUrl;
  }

  getPipelines(searchString?: string): Observable<PipelineGroupSearch[]> {
    const requestUrl = `${this.baseUrl}/groups/pipelines/union`;
    let params = new HttpParams();

    if (searchString) {
      params = params.set('searchString', searchString);
    }

    return this.http.get<PipelineGroupSearch[]>(requestUrl, { params: params });
  }

  getPipelineRunsForPipeline(pipelineId: string, groupId: string): Observable<PipelineRun[]> {
    return this.http.get<PipelineRunRaw[]>(`${this.baseUrl}/v3/pipelines/${pipelineId}/pipelineRuns/group/${groupId}`).pipe(
      map((pipelineRuns) => {
        return pipelineRuns.map((pipelineRun) => {
          return {
            ...pipelineRun,
            latestReleaseStatusKeyInGroup: ReleaseStatus.getByKey(pipelineRun.latestReleaseStatusKeyInGroup),
            isRelatedPipelineRunDeprecated: this.isRelatedPipelineRunOlderThanOneDay(pipelineRun),
            executionStatus: PipelineRunExecutionStatus.getByKey(pipelineRun.executionStatusKey),
          };
        });
      }),
    );
  }

  isRelatedPipelineRunOlderThanOneDay(pipelineRun: PipelineRunRaw) {
    const leadingStartDateTime = new Date(pipelineRun.startDateTime);
    const relatedStartDateTime = pipelineRun.relatedStartDateTime ? new Date(pipelineRun.relatedStartDateTime) : null;
    return relatedStartDateTime ? leadingStartDateTime.getTime() - relatedStartDateTime.getTime() > 24 * 3600 * 1000 : false;
  }

  getPipelineRunCoverageAggregation(pipelineRunId: number) {
    return this.http.get<any[]>(`${this.baseUrl}/v3/pipelineRuns/${pipelineRunId}/coverage/aggregate`).pipe(
      map((summary: any) => {
        if (!summary) {
          return summary;
        }
        return {
          lineRate: summary.avgLineRate * 100,
          branchRate: summary.avgBranchRate * 100,
        };
      }),
    );
  }

  buildFileLinkUrlForStepResult(stepResultId: number) {
    return `${this.baseUrl}//v1/files/stepResults/${stepResultId}/download` + `?x-delivery-guid=${this.envService.getDeliveryGuid()}`;
  }

  buildFileLinkForComplianceCertificate(fileName: string, pipelineId: string, pipelineRunId: number): string {
    return (
      `${this.baseUrl}/v1/files/compliance-certificates/${fileName}/download` +
      `?x-delivery-guid=${this.envService.getDeliveryGuid()}&pipelineId=${pipelineId}&pipelineRunId=${pipelineRunId}`
    );
  }

  getStepResultCoverageResults(stepResultId: number) {
    return this.http.get<CodeCoverage[]>(`${this.baseUrl}/v2/stepResults/${stepResultId}/coverage`).pipe(map((result) => result[0]));
  }

  getStepResultUnitTestResult(stepResultId: number) {
    return this.http.get(`${this.baseUrl}/stepResults/${stepResultId}/unitTest`);
  }

  getStepResults(pipelineRunId: number) {
    return this.http.get<StepResult[]>(`${this.baseUrl}/v3/pipelineRuns/${pipelineRunId}/stepResults`);
  }

  getRequirementsForGroup(groupId: string) {
    return this.http.get<PipelineRunRequirement[]>(`${this.baseUrl}/v3/groups/${groupId}/requirements`);
  }

  getPipelineIdsByKeys(pipelineKeys: string[]): Observable<PipelineIdsByKey[]> {
    return this.http.post<PipelineIdsByKey[]>(`${this.baseUrl}/v3/pipelines/translatePipelineKeys`, pipelineKeys);
  }

  getPipelineRunSonarQubeCoverage(pipelineRunId: number): Observable<SonarQubeCoverage> {
    return this.http.get<SonarQubeCoverage>(`${this.baseUrl}/v3/pipelineRuns/${pipelineRunId}/sonarqube-coverage`);
  }
}
