<div class="dialogHeader">
  <p class="dialogTitle">List of compliant SCMs</p>
  <mat-icon (click)="closeDialog()" aria-hidden="false" aria-label="cancel" class="closeButton">cancel</mat-icon>
</div>

<div class="flex flex-col" mat-dialog-content>
  <span
    >This is the list of compliant SCM systems you need to use to fulfill requirement
    <a
      rel="noopener"
      href="https://wiki.wdf.sap.corp/wiki/display/pssl/SLC-25"
      target="_blank"
      [fusionTooltip]="'Open SLC-25 Product Standard'"
      ><span>SLC-25</span><fusion-icon id="SLC25DocuLinkIcon" name="link-external" size="S"></fusion-icon></a
    >.
  </span>

  <span class="flex flex-grow"></span>

  <ul *ngIf="slc25Whitelist$ | async as slc25Whitelist; else noconfig" class="whitelist-repository-list">
    <li *ngFor="let allowedSCM of slc25Whitelist">
      <strong>{{ allowedSCM.name }}</strong>
      <span> - {{ allowedSCM.url }}</span>
    </li>
  </ul>
  <ng-template #noconfig>
    <strong>No repositories configured.</strong>
  </ng-template>

  <span class="flex flex-grow"></span>

  <span
    >List maintained in
    <a
      rel="noopener"
      href="https://github.tools.sap/P4TEAM/cumulus-compliance-configuration"
      target="_blank"
      [fusionTooltip]="'Open Compliance configuration repository'"
      ><span>Cumulus compliance repository</span><fusion-icon id="complianceDocuLinkIcon" name="link-external" size="S"></fusion-icon
    ></a>
  </span>
</div>
