<div class="dialogHeader">
  <p class="dialogTitle">Source Code Management (SLC-25)</p>
  <mat-icon aria-hidden="false" aria-label="cancel" class="closeButton" (click)="closeDialog()" data-test-id="slc-25-detail-popup-close-btn"
    >cancel
  </mat-icon>
</div>

<div
  *ngIf="{
    isLoading: isLoading$ | async,
    isLoadingError: isLoadingError$ | async,
    dataSource: dataSource$ | async,
  } as data"
  class="flex flex-col"
  mat-dialog-content
>
  <div class="flex h-full w-full">
    <app-dialog-error-information (reloadClicked)="reload()" *ngIf="data?.isLoadingError"></app-dialog-error-information>
    <kendo-grid
      class="flex h-full w-full"
      id="slc25-detail-grid"
      data-test-id="slc-25-detail-popup-grid"
      *ngIf="!data?.isLoadingError"
      [data]="dataSource"
      [loading]="data.isLoading"
      [sortable]="true"
      [sort]="gridState.sort"
      [filterable]="true"
      [filter]="gridState.filter"
      (dataStateChange)="dataStateChanged($event)"
    >
      <kendo-grid-column field="pipelineKey" title="Pipeline" width="35%">
        <ng-template kendoGridCellTemplate kendoTooltip let-dataItem>
          <app-kpi-column-pipeline [item]="dataItem" fusionFeatureUsageTracker="open-slc25-pipeline-url"></app-kpi-column-pipeline>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="scmsLink" title="SCM System" width="45%" [filterable]="false" [sortable]="false">
        <ng-template kendoGridCellTemplate kendoTooltip let-dataItem>
          <a
            *ngIf="!!dataItem.scmsUrl && dataItem.complianceInformationAvailable"
            target="_blank"
            class="link"
            [href]="dataItem.scmsUrl"
            fusionTooltip="Open SCM System"
            fusionFeatureUsageTracker="open-slc25-scmsLink"
            [fusionFeatureUsageTrackerEvents]="['click']"
            rel="noopener noreferrer"
          >
            <span class="link-text">{{ dataItem.scmsUrl }}</span>
            <fusion-icon class="link-icon" name="link-external" size="S"></fusion-icon>
          </a>
          <span *ngIf="!dataItem.scmsUrl && dataItem.complianceInformationAvailable" fusionTooltip="No SCMS-URL available" class="no-link">
            {{ dataItem.key }}
          </span>
          <span *ngIf="!dataItem.complianceInformationAvailable">&mdash;</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="complianceStatus.displayKey" title="Status" width="20%">
        <ng-template kendoGridCellTemplate kendoTooltip let-dataItem>
          <app-kpi-column-compliance-status
            [item]="dataItem"
            dataTestId="slc-25-detail-popup-grid-compliance-status"
          ></app-kpi-column-compliance-status>
        </ng-template>
      </kendo-grid-column>

      <ng-template kendoGridLoadingTemplate>
        <app-table-loading-indicator></app-table-loading-indicator>
      </ng-template>
    </kendo-grid>
  </div>
</div>
