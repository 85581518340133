<div class="dialogHeader">
  <p class="dialogTitle">Cobertura/JaCoCo Coverage</p>
  <mat-icon
    aria-hidden="false"
    aria-label="cancel"
    class="closeButton"
    (click)="closeDialog()"
    data-test-id="code-coverage-detail-popup-close-btn"
    >cancel
  </mat-icon>
</div>

<div
  *ngIf="{
    isLoading: isLoading$ | async,
    isLoadingError: isLoadingError$ | async,
    dataSource: dataSource$ | async,
  } as data"
  class="flex flex-col"
  mat-dialog-content
>
  <div class="flex h-full w-full">
    <app-dialog-error-information (reloadClicked)="reload()" *ngIf="data?.isLoadingError"></app-dialog-error-information>
    <kendo-grid
      class="flex h-full w-full"
      id="code-coverage-detail-grid"
      data-test-id="code-coverage-detail-popup-grid"
      *ngIf="!data?.isLoadingError"
      [data]="dataSource"
      [loading]="data.isLoading"
      [sortable]="true"
      [sort]="gridState.sort"
      [filterable]="true"
      [filter]="gridState.filter"
      (dataStateChange)="dataStateChanged($event)"
    >
      <kendo-grid-column field="pipelineKey" title="Pipeline" width="44px">
        <ng-template kendoGridCellTemplate kendoTooltip let-dataItem>
          <app-kpi-column-pipeline
            [item]="dataItem"
            fusionFeatureUsageTracker="open-code-coverage-details-pipeline-url"
          ></app-kpi-column-pipeline>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="lineRate" title="Line Rate" width="20px">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-numeric-filter-cell
            [column]="column"
            [filter]="filter"
            [spinners]="false"
            min="0"
            max="100"
            format="##.#"
            decimals="1"
          >
          </kendo-grid-numeric-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <div data-test-id="code-coverage-lineRate-column" class="flex justify-end items-center">
            <span *ngIf="isNullOrUndefined(dataItem.lineRate)">&mdash;</span>
            <span *ngIf="!isNullOrUndefined(dataItem.lineRate)">{{ dataItem.lineRate | number: '1.1' }}%</span>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="branchRate" title="Branch Rate" width="20px">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-numeric-filter-cell
            [column]="column"
            [filter]="filter"
            [spinners]="false"
            min="0"
            max="100"
            format="##.#"
            decimals="1"
          ></kendo-grid-numeric-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <div data-test-id="code-coverage-branchRate-column" class="flex justify-end items-center">
            <span *ngIf="isNullOrUndefined(dataItem.branchRate)">&mdash;</span>
            <span *ngIf="!isNullOrUndefined(dataItem.branchRate)">{{ dataItem.branchRate | number: '1.1' }}%</span>
          </div>
        </ng-template>
      </kendo-grid-column>

      <ng-template kendoGridLoadingTemplate>
        <app-table-loading-indicator></app-table-loading-indicator>
      </ng-template>
    </kendo-grid>
  </div>
</div>
