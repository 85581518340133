import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { PipelineRunModeIds } from '../../model/pipeline-run/pipeline-run';
import { ComplianceInfoDetails, ComplianceInfoSummary, ComplianceStatus } from '../../ngrx/model/compliance-information';
import { ProductStandard } from '../../shared/model/product-standard';

export interface ComplianceStatusLabelLoadingState {
  loadingError: boolean;
  loadingNotFound: boolean;
}

export interface TooltipSettings {
  text: string | TemplateRef<any>;
  isDisabled: boolean;
}

@Component({
  selector: 'app-compliance-status-label',
  templateUrl: './compliance-status-label.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComplianceStatusLabelComponent {
  public ComplianceStatus = ComplianceStatus;

  @Input() complianceInformation: ComplianceInfoDetails | ComplianceInfoSummary;
  @Input() loadingState: ComplianceStatusLabelLoadingState;
  @Input() noFilesUploadedCondition = true;
  @Input() labelClickable = false;
  @Input() isExternalLink = true;
  @Input() tooltipText: string;

  @Output() labelClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() reloadButtonClicked: EventEmitter<void> = new EventEmitter<void>();

  noRelevantFilesUploaded(): boolean {
    return (
      !(this.complianceInformation as any)?.complianceInformationAvailable &&
      !this.loadingState.loadingError &&
      this.isPipelineLevel() &&
      this.noFilesUploadedCondition
    );
  }

  isPipelineLevel() {
    return !!(this.complianceInformation as ComplianceInfoDetails)?.pipelineRunId;
  }

  labelClick() {
    if (this.isClickable()) {
      this.labelClicked.emit();
    }
  }

  isClickable() {
    return !this.isPipelineLevel() || this.labelClickable;
  }

  getTooltipSettings(pipelineRunModeId: number, pipelineRunsComplianceInfo): TooltipSettings {
    const result: TooltipSettings = {
      text: '',
      isDisabled: false,
    };

    if (!this.isPipelineLevel()) {
      result.text = 'Open Details';
    } else if (pipelineRunModeId === PipelineRunModeIds.Optimized_Commit) {
      result.text = pipelineRunsComplianceInfo;
    } else if (this.tooltipText && this.labelClickable) {
      result.text = this.tooltipText;
    } else {
      result.isDisabled = true;
    }
    return result;
  }

  getStatusTestId(psrKey: string, pipelineKey: string) {
    const productStandardKey = this.getProductStandardKey(psrKey);
    return productStandardKey.generateTestId('-compliance-status-text-', pipelineKey);
  }

  getCheckResultTextTestId(psrKey: string) {
    const productStandardKey = this.getProductStandardKey(psrKey);
    return productStandardKey.generateTestId('-check-result-text');
  }

  getDisplayKey(psrKey: string) {
    return this.getProductStandardKey(psrKey)?.displayKey;
  }

  getProductStandardKey(psrKey: string) {
    return ProductStandard.getByKey(psrKey);
  }
}
