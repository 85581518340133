import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Pipeline } from '../../model/pipeline/pipeline';
import { EnvironmentService } from '../environment.service';

export interface PipelineRunProcessingStatus {
  numberOfFiles: number;
  numberOfProcessedFiles: number;
  unprocessedFiles: string[];
  processingStatusId: number;
  processingStatusName: string;
}

export interface PipelineProcessingStatus extends PipelineProcessingStatusRequestBodyItem {
  pipelineRunProcessingStatus: PipelineRunProcessingStatus;
}

export interface PipelineProcessingStatusRequestBodyItem {
  pipelineId: string;
  pipelineRunId: number;
}

@Injectable({
  providedIn: 'root',
})
export class ProcessingStatusService {
  get baseUrl() {
    return `https://${this.envService.getBaseUrl()}/zprs/gcp/file-parser/api/processing-status`;
  }

  constructor(
    private readonly envService: EnvironmentService,
    private readonly httpClient: HttpClient,
  ) {}

  getPipelineRunProcessingStatus(pipelineRunId: number): Observable<PipelineRunProcessingStatus> {
    // since bucket name = pipelineId in our current data model
    return this.httpClient.get<PipelineRunProcessingStatus>(`${this.baseUrl}/pipeline-run/${pipelineRunId}`);
  }

  getPipelinesProcessingStatus(pipelines: Pipeline[]): Observable<PipelineProcessingStatus[]> {
    // since bucket name = pipelineId in our current data model
    const pipelineInfos: PipelineProcessingStatusRequestBodyItem[] = pipelines.map((pipeline) => ({
      pipelineId: pipeline.pipelineId,
      pipelineRunId: pipeline.pipelineRunId,
    }));
    return this.httpClient.post<PipelineProcessingStatus[]>(`${this.baseUrl}/pipelines`, pipelineInfos);
  }

  restorePipelineRun(pipelineRunId: number): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/pipeline-run/${pipelineRunId}/restore`, null);
  }

  restorePipelineRuns(pipelineRunIds: number[]): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/pipeline-run/restore`, pipelineRunIds);
  }
}
