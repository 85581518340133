import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { EMPTY, from, of } from 'rxjs';
import { catchError, first, map, mergeMap, switchMap } from 'rxjs/operators';
import { AccessApiService } from 'src/app/shared/access-api/access-api.service';
import { AccessGroupService } from '../../../shared/access-api/access-group.service';
import { GroupService } from '../../../shared/group/group.service';
import { ProcessingStatusService } from '../../../shared/processing-status/processing-status.service';
import { reloadGroup } from '../deliveries/deliveries.actions';
import { selectSelectedGroupComplianceMode } from '../deliveries/deliveries.selectors';
import { setSelectedPipelineRunOnlyInStore } from '../pipeline-run/pipeline-run.actions';
import { setPipelineRunProcessingStatus } from '../processing-status/processing-status.actions';
import {
  loadPipelineProcessingStatus,
  loadPipelinesByQuery,
  loadPipelinesForDelivery,
  removePipelineFromDelivery,
  removePipelineFromDeliverySuccessful,
  setPipelinesByQuery,
  setPipelinesByQueryError,
  setPipelinesForDelivery,
} from './pipeline.actions';
import { selectPipelineById, selectPipelinesSorted } from './pipeline.selectors';

@Injectable()
export class PipelineEffects {
  public onLoadPipelinesForDelivery$ = createEffect(() => {
    return this.action$.pipe(
      ofType(loadPipelinesForDelivery),
      concatLatestFrom(() => this.store$.select(selectSelectedGroupComplianceMode)),
      switchMap(([action, groupComplianceMode]) => {
        return this.accessGroupService.getPipelinesForDelivery(action.delivery.id, groupComplianceMode).pipe(
          map((pipelines) => setPipelinesForDelivery({ pipelines })),
          catchError((error) => {
            // 404 by loading pipelines means that the current group does not have any pipelines configured
            // so we have to set the pipelines to empty array
            if (error.status === 404) {
              return of(setPipelinesForDelivery({ pipelines: [] }));
            }
            return EMPTY;
          }),
        );
      }),
    );
  });

  /**
   * Needs to happen after setPipelinesWithComponentsForDelivery and not in setPipelinesForDelivery because of asynchronous
   * execution new values might be overwritten by older ones.
   * Does not happen in onConfigOrPipelineLoadedSetComponentsOfPipeline because it would cause unnecessary HTTP requests.
   * Could be moved to onLoadPipelinesForDelivery as soon as we have the processing data in the store (in a separate slice)
   */
  public onPipelineLoadedSetProcessingState$ = createEffect(() => {
    return this.action$.pipe(
      ofType(setPipelinesForDelivery),
      concatLatestFrom(() => this.store$.select(selectPipelinesSorted)),
      switchMap(([_, pipelines]) => {
        return this.processingStatusService.getPipelinesProcessingStatus(pipelines).pipe(
          first(),
          mergeMap((processingStatusOfPipelines) => from(processingStatusOfPipelines)),
          map((processingStatusOfPipeline) => {
            return setPipelineRunProcessingStatus({
              pipelineRunId: processingStatusOfPipeline.pipelineRunId,
              processingStatus: processingStatusOfPipeline.pipelineRunProcessingStatus,
            });
          }),
        );
      }),
    );
  });

  public onLoadPipelineProcessingStatus = createEffect(() => {
    return this.action$.pipe(
      ofType(loadPipelineProcessingStatus),
      concatLatestFrom((action) => this.store$.select(selectPipelineById(action.pipelineId))),
      mergeMap(([_, pipeline]) => {
        return this.processingStatusService.getPipelinesProcessingStatus([pipeline]).pipe(
          first(),
          map((processingStatusOfPipelines) => {
            const processingStatusOfPipeline = processingStatusOfPipelines[0];
            return setPipelineRunProcessingStatus({
              pipelineRunId: processingStatusOfPipeline.pipelineRunId,
              processingStatus: processingStatusOfPipeline.pipelineRunProcessingStatus,
            });
          }),
        );
      }),
    );
  });

  public onPipelineLoadedSetSelectedPipelineRuns$ = createEffect(() => {
    return this.action$.pipe(
      ofType(setPipelinesForDelivery),
      switchMap((action) => {
        const actionsToTrigger = [];
        action.pipelines.forEach((pipeline) => {
          actionsToTrigger.push(setSelectedPipelineRunOnlyInStore(pipeline.pipelineId, pipeline.pipelineRunId));
        });
        return actionsToTrigger;
      }),
    );
  });

  public onLoadPipelinesByQuery$ = createEffect(() => {
    return this.action$.pipe(
      ofType(loadPipelinesByQuery),
      switchMap((action) => {
        return this.accessApiService.getPipelines(action.searchString).pipe(
          map((pipelines) => setPipelinesByQuery({ searchString: action.searchString, pipelines: pipelines })),
          catchError((_) => {
            return of(setPipelinesByQueryError());
          }),
        );
      }),
    );
  });

  public onRemovePipelineFromDelivery$ = createEffect(() => {
    return this.action$.pipe(
      ofType(removePipelineFromDelivery),
      switchMap((action) => {
        return this.groupService
          .removePipelinesFromGroup(action.deliveryId, [action.pipelineId])
          .pipe(map(() => removePipelineFromDeliverySuccessful({ pipelineId: action.pipelineId })));
      }),
    );
  });

  public onRemovePipelineFromDeliverySuccessfulToLoadPipelinesForDelivery$ = createEffect(() => {
    return this.action$.pipe(
      ofType(removePipelineFromDeliverySuccessful),
      map((_) => reloadGroup()),
    );
  });

  constructor(
    private readonly action$: Actions,
    private readonly accessGroupService: AccessGroupService,
    private readonly groupService: GroupService,
    private readonly processingStatusService: ProcessingStatusService,
    private readonly accessApiService: AccessApiService,
    private readonly store$: Store,
  ) {}
}
