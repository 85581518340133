<ng-container
  *ngIf="{
    loading: isLoading$ | async,
    loadingError: isLoadingError$ | async,
    loadingNotFound: isLoadingNotFound$ | async,
    apiMetadataValidatorData: apiMetadataValidatorData$ | async,
  } as data"
>
  <app-list-item-kpis
    [flag]="'kpi.flag.beta' | translate"
    [isLoading]="data.loading"
    [title]="'kpi.tile.api-metadata-validator.title' | translate"
  >
    <div class="flex flex-col gap-3.5 w-full" kpi-tile-content>
      <div *ngIf="data.apiMetadataValidatorData as apiMetadataValidator" class="flex flex-col h-full w-full">
        <div class="flex flex-row justify-start items-center">
          <span class="label-text">Error</span>
          <span class="flex flex-grow"></span>
          <span class="label-value">{{ apiMetadataValidator?.error ?? '&mdash;' }}</span>
        </div>
        <mat-divider></mat-divider>
        <div class="flex flex-row justify-start items-center">
          <span class="label-text">Warning</span>
          <span class="flex flex-grow"></span>
          <span class="label-value">{{ apiMetadataValidator?.warning ?? '&mdash;' }}</span>
        </div>
        <mat-divider></mat-divider>
        <div class="flex flex-row justify-start items-center">
          <span class="label-text">Info</span>
          <span class="flex flex-grow"></span>
          <span class="label-value">{{ apiMetadataValidator?.info ?? '&mdash;' }}</span>
        </div>
      </div>

      <div class="flex flex-grow">
        <span
          *ngIf="data.apiMetadataValidatorData && !data.loadingNotFound && !data.loadingError"
          class="status-text status-text-{{
            data.apiMetadataValidatorData?.isApiMetadataValidatorFulfilled ? 'COMPLIANT' : 'NOT_COMPLIANT'
          }}"
          [class.clickable]="!currentPipelineRunId"
          (click)="openDetailsDialog()"
        >
          <div>
            <span
              fusionTooltip="Open Details"
              [fusionTooltipDisabled]="!!currentPipelineRunId"
              fusionTooltipPosition="bottom-right"
              attr.data-test-id="api-metadata-validator-status-text-{{ currentPipelineRunId ? 'pipeline' : 'aggregated' }}"
            >
              {{ data.apiMetadataValidatorData?.displayStatus }}
            </span>
          </div>
        </span>
      </div>
    </div>
    <div kpi-tile-footer class="flex flex-grow">
      <span
        >To completely comply with UA-090, see
        <a href="https://wiki.one.int.sap/wiki/x/P5iaj" target="_blank" rel="noopener noreferrer" class="clickable"
          >requirement description<fusion-icon id="UA090LinkIcon" name="link-external" size="S"></fusion-icon>.</a
        ></span
      >
    </div>
  </app-list-item-kpis>
</ng-container>
