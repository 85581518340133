import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ComplianceInfoDetails, ComplianceStatus } from '../../ngrx/model/compliance-information';
import { ProductStandard } from '../../shared/model/product-standard';

interface IconAndClassName {
  iconName: string;
  className: string;
}

@Component({
  selector: 'app-compliance-status-tooltip',
  templateUrl: './compliance-status-tooltip.component.html',
  styleUrls: ['./compliance-status-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComplianceStatusTooltipComponent {
  @Input() complianceInformation: ComplianceInfoDetails;

  private readonly iconMap = new Map<ComplianceStatus, string>([
    [ComplianceStatus.COMPLIANT, 'shield-check'],
    [ComplianceStatus.NOT_COMPLIANT, 'exclamation-triangle'],
    [ComplianceStatus.UNKNOWN, 'shield-question'],
    [ComplianceStatus.IGNORED, 'shield-x'],
  ]);

  getIconAndClassName(complianceStatus: ComplianceStatus): IconAndClassName {
    return {
      iconName: this.iconMap.get(complianceStatus),
      className: `status-text-${complianceStatus.key}`,
    } as IconAndClassName;
  }

  getTooltipTestId(psrKey: string, pipelineKey: string) {
    const productStandardKey = ProductStandard.getByKey(psrKey);
    return productStandardKey.generateTestId('-compliance-status-tooltip-', pipelineKey);
  }
}
