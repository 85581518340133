import { createAction, props } from '@ngrx/store';
import { PipelineRunProcessingStatus } from '../../../shared/processing-status/processing-status.service';

export const loadPipelineRunProcessingStatus = createAction(
  `[Processing State] Load ProcessingStatus for pipeline run`,
  props<{ pipelineRunId: number }>(),
);

export const setPipelineRunProcessingStatus = createAction(
  `[Processing State] Set ProcessingStatus for pipeline run`,
  props<{ pipelineRunId: number; processingStatus: PipelineRunProcessingStatus }>(),
);

// Errors & NOT FOUND
export const loadPipelineRunProcessingStatusError = createAction(
  `[Processing State] Load ProcessingStatus for pipeline run failed`,
  props<{ pipelineRunId: number }>(),
);

export const loadPipelineRunProcessingStatusNotFound = createAction(
  `[Processing State] Load ProcessingStatus for pipeline run not found`,
  props<{ pipelineRunId: number }>(),
);

// Restore
export const restorePipelineRun = createAction(
  `[Processing State] Trigger restoring of a pipeline run`,
  props<{ pipelineRunId: number; pipelineId: string; pipelineRunKey: string; isLatestPipelineRun: boolean }>(),
);

export const restorePipelineRunSuccessful = createAction(
  `[Processing State] Restoring of a pipeline run successful`,
  props<{ pipelineRunId: number; pipelineId: string; isLatestPipelineRun: boolean }>(),
);

export const restorePipelineRunError = createAction(
  `[Processing State] Restoring of a pipeline run failed`,
  props<{ pipelineRunId: number; pipelineId: string; isLatestPipelineRun: boolean }>(),
);
