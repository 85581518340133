import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { process, State } from '@progress/kendo-data-query';
import { MalwarescanDetails } from '../../../ngrx/model/malwarescan';
import {
  selectIsDeliveryMalwarescanDetailsLoading,
  selectIsDeliveryMalwarescanDetailsLoadingError,
} from '../../../ngrx/store/loading-state/loading-state.selectors';
import { loadMalwarescanGroupDetails } from '../../../ngrx/store/malwarescan/malwarescan.actions';
import { selectMalwarescanGroupDetails } from '../../../ngrx/store/malwarescan/malwarescan.selectors';
import { AccessApiService } from '../../../shared/access-api/access-api.service';
import { GridDataSortService } from '../../../shared/grid-data-sort-service/grid-data-sort.service';
import { KpiDialogsData } from '../../kpis.module';

@Component({
  selector: 'app-malwarescan-details-dialog',
  templateUrl: './malwarescan-details-dialog.component.html',
  styleUrls: ['./malwarescan-details-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MalwarescanDetailsDialogComponent implements OnInit {
  public dataSource$!: Observable<GridDataResult>;
  public isLoading$!: Observable<boolean>;
  public isLoadingError$!: Observable<boolean>;

  malwareScanInfoSorted: MalwarescanDetails[];
  dataSource: GridDataResult;

  gridState: State = {
    filter: {
      logic: 'and',
      filters: [],
    },
    sort: [{ field: 'displayStatus', dir: 'asc' }],
  };

  constructor(
    private readonly dialogRef: MatDialogRef<MalwarescanDetailsDialogComponent>,
    private readonly store$: Store,
    private readonly accessApiService: AccessApiService,
    private readonly gridDataSortService: GridDataSortService,
    @Inject(MAT_DIALOG_DATA) private readonly dialogData: KpiDialogsData,
  ) {
    this.dataSource$ = this.store$.select(selectMalwarescanGroupDetails).pipe(
      map((data: MalwarescanDetails[]) => {
        this.malwareScanInfoSorted = data;
        this.dataStateChanged(this.gridState);
        return this.dataSource;
      }),
    );
    this.isLoading$ = this.store$.select(selectIsDeliveryMalwarescanDetailsLoading);
    this.isLoadingError$ = this.store$.select(selectIsDeliveryMalwarescanDetailsLoadingError);
  }

  ngOnInit() {
    this.dialogRef.updateSize('60vw', 'auto');
  }

  reload() {
    this.store$.dispatch(
      loadMalwarescanGroupDetails({
        deliveryId: this.dialogData?.deliveryId,
        groupComplianceMode: this.dialogData?.groupComplianceMode,
      }),
    );
  }

  closeDialog(data?: any) {
    this.dialogRef.close(data);
  }

  buildFileLinkUrlForStepResult(stepResultId: number): string {
    return this.accessApiService.buildFileLinkUrlForStepResult(stepResultId);
  }

  dataStateChanged(state) {
    this.gridState = state;
    this.dataSource = process(this.malwareScanInfoSorted, this.gridState);
    this.dataSource.data = this.gridDataSortService.sortByReplacedNumberField(
      this.gridState.sort,
      'displayStatus',
      'statusSortOrder',
      this.dataSource.data,
    );
  }
}
