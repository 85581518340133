import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { RequirementDetail } from '../../model/requirement-detail/requirement-detail';
import { isRequirementsMappingFailed, RequirementTestcaseResult } from '../../model/requirement-detail/requirement-detail-testcase';
import {
  selectDeliveryRequirements,
  selectDeliveryRequirementsTestcases,
  selectPipelineRunRequirementsTestcases,
} from '../../ngrx/store/fc-2/fc-2.selectors';
import {
  selectFc2DeliveryLoadingError,
  selectFC2DeliveryOverallLoading,
  selectFc2PipelineRunLoadingError,
  selectFC2PipelineRunOverallLoading,
} from '../../ngrx/store/loading-state/loading-state.selectors';
import { JiraService } from '../../shared/jira-service/jira.service';
import { RequirementsDetailDialogComponent } from './dialog/requirements-detail-dialog.component';
import { Requirement } from '../../model/jira/release-item';

@Component({
  selector: 'app-list-item-kpis-fc2',
  templateUrl: './list-item-kpis-fc2.component.html',
  styleUrls: ['./list-item-kpis-fc2.component.scss'],
})
export class ListItemKpisFc2Component implements OnInit, OnChanges {
  @Input() readOnly = false;
  @Input() pipelineRunId: number;

  testCasesData$: Observable<RequirementTestcaseResult[]>;
  jiraRequirementsData$: Observable<Requirement[]>;
  loading$: Observable<boolean>;
  loadingError$: Observable<boolean>;

  constructor(
    private readonly matDialog: MatDialog,
    private readonly jiraService: JiraService,
    private readonly store$: Store,
  ) {}

  ngOnInit() {
    this.jiraRequirementsData$ = this.store$.select(selectDeliveryRequirements);
    if (this.pipelineRunId) {
      this.pipelineRunChanged();
    } else {
      this.testCasesData$ = this.store$.select(selectDeliveryRequirementsTestcases);
      this.loading$ = this.store$.select(selectFC2DeliveryOverallLoading);
      this.loadingError$ = this.store$.select(selectFc2DeliveryLoadingError);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.pipelineRunId) {
      this.pipelineRunChanged();
    }
  }

  pipelineRunChanged() {
    const pipelineRunId = this.pipelineRunId;
    this.loading$ = this.store$.select(selectFC2PipelineRunOverallLoading(pipelineRunId));
    this.loadingError$ = this.store$.select(selectFc2PipelineRunLoadingError(pipelineRunId));
    this.testCasesData$ = this.store$.select(selectPipelineRunRequirementsTestcases(pipelineRunId));
  }

  getAmount(testCases: RequirementTestcaseResult[]): number {
    if (!testCases) {
      return null;
    }
    /* We want the number of requirements whose test cases are all OK.
    For this, we get the number of requirements with test cases
    and the number of requirements with at least one failed test case
    This approach is of order O(n log n) whereas the trivial approach
    (iterate through all test cases for every requirement) would be of
    order O(n*m) where n is the number of test cases and m the number of requirements */
    const requirementsWithTests = new Set();
    const requirementsNotOk = new Set();
    for (const testcase of testCases) {
      if (!testcase) {
        continue;
      }
      if (this.getOverallTestStatus([testcase]) !== 'OK') {
        requirementsNotOk.add(testcase.requirementKey);
      }
      requirementsWithTests.add(testcase.requirementKey);
    }
    return requirementsWithTests.size - requirementsNotOk.size;
  }

  getAmountOfRequirementsWithTests(testCases: RequirementTestcaseResult[]): number {
    if (!testCases) {
      return null;
    }
    const requirementsWithTests = new Set();
    for (const testcase of testCases) {
      if (!testcase) {
        continue;
      }
      requirementsWithTests.add(testcase.requirementKey);
    }
    return requirementsWithTests.size;
  }

  getRequirementDetails(requirements: Requirement[], testCases: RequirementTestcaseResult[]): RequirementDetail[] {
    if (!requirements || !requirements.length || !testCases) {
      console.error('deliveryRequirements or deliveryTestcases not set, cannot get meaningful requirement details');
      return [];
    }
    const requirementDetails = [];
    requirements.forEach((requirement) => {
      const testcases = this.getTestcases(requirement, testCases);
      const testStatus = this.getOverallTestStatus(testcases);
      requirementDetails.push({
        id: requirement.id.toString(),
        key: requirement.key,
        projectKey: requirement.key,
        testStatus,
        link: requirement.link ?? this.jiraService.getJiraLink(requirement),
        testcases,
        status: {
          name: requirement.statusName,
          sort: this.getStatusSort(requirement.statusName),
        },
        fixVersions: requirement.fixVersions,
        sort: this.getSort(testStatus),
        summary: requirement.summary,
      });
    });
    return requirementDetails;
  }

  getTestcases(jiraElement: any, testCases: RequirementTestcaseResult[]): RequirementTestcaseResult[] {
    return testCases.filter((test) => test?.requirementKey.toUpperCase() === jiraElement.key.toUpperCase());
  }

  getSort(overallTestStatus: string): number {
    let testStatusSortNumber: number;
    switch (overallTestStatus) {
      case 'Not OK':
        testStatusSortNumber = 1;
        break;
      case 'No tests':
        testStatusSortNumber = 2;
        break;
      case 'OK':
        testStatusSortNumber = 3;
        break;
    }
    return testStatusSortNumber;
  }

  getOverallTestStatus(tests: RequirementTestcaseResult[]): string {
    if (!tests || !tests.length) {
      return 'No tests';
    }
    for (const test of tests.filter((ts) => !!ts)) {
      if (isRequirementsMappingFailed(test) || !test.status || !test.name.length || test.skipped || test.hasIncorrectVersion) {
        return 'Not OK';
      }
    }
    return 'OK';
  }

  openRequirementDetails(requirements: Requirement[], testCases: RequirementTestcaseResult[]) {
    const requirementDetails = this.getRequirementDetailsToShow(requirements, testCases);
    this.matDialog.open(RequirementsDetailDialogComponent, {
      data: {
        showDetail: true,
        readOnly: this.readOnly,
        requirementDetails,
        isPipelineRunView: !!this.pipelineRunId,
      },
      panelClass: 'requirement-details-container',
      autoFocus: 'input',
    });
  }

  hasNotOkRequirements(testCases: RequirementTestcaseResult[], requirements?: Requirement[]): boolean {
    const successfullyTestedRequirements = this.getAmount(testCases);
    if (this.pipelineRunId) {
      return this.getAmountOfRequirementsWithTests(testCases) > successfullyTestedRequirements;
    } else {
      return requirements?.length > successfullyTestedRequirements;
    }
  }

  private getRequirementDetailsToShow(requirements: Requirement[], testCases: RequirementTestcaseResult[]) {
    const requirementDetails: RequirementDetail[] = this.getRequirementDetails(requirements, testCases);
    if (this.pipelineRunId) {
      return requirementDetails.filter((req) => req.testStatus !== 'No tests');
    }
    return requirementDetails;
  }

  private getStatusSort(jiraStatus: string): number {
    let sortNumber: number;
    switch (jiraStatus) {
      case 'Reopened':
      case 'Blocked':
      case 'Open':
        sortNumber = 1;
        break;
      case 'In Progress':
        sortNumber = 2;
        break;
      case 'Consumed':
      case 'Completed':
      case 'Inactive':
        sortNumber = 3;
        break;
    }
    return sortNumber;
  }
}
