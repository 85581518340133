import { Component, Inject, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ConfirmationDialogData {
  title: string;
  positiveButtonText: string;
  negativeButtonText: string;
  content?: TemplateRef<any>;
}

export enum ConfirmationDialogResult {
  POSITIVE,
  NEGATIVE,
}

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  data: ConfirmationDialogData = {
    title: 'Confirmation Dialog',
    positiveButtonText: 'Confirm',
    negativeButtonText: 'Cancel',
  };

  result = ConfirmationDialogResult;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: ConfirmationDialogData,
    private readonly dialogRef: MatDialogRef<any>,
  ) {
    this.data = { ...this.data, ...this.dialogData };
  }
}
