import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Psl1Slice } from '../app-state';

// First selector should be a featureSelector
export const selectPsl1Slice = createFeatureSelector<Psl1Slice>('psl1Slice');

export const selectPsl1GroupSummary = createSelector(selectPsl1Slice, (psl1Slice) => {
  return psl1Slice.aggregationSummary;
});

export const selectPsl1GroupDetails = createSelector(selectPsl1Slice, (psl1Slice) => {
  return psl1Slice.aggregationDetails;
});

export const selectPsl1SingleDetails = createSelector(selectPsl1Slice, (psl1Slice) => {
  return psl1Slice.selectedPipelineRuns;
});

export const selectPsl1SingleDetailById = (pipelineRunId: number) =>
  createSelector(selectPsl1Slice, (psl1Slice: Psl1Slice) => {
    return psl1Slice.selectedPipelineRuns[pipelineRunId];
  });
