import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RequirementDetail } from '../../../../model/requirement-detail/requirement-detail';
import { orderBy, process, SortDescriptor, State } from '@progress/kendo-data-query';
import { DataStateChangeEvent, ExcelExportEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { MatomoService } from '@tools/fusion';
import { Workbook } from '@progress/kendo-angular-excel-export';
import { saveAs } from '@progress/kendo-file-saver';
import { TranslateService } from '@ngx-translate/core';
import { TraceabilityExcelService } from '../../../../shared/traceability-excel-export/traceability-excel.service';

@Component({
  selector: 'app-requirements',
  templateUrl: './requirements.component.html',
  styleUrls: ['./requirements.component.scss'],
})
export class RequirementsComponent {
  sort: SortDescriptor[] = [
    {
      field: 'sort',
      dir: 'asc',
    },
  ];
  sortedRequirements: RequirementDetail[];
  dataSource: GridDataResult;
  exportingExcel = false;
  gridState: State = {
    skip: 0,
    take: 100000,
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  @Input() set requirementDetails(requirementDetails: RequirementDetail[]) {
    this.sortedRequirements = orderBy(requirementDetails, this.sort);
    this.dataSource = process(this.sortedRequirements, {});
  }

  @Input() showDetail = false;

  @Input() readOnly: boolean;

  @Input() showLightweightTestExport = true;

  @Input() showExcelExport = true;

  @Output() requirementSelect = new EventEmitter<RequirementDetail>();

  constructor(
    private readonly matomoService: MatomoService,
    private readonly translateService: TranslateService,
    private readonly traceabilityExcelService: TraceabilityExcelService,
  ) {}

  private getStatusColorClass(jiraStatus: string): string {
    switch (jiraStatus) {
      case 'Reopened':
      case 'Blocked':
      case 'Open':
        return 'jiraStatus-open';
      case 'In Progress':
        return 'jiraStatus-progress';
      case 'Consumed':
      case 'Completed':
      case 'Inactive':
        return 'jiraStatus-completed';
    }
    return 'jiraStatus-open';
  }

  public gridDataStateChange(gridState: DataStateChangeEvent): void {
    this.gridState = gridState;
    this.dataSource = process(this.sortedRequirements, this.adaptSortDescriptors(gridState));
    this.matomoService.trackEvent('click-filter', 'filter-requirements-in-FC2');
  }

  // this method uses the appropriate sort descriptor based on the sorted field name.
  // the two columns below are now sorted numerically, not alphabetically.

  private adaptSortDescriptors(gridState: DataStateChangeEvent) {
    switch (gridState?.sort[0]?.field) {
      case 'jiraStatus.name':
        return {
          ...gridState,
          sort: [{ field: 'status.sort', dir: gridState.sort[0].dir }],
        };
      case 'testStatus':
        return {
          ...gridState,
          sort: [{ field: 'sort', dir: gridState.sort[0].dir }],
        };
      default:
        return gridState;
    }
  }

  getStatusClasses(jiraStatus: string) {
    if (!jiraStatus) {
      return {};
    }
    const obj = { jiraStatus: true };
    obj[this.getStatusColorClass(jiraStatus)] = true;
    return obj;
  }

  getTestStatusClass(testStatus: string): string {
    if (testStatus === 'OK' || testStatus === 'OK*') {
      return 'test-status-ok';
    } else if (testStatus === 'No tests') {
      return 'test-status-no-tests';
    } else {
      return 'test-status-failed';
    }
  }

  onExcelExport(args: ExcelExportEvent): void {
    this.exportingExcel = true;
    args.preventDefault();

    const workbook = this.traceabilityExcelService.createTraceabilityWorkbook(args.workbook, this.sortedRequirements);

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    new Workbook(workbook).toDataURL().then((dataUrl: string) => {
      // https://www.telerik.com/kendo-angular-ui/components/filesaver/
      saveAs(dataUrl, this.translateService.instant('kpi.tile.requirements.excel-export.file-name'));
      this.exportingExcel = false;
    });
  }
}
