<div class="dialogHeader">
  <p class="dialogTitle">API Metadata Validator</p>
  <mat-icon
    aria-hidden="false"
    aria-label="cancel"
    class="closeButton"
    (click)="closeDialog()"
    data-test-id="api-metadata-validator-detail-popup-close-btn"
    >cancel
  </mat-icon>
</div>

<div
  *ngIf="{
    isLoading: isLoading$ | async,
    isLoadingError: isLoadingError$ | async,
    dataSource: dataSource$ | async,
  } as data"
  class="flex flex-col"
  mat-dialog-content
>
  <div class="flex h-full w-full">
    <app-dialog-error-information (reloadClicked)="reload()" *ngIf="data?.isLoadingError"></app-dialog-error-information>
    <kendo-grid
      class="flex h-full w-full"
      id="api-metadata-validator-detail-grid"
      data-test-id="api-metadata-validator-detail-popup-grid"
      *ngIf="!data?.isLoadingError"
      [data]="dataSource"
      [loading]="data.isLoading"
      [sortable]="true"
      [sort]="gridState.sort"
      [filterable]="true"
      [filter]="gridState.filter"
      (dataStateChange)="dataStateChanged($event)"
    >
      <kendo-grid-column field="pipelineKey" title="Pipeline" width="44%">
        <ng-template kendoGridCellTemplate kendoTooltip let-dataItem>
          <app-kpi-column-pipeline [item]="dataItem" fusionFeatureUsageTracker="open-fc3-pipeline-url"></app-kpi-column-pipeline>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="error"
        title="Error"
        width="12%"
        class="text-align-right"
        headerClass="text-align-right"
        [filterable]="false"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{ dataItem?.error ?? '&mdash;' }}</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="warning"
        title="Warning"
        width="12%"
        class="text-align-right"
        headerClass="text-align-right"
        [filterable]="false"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{ dataItem?.warning ?? '&mdash;' }}</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="info" title="Info" width="12%" class="text-align-right" headerClass="text-align-right" [filterable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{ dataItem?.info ?? '&mdash;' }}</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="displayStatus" title="Status" width="20%">
        <ng-template kendoGridCellTemplate kendoTooltip let-dataItem>
          <div
            *ngIf="!isNullOrUndefined(dataItem?.isApiMetadataValidatorFulfilled)"
            class="status-text-tooltip-container flex flex-row justify-start items-center"
          >
            <span class="status-circle status-circle-{{ dataItem?.isApiMetadataValidatorFulfilled ? 'COMPLIANT' : 'NOT_COMPLIANT' }}">
            </span>
            <span>{{ dataItem?.displayStatus }}</span>
          </div>
          <div class="flex flex-row justify-items-center" *ngIf="isNullOrUndefined(dataItem?.isApiMetadataValidatorFulfilled)">
            <span>&mdash;</span>
          </div>
        </ng-template>
      </kendo-grid-column>

      <ng-template kendoGridLoadingTemplate>
        <app-table-loading-indicator></app-table-loading-indicator>
      </ng-template>
    </kendo-grid>
  </div>
</div>
