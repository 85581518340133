import { ComplianceInfoDetails, ComplianceInfoSummary } from '../../model/compliance-information';
import { ComplianceService } from '../../../shared/compliance-service/compliance.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import {
  loadSlc25GroupDetails,
  loadSlc25GroupDetailsError,
  loadSlc25GroupSummary,
  loadSlc25GroupSummaryError,
  loadSlc25GroupSummaryNotFound,
  loadSlc25SingleDetails,
  loadSlc25SingleDetailsError,
  loadSlc25SingleDetailsNotFound,
  setSlc25GroupDetails,
  setSlc25GroupSummary,
  setSlc25SingleDetails,
} from './slc-25.actions';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ProductStandard } from '../../../shared/model/product-standard';

@Injectable()
export class Slc25Effects {
  public onLoadGroupSummary$ = createEffect(() => {
    return this.action$.pipe(
      ofType(loadSlc25GroupSummary),
      switchMap((action) =>
        this.comlianceService
          .getGroupSummaryComplianceInformationForAction(ProductStandard.slc25, action.deliveryId, action.groupComplianceMode)
          .pipe(
            map((groupSummary: ComplianceInfoSummary) => {
              return setSlc25GroupSummary({ groupSummary });
            }),
            catchError((error: HttpErrorResponse) => {
              const deliveryId = action.deliveryId;
              if (error.status === 404) {
                return of(loadSlc25GroupSummaryNotFound({ deliveryId }));
              }
              return of(loadSlc25GroupSummaryError({ deliveryId }));
            }),
          ),
      ),
    );
  });

  public onLoadGroupDetails$ = createEffect(() => {
    return this.action$.pipe(
      ofType(loadSlc25GroupDetails),
      switchMap((action) =>
        this.comlianceService
          .getGroupDetailsComplianceInformationForAction(ProductStandard.slc25, action.deliveryId, action.groupComplianceMode)
          .pipe(
            map((groupDetails: ComplianceInfoDetails[]) => setSlc25GroupDetails({ groupDetails })),
            catchError(() => {
              return of(loadSlc25GroupDetailsError({ deliveryId: action.deliveryId }));
            }),
          ),
      ),
    );
  });

  public onLoadSingleDetails$ = createEffect(() => {
    return this.action$.pipe(
      ofType(loadSlc25SingleDetails),
      mergeMap((action) =>
        this.comlianceService.getSingleDetailsComplianceInformationForAction(ProductStandard.slc25, action.pipelineRunId).pipe(
          map((singleDetails: ComplianceInfoDetails) =>
            setSlc25SingleDetails({
              pipelineRunId: action.pipelineRunId,
              singleDetails,
            }),
          ),
          catchError((error) => {
            if (error.status === 404) {
              return of(loadSlc25SingleDetailsNotFound({ pipelineRunId: action.pipelineRunId }));
            }
            return of(loadSlc25SingleDetailsError({ pipelineRunId: action.pipelineRunId }));
          }),
          takeUntil(
            this.action$.pipe(
              ofType(loadSlc25SingleDetails),
              filter((newAction) => newAction.pipelineRunId === action.pipelineRunId),
            ),
          ),
        ),
      ),
    );
  });

  constructor(
    private readonly action$: Actions,
    private readonly comlianceService: ComplianceService,
  ) {}
}
