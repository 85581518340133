import { createReducer, on } from '@ngrx/store';
import { CustomPolicyResultsSlice } from '../app-state';
import { initialCustomPolicyResultsSlice } from '../initial-slices';
import * as CustomPolicyResultsActions from './custom-policy-results.actions';

export const CustomPolicyResultsReducer = createReducer(
  initialCustomPolicyResultsSlice,
  on(CustomPolicyResultsActions.loadCustomPolicyResultsGroupSummary, (state, _): CustomPolicyResultsSlice => {
    return { ...state, groupSummary: initialCustomPolicyResultsSlice.groupSummary };
  }),
  on(CustomPolicyResultsActions.setCustomPolicyResultsSingleSummary, (state, props): CustomPolicyResultsSlice => {
    const pipelineRunsSummary = { ...state.pipelineRunsSummary };
    pipelineRunsSummary[props.pipelineRunId] = props.singleSummary;
    return { ...state, pipelineRunsSummary };
  }),
  on(CustomPolicyResultsActions.setCustomPolicyResultsSingleDetails, (state, props): CustomPolicyResultsSlice => {
    const pipelineRunsDetails = { ...state.pipelineRunsDetails };
    pipelineRunsDetails[props.pipelineRunId] = props.singleDetails;
    return { ...state, pipelineRunsDetails };
  }),
  on(CustomPolicyResultsActions.setCustomPolicyResultsGroupSummary, (state, props): CustomPolicyResultsSlice => {
    return { ...state, groupSummary: props.groupSummary };
  }),
  on(CustomPolicyResultsActions.setCustomPolicyResultsGroupDetails, (state, props): CustomPolicyResultsSlice => {
    return { ...state, groupDetails: props.groupDetails };
  }),
);
