import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { GridDataResult, GridItem } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { Observable, map } from 'rxjs';
import { PolicyResultsPipelineRunDetail } from '../../../ngrx/model/policy-results';
import { loadCentralPolicyResultsSingleDetails } from '../../../ngrx/store/central-policy-results/central-policy-results.actions';
import { selectCentralPolicyResultsSingleDetailById } from '../../../ngrx/store/central-policy-results/central-policy-results.selectors';
import {
  selectIsCentralPolicyResultsPipelineRunDetailsLoading,
  selectIsCentralPolicyResultsPipelineRunDetailsLoadingError,
} from '../../../ngrx/store/loading-state/loading-state.selectors';
import { AccessApiPolicyService } from '../../../shared/access-api/policy/access-api-policy.service';
@Component({
  selector: 'app-policy-results-details',
  templateUrl: './policy-results-details.component.html',
  styleUrls: ['./policy-results-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PolicyResultsDetailsComponent implements OnChanges {
  @Input() pipelineRunId: number;
  @Output() policyAmount = new EventEmitter<number>();

  public dataSource$!: Observable<GridDataResult>;
  public isLoading$!: Observable<boolean>;
  public isLoadingError$!: Observable<boolean>;

  policyResultsInfo: PolicyResultsPipelineRunDetail[];
  dataSource: GridDataResult;
  showToolNameColumn = false;

  gridState: State = {
    filter: {
      logic: 'and',
      filters: [],
    },
    sort: [
      {
        field: 'policyComplianceStatus.displayKey',
        dir: 'desc',
      },
    ],
  };

  constructor(
    private readonly store$: Store,
    private readonly accessApiPolicy: AccessApiPolicyService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pipelineRunId) {
      this.pipelineRunChanged();
    }
  }

  reload() {
    this.store$.dispatch(
      loadCentralPolicyResultsSingleDetails({
        pipelineRunId: this.pipelineRunId,
      }),
    );
  }

  isSubPathInformationAvailable(): boolean {
    return this.policyResultsInfo.filter((result) => !!result?.subPath).length > 0;
  }

  trackByResultId(index: number, item: GridItem) {
    const row = item.data as PolicyResultsPipelineRunDetail;
    return row?.policyResultId;
  }

  getPolicyDisplayText(policy: PolicyResultsPipelineRunDetail): string {
    return `${policy.policyKey}: ${policy.policyLabel}`;
  }

  mailTo(data: PolicyResultsPipelineRunDetail): void {
    const subject = encodeURIComponent(`Question Regarding Policy: "${data.policyText}"`);
    const lines = [];
    lines.push(`Hello colleagues,`);
    lines.push(``);
    lines.push(`I have a question regarding the following policy: "${data.policyText}".`);
    lines.push(``);
    if (data.validationErrorMessages.length > 0) {
      lines.push(`The following error messages are shown:`);
      data.validationErrorMessages.forEach((message) => {
        lines.push(`- ${message}`);
      });
      lines.push(``);
    }
    lines.push(`Thank you and best regards,`);
    lines.push(``);

    const body = encodeURIComponent(lines.join('\n'));

    const mailTo = `mailTo:${data.policyOwner}?subject=${subject}&cc=${data.policyOwnerSubstitute}&body=${body}`;
    window.open(mailTo, '_blank', 'noopener,noreferrer');
  }

  downloadFile(data: PolicyResultsPipelineRunDetail): void {
    window.open(this.accessApiPolicy.buildFileLinkUrlForPolicyResult(data.policyResultId), '_blank', 'noopener,noreferrer');
  }

  getDownloadTooltipText(fileName: string, uploadPath: string): string {
    return uploadPath ? `${uploadPath}/${fileName}` : `${fileName}`;
  }

  private pipelineRunChanged() {
    this.dataSource$ = this.store$.select(selectCentralPolicyResultsSingleDetailById(this.pipelineRunId)).pipe(
      map((data) => {
        this.policyResultsInfo = data;
        this.dataSource = process(this.policyResultsInfo, this.gridState);
        this.policyAmount.emit(this.dataSource.total);
        this.showToolNameColumn = this.policyResultsInfo.some((result) => !!result.toolName);
        return this.dataSource;
      }),
    );
    this.isLoading$ = this.store$.select(selectIsCentralPolicyResultsPipelineRunDetailsLoading(this.pipelineRunId));
    this.isLoadingError$ = this.store$.select(selectIsCentralPolicyResultsPipelineRunDetailsLoadingError(this.pipelineRunId));
  }
}
