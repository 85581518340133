import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { POPUP_CONTAINER, PopupService } from '@progress/kendo-angular-popup';
import { PolicyResultsGroupDetails } from 'src/app/ngrx/model/policy-results';
import { loadCentralPolicyResultsSingleDetails } from 'src/app/ngrx/store/central-policy-results/central-policy-results.actions';
import { loadCustomPolicyResultsSingleDetails } from '../../../ngrx/store/custom-policy-results/custom-policy-results.actions';
import { Animations } from '../../../shared/animations';
import { KpiDialogsData } from '../../kpis.module';

@Component({
  selector: 'app-list-item-kpis-policy-result-aggregation-dialog',
  templateUrl: './list-item-kpis-policy-result-aggregation-dialog.component.html',
  styleUrls: ['./list-item-kpis-policy-result-aggregation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('tabSwitch', [
      state('left', style({})),
      state('right', style({ 'margin-left': '-100%' })),
      transition('left => right', animate(Animations.parameters.baseDurationMs + ' ease-out')),
      transition('right => left', animate(Animations.parameters.baseDurationMs + ' ease-out')),
    ]),
  ],
  providers: [
    {
      // Provide the current component element as Popup container.
      provide: POPUP_CONTAINER,
      useExisting: ElementRef,
    },
    // Create a new Popup Service that uses the provided container.
    PopupService,
  ],
})
export class ListItemKpisPolicyResultAggregationDialogComponent implements OnInit {
  public isSelected = false;
  public selectedPipelineRun: PolicyResultsGroupDetails;
  public isCustomPolicyResults: boolean;
  public dialogTitle: string;

  constructor(
    private readonly dialogRef: MatDialogRef<ListItemKpisPolicyResultAggregationDialogComponent>,
    private readonly store$: Store,
    @Inject(MAT_DIALOG_DATA) public dialogData: KpiDialogsData,
  ) {}

  ngOnInit(): void {
    this.dialogRef.updateSize('70vw', 'auto');
    this.isCustomPolicyResults = this.dialogData.isCustomPolicyResults;
    this.dialogTitle = this.isCustomPolicyResults ? 'kpi.dialog.custom.policy.results.title' : 'kpi.dialog.policy.results.title';
  }

  pipelineRunSelected(details: PolicyResultsGroupDetails) {
    this.isSelected = !!details;
    if (details) {
      this.selectedPipelineRun = details;
      const pipelineRun = { pipelineRunId: details.pipelineRunId };
      if (this.isCustomPolicyResults) {
        this.store$.dispatch(loadCustomPolicyResultsSingleDetails(pipelineRun));
      } else {
        this.store$.dispatch(loadCentralPolicyResultsSingleDetails(pipelineRun));
      }
    }
  }

  closeDialog(data?: any) {
    this.dialogRef.close(data);
  }
}
