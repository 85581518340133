import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CustomPolicyResultsSlice } from '../app-state';

// First selector should be a featureSelector
export const selectCustomPolicyResultsSlice = createFeatureSelector<CustomPolicyResultsSlice>('customPolicyResultsSlice');

export const selectCustomPolicyResultsSingleDetailById = (pipelineRunId: number) =>
  createSelector(selectCustomPolicyResultsSlice, (customPolicyResultsSlice: CustomPolicyResultsSlice) => {
    return customPolicyResultsSlice.pipelineRunsDetails[pipelineRunId] ?? [];
  });

export const selectCustomPolicyResultsSingleSummaryById = (pipelineRunId: number) =>
  createSelector(selectCustomPolicyResultsSlice, (customPolicyResultsSlice: CustomPolicyResultsSlice) => {
    return customPolicyResultsSlice.pipelineRunsSummary[pipelineRunId];
  });

export const selectCustomPolicyResultsGroupSummary = createSelector(selectCustomPolicyResultsSlice, (CustomPolicyResultsSlice) => {
  return CustomPolicyResultsSlice.groupSummary;
});

export const selectCustomPolicyResultsGroupDetails = createSelector(selectCustomPolicyResultsSlice, (CustomPolicyResultsSlice) => {
  return CustomPolicyResultsSlice.groupDetails;
});
