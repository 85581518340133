import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { NgxGaugeType } from 'ngx-gauge/gauge/gauge';
import { Observable } from 'rxjs';
import { SonarQubeCoverage, SonarQubeCoverageGroupSummary } from '../../model/sonarqube/sonar-qube.model';
import {
  selectIsPipelineRunSonarQubeCoverageLoading,
  selectIsSonarQubeCoverageGroupSummaryLoading,
} from '../../ngrx/store/loading-state/loading-state.selectors';
import { loadSonarQubeCoverageGroupDetails } from '../../ngrx/store/sonar-qube-coverage/sonar-qube-coverage.actions';
import {
  selectSonarQubeCoverageForPipelineRun,
  selectSonarQubeCoverageGroupSummary,
} from '../../ngrx/store/sonar-qube-coverage/sonar-qube-coverage.selectors';
import { thresholds } from '../../shared/components/coverage-info/coverage-info.component';
import { GroupComplianceMode } from '../../shared/model/group-compliance-mode';
import { SonarqubeCoverageDetailsDialogComponent } from './sonarqube-coverage-details-dialog/sonarqube-coverage-details-dialog.component';

@Component({
  selector: 'app-list-item-kpis-sonarqube-coverage',
  templateUrl: './list-item-kpis-sonarqube-coverage.component.html',
  styleUrls: ['./list-item-kpis-sonarqube-coverage.component.scss'],
})
export class ListItemKpisSonarqubeCoverageComponent implements OnChanges, OnInit {
  isLoading$: Observable<boolean>;
  coverageInfo$: Observable<SonarQubeCoverage | SonarQubeCoverageGroupSummary>;

  @Input() pipelineRunId: number;
  @Input() groupId: string;
  @Input() groupComplianceMode: GroupComplianceMode;
  @Input()
  threshold;

  gaugeType = 'arch' as NgxGaugeType;
  thresholds = thresholds;

  constructor(
    private readonly store$: Store,
    private readonly dialog: MatDialog,
  ) {}
  ngOnInit() {
    if (!this.pipelineRunId) {
      this.isLoading$ = this.store$.select(selectIsSonarQubeCoverageGroupSummaryLoading);
      this.coverageInfo$ = this.store$.select(selectSonarQubeCoverageGroupSummary);
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pipelineRunId) {
      this.pipelineRunChanged();
    }
  }

  pipelineRunChanged() {
    this.coverageInfo$ = this.store$.select(selectSonarQubeCoverageForPipelineRun(this.pipelineRunId));
    this.isLoading$ = this.store$.select(selectIsPipelineRunSonarQubeCoverageLoading(this.pipelineRunId));
  }

  public onViewDetailsClicked(): void {
    if (!this.pipelineRunId) {
      const data = { deliveryId: this.groupId, groupComplianceMode: this.groupComplianceMode };
      this.store$.dispatch(loadSonarQubeCoverageGroupDetails(data));
      this.dialog.open(SonarqubeCoverageDetailsDialogComponent, { data });
    }
  }
}
