import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProcessingStatusSlice } from '../app-state';
import { PipelineRunDocument } from '../../../model/documents/pipeline-run-document.model';

export const selectProcessingStatusSlice = createFeatureSelector<ProcessingStatusSlice>('processingStatusSlice');

export const selectPipelineRunProcessingStatus = (pipelineRunId: number) =>
  createSelector(selectProcessingStatusSlice, (processingStatusSlice) => {
    return processingStatusSlice.pipelineRunProcessingStatus[pipelineRunId];
  });

export const selectPipelineRunUnprocessedFilesRaw = (pipelineRunId: number) =>
  createSelector(selectPipelineRunProcessingStatus(pipelineRunId), (pipelineRunProcessingStatus) => {
    return pipelineRunProcessingStatus?.unprocessedFiles;
  });

export const selectHasUnprocessedFiles = (pipelineRunId: number) =>
  createSelector(selectPipelineRunUnprocessedFilesRaw(pipelineRunId), (unprocessedFiles) => {
    return unprocessedFiles?.length > 0;
  });

export const selectPipelineRunUnprocessedFilesMapped = (pipelineRunId: number) =>
  createSelector(selectPipelineRunUnprocessedFilesRaw(pipelineRunId), (unprocessedFilesRaw: string[]) => {
    return unprocessedFilesRaw.map((file) => PipelineRunDocument.fromUnprocessedFile(file));
  });
