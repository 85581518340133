<kendo-grid
  class="flex h-full w-full"
  id="testcase-list-grid"
  #grid
  data-test-id="fc-2-requirement-detail-testcase-list-grid"
  [data]="dataSource"
  [resizable]="true"
>
  <kendo-grid-column field="name" title="Test Case" width="30%">
    <ng-template kendoGridCellTemplate let-dataItem kendoTooltip>
      <a
        *ngIf="(dataItem.gitSearchUrl && dataItem.gitSearchUrl !== 'null/find/null' && dataItem.name[0] !== '.') || dataItem.jiraLink"
        target="_blank"
        class="link"
        [fusionTooltip]="dataItem.jiraLink ? 'Open in Jira' : 'Open Repository'"
        [fusionFeatureUsageTracker]="
          dataItem.jiraLink ? 'requirements-detail-dialog-view-test-in-jira' : 'requirements-detail-dialog-view-test-in-repository'
        "
        [href]="dataItem.jiraLink || dataItem.gitSearchUrl + '?q=' + dataItem.source"
        rel="noopener noreferrer"
      >
        <span [innerHTML]="highlightTestCaseInDetail(dataItem.name[0], requirementDetail.key)"></span>
        <fusion-icon id="gitOrJiraLinkIcon" [name]="'link-external'" size="S"></fusion-icon>
      </a>
      <p
        *ngIf="(!dataItem.gitSearchUrl || dataItem.gitSearchUrl === 'null/find/null') && dataItem.name[0] !== '.' && !dataItem.jiraLink"
        [innerHTML]="highlightTestCaseInDetail(dataItem.name[0], requirementDetail.key)"
      ></p>
      <p *ngIf="isRequirementsMappingFailed(dataItem)">{{ dataItem.sourceReference }}</p>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="status" title="Status" width="15%">
    <ng-template kendoGridCellTemplate let-dataItem kendoTooltip>
      <span class="dot" [style.background-color]="getStatusColor(dataItem)"></span>
      {{ getStatusText(dataItem) }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="stepResultTypeName" title="Type" width="10%">
    <ng-template kendoGridCellTemplate let-dataItem kendoTooltip>
      <span *ngIf="dataItem.stepResultTypeName" [fusionTooltip]="dataItem.stepResultTypeName" class="stepResultType"
        >{{ dataItem.stepResultTypeName }}
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="source" title="Source Path" width="20%">
    <ng-template kendoGridCellTemplate let-dataItem kendoTooltip>
      <span class="text">{{ dataItem.source ? dataItem.source : '' }}</span>
      <button
        *ngIf="dataItem.source"
        fusionTooltip="Copy to Clipboard"
        fusionIconButton
        fusionIcon="documents-outline"
        fusionIconSize="M"
        [cdkCopyToClipboard]="clipboard"
        (click)="copyValue(dataItem.source)"
        fusionFeatureUsageTracker="requirements-detail-dialog-copy-source"
      ></button>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngIf="isOptimizedPipeline && isPipelineRunView" field="pipelineRunKey" title="Pipeline Run" width="25">
    <ng-template kendoGridCellTemplate let-dataItem>
      <app-optimized-pipeline-run-info [optimizedPipelineRun]="dataItem"></app-optimized-pipeline-run-info>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="pipelineKey" title="Pipeline" width="25%" *ngIf="!isPipelineRunView">
    <ng-template kendoGridCellTemplate let-dataItem kendoTooltip>
      <div *ngIf="dataItem.pipelineKey">
        <a
          *ngIf="checkBuildUrl(dataItem.buildUrl)"
          target="_blank"
          class="link"
          [href]="dataItem.buildUrl"
          [fusionTooltip]="'Open Pipeline'"
          fusionFeatureUsageTracker="requirements-detail-dialog-open-pipeline"
          rel="noopener noreferrer"
          >{{ dataItem.pipelineKey | pipelineKeyWithoutTimestamp }}
          <fusion-icon id="pipelineLinkIcon" [name]="'link-external'" size="S"></fusion-icon>
        </a>
        <span *ngIf="!checkBuildUrl(dataItem.buildUrl)" class="text">{{ dataItem.pipelineKey | pipelineKeyWithoutTimestamp }}</span>
      </div>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
