/**
 * Contains all interfaces related to pipelineRun
 */

import { ReleaseStatus } from '../release-status/release-status';

export interface PipelineRunRequirement {
  relevantPipelineRunId?: number;
  requirementId?: number;
  requirementKey: string;
  requirementCreatedAt?: string;
  requirementUpdatedAt?: string;
}

export enum PipelineRunModeIds {
  Standard = 1,
  Optimized_Commit = 2,
  Optimized_Scheduled = 3,
}

export interface PipelineRunReleaseStatus {
  id: string;
  pipelineRunId: number;
  releaseStatusId: number;
  groupKey: string;
  groupTypeKey: number;
  releaseStatus: ReleaseStatus;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
}
