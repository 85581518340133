import { Injectable } from '@angular/core';
import { SortDescriptor } from '@progress/kendo-data-query';
import { ComplianceInfoDetails } from '../../ngrx/model/compliance-information';

@Injectable({
  providedIn: 'root',
})
export class GridDataSortService {
  sortByReplacedNumberField(sortDespriptorData: SortDescriptor[], originalSortField: string, replacedSortfield: string, data: any[]) {
    // get kendo column sort descriptor if e.g. column field 'complianceDisplayStatus' is selected, but should be sorted by column field 'statusSortOrder'
    const sortDescriptor = sortDespriptorData.find((sortDescriptor) => sortDescriptor.field == originalSortField);
    if (sortDescriptor) {
      if (sortDescriptor.dir == 'asc') {
        return data.sort((dataItem1, dataItem2) => dataItem1[replacedSortfield] - dataItem2[replacedSortfield]);
      } else if (sortDescriptor.dir == 'desc') {
        return data.sort((dataItem1, dataItem2) => dataItem2[replacedSortfield] - dataItem1[replacedSortfield]);
      } else {
        return data;
      }
    } else {
      return data;
    }
  }

  sortByReplacedNumberFieldObject(
    sortDespriptorData: SortDescriptor[],
    originalSortField: string,
    replacedSortField: string,
    replacedSortSubfield: string,
    data: any[],
  ) {
    // get kendo column sort descriptor if e.g. column field 'complianceDisplayStatus' is selected, but should be sorted by column field 'statusSortOrder'
    const sortDescriptor = sortDespriptorData.find((sortDescriptor) => sortDescriptor.field == originalSortField);
    if (sortDescriptor) {
      if (sortDescriptor.dir == 'asc') {
        return data.sort(
          (dataItem1, dataItem2) => dataItem1[replacedSortField][replacedSortSubfield] - dataItem2[replacedSortField][replacedSortSubfield],
        );
      } else if (sortDescriptor.dir == 'desc') {
        return data.sort(
          (dataItem1, dataItem2) => dataItem2[replacedSortField][replacedSortSubfield] - dataItem1[replacedSortField][replacedSortSubfield],
        );
      } else {
        return data;
      }
    } else {
      return data;
    }
  }

  sortComplianceStateByStatusSortOrder(
    sortDespriptorData: SortDescriptor[],
    data: ComplianceInfoDetails[],
    originalSortField = 'complianceStatus.displayKey',
    replacedSortfield = 'statusSortOrder',
  ): ComplianceInfoDetails[] {
    return this.sortByReplacedNumberField(sortDespriptorData, originalSortField, replacedSortfield, data);
  }

  sortPolicyComplianceStateByStatusSortOrder(
    sortDescriptorData: SortDescriptor[],
    data: any[],
    originalSortField = 'policyComplianceStatus.displayKey',
    replacedSortField = 'policyComplianceStatus',
    replacedSortSubfield = 'statusSortOrder',
  ): any[] {
    return this.sortByReplacedNumberFieldObject(sortDescriptorData, originalSortField, replacedSortField, replacedSortSubfield, data);
  }
}
