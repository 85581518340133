<div class="dialog-content-flex flex-grow flex flex-col gap-8">
  <div class="flex flex-col gap-4 items-center">
    <div id="question" class="font-size-18 text-align-center line-height-30">
      Are you sure, you want to <strong>revert the current released state</strong> of the following selected pipeline run to
      <strong>its previous state</strong> in this (micro) delivery (group)?
    </div>
    <div class="description flex flex-col gap-2 w-full">
      <div class="flex flex-row">
        <strong>{{ data?.startDateTime | date: 'MMM d, y hh:mm:ss a' }}</strong>
        <div class="chip-margin" *ngIf="data?.latestReleaseStatusKeyInGroup.key">
          <mat-chip class="mat-chip-small" [disableRipple]="true" [disabled]="true">
            <small class="text-overflow">{{ data?.latestReleaseStatusKeyInGroup.displayKey }}</small>
          </mat-chip>
        </div>
      </div>
      <span>{{ data?.pipelineRunKey }}</span>
    </div>
  </div>
  <div class="flex flex-col gap-4">
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Comment</mat-label>
      <textarea
        matInput
        required
        [maxlength]="maxCommentLength"
        type="text"
        placeholder="Write a short comment why the released state was reverted..."
        [(ngModel)]="commentValue"
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="10"
        data-test-id="release-status-revert-comment"
        fusionFeatureUsageTracker="textarea-revert-comment"
      ></textarea>
      <mat-hint align="start">To revert the state released, a comment for documentation purposes is required.</mat-hint>
      <mat-hint align="end">{{ commentValue?.length }} / {{ maxCommentLength }}</mat-hint>
    </mat-form-field>
  </div>
</div>
<div class="dialog-actions flex-grow-0 flex flex-row justify-between">
  <button
    (click)="triggerCancelDialog()"
    fusionButton
    fusionButtonKind="flat"
    fusionColor="secondary"
    data-test-id="cancel-btn"
    fusionFeatureUsageTracker="revert-confirmation-dialog-cancel-btn"
    [fusionFeatureUsageTrackerEvents]="['click', 'mouseover']"
  >
    Cancel
  </button>
  <button
    (click)="handleRevertReleaseStatus(ReleaseStatus.RELEASED)"
    [disabled]="commentValue === ''"
    [fusionTooltip]="commentValue ? 'Submit reverting current Released state.' : 'Comment is required to revert the released state.'"
    fusionButton
    fusionButtonKind="filled"
    fusionColor="primary"
    data-test-id="submit-btn"
    fusionFeatureUsageTracker="revert-confirmation-dialog-submit-btn"
    [fusionFeatureUsageTrackerEvents]="['click', 'mouseover']"
  >
    Submit
  </button>
</div>
