import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { PipelineRunDocument } from '../../../../model/documents/pipeline-run-document.model';
import { AccessApiService } from '../../../../shared/access-api/access-api.service';
import { StepResultType } from '../../../../shared/model/step-result-type';
import { isNullOrUndefined } from '../../../../shared/utils';

// To be deleted in the near future

@Injectable({
  providedIn: 'root',
})
export class DocumentCoverageService {
  constructor(private readonly accessApiService: AccessApiService) {}

  addCoverageInformation(document: PipelineRunDocument): PipelineRunDocument {
    (document as any).isCoverageType = this.isCoverageType(document);
    if ((document as any).isCoverageType) {
      // currently only coverage are loaded async to set only coverage step results to loading
      (document as any).loading = (document as any).isCoverageType;
      (document as any).getInfo = this.getCoverageInfo(document).pipe(
        finalize(() => {
          (document as any).loading = false;
        }),
        catchError((err) => {
          (document as any).error = err;
          return EMPTY;
        }),
      );
    }
    return document;
  }

  private getCoverageInfo(document: PipelineRunDocument) {
    if (document.stepResultTypeId === StepResultType.SonarQube) {
      return this.accessApiService.getPipelineRunSonarQubeCoverage(document.pipelineRunId).pipe(
        map((result) => {
          const lineRate = result.lineCoverage;
          const branchRate = result.branchCoverage;
          if (isNullOrUndefined(lineRate) && isNullOrUndefined(branchRate)) {
            return undefined;
          }
          return {
            lineRate: +lineRate,
            branchRate: +branchRate,
          };
        }),
      );
    }
    return this.accessApiService.getStepResultCoverageResults(document.stepResultId).pipe(
      map((result) => {
        if (!result) {
          return undefined;
        }

        return {
          branchRate: +result.branchRate,
          lineRate: +result.lineRate,
        };
      }),
    );
  }

  private isCoverageType(document: PipelineRunDocument) {
    return (
      document.stepResultTypeId === StepResultType.JacocoCoverageParser ||
      document.stepResultTypeId === StepResultType.CoberturaCoverageParser ||
      (document.stepResultTypeId === StepResultType.Karma &&
        document.fileName.includes('cobertura') &&
        document.fileName.endsWith('.xml')) ||
      (document.stepResultTypeId === StepResultType.SonarQube &&
        document.fileName.includes('sonarscan') &&
        document.fileName.endsWith('.json'))
    );
  }
}
