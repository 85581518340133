import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Group } from '../model/group/group';
import { AddPipelineDialogComponent } from './add-pipeline-dialog.component';
import { MatDialog } from '@angular/material/dialog';

export interface DialogData {
  group: Group;
}

@Injectable({
  providedIn: 'root',
})
export class AddPipelineDialogService {
  dialogClosed$: Observable<any>;

  constructor(private readonly matDialog: MatDialog) {}

  openDialog(options: any) {
    this.dialogClosed$ = this.matDialog.open(AddPipelineDialogComponent, options).afterClosed();
  }
}
