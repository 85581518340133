import { Component, Input } from '@angular/core';
import { ComplianceInfoUrlDetails } from 'src/app/ngrx/model/compliance-information';

@Component({
  selector: 'app-kpi-column-pipeline',
  templateUrl: './kpi-column-pipeline.component.html',
})
export class KpiColumnPipelineComponent {
  @Input() item: ComplianceInfoUrlDetails;
  @Input() fusionFeatureUsageTracker: string;
}
