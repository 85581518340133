import { Delivery, Group } from '../../../model/group/group';
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { GroupTypeMicrodelivery, ReleaseDecisionEntityTypes } from '../../../ngrx/model/group-type-microdelivery';
import { setSelectedDelivery } from '../../../ngrx/store/deliveries/deliveries.actions';
import { selectSiriusDelivery } from '../../../ngrx/store/deliveries/deliveries.selectors';
import {
  selectMicrodeliveriesErrorCount,
  selectMicrodeliveriesLoading,
  selectMicrodeliveriesRequestCount,
} from '../../../ngrx/store/loading-state/loading-state.selectors';
import { selectDisplayedMicrodeliveriesSorted } from '../../../ngrx/store/microdeliveries/microdeliveries.selectors';
import { EnvironmentService } from '../../environment.service';
import { JiraService } from '../../jira-service/jira.service';
import { getProcessingStatus } from '../../group/group.utils';

interface ReleaseSelectionListItem {
  text: string;
  value: GroupTypeMicrodelivery;
}

@Component({
  selector: 'app-release-selection',
  templateUrl: './release-selection.component.html',
  styleUrls: ['./release-selection.component.scss'],
})
export class ReleaseSelectionComponent implements OnInit {
  defaultReleaseSelectionListItem: ReleaseSelectionListItem;
  releaseSelectionListItems$: Observable<ReleaseSelectionListItem[]>;
  selectedReleaseItem: ReleaseSelectionListItem;
  siriusDelivery$: Observable<Delivery>;

  // UI variables
  loadingReleases$!: Observable<boolean>;
  errorCount$!: Observable<number>;
  requestCount$!: Observable<number>;

  @Input() readOnly: boolean;

  constructor(
    private readonly environmentService: EnvironmentService,
    private readonly translateService: TranslateService,
    private readonly jiraService: JiraService,
    private readonly store$: Store,
  ) {}

  ngOnInit() {
    // set default release item with delivery name and translation as fallback
    this.defaultReleaseSelectionListItem = {
      text: this.environmentService.getDeliveryName() || this.translateService.instant('release-selection.combobox.all-versions.text'),
      value: null,
    };

    this.siriusDelivery$ = this.store$.select(selectSiriusDelivery).pipe(
      tap((siriusDelivery: Delivery) => {
        this.defaultReleaseSelectionListItem.text += ` (${this.getDeliveryStatusBasedOnDates(siriusDelivery)})`;
      }),
    );
    this.loadingReleases$ = this.store$.select(selectMicrodeliveriesLoading);
    this.errorCount$ = this.store$.select(selectMicrodeliveriesErrorCount);
    this.requestCount$ = this.store$.select(selectMicrodeliveriesRequestCount);
    this.releaseSelectionListItems$ = this.store$.select(selectDisplayedMicrodeliveriesSorted).pipe(
      map((microdeliveries) =>
        microdeliveries.map((entity) => ({
          text: `${entity.group.name} (${this.getDeliveryStatusBasedOnDates(entity.group)})`,
          value: entity,
        })),
      ),
    );
  }

  releaseChanged(release: GroupTypeMicrodelivery, siriusDelivery: Delivery) {
    let microdelivery: Delivery = null;
    if (release?.group) {
      microdelivery = {
        ...release?.group,
        isMicrodelivery: true,
        releaseDecisionEntityType: release?.releaseDecisionEntityType,
        jiraIssueId: release?.jiraIssueId,
        projectKey: release?.projectKey,
        arePipelinesSetByComponents: !!release?.linkedPipelineIds,
      };
    }
    this.store$.dispatch(setSelectedDelivery({ delivery: microdelivery || siriusDelivery }));
  }

  /**
   * Returns the Jira Link based on the given releaseItem property
   * @param releaseSelectionItem: Used to build the Jira Link
   */
  buildPipelineLink(releaseSelectionItem: ReleaseSelectionListItem) {
    const microdelivery = releaseSelectionItem.value;
    if (!microdelivery) {
      return undefined;
    }
    switch (microdelivery.releaseDecisionEntityType) {
      case ReleaseDecisionEntityTypes.JIRA_ISSUE:
        return this.jiraService.getJiraLinkForIssue(microdelivery.group.key);
      case ReleaseDecisionEntityTypes.JIRA_RELEASE:
        return this.jiraService.getJiraLinkForRelease(microdelivery.projectKey, microdelivery.jiraIssueId);
      case ReleaseDecisionEntityTypes.REQUIREMENT_FILE:
        return microdelivery.projectKey;
    }
  }

  getOpenMicrodeliveryLinkText(microdelivery: GroupTypeMicrodelivery) {
    if (microdelivery.releaseDecisionEntityType !== ReleaseDecisionEntityTypes.REQUIREMENT_FILE) {
      return 'release-selection.open-microdelivery.in-jira';
    } else {
      return 'release-selection.open-microdelivery';
    }
  }

  private getDeliveryStatusBasedOnDates(delivery: Group) {
    return getProcessingStatus(delivery);
  }
}
