import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { POPUP_CONTAINER, PopupService } from '@progress/kendo-angular-popup';
import { loadCentralPolicyKeyGroupDetails } from 'src/app/ngrx/store/central-policy-usage/central-policy-usage.actions';
import { GroupCustomPolicyUsageByPolicyKey, GroupPolicyUsageByPolicyKey } from '../../../ngrx/model/policy-results';
import { loadCustomPolicyKeyGroupDetails } from '../../../ngrx/store/custom-policy-usage/custom-policy-usage.actions';
import { Animations } from '../../../shared/animations';
import { KpiDialogsData } from '../../kpis.module';

@Component({
  selector: 'app-list-item-kpis-policy-usage-aggregation-dialog',
  templateUrl: './list-item-kpis-policy-usage-aggregation-dialog.component.html',
  styleUrls: ['./list-item-kpis-policy-usage-aggregation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('tabSwitch', [
      state('left', style({})),
      state('right', style({ 'margin-left': '-100%' })),
      transition('left => right', animate(Animations.parameters.baseDurationMs + ' ease-out')),
      transition('right => left', animate(Animations.parameters.baseDurationMs + ' ease-out')),
    ]),
  ],
  providers: [
    {
      // Provide the current component element as Popup container.
      provide: POPUP_CONTAINER,
      useExisting: ElementRef,
    },
    // Create a new Popup Service that uses the provided container.
    PopupService,
  ],
})
export class ListItemKpisPolicyUsageAggregationDialogComponent implements OnInit {
  public isSelected = false;
  public selectedPolicyKey: GroupPolicyUsageByPolicyKey | GroupCustomPolicyUsageByPolicyKey;
  public isCustomPolicyUsage: boolean;
  public dialogTitle: string;

  constructor(
    private readonly dialogRef: MatDialogRef<ListItemKpisPolicyUsageAggregationDialogComponent>,
    private readonly store$: Store,
    @Inject(MAT_DIALOG_DATA) public dialogData: KpiDialogsData,
  ) {}

  ngOnInit(): void {
    this.dialogRef.updateSize('80vw', 'auto');
    this.isCustomPolicyUsage = this.dialogData.isCustomPolicyUsage;
    this.dialogTitle = this.isCustomPolicyUsage ? 'kpi.dialog.custom.policy.usage.title' : 'kpi.dialog.policy.usage.title';
  }

  policyKeySelected(details: any) {
    this.isSelected = !!details;
    if (details) {
      this.selectedPolicyKey = details;
      if (this.isCustomPolicyUsage) {
        this.store$.dispatch(
          loadCustomPolicyKeyGroupDetails({
            groupId: this.dialogData.groupId,
            policyKey: this.selectedPolicyKey['customPolicyKey'],
            groupComplianceMode: this.dialogData.groupComplianceMode,
          }),
        );
      } else {
        this.store$.dispatch(
          loadCentralPolicyKeyGroupDetails({
            groupId: this.dialogData.groupId,
            policyKey: this.selectedPolicyKey['policyKey'],
            groupComplianceMode: this.dialogData.groupComplianceMode,
          }),
        );
      }
    }
  }

  closeDialog(data?: any) {
    this.dialogRef.close(data);
  }
}
