import { createAction, props } from '@ngrx/store';
import { PipelineGroupSearch } from 'src/app/shared/access-api/access-api.service';
import { Delivery } from '../../../model/group/group';
import { Pipeline } from '../../../model/pipeline/pipeline';

export const pipelineKey = 'pipeline';

export const loadPipelinesForDelivery = createAction(
  `[${pipelineKey}] Trigger loading of pipelines for delivery from backend`,
  props<{ delivery: Delivery }>(),
);

export const setPipelinesForDelivery = createAction(
  `[${pipelineKey}] Set pipelines for delivery`,
  props<{
    pipelines: Pipeline[];
  }>(),
);

export const setPipelinesWithProcessingStateForDelivery = createAction(
  `[${pipelineKey}] Set pipelines with processing state for delivery`,
  props<{ pipelines: Pipeline[] }>(),
);

export const removePipelineFromDelivery = createAction(
  `[${pipelineKey}] Remove pipeline from delivery`,
  props<{ pipelineId: string; deliveryId: string }>(),
);

export const removePipelineFromDeliverySuccessful = createAction(
  `[${pipelineKey} Remove pipeline from delivery successful]`,
  props<{ pipelineId: string }>(),
);

export const loadPipelinesByQuery = createAction(
  `Trigger loading of pipelines from backend by Query`,
  props<{
    searchString: string;
  }>(),
);

export const resetPipelineResults = createAction(`Reset pipeline query results`);

export const setPipelinesByQuery = createAction(
  `Set pipelines from backend by Query`,
  props<{ searchString: string; pipelines: PipelineGroupSearch[] }>(),
);

export const setPipelinesByQueryError = createAction(`Set pipelines from backend by Query error`);

export const loadPipelineProcessingStatus = createAction(
  `[${pipelineKey}] Load processing status of Pipeline`,
  props<{ pipelineId: string }>(),
);

export const setPipeline = createAction(`[${pipelineKey}] Set Pipeline`, props<{ pipeline: Pipeline }>());
