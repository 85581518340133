import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { AccessGroupService } from '../../../shared/access-api/access-group.service';
import { AccessPipelineRunService } from '../../../shared/access-api/access-pipeline-run.service';
import { AtcInfoDetails, AtcInfoSummary } from '../../model/atc';
import {
  loadAtcGroupSummary,
  loadAtcGroupSummaryError,
  loadAtcGroupSummaryNotFound,
  loadAtcPipelineRunError,
  loadAtcPipelineRunNotFound,
  loadPipelineRunATCInformation,
  setAtcGroupSummary,
  setPipelineRunATCInformation,
} from './atc.actions';

@Injectable()
export class AtcEffects {
  public onLoadATCGroupSummary$ = createEffect(() => {
    return this.action$.pipe(
      ofType(loadAtcGroupSummary),
      switchMap((action) =>
        this.accessGroupService.getGroupSummaryAtcInformation(action.deliveryId, action.groupComplianceMode).pipe(
          map((groupSummary: AtcInfoSummary) => setAtcGroupSummary({ groupSummary })),
          catchError((error: HttpErrorResponse) => {
            if (error.status === 404) {
              return of(loadAtcGroupSummaryNotFound({ deliveryId: action.deliveryId }));
            }
            return of(loadAtcGroupSummaryError({ deliveryId: action.deliveryId }));
          }),
        ),
      ),
    );
  });

  public onLoadATCSingle$ = createEffect(() => {
    return this.action$.pipe(
      ofType(loadPipelineRunATCInformation),
      mergeMap((action) =>
        this.accessPipelineRunService.getPipelineRunAtc(action.pipelineRunId).pipe(
          map((pipelineRunAtcInformation: AtcInfoDetails) =>
            setPipelineRunATCInformation({ pipelineRunId: action.pipelineRunId, pipelineRunAtcInformation }),
          ),
          catchError((error: HttpErrorResponse) => {
            if (error.status === 404) {
              return of(loadAtcPipelineRunNotFound({ pipelineRunId: action.pipelineRunId }));
            }
            return of(loadAtcPipelineRunError({ pipelineRunId: action.pipelineRunId }));
          }),
          takeUntil(
            this.action$.pipe(
              ofType(loadPipelineRunATCInformation),
              filter((newAction) => newAction.pipelineRunId === action.pipelineRunId),
            ),
          ),
        ),
      ),
    );
  });

  constructor(
    private readonly action$: Actions,
    private readonly accessGroupService: AccessGroupService,
    private readonly accessPipelineRunService: AccessPipelineRunService,
  ) {}
}
