import { ComplianceInfoSummary, Fc3ComplianceInfoDetails } from '../../model/compliance-information';
import { createAction, props } from '@ngrx/store';
import { ProductStandard } from '../../../shared/model/product-standard';
import { GroupComplianceMode } from '../../../shared/model/group-compliance-mode';

export const loadFc3GroupSummary = createAction(
  `[${ProductStandard.fc3}] Trigger loading of ${ProductStandard.fc3} compliance information for group-summary from backend`,
  props<{ deliveryId: string; groupComplianceMode: GroupComplianceMode }>(),
);
export const setFc3GroupSummary = createAction(
  `[${ProductStandard.fc3}] Set ${ProductStandard.fc3} compliance information for group-summary from backend in store`,
  props<{ groupSummary: ComplianceInfoSummary }>(),
);

export const loadFc3GroupDetails = createAction(
  `[${ProductStandard.fc3}] Trigger loading of ${ProductStandard.fc3} compliance information for group-details from backend`,
  props<{ deliveryId: string; groupComplianceMode: GroupComplianceMode }>(),
);
export const setFc3GroupDetails = createAction(
  `[${ProductStandard.fc3}] Set ${ProductStandard.fc3} compliance information for group-details from backend in store`,
  props<{ groupDetails: Fc3ComplianceInfoDetails[] }>(),
);

export const loadFc3SingleDetails = createAction(
  `[${ProductStandard.fc3}] Trigger loading of ${ProductStandard.fc3} compliance information for single-details from backend`,
  props<{ pipelineRunId: number }>(),
);
export const setFc3SingleDetails = createAction(
  `[${ProductStandard.fc3}] Set ${ProductStandard.fc3} compliance information for single-details from backend in store`,
  props<{ pipelineRunId: number; singleDetails: Fc3ComplianceInfoDetails }>(),
);

export const loadFc3GroupSummaryError = createAction(
  `[${ProductStandard.fc3}] Loading of ${ProductStandard.fc3} Group Summary from backend error`,
  props<{ deliveryId: string }>(),
);

export const loadFc3GroupSummaryNotFound = createAction(
  `[${ProductStandard.fc3}] Loading of ${ProductStandard.fc3} Group Summary from backend not found`,
  props<{ deliveryId: string }>(),
);

export const loadFc3SingleDetailsError = createAction(
  `[${ProductStandard.fc3}] Loading of ${ProductStandard.fc3} Single Details from backend error`,
  props<{ pipelineRunId: number }>(),
);

export const loadFc3SingleDetailsNotFound = createAction(
  `[${ProductStandard.fc3}] Loading of ${ProductStandard.fc3} Single Details from backend not found`,
  props<{ pipelineRunId: number }>(),
);

export const loadFc3GroupDetailsError = createAction(
  `[${ProductStandard.fc3}] Loading of ${ProductStandard.fc3} Group Details from backend error`,
  props<{ deliveryId: string }>(),
);
