import { ReleaseDecisionEntityTypes } from '../../ngrx/model/group-type-microdelivery';

interface GroupType {
  groupTypeKey: number;
  name?: string;
  description?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export enum GroupTypeKey {
  DELIVERY = 4,
  MICRODELIVERY = 5,
  EXTERNAL_MICRODELIVERY = 6,
}

export interface Group {
  id?: string;
  key: string;
  name?: string;
  description?: string;
  backlogLink?: string;
  incidentManagementLink?: string;
  sourceCodeManagementLink?: string;
  childGroups?: Group[];
  groupTypes: GroupType[];
  isLocked?: boolean;
  activationDate?: Date;
  finishDate?: Date;
  createdAt?: Date;
  updatedAt?: Date;
  createdByUserId?: string;
  updatedByUserId?: string;
}

export interface DeliveryExtraFields {
  isMicrodelivery: boolean;
  releaseDecisionEntityType?: ReleaseDecisionEntityTypes;
  jiraIssueId?: number;
  projectKey?: string;
  arePipelinesSetByComponents?: boolean;
}

export type Delivery = Group & DeliveryExtraFields;
