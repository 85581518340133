import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { PipelineRunProcessingStatus, ProcessingStatusService } from '../../../shared/processing-status/processing-status.service';
import { reloadGroupCodeCompliance } from '../deliveries/deliveries.actions';
import { setSelectedPipelineRun } from '../pipeline-run/pipeline-run.actions';
import { loadPipelineProcessingStatus } from '../pipeline/pipeline.actions';
import * as ProcessingStatusActions from './processing-status.actions';

@Injectable()
export class ProcessingStatusEffects {
  public onLoadPipelineRunProcessingStatus$ = createEffect(() => {
    return this.action$.pipe(
      ofType(ProcessingStatusActions.loadPipelineRunProcessingStatus),
      mergeMap((action) =>
        this.processingStatusService.getPipelineRunProcessingStatus(action.pipelineRunId).pipe(
          map((processingStatus: PipelineRunProcessingStatus) =>
            ProcessingStatusActions.setPipelineRunProcessingStatus({
              pipelineRunId: action.pipelineRunId,
              processingStatus: processingStatus,
            }),
          ),
          catchError((error) => {
            if (error.status === 404) {
              return of(ProcessingStatusActions.loadPipelineRunProcessingStatusNotFound({ pipelineRunId: action.pipelineRunId }));
            }
            return of(ProcessingStatusActions.loadPipelineRunProcessingStatusError({ pipelineRunId: action.pipelineRunId }));
          }),
          takeUntil(
            this.action$.pipe(
              ofType(ProcessingStatusActions.loadPipelineRunProcessingStatus),
              filter((newAction) => newAction.pipelineRunId === action.pipelineRunId),
            ),
          ),
        ),
      ),
    );
  });

  public onRestorePipelineRun = createEffect(() => {
    return this.action$.pipe(
      ofType(ProcessingStatusActions.restorePipelineRun),
      switchMap((action) => {
        return this.processingStatusService.restorePipelineRun(action.pipelineRunId).pipe(
          map(() =>
            ProcessingStatusActions.restorePipelineRunSuccessful({
              pipelineId: action.pipelineId,
              pipelineRunId: action.pipelineRunId,
              isLatestPipelineRun: action.isLatestPipelineRun,
            }),
          ),
          catchError(() => {
            return of(
              ProcessingStatusActions.restorePipelineRunError({
                pipelineId: action.pipelineId,
                pipelineRunId: action.pipelineRunId,
                isLatestPipelineRun: action.isLatestPipelineRun,
              }),
            );
          }),
        );
      }),
    );
  });

  public onRestorePipelineRunSuccessful = createEffect(() => {
    return this.action$.pipe(
      ofType(ProcessingStatusActions.restorePipelineRunSuccessful),
      switchMap((action) => {
        if (action.isLatestPipelineRun) {
          return [
            setSelectedPipelineRun(action.pipelineId, action.pipelineRunId),
            loadPipelineProcessingStatus({ pipelineId: action.pipelineId }),
            reloadGroupCodeCompliance(),
          ];
        } else {
          return [setSelectedPipelineRun(action.pipelineId, action.pipelineRunId)];
        }
      }),
    );
  });

  constructor(
    private readonly action$: Actions,
    private readonly processingStatusService: ProcessingStatusService,
  ) {}
}
