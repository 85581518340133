import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';

import { ComplianceService } from '../../../shared/compliance-service/compliance.service';
import { ComplianceInfoSummary, Fc3ComplianceInfoDetails } from '../../model/compliance-information';
import {
  loadFc3GroupDetails,
  loadFc3GroupDetailsError,
  loadFc3GroupSummary,
  loadFc3GroupSummaryError,
  loadFc3GroupSummaryNotFound,
  loadFc3SingleDetails,
  loadFc3SingleDetailsError,
  loadFc3SingleDetailsNotFound,
  setFc3GroupDetails,
  setFc3GroupSummary,
  setFc3SingleDetails,
} from './fc-3.actions';
import { ProductStandard } from '../../../shared/model/product-standard';

@Injectable()
export class Fc3Effects {
  public onLoadGroupSummary$ = createEffect(() => {
    return this.action$.pipe(
      ofType(loadFc3GroupSummary),
      switchMap((action) =>
        this.comlianceService
          .getGroupSummaryComplianceInformationForAction(ProductStandard.fc3, action.deliveryId, action.groupComplianceMode)
          .pipe(
            map((groupSummary: ComplianceInfoSummary) => setFc3GroupSummary({ groupSummary })),
            catchError((error: HttpErrorResponse) => {
              const deliveryId = action.deliveryId;
              if (error.status === 404) {
                return of(loadFc3GroupSummaryNotFound({ deliveryId }));
              }
              return of(loadFc3GroupSummaryError({ deliveryId }));
            }),
          ),
      ),
    );
  });

  public onLoadGroupDetails$ = createEffect(() => {
    return this.action$.pipe(
      ofType(loadFc3GroupDetails),
      switchMap((action) =>
        this.comlianceService
          .getGroupDetailsComplianceInformationForAction(ProductStandard.fc3, action.deliveryId, action.groupComplianceMode)
          .pipe(
            map((groupDetails: Fc3ComplianceInfoDetails[]) => setFc3GroupDetails({ groupDetails })),
            catchError(() => {
              return of(loadFc3GroupDetailsError({ deliveryId: action.deliveryId }));
            }),
          ),
      ),
    );
  });

  public onLoadSingleDetails$ = createEffect(() => {
    return this.action$.pipe(
      ofType(loadFc3SingleDetails),
      mergeMap((action) =>
        this.comlianceService.getSingleDetailsComplianceInformationForAction(ProductStandard.fc3, action.pipelineRunId).pipe(
          map((singleDetails: Fc3ComplianceInfoDetails) =>
            setFc3SingleDetails({
              pipelineRunId: action.pipelineRunId,
              singleDetails,
            }),
          ),
          catchError((error) => {
            if (error.status === 404) {
              return of(loadFc3SingleDetailsNotFound({ pipelineRunId: action.pipelineRunId }));
            }
            return of(loadFc3SingleDetailsError({ pipelineRunId: action.pipelineRunId }));
          }),
          takeUntil(
            this.action$.pipe(
              ofType(loadFc3SingleDetails),
              filter((newAction) => newAction.pipelineRunId === action.pipelineRunId),
            ),
          ),
        ),
      ),
    );
  });

  constructor(
    private readonly action$: Actions,
    private readonly comlianceService: ComplianceService,
  ) {}
}
