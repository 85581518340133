import { StepResultFile } from './step-result-file';
export enum MalwarescanStatus {
  MALWARE_DETECTED = 'Malware Detected',
  NO_MALWARE_DETECTED = 'No Malware Detected',
}
export class MalwarescanDetails {
  groupId?: string;
  pipelineKey: string;
  pipelineUrl: string;
  pipelineRunId: number;
  bucketId: string;
  buildUrl: string;
  malwareDetected: boolean;
  documents: StepResultFile[];
  displayStatus?: MalwarescanStatus;
  statusSortOrder?: number;
}

export class MalwarescanSummary {
  groupId?: string;
  malwareDetected: boolean;
  displayStatus?: MalwarescanStatus;
  statusSortOrder?: number;
}
