<div>
  <button
    (click)="exportToExcel()"
    [disabled]="isLoadingOrNoData()"
    data-test-id="traceability-excel-export-button"
    fusionButton
    fusionButtonKind="outline"
    fusionFeatureUsageTracker="traceability-excel-export"
  >
    <span *ngIf="!loading">Export to Excel</span>
    <app-loading *ngIf="loading" text="Generating Excel" [fusionTooltip]="getLoadingTooltipMessage()"></app-loading>
  </button>
</div>
