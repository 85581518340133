import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { loadPipelineRunDocuments } from '../../ngrx/store/documents/documents.actions';
import {
  selectIsPipelineRunProcessingStatusLoading,
  selectIsPipelineRunProcessingStatusLoadingError,
  selectIsPipelineRunProcessingStatusLoadingNotFound,
} from '../../ngrx/store/loading-state/loading-state.selectors';
import { loadPipelineRunProcessingStatus } from '../../ngrx/store/processing-status/processing-status.actions';
import { selectPipelineRunProcessingStatus } from '../../ngrx/store/processing-status/processing-status.selectors';
import { PipelineRunProcessingStatus } from '../../shared/processing-status/processing-status.service';
import { DocumentsDetailsDialogComponent } from './documents-details-dialog/documents-details-dialog.component';

@Component({
  selector: 'app-list-item-kpis-documents',
  templateUrl: './list-item-kpis-documents.component.html',
  styleUrls: ['./list-item-kpis-documents.component.scss'],
})
export class ListItemKpisDocumentsComponent implements OnInit, OnChanges {
  @Input() pipelineId: string;
  @Input() pipelineRunId: number;

  pipelineRunProcessingStatus$!: Observable<PipelineRunProcessingStatus>;
  isPipelineRunProcessingStatusLoading$!: Observable<boolean>;
  isPipelineRunProcessingStatusLoadingError$!: Observable<boolean>;
  isPipelineRunProcessingStatusLoadingNotFound$!: Observable<boolean>;

  constructor(
    private readonly store$: Store,
    private readonly dialog: MatDialog,
  ) {}

  ngOnInit() {
    if (this.pipelineRunId) {
      this.loadPipelineRunInfo();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.pipelineRunId) {
      this.loadPipelineRunInfo();
    }
  }

  loadPipelineRunInfo() {
    this.loadPipelineRunProcessingStatus();
  }

  loadPipelineRunProcessingStatus() {
    this.pipelineRunProcessingStatus$ = this.store$.select(selectPipelineRunProcessingStatus(this.pipelineRunId));
    this.isPipelineRunProcessingStatusLoading$ = this.store$.select(selectIsPipelineRunProcessingStatusLoading(this.pipelineRunId));
    this.isPipelineRunProcessingStatusLoadingError$ = this.store$.select(
      selectIsPipelineRunProcessingStatusLoadingError(this.pipelineRunId),
    );
    this.isPipelineRunProcessingStatusLoadingNotFound$ = this.store$.select(
      selectIsPipelineRunProcessingStatusLoadingNotFound(this.pipelineRunId),
    );
  }

  reloadInfo() {
    if (this.pipelineRunId) {
      this.store$.dispatch(
        loadPipelineRunProcessingStatus({
          pipelineRunId: this.pipelineRunId,
        }),
      );
    }
  }

  public openDetailsDialog(): void {
    const data = { pipelineId: this.pipelineId, pipelineRunId: this.pipelineRunId };
    this.store$.dispatch(loadPipelineRunDocuments({ pipelineRunId: this.pipelineRunId }));
    this.dialog.open(DocumentsDetailsDialogComponent, { data });
  }
}
