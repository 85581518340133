<ng-container
  *ngIf="{
    loading: isLoading$ | async,
    loadingError: isLoadingError$ | async,
    fosstars: fosstarsSummary$ | async,
    ratings: this.ratings,
  } as data"
>
  <app-list-item-kpis [flag]="'kpi.flag.beta' | translate" [isLoading]="data.loading" [title]="'kpi.tile.fosstar.title' | translate">
    <div class="flex flex-col gap-3.5" kpi-tile-content>
      <div kpi-tile-content class="fosstar-container flex flex-col" data-test-id="fosstars-check-result-text">
        <div *ngIf="data.fosstars">
          <div (click)="openFosstarDetails()" fusionFeatureUsageTracker="kpi-open-fosstar-details" class="flex flex-row flex-wrap gap-3">
            <div *ngFor="let rating of this.ratings" class="risk-text flex gap-2.5 justify-start items-center" id="fosstarContent">
              <div class="label-value flex" id="ratingAmount" attr.data-test-id="{{ 'fosstars-check-issue-type-result-' + rating.type }}">
                {{ rating.amount }}
              </div>
              <div class="label-text">{{ rating.label }}</div>
            </div>
          </div>
        </div>
        <app-error-information (click)="reload()" *ngIf="loadingError"></app-error-information>
      </div>
    </div>
  </app-list-item-kpis>
</ng-container>
