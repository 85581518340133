import { createReducer, on } from '@ngrx/store';
import { TestRegressionSlice } from '../app-state';
import { initialTestRegressionSlice } from '../initial-slices';
import * as TestRegressionActions from './test-regression.actions';

export const testRegresionReducer = createReducer(
  initialTestRegressionSlice,
  on(TestRegressionActions.setPipelineRequirements, (state, props): TestRegressionSlice => {
    const pipelineRequirements = { ...state.pipelineRequirements };
    pipelineRequirements[props.pipelineId] = props.requirements;
    return {
      ...state,
      pipelineRequirements,
    };
  }),
  on(TestRegressionActions.setPipelineRunTestCasesForRegression, (state, props): TestRegressionSlice => {
    const pipelineRunRegressionTests = { ...state.pipelineRunRegressionTests };
    pipelineRunRegressionTests[props.pipelineRunId] = props.testcases;
    return {
      ...state,
      pipelineRunRegressionTests,
    };
  }),
);
