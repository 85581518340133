import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CodeCoverageInfoSlice } from '../app-state';

export const selectCodeCoverageInfoSlice = createFeatureSelector<CodeCoverageInfoSlice>('codeCoverageInfoSlice');

export const selectCodeCoverageGroupSummary = createSelector(selectCodeCoverageInfoSlice, (codeCoverageSlice) => {
  return codeCoverageSlice.aggregationSummary;
});

export const selectCodeCoverageGroupDetails = createSelector(selectCodeCoverageInfoSlice, (codeCoverageSlice) => {
  return codeCoverageSlice.aggregationDetails;
});

export const selectCoverageInfoForPipelineRun = (pipelineRunId: number) =>
  createSelector(selectCodeCoverageInfoSlice, (pipelineRunSlice) => {
    return pipelineRunSlice.selectedPipelineRuns[pipelineRunId];
  });
