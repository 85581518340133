import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { EnvironmentData, ParentMessage } from '../model/environment/environment-data';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  _environmentData: EnvironmentData;
  _groupId: string;
  private _parentMessage: ParentMessage;

  getEnvironmentData(): EnvironmentData {
    return this._environmentData;
  }

  constructor(private readonly http: HttpClient) {}

  set parentMessage(message: ParentMessage) {
    this._parentMessage = message;
  }

  get parentMessage(): ParentMessage {
    return this._parentMessage;
  }

  async loadEnvironmentData() {
    if (!this._environmentData) {
      if (environment.production) {
        this._environmentData = await lastValueFrom(this.http.get<EnvironmentData>('./config'));
      } else {
        this._environmentData = environment;
      }
    }
    return this._environmentData;
  }

  isProduction() {
    return environment.production;
  }

  getDeliveryGuid() {
    if (!this.isProduction()) {
      return this.getEnvironmentData().deliveryGuid;
    }
    return this.parentMessage?.deliveryGuid;
  }

  getDeliveryName() {
    if (!this.isProduction()) {
      return this.getEnvironmentData().deliveryName;
    }
    return this.parentMessage?.deliveryName;
  }

  getDeliveryIsLocked() {
    const isLocked: boolean = !this.isProduction() ? this.getEnvironmentData().isLocked : this.parentMessage.isLocked;
    const activationDate = this.getDeliveryActivationDate();
    return isLocked && !!activationDate;
  }

  getDeliveryActivationDate() {
    if (!this.isProduction()) {
      return this.getEnvironmentData().activationDate;
    }
    return this.parentMessage?.activationDate;
  }

  getBaseUrl() {
    if (!this.isProduction()) {
      return this.getEnvironmentData().baseUrl;
    }
    return this.parentMessage?.baseUrl;
  }

  isReadOnly() {
    if (!this.isProduction()) {
      return this.getEnvironmentData().readOnly;
    }
    return this.parentMessage?.readOnly;
  }

  getMicroDeliveryBaseUrl(path: string) {
    const apiPrefix = path.indexOf('api') === -1;
    return `https://${this.getBaseUrl()}/zprs/gcp/microdelivery-service/${apiPrefix ? 'api/' : ''}${path}`;
  }

  getGroupServiceBaseUrl(path: string) {
    const apiPrefix = path.indexOf('api') === -1;
    return `https://${this.getBaseUrl()}/zprs/gcp/group-service/${apiPrefix ? 'api/' : ''}${path}`;
  }

  getHyperSpaceUrl() {
    return this.getEnvironmentData().hyperspace;
  }

  getParentMessageId() {
    return this.getEnvironmentData().parentId;
  }

  getXRayStatusFieldKey() {
    return this.getEnvironmentData().xRayStatusFieldKey;
  }

  getIssueTypeIdMicroDelivery(): number {
    return this.getEnvironmentData().issueTypeIdMicroDelivery;
  }

  set groupId(groupId) {
    this._groupId = groupId;
  }

  get groupId() {
    return this._groupId;
  }

  getJiraUrl() {
    return this.getEnvironmentData().jiraUrl;
  }

  getGcpStorageBaseUrl() {
    return this.getEnvironmentData().gcpStorageBaseUrl;
  }
}
