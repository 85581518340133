import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Fc3ComplianceInfoDetails } from '../../../ngrx/model/compliance-information';
import { loadFc3GroupDetails } from '../../../ngrx/store/fc-3/fc-3.actions';
import { selectFc3GroupDetails } from '../../../ngrx/store/fc-3/fc-3.selectors';
import { selectIsFc3DetailsLoading, selectIsFc3DetailsLoadingError } from '../../../ngrx/store/loading-state/loading-state.selectors';
import { GridDataSortService } from '../../../shared/grid-data-sort-service/grid-data-sort.service';
import { KpiDialogsData } from '../../kpis.module';

@Component({
  selector: 'app-fc3-dialog',
  templateUrl: './fc3-details-dialog.component.html',
  styleUrls: ['./fc3-details-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Fc3DetailsDialogComponent implements OnInit {
  public dataSource$!: Observable<GridDataResult>;
  public isLoading$!: Observable<boolean>;
  public isLoadingError$!: Observable<boolean>;

  fc3InfoSorted: Fc3ComplianceInfoDetails[];
  dataSource: GridDataResult;

  gridState: State = {
    filter: {
      logic: 'and',
      filters: [],
    },
    sort: [{ field: 'complianceStatus.displayKey', dir: 'asc' }],
  };

  constructor(
    private readonly dialogRef: MatDialogRef<Fc3DetailsDialogComponent>,
    private readonly store$: Store,
    private readonly gridDataSortService: GridDataSortService,
    @Inject(MAT_DIALOG_DATA) private readonly dialogData: KpiDialogsData,
  ) {
    this.dataSource$ = this.store$.select(selectFc3GroupDetails).pipe(
      map((data) => {
        this.fc3InfoSorted = data;
        this.dataStateChanged(this.gridState);
        return this.dataSource;
      }),
    );
    this.isLoading$ = this.store$.select(selectIsFc3DetailsLoading);
    this.isLoadingError$ = this.store$.select(selectIsFc3DetailsLoadingError);
  }

  reload() {
    this.store$.dispatch(
      loadFc3GroupDetails({
        deliveryId: this.dialogData?.deliveryId,
        groupComplianceMode: this.dialogData?.groupComplianceMode,
      }),
    );
  }

  ngOnInit() {
    this.dialogRef.updateSize('60vw', 'auto');
  }

  closeDialog(data?: any) {
    this.dialogRef.close(data);
  }

  dataStateChanged(state) {
    this.gridState = state;
    this.dataSource = process(this.fc3InfoSorted, this.gridState);
    this.dataSource.data = this.gridDataSortService.sortComplianceStateByStatusSortOrder(this.gridState.sort, this.dataSource.data);
  }
}
