export const en = {
  'compliance.status.tooltip.UNKNOWN': `We do not have enough data for a compliance statement.<br/> If you think you are compliant, please reach out to us.`,
  'compliance.status.tooltip.IGNORED':
    'Product Standard has not been checked because it is not listed in the cumulus-configuration.json as productStandardsToCheck',

  // compliance tiles + detail-dialogs --------------------------------------------------------------------------------
  'kpi.tile.policy.results.title': `Central Policy Results (<a class='link' rel='noopener' href='https://wiki.one.int.sap/wiki/x/icIy1g' target='_blank'><span>Policies</span><i id='PoliciesDocuLinkIcon' class='tileDocuLinkIcon fi fi-link-external'></i></a>)`,
  'kpi.tile.custom.policy.results.title': `Custom Policy Results (<a class='link' rel='noopener' href='https://wiki.one.int.sap/wiki/x/icIy1g' target='_blank'><span>Policies</span><i id='CustomPoliciesDocuLinkIcon' class='tileDocuLinkIcon fi fi-link-external'></i></a>)`,
  'kpi.tile.policy.usage.title': `Central Policy Usage (<a class='link' rel='noopener' href='https://wiki.one.int.sap/wiki/x/icIy1g' target='_blank'><span>Policies</span><i id='PoliciesDocuLinkIcon' class='tileDocuLinkIcon fi fi-link-external'></i></a>)`,
  'kpi.tile.custom.policy.usage.title': `Custom Policy Usage (<a class='link' rel='noopener' href='https://wiki.one.int.sap/wiki/x/icIy1g' target='_blank'><span>Policies</span><i id='PoliciesDocuLinkIcon' class='tileDocuLinkIcon fi fi-link-external'></i></a>)`,

  'kpi.dialog.custom.policy.results.title': 'Custom Policy Results',
  'kpi.dialog.policy.results.title': 'Central Policy Results',

  'kpi.dialog.custom.policy.usage.title': 'Custom Policy Usage',
  'kpi.dialog.policy.usage.title': 'Central Policy Usage',

  'kpi.tile.slc25.title': `Source Code Management (<a class='link' rel='noopener' href='https://wiki.wdf.sap.corp/wiki/x/HxQ1kg' target='_blank'><span>SLC-25</span><i id='SLC25DocuLinkIcon' class='tileDocuLinkIcon fi fi-link-external'></i></a>)`,

  'kpi.tile.fc1.title': `Static Code Checks (<a class='link' rel='noopener' href='https://wiki.wdf.sap.corp/wiki/x/9yqvl' target='_blank'><span>FC-1</span><i id='FC1DocuLinkIcon' class='tileDocuLinkIcon fi fi-link-external'></i></a>)`,

  'kpi.tile.ip.scan.title':
    'IP Scan Plan (<a class="link" rel="noopener" href="https://wiki.wdf.sap.corp/wiki/x/8o5wkg" target="_blank"><span>IP Scan</span><i id="IPScanDocuLinkIcon" class="tileDocuLinkIcon fi fi-link-external"></i></a>)',

  'kpi.tile.malwarescan.title':
    'Malware Scan Results (<a class="link" rel="noopener" href="https://wiki.wdf.sap.corp/wiki/x/tJ2fqQ" target="_blank"><span>Malware Scanner</span><i id="MalwarescanDocuLinkIcon" class="tileDocuLinkIcon fi fi-link-external"></i></a>)',

  'kpi.tile.fc3.title': `Regression Tests (<a class='link' rel='noopener' href='https://wiki.wdf.sap.corp/wiki/x/ZBqtlQ' target='_blank'><span>FC-3/FC-4</span><i id='FC3DocuLinkIcon' class='tileDocuLinkIcon fi fi-link-external'></i></a>)`,
  'kpi.tile.atc.title': `ABAP Test Cockpit Issues (<a class='link' rel='noopener' href='https://wiki.wdf.sap.corp/wiki/x/fwSCm' target='_blank'><span>ATC</span><i id='ATCDocuLinkIcon' class='tileDocuLinkIcon fi fi-link-external'></i></a>)`,
  'kpi.tile.fosstar.title':
    'Open Source Software Security Ratings (<a class="link" rel="noopener" href="https://wiki.wdf.sap.corp/wiki/x/Xd33ig" target="_blank"><span>Fosstars</span><i id="FOSStarsDocuLinkIcon" class="tileDocuLinkIcon fi fi-link-external"></i></a>)',
  'kpi.tile.fosstar.details.title': 'Open Source Software Security Ratings (Fosstars)',

  'kpi.tile.fc2.title':
    'Test of new Functionality (<a class="link" rel="noopener" href="https://wiki.wdf.sap.corp/wiki/x/1psyhQ" target="_blank"><span>FC-2</span><i id="FC2DocuLinkIcon" class="tileDocuLinkIcon fi fi-link-external"></i></a>)',
  'kpi.tile.requirement.title': 'Requirements',

  'kpi.tile.documents.title': 'Documents',
  'kpi.tile.documents.details.tooltip.download': 'Click to download file',

  'kpi.tile.tests.title': 'Tests',

  'kpi.tile.coverage.title':
    'Cobertura/JaCoCo Coverage (<a class="link" rel="noopener" href="https://wiki.wdf.sap.corp/wiki/x/GB81kg" target="_blank"><span>Code Coverage</span><i class="tileDocuLinkIcon fi fi-link-external"></i></a>)',

  'kpi.tile.sonarqube.coverage.title':
    'SonarQube Coverage (<a class="link" rel="noopener" href="https://wiki.wdf.sap.corp/wiki/x/GB81kg" target="_blank"><span>Code Coverage</span><i class="tileDocuLinkIcon fi fi-link-external"></i></a>)',

  'kpi.flag.beta': 'Beta',
  'kpi.flag.deprecated': 'Deprecated',

  'kpi.tile.regression.title': `Feature Regression Tests (<a class='link' rel='noopener' href='https://wiki.wdf.sap.corp/wiki/x/9BCjog' target='_blank'><span>FRT</span><i id='FRTDocuLinkIcon' class='tileDocuLinkIcon fi fi-link-external'></i></a>)`,

  'default.error.text': 'Error loading information. Please try it again later.',
  'default.error.text.dialog': 'Oops... We ran into an problem and could not load this information. Please refresh or try again later!',

  'kpi.tile.api-metadata-validator.title': `API Metadata Validator (<a class='link' rel='noopener' href='https://wiki.one.int.sap/wiki/x/IYr_wg' target='_blank'><span>Part of UA-090</span><i class='tileDocuLinkIcon fi fi-link-external'></i></a>)`,

  // page sections -----------------------------------------------------------------------------------------------------

  // release selection
  'release-selection.combobox.all-versions.text': 'All Microdeliveries',
  'release-selection.open-microdelivery': 'Open Microdelivery',
  'release-selection.open-microdelivery.in-jira': 'Open Microdelivery in Jira',

  // group compliance modes
  'group-compliance-mode.latest': 'Latest Runs',
  'group-compliance-mode.latest-promoted': 'Latest Promoted Runs',
  'group-compliance-mode.latest-deployed-to-production': 'Latest Deployed to Production Runs',
  'group-compliance-mode.latest-release-candidate': 'Latest Release Candidate Runs',
  'group-compliance-mode.latest-released': 'Latest Released Runs',

  // connected pipelines
  'connected-pipelines.pipeline-details.no-pipeline-runs.general': 'No Pipeline Runs found',
  'connected-pipelines.pipeline-details.no-pipeline-runs.status-new':
    "Your delivery is still in status 'New'. Activate the delivery to see relevant pipeline runs.",
  'connected-pipelines.pipeline-details.no-pipeline-runs.status-released': 'No Pipeline Runs released in this delivery',
  'connected-pipelines.column-names.pipeline-key': 'Pipeline Key',
  'connected-pipelines.column-names.jira-components': 'Jira Components',
  'connected-pipelines.column-names.latest-run': `Latest Run For Selected View Mode ({{pipelineRunCountInMode}})`,

  'kpi.tile.fc2.group.summary': 'traceability relevant requirements successfully tested.',
  'kpi.tile.traceability.pipeline-run.summary': 'traceability relevant requirements successfully tested in this pipeline run.',
  'kpi.tile.requirements.pipeline-run.summary': 'requirement keys found in pipeline run.',
  'kpi.tile.requirements.excel-export.file-name': 'Traceability_Summary.xlsx',
  'kpi.tile.requirements.excel-export-button.loading-tooltip': 'That might take up to 2 minutes. Please do not close this window.',

  'pipeline-list.unprocessed-files': 'There are unprocessed Files',
  'pipeline-list.pipeline-run.release-status.tooltip':
    'Latest Release Status of Pipeline Run. Pipeline Run can also have another (additional) Release Status',

  'pipeline-list.pipeline-run.execution-status.tooltip': 'Latest Execution Status of Pipeline Run.',
  'pipeline-list.pipelines-running.warning-box.single': 'Pipeline is currently running. Refresh the page to get the latest results.',
  'pipeline-list.pipelines-running.warning-box.multiple': 'Pipelines are currently running. Refresh the page to get the latest results.',

  // button tooltips
  'button-tooltip.manage-pipeline.disabled-by-mapping': 'Connected Pipelines are managed by Jira Component Mapping.',
  'button-tooltip.manage-pipeline.disabled-by-lock': 'Selected Delivery/Microdelivery is not active.',
  'button-tooltip.manage-pipeline.disabled-by-readonly': 'You are not authorized to edit the selected Delivery.',
  'button-tooltip.manage-pipeline.enabled-delivery-delete-message':
    'We will only unlink the pipeline from the current Delivery.\nExisting pipeline runs and the pipeline itself will not be deleted.',
  'button-tooltip.manage-pipeline.enabled-microdelivery-delete-message':
    'We will only unlink the pipeline from the current Microdelivery.\nExisting pipeline runs and the pipeline itself will not be deleted.',
  'pipeline.optimized.flag': 'OPTIMIZED PIPELINE',
  // optimized pipeline run
  'icon-tooltip.show-pipeline-deprecated': 'Related scheduled run should <strong>not</strong> be more than 24h prior to the selected run.',
  'pipeline.mode.2.flag': 'COMMIT RUN',
  'pipeline.mode.3.flag': 'SCHEDULED RUN',

  // Add Pipeline Dialog
  'pipeline.add.dialog.info.box': `By adding a single pipeline or multiple pipelines from a group you will be able to see the results of the different runs in your (micro-) delivery.
    <br />
    <br />
    <b>Important:</b> Only pipeline responsibles maintained in
    <a class='link' rel='noopener' href='{{hyperspaceUrl}}' target='_blank'>
    <span>Hyperspace</span><i class='tileDocuLinkIcon fi fi-link-external'></i></a> are able to search and add a pipeline (group) to a (micro-) delivery.`,
  'pipeline.add.dialog.indicator.group': 'Group',
  'pipeline.add.dialog.indicator.pipeline': 'Pipeline',
  'add.pipeline.dialog.add.text.null': 'Add',
  'add.pipeline.dialog.add.text.pipeline': 'Add Pipeline',
  'add.pipeline.dialog.add.text.group': 'Add Group',

  // Policy Results
  'policy.result.group.summary.description': 'with executed policies',

  // Policy Usage
  'policy.usage.group.summary.description': 'executed',

  // Home Screen
  'home.screen.contact.summary': `You can contact us on Slack
      <a href='https://slack.com/app_redirect?channel=sap-tools-cumulus' rel='noopener noreferrer' target='_blank'>#sap-tools-cumulus</a> or
      create a
      <a
        href='https://itsm.services.sap/sp?id=sc_cat_item&sys_id=703f22d51b3b441020c8fddacd4bcbe2&sysparm_category=e15706fc0a0a0aa7007fc21e1ab70c2f&service_offering=815f03371b487410341e11739b4bcbed'
        rel='noopener noreferrer'
        target='_blank'
        >Service Now</a
      >
      Ticket (Component: ISV-DL-CICD-DELIVERY-CUMULUS).`,

  // Change Pipeline Run Release Status Dialog
  'change.pipeline.run.release.state.dialog.summary': `
  <strong>Definition of state</strong>
          <span
          ><b>"Release Candidate": </b>Setting the selected pipeline run to state <i>"Release Candidate"</i> means you are only marking it
            as a potential candidate you want to release.</span
          >
          <span
          ><b>"Released": </b>Setting the selected pipeline run to state <i>"Released"</i> means you are marking the run as released
            within the selected (micro)delivery (group), which will protect all your uploaded files in your Cumulus storage for this run
            from being overwritten.<br />
            While setting the <i>"Released"</i> state is an immediate action, an asynchronous process will be triggered for consistency
            purposes. Unprocessed documents may be visible until this process has been completed.</span
          >
          <div class='flex flex-row gap-1 justify-start items-center'>
            <fusion-icon class='info-icon' [name]="'exclamation-triangle-outline'" size='M'></fusion-icon
            >
            <span>IMPORTANT: Resetting the state "Released" is not possible.</span>
          </div>
  `,

  //! Change pipeline run release state dialog

  'release-candidate.revert-button.tooltip.release-candidate': "Revert current state 'release candidate' to previous state.",
  'release-candidate.revert-button.tooltip.released': "Selected pipeline run is not in state 'release candidate.'",
  'release-candidate.revert-button.tooltip.initial': "Selected pipeline run is not in state 'release candidate.'",
  'release-candidate.revert-button.tooltip.promoted': "Selected pipeline run is not in state 'release candidate.'",
  'release-candidate.revert-button.tooltip.deployed-to-production': "Selected pipeline run is not in state 'release candidate.'",

  'released.revert-button.tooltip.release-candidate': "Selected pipeline run is not in state 'released'.",
  'released.revert-button.tooltip.released': "Revert current state 'released' to previous state.",
  'released.revert-button.tooltip.initial': "Selected pipeline run is not in state 'released'.",
  'released.revert-button.tooltip.deployed-to-production': "Selected pipeline run is not in state 'released'.",
  'released.revert-button.tooltip.promoted': "Selected pipeline run is not in state 'released'.",

  'release-candidate.set-button-tooltip.release-candidate':
    "The selected pipeline run is already in state 'release candidate' for this (micro)delivery (group).",
  'release-candidate.set-button-tooltip.released':
    "Set state to 'release candidate' directly is not supported from current state 'released', only reverting released state.",
  'release-candidate.set-button-tooltip.initial': "Set current state to 'release candidate'.",
  'release-candidate.set-button-tooltip.promoted': "Set current state to 'release candidate'.",
  'release-candidate.set-button-tooltip.deployed-to-production': "Set current state to 'release candidate'.",

  'release.set-button.tooltip.release-candidate': "Set current state to 'released'.",
  'release.set-button.tooltip.released': "The selected pipeline run is already in state 'released' for this (micro)delivery (group).",
  'release.set-button.tooltip.initial': "Set current state to 'released'.",
  'release.set-button.tooltip.deployed-to-production': "Set current state to 'released'.",
  'release.set-button.tooltip.promoted': "Set current state to 'released'.",

  // Deprecation Popover
  'deprecation.popover.fc1.checkDate': '{{FC-1-Deprecation}}',
  'deprecation.popover.fc1.visualizationDate': '{{FC-1-EndOfVisualisation}}',
  'deprecation.popover.fc1.reason': `Current FC-1 compliance check will be replaced by new Policy as Code approach.`,
  'deprecation.popover.fc1.impact': `Results of the FC-1 compliance check are available in the central policies tile.`,

  'deprecation.popover.slc25.checkDate': '{{SLC-25-Deprecation}}',
  'deprecation.popover.slc25.visualizationDate': '{{SLC-25-EndOfVisualisation}}',
  'deprecation.popover.slc25.reason': `Current SLC-25 compliance check will be replaced by new Policy as Code approach.`,
  'deprecation.popover.slc25.impact': `Results of the SLC-25 compliance check are available in the central policies tile.`,
};
