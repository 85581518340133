<div
  *ngIf="{
    isLoading: isLoading$ | async,
    isLoadingError: isLoadingError$ | async,
    dataSource: dataSource$ | async,
  } as data"
  class="flex flex-col"
  mat-dialog-content
>
  <div class="flex h-full w-full">
    <app-dialog-error-information (reloadClicked)="reload()" *ngIf="data?.isLoadingError"></app-dialog-error-information>
    <kendo-grid
      class="flex h-full w-full"
      id="policy-results-group-detail-grid"
      data-test-id="custom-policy-results-aggregated-detail-popup-grid"
      *ngIf="!data?.isLoadingError"
      [data]="dataSource"
      [loading]="data.isLoading"
      [sortable]="true"
      [sort]="gridState.sort"
      [filterable]="true"
      [filter]="gridState.filter"
      [resizable]="true"
      (dataStateChange)="dataStateChanged($event)"
    >
      <kendo-grid-column field="pipelineKey" title="Pipeline" width="37">
        <ng-template kendoGridCellTemplate kendoTooltip let-dataItem>
          <app-kpi-column-pipeline
            [item]="dataItem"
            fusionFeatureUsageTracker="open-custom-policy-results-group-pipeline-url"
          ></app-kpi-column-pipeline>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="policyCompliantAmount"
        title="Compliant Custom Policies"
        width="23"
        class="text-align-right"
        headerClass="text-align-right"
        [filterable]="true"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div data-test-id="customPolicyCompliantAmount-column">
            {{ dataItem.policyCompliantAmount }}
          </div>
        </ng-template>
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" [spinners]="false" [min]="0" format="#">
          </kendo-grid-numeric-filter-cell>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="policyNotCompliantAmount"
        title="Not Compliant Custom Policies"
        width="23"
        class="text-align-right"
        headerClass="text-align-right"
        [filterable]="true"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div data-test-id="customPolicyNotCompliantAmount-column">
            {{ dataItem.policyNotCompliantAmount }}
          </div>
        </ng-template>

        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" [spinners]="false" [min]="0" format="#">
          </kendo-grid-numeric-filter-cell>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column [filterable]="false" [sortable]="false" width="15">
        <ng-template kendoGridCellTemplate let-dataItem>
          <a
            data-test-id="custom-policy-results-group-details-popup-view-details-link"
            class="flex flex-row justify-end items-center"
            (click)="selectedPipelineRun.emit(dataItem)"
            fusionFeatureUsageTracker="custom-policy-results-group-detail-dialog-view-details"
          >
            <span>View Details</span>
            <fusion-icon class="forwardButton" name="chevron-right" size="XL"></fusion-icon>
          </a>
        </ng-template>
      </kendo-grid-column>

      <ng-template kendoGridLoadingTemplate>
        <app-table-loading-indicator></app-table-loading-indicator>
      </ng-template>
    </kendo-grid>
  </div>
</div>
