import { animate, state, style, transition, trigger } from '@angular/animations';

export class Animations {
  /**
   * Reusable animation parameters
   */
  static readonly msEaseInOut = 'ms ease-in-out';
  public static readonly baseDuration = 200;

  public static readonly parameters = {
    baseDurationMs: Animations.baseDuration + 'ms',
  };

  public static readonly fade = trigger('fade', [
    transition(':enter', [style({ opacity: 0 }), animate(Animations.parameters.baseDurationMs, style({ opacity: 1 }))]),
    transition(':leave', [style({ opacity: 1 }), animate(Animations.parameters.baseDurationMs, style({ opacity: 0 }))]),
  ]);

  public static readonly slideDown = trigger('slideDown', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate(
        Animations.parameters.baseDurationMs,
        style({
          opacity: 1,
          height: '*',
        }),
      ),
    ]),
    transition(':leave', [
      style({ opacity: 1 }),
      animate(
        Animations.parameters.baseDurationMs,
        style({
          opacity: 0,
          height: 0,
        }),
      ),
    ]),
  ]);

  public static readonly slideRight = trigger('slideRight', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate(
        Animations.parameters.baseDurationMs,
        style({
          opacity: 1,
          width: '*',
        }),
      ),
    ]),
    transition(':leave', [
      style({ opacity: 1 }),
      animate(
        Animations.parameters.baseDurationMs,
        style({
          opacity: 0,
          width: 0,
        }),
      ),
    ]),
  ]);

  public static readonly blink = trigger('blink', [
    transition(':enter', [style({ opacity: 0 }), animate(Animations.parameters.baseDurationMs, style({ opacity: 1 }))]),
  ]);

  public static readonly headerDropdown = trigger('headerDropdown', [
    state(
      '1',
      style({
        transform: 'rotate(90deg)',
      }),
    ),
    state(
      '0',
      style({
        transform: 'rotate(0deg)',
      }),
    ),
    transition('1 => 0', animate(Animations.parameters.baseDurationMs + ' ease-in-out')),
    transition('0 => 1', animate(Animations.parameters.baseDurationMs + ' ease-in-out')),
  ]);

  public static readonly sidebarSlide = trigger('sidebarSlide', [
    state(
      '1',
      style({
        width: '200px',
      }),
    ),
    state(
      '0',
      style({
        width: '40px',
      }),
    ),
    transition('1 => 0', animate(`${Animations.baseDuration * 2} ${Animations.msEaseInOut}`)),
    transition('0 => 1', animate(`${Animations.baseDuration * 2} ${Animations.msEaseInOut}`)),
  ]);

  public static readonly sidebarFlipArrow = trigger('sidebarFlipArrow', [
    state(
      '1',
      style({
        transform: 'rotate(0deg)',
      }),
    ),
    state(
      '0',
      style({
        transform: 'rotate(180deg)',
      }),
    ),
    transition('1 => 0', animate(`${Animations.baseDuration * 2} ${Animations.msEaseInOut}`)),
    transition('0 => 1', animate(`${Animations.baseDuration * 2} ${Animations.msEaseInOut}`)),
  ]);

  public static readonly rotateLoading = trigger('rotateLoading', [
    state('loading_start', style({ transform: 'rotate(45deg)' })),
    state('loading_end', style({ transform: 'rotate(405deg)' })),
    state('loading_end_tick', style({ transform: 'rotate(405deg)' })),

    transition('loading_start => loading_end', animate('1000ms linear')), // 360deg
    transition('loading_end => loading_start', animate('0.01ms linear')),
    transition('loading_start => loading_end_tick', animate('1125ms ease-out')), // 405deg
  ]);
}
