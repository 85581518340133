import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Fc1ComplianceInfoDetails } from '../../../ngrx/model/compliance-information';
import { loadFc1GroupDetails } from '../../../ngrx/store/fc-1/fc-1.actions';
import { selectFc1GroupDetails } from '../../../ngrx/store/fc-1/fc-1.selectors';
import { selectIsFc1DetailsLoading, selectIsFc1DetailsLoadingError } from '../../../ngrx/store/loading-state/loading-state.selectors';
import { GridDataSortService } from '../../../shared/grid-data-sort-service/grid-data-sort.service';
import { KpiDialogsData } from '../../kpis.module';

@Component({
  selector: 'app-fc1-dialog',
  templateUrl: './fc1-details-dialog.component.html',
  styleUrls: ['./fc1-details-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Fc1DetailsDialogComponent implements OnInit {
  public dataSource$!: Observable<GridDataResult>;
  public isLoading$!: Observable<boolean>;
  public isLoadingError$!: Observable<boolean>;

  fc1InfoSorted: Fc1ComplianceInfoDetails[];
  dataSource: GridDataResult;

  hasAtc = false;
  hasSonarQube = false;

  gridState: State = {
    filter: {
      logic: 'and',
      filters: [],
    },
    sort: [{ field: 'complianceStatus.displayKey', dir: 'asc' }],
  };

  constructor(
    private readonly dialogRef: MatDialogRef<Fc1DetailsDialogComponent>,
    private readonly store$: Store,
    private readonly gridDataSortService: GridDataSortService,
    @Inject(MAT_DIALOG_DATA) private readonly dialogData: KpiDialogsData,
  ) {
    this.dataSource$ = this.store$.select(selectFc1GroupDetails).pipe(
      tap((data) => {
        // especially with the combination of "no compliance information available"
        // probably somewhat technology dependent....
        // or we always show all columns
        this.hasSonarQube = !!data.find((item) => item.sonarQube);
        // ATC is not yet really implemented...
        this.hasAtc = !!data.find((item) => item.atc);
      }),
      map((data) => {
        this.fc1InfoSorted = data;
        this.dataStateChanged(this.gridState);
        return this.dataSource;
      }),
    );
    this.isLoading$ = this.store$.select(selectIsFc1DetailsLoading);
    this.isLoadingError$ = this.store$.select(selectIsFc1DetailsLoadingError);
  }

  reload() {
    this.store$.dispatch(
      loadFc1GroupDetails({
        deliveryId: this.dialogData?.deliveryId,
        groupComplianceMode: this.dialogData?.groupComplianceMode,
      }),
    );
  }

  ngOnInit() {
    this.dialogRef.updateSize('60vw', 'auto');
  }

  closeDialog(data?: any) {
    this.dialogRef.close(data);
  }

  openSonarIssuesInNewTab(url: string) {
    if (url) {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  }

  dataStateChanged(state) {
    this.gridState = state;
    this.dataSource = process(this.fc1InfoSorted, this.gridState);
    this.dataSource.data = this.gridDataSortService.sortComplianceStateByStatusSortOrder(this.gridState.sort, this.dataSource.data);
  }
}
