import { createReducer, on } from '@ngrx/store';
import { DeliveriesSlice } from '../app-state';
import { initialDeliveriesSlice } from '../initial-slices';
import * as DeliveriesActions from './deliveries.actions';

export const deliveriesReducer = createReducer(
  initialDeliveriesSlice,
  on(DeliveriesActions.loadSiriusDeliverySuccessful, (state, props): DeliveriesSlice => {
    return {
      ...state,
      siriusDelivery: props.delivery,
    };
  }),
  on(DeliveriesActions.setSelectedDelivery, (state, props): DeliveriesSlice => {
    return {
      ...state,
      selectedDelivery: props.delivery,
    };
  }),
  on(DeliveriesActions.setSelectedComplianceShowMode, (state, props): DeliveriesSlice => {
    return {
      ...state,
      groupComplianceMode: props.groupComplianceMode,
    };
  }),
  on(DeliveriesActions.loadDeliveryFixVersionsEmpty, (state, _): DeliveriesSlice => {
    return {
      ...state,
      deliveryFixVersions: [],
    };
  }),
  on(DeliveriesActions.loadDeliveryFixVersionsSuccessful, (state, props): DeliveriesSlice => {
    return {
      ...state,
      deliveryFixVersions: props.deliveryFixVersions.map((fixVerison) => ({
        ...fixVerison,
        // Trim the string properties in order to handle typos Jira Releases
        projectKey: fixVerison.projectKey.trim(),
        versionName: fixVerison.versionName.trim(),
      })),
    };
  }),
  on(DeliveriesActions.loadDeliveryFixVersionsEmpty, (state, _): DeliveriesSlice => {
    return {
      ...state,
      deliveryFixVersions: [],
    };
  }),
);
