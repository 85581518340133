import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AtcInfoDetails, AtcInfoSummary } from '../../ngrx/model/atc';
import { selectAtcGroupSummary, selectAtcSingleDetails } from '../../ngrx/store/atc/atc.selectors';
import { selectIsAtcPipelineRunLoading, selectIsAtcSummaryLoading } from '../../ngrx/store/loading-state/loading-state.selectors';
import { GroupComplianceMode } from '../../shared/model/group-compliance-mode';

@Component({
  selector: 'app-list-item-kpis-atc',
  templateUrl: './list-item-kpis-atc.component.html',
  styleUrls: ['./list-item-kpis-atc.component.scss'],
})
export class ListItemKpisAtcComponent implements OnInit, OnChanges {
  threshold = 0;
  @Input() groupId: string;
  @Input() pipelineRunId: number;
  @Input() groupComplianceMode: GroupComplianceMode;
  public atcInfo$!: Observable<AtcInfoDetails | AtcInfoSummary>;
  public isLoading$!: Observable<boolean>;

  constructor(private readonly store$: Store) {}

  ngOnInit() {
    if (!this.pipelineRunId) {
      this.isLoading$ = this.store$.select(selectIsAtcSummaryLoading);
      this.atcInfo$ = this.store$.select(selectAtcGroupSummary);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.pipelineRunId && !this.groupId) {
      this.pipelineRunChanged();
    }
  }

  pipelineRunChanged() {
    const pipelineRunId = this.pipelineRunId;
    this.isLoading$ = this.store$.select(selectIsAtcPipelineRunLoading(pipelineRunId));
    this.atcInfo$ = this.store$.select(selectAtcSingleDetails(pipelineRunId));
  }
}
