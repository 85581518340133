<div class="dialog-content-flex flex-grow">
  <div class="flex flex-col gap-7">
    <div class="flex flex-row gap-2.5 info-box">
      <div>
        <fusion-icon class="info-icon" [name]="'info-circle-outline'" size="L"> </fusion-icon>
      </div>
      <div class="flex flex-col gap-2">
        <strong>Definition of state</strong>
        <span
          ><b>"Release Candidate": </b>Setting the selected pipeline run to state <i>"Release Candidate"</i> means you are only marking it
          as a potential candidate you want to release.</span
        >
        <span
          ><b>"Released": </b>Setting the selected pipeline run to state <i>"Released"</i> means you are marking the run as released within
          the selected (micro)delivery (group), which will protect all your uploaded files in your Cumulus storage for this run from being
          overwritten.<br />
          While setting the <i>"Released"</i> state is an immediate action, an asynchronous process will be triggered for consistency
          purposes. Unprocessed documents may be visible until this process has been completed.</span
        >
      </div>
    </div>
    <div class="flex flex-col gap-2.5">
      <span class="title">Change current state for selected pipeline run:</span>
      <div class="description flex flex-col gap-2">
        <div class="flex flex-row">
          <strong>{{ data?.startDateTime | date: 'MMM d, y hh:mm:ss a' }}</strong>
          <div class="chip-margin" *ngIf="latestReleaseStatusKey">
            <mat-chip class="mat-chip-small" [disableRipple]="true" [disabled]="true">
              <small class="text-overflow">{{ data?.latestReleaseStatusKeyInGroup.displayKey }}</small>
            </mat-chip>
          </div>
        </div>
        <span data-test-id="pipeline-run-key">{{ data?.pipelineRunKey }}</span>
      </div>
    </div>
  </div>
</div>
<div class="dialog-actions flex-grow-0 flex flex-row items-center justify-between">
  <div class="flex flex-row gap-6">
    <div [fusionTooltip]="'release-candidate.revert-button.tooltip.' + (latestReleaseStatusKey || 'initial') | translate">
      <button
        [disabled]="
          !changePipelineRunReleaseStatusService.isPipelineRunSpecificReleaseState(latestReleaseStatusKey, ReleaseStatus.RELEASE_CANDIDATE)
        "
        (click)="handleRevertReleaseStatus(ReleaseStatus.RELEASE_CANDIDATE)"
        fusionButton
        fusionButtonKind="flat"
        fusionColor="secondary"
        data-test-id="revert-release-candidate-btn"
        fusionFeatureUsageTracker="revert-release-candidate-btn"
        [fusionFeatureUsageTrackerEvents]="['click', 'mouseover']"
      >
        Revert State Release Candidate
      </button>
    </div>
    <div [fusionTooltip]="'released.revert-button.tooltip.' + (latestReleaseStatusKey || 'initial') | translate">
      <button
        [disabled]="
          !changePipelineRunReleaseStatusService.isPipelineRunSpecificReleaseState(latestReleaseStatusKey, ReleaseStatus.RELEASED)
        "
        (click)="handleRevertReleaseStatus(ReleaseStatus.RELEASED)"
        fusionButton
        fusionButtonKind="flat"
        fusionColor="secondary"
        data-test-id="revert-released-btn"
        fusionFeatureUsageTracker="revert-released-btn"
        [fusionFeatureUsageTrackerEvents]="['click', 'mouseover']"
      >
        Revert State Released
      </button>
    </div>
  </div>
  <div class="flex flex-row gap-6">
    <div [fusionTooltip]="'release-candidate.set-button-tooltip.' + (latestReleaseStatusKey || 'initial') | translate">
      <button
        [disabled]="
          changePipelineRunReleaseStatusService.isPipelineRunSpecificReleaseState(
            latestReleaseStatusKey,
            ReleaseStatus.RELEASE_CANDIDATE
          ) || changePipelineRunReleaseStatusService.isPipelineRunSpecificReleaseState(latestReleaseStatusKey, ReleaseStatus.RELEASED)
        "
        (click)="handleSetReleaseStatus(ReleaseStatus.RELEASE_CANDIDATE)"
        fusionButton
        fusionButtonKind="filled"
        fusionColor="primary"
        data-test-id="set-to-release-candidate-btn"
        fusionFeatureUsageTracker="set-to-release-candidate-btn"
        [fusionFeatureUsageTrackerEvents]="['click', 'mouseover']"
      >
        Set to Release Candidate
      </button>
    </div>
    <div [fusionTooltip]="'release.set-button.tooltip.' + (latestReleaseStatusKey || 'initial') | translate">
      <button
        [disabled]="changePipelineRunReleaseStatusService.isPipelineRunSpecificReleaseState(latestReleaseStatusKey, ReleaseStatus.RELEASED)"
        (click)="handleSetReleaseStatus(ReleaseStatus.RELEASED)"
        fusionButton
        fusionButtonKind="filled"
        fusionColor="primary"
        data-test-id="set-to-released-btn"
        fusionFeatureUsageTracker="set-to-released-btn"
        [fusionFeatureUsageTrackerEvents]="['click', 'mouseover']"
      >
        Set to Released
      </button>
    </div>
  </div>
</div>
