import { createAction, props } from '@ngrx/store';
import { AllowedScms } from './allowed-scms.model';

export const allowedScmsList = 'Allowed-Scms-List';

export const loadAllowedScmsList = createAction(`[${allowedScmsList}] Trigger loading of ${allowedScmsList} from backend`);
export const setAllowedScms = createAction(
  `[${allowedScmsList}] Set ${allowedScmsList} from backend in store`,
  props<{ allowedScms: AllowedScms[] }>(),
);

export const loadFailedAllowedScms = createAction(`[${allowedScmsList}] Loading of ${allowedScmsList} Data from backend failed`);
