<div class="dialogHeader">
  <fusion-icon
    *ngIf="isSelected"
    class="backButton"
    name="chevron-left"
    size="XL"
    (click)="policyKeySelected(null)"
    data-test-id="policy-usage-group-detail-popup-back-btn"
    fusionFeatureUsageTracker="policy-usage-group-detail-dialog-back"
  >
  </fusion-icon>
  <p class="dialogTitle" *ngIf="isSelected && !isCustomPolicyUsage">{{ dialogTitle | translate }} | {{ selectedPolicyKey.policyKey }}</p>
  <p class="dialogTitle" *ngIf="isSelected && isCustomPolicyUsage">
    {{ dialogTitle | translate }} | {{ selectedPolicyKey.customPolicyKey }}
  </p>

  <p class="dialogTitle" *ngIf="!isSelected">{{ dialogTitle | translate }}</p>

  <mat-icon
    aria-hidden="false"
    aria-label="cancel"
    class="closeButton"
    (click)="closeDialog()"
    data-test-id="policy-usage-group-detail-popup-close-btn"
    >cancel
  </mat-icon>
</div>

<div class="tabContainer flex flex-row">
  <!-- hide the component instead of using ngIf to keep the filter state (only master, not detail page) -->
  <app-policy-usage-aggregation-details
    class="w-full"
    [ngClass]="{ hidden: isSelected }"
    *ngIf="!isCustomPolicyUsage"
    [groupId]="dialogData?.groupId"
    [groupComplianceMode]="dialogData?.groupComplianceMode"
    (selectedPolicy)="policyKeySelected($event)"
  ></app-policy-usage-aggregation-details>
  <app-central-policy-results
    class="w-full"
    *ngIf="!isCustomPolicyUsage && isSelected"
    [selectedPolicyKey]="selectedPolicyKey?.policyKey"
    [groupId]="dialogData?.groupId"
    [groupComplianceMode]="dialogData?.groupComplianceMode"
  ></app-central-policy-results>
  <!-- hide the component instead of using ngIf to keep the filter state (only master, not detail page) -->
  <app-custom-policy-usage-aggregation-details
    class="w-full"
    [ngClass]="{ hidden: isSelected }"
    *ngIf="isCustomPolicyUsage"
    [groupId]="dialogData?.groupId"
    [groupComplianceMode]="dialogData?.groupComplianceMode"
    (selectedPolicy)="policyKeySelected($event)"
  ></app-custom-policy-usage-aggregation-details>
  <app-custom-policy-results
    class="w-full"
    *ngIf="isCustomPolicyUsage && isSelected"
    [selectedPolicyKey]="selectedPolicyKey?.policyKey"
    [groupId]="dialogData?.groupId"
    [groupComplianceMode]="dialogData?.groupComplianceMode"
  ></app-custom-policy-results>
</div>
