import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  selectIsCentralPolicyUsageGroupSummaryLoading,
  selectIsCentralPolicyUsageGroupSummaryLoadingError,
  selectIsCentralPolicyUsageGroupSummaryLoadingNotFound,
  selectIsCustomPolicyUsageGroupSummaryLoading,
  selectIsCustomPolicyUsageGroupSummaryLoadingError,
  selectIsCustomPolicyUsageGroupSummaryLoadingNotFound,
} from 'src/app/ngrx/store/loading-state/loading-state.selectors';
import { GroupComplianceMode } from 'src/app/shared/model/group-compliance-mode';
import { GroupPolicyUsageSummary } from '../../ngrx/model/policy-results';
import { loadCentralPolicyUsageGroupDetails } from '../../ngrx/store/central-policy-usage/central-policy-usage.actions';
import { selectCentralPolicyUsageGroupSummary } from '../../ngrx/store/central-policy-usage/central-policy-usage.selectors';
import { loadCustomPolicyUsageGroupDetails } from '../../ngrx/store/custom-policy-usage/custom-policy-usage.actions';
import { selectCustomPolicyUsageGroupSummary } from '../../ngrx/store/custom-policy-usage/custom-policy-usage.selectors';
import { ListItemKpisPolicyUsageAggregationDialogComponent } from './list-item-kpis-policy-usage-aggregation-dialog/list-item-kpis-policy-usage-aggregation-dialog.component';

@Component({
  selector: 'app-list-item-kpis-policy-usage-aggregation',
  templateUrl: './list-item-kpis-policy-usage-aggregation.component.html',
})
export class ListItemKpisPolicyUsageAggregationComponent implements OnInit {
  @Input() groupId: string;
  @Input() groupComplianceMode: GroupComplianceMode;
  @Input() isCustomPolicyUsage = false;
  public policyUsageGroupInfo$!: Observable<GroupPolicyUsageSummary>;
  public isLoading$!: Observable<boolean>;
  public isLoadingError$!: Observable<boolean>;
  public isLoadingNotFound$!: Observable<boolean>;
  public policyUsageTileTitleRef: string;
  public dataTestIdForDetailsButton: string;
  public fusionFeatureTrackerForOpenDetailsDialog: string;

  constructor(
    private readonly store$: Store,
    private readonly dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    if (this.isCustomPolicyUsage) {
      this.policyUsageGroupInfo$ = this.store$.select(selectCustomPolicyUsageGroupSummary);
      this.isLoading$ = this.store$.select(selectIsCustomPolicyUsageGroupSummaryLoading);
      this.isLoadingError$ = this.store$.select(selectIsCustomPolicyUsageGroupSummaryLoadingError);
      this.isLoadingNotFound$ = this.store$.select(selectIsCustomPolicyUsageGroupSummaryLoadingNotFound);
      this.policyUsageTileTitleRef = 'kpi.tile.custom.policy.usage.title';
      this.dataTestIdForDetailsButton = 'custom-policy-usage-aggregated-popup-view-details-link';
      this.fusionFeatureTrackerForOpenDetailsDialog = 'kpi-open-aggregated-custom-policy-usage-details';
    } else {
      this.policyUsageGroupInfo$ = this.store$.select(selectCentralPolicyUsageGroupSummary);
      this.isLoading$ = this.store$.select(selectIsCentralPolicyUsageGroupSummaryLoading);
      this.isLoadingError$ = this.store$.select(selectIsCentralPolicyUsageGroupSummaryLoadingError);
      this.isLoadingNotFound$ = this.store$.select(selectIsCentralPolicyUsageGroupSummaryLoadingNotFound);
      this.policyUsageTileTitleRef = 'kpi.tile.policy.usage.title';
      this.dataTestIdForDetailsButton = 'policy-usage-aggregated-popup-view-details-link';
      this.fusionFeatureTrackerForOpenDetailsDialog = 'kpi-open-aggregated-policy-usage-details';
    }
  }

  public openDetailsDialog(): void {
    const group = { groupId: this.groupId, groupComplianceMode: this.groupComplianceMode };
    if (this.isCustomPolicyUsage) {
      this.store$.dispatch(loadCustomPolicyUsageGroupDetails(group));
    } else {
      this.store$.dispatch(loadCentralPolicyUsageGroupDetails(group));
    }
    const dialogData = {
      groupId: this.groupId,
      groupComplianceMode: this.groupComplianceMode,
      isCustomPolicyUsage: this.isCustomPolicyUsage,
    };
    this.dialog.open(ListItemKpisPolicyUsageAggregationDialogComponent, { data: dialogData, panelClass: 'requirement-details-container' });
  }
}
