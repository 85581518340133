<div class="flex flex-row gap-5 justify-start items-center w-full">
  <fusion-icon
    *ngIf="showWarning$ | async"
    class="error"
    name="exclamation-triangle-outline"
    size="L"
    [fusionTooltip]="warningTooltip"
  ></fusion-icon>
  <div class="pipelinerun-info-container flex flex-col" data-test-id="pipelinerun-info-container">
    <div class="flex flex-row gap-2">
      <strong class="pipeline-run-info-date">{{ pipeline?.pipelineRunStartDateTime | date: 'MMM d, y hh:mm:ss a' }}</strong>
      <div *ngIf="pipeline?.pipelineRunExecutionStatus?.displayKey" attr.data-test-id="{{ _dataTestIdRunExecutionStatus }}">
        <app-status-chip
          [status]="pipeline?.pipelineRunExecutionStatus"
          statusType="execution-status"
          [statusDate]="pipeline?.pipelineRunExecutionStatusDate"
          [showStatusDate]="true"
          [showTooltip]="true"
        ></app-status-chip>
      </div>
      <div *ngIf="pipeline?.pipelineRunReleaseStatusKeyInGroup.key" attr.data-test-id="{{ _dataTestIdRunReleaseStatus }}">
        <app-status-chip
          [status]="pipeline?.pipelineRunReleaseStatusKeyInGroup"
          statusType="release-status"
          [statusDate]="pipeline?.pipelineRunReleaseStatusCreatedAtInGroup"
          [showStatusDate]="true"
          [showTooltip]="true"
        ></app-status-chip>
      </div>
      <div *ngIf="pipeline?.hasScheduledInfo" attr.data-test-id="{{ _dataTestIdRunScheduledInfo }}">
        <mat-chip class="mat-chip-small" [disableRipple]="true" [disabled]="true">
          <div class="flex flex-row gap-1 justify-start items-center">
            <span class="text-overflow" fusionTooltip="Scheduled run info available">Scheduled Info</span>
            <fusion-icon
              name="check"
              size="S"
              fusionFeatureUsageTracker="headerInfo-pipelineRun-scheduledInfo-icon"
              [fusionFeatureUsageTrackerEvents]="['mouseover']"
            ></fusion-icon>
          </div>
        </mat-chip>
      </div>
    </div>
    <span
      class="pipelinerun-info-text clickable"
      fusionTooltip="{{ pipeline?.pipelineRunKey }}"
      fusionTooltipPosition="top-left"
      (click)="emitShowDetailsEvent()"
      attr.data-test-id="{{ _dataTestIdRunKey }}"
    >
      {{ pipeline?.pipelineRunKey }}
    </span>
    <div
      class="text-overflow flex flex-row gap-1 justify-start items-center"
      *ngIf="pipeline?.pipelineRunModeId === pipelineRunModeIds.Optimized_Commit"
      style="font-size: 13px"
      data-test-id="latest-related-scheduled-run-header-info"
    >
      <span>Related Scheduled Run:</span>
      <div
        [class.warning]="!pipeline?.relatedPipelineRunKey"
        [class.error]="pipeline?.isRelatedPipelineRunDeprecated"
        class="text-overflow flex flex-row gap-1 justify-start items-center"
        fusionTooltipPosition="top-left"
        [fusionTooltip]="relatedScheduledPipelineRun"
        [fusionTooltipDisabled]="disableTooltip(pipeline?.isRelatedPipelineRunDeprecated, pipeline?.relatedPipelineRunKey)"
      >
        <span *ngIf="pipeline?.relatedPipelineRunKey && pipeline?.relatedPipelineRunStartDateTime" class="text-overflow"
          >{{ pipeline?.relatedPipelineRunKey }} -
          <em>{{ pipeline?.relatedPipelineRunStartDateTime | date: 'MMM d, y hh:mm:ss a' }}</em></span
        >
        <span *ngIf="!pipeline?.relatedPipelineRunKey" class="text-overflow">No related scheduled run found.</span>
        <fusion-icon
          *ngIf="pipeline?.isRelatedPipelineRunDeprecated || !pipeline?.relatedPipelineRunKey"
          name="exclamation-triangle"
        ></fusion-icon>
        <ng-template #relatedScheduledPipelineRun>
          <div *ngIf="pipeline?.isRelatedPipelineRunDeprecated" [innerHTML]="'icon-tooltip.show-pipeline-deprecated' | translate"></div>
          <div *ngIf="!pipeline?.isRelatedPipelineRunDeprecated && pipeline?.relatedPipelineRunKey">
            {{ pipeline?.relatedPipelineRunKey }}
            - {{ pipeline?.relatedPipelineRunStartDateTime | date: 'MMM d, y hh:mm:ss a' }}
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
