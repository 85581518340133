<ng-container
  *ngIf="{
    loading: isLoading$ | async,
    loadingError: isLoadingError$ | async,
    malwarescanInfo: malwarescanInfo$ | async,
  } as data"
>
  <app-list-item-kpis [flag]="'kpi.flag.beta' | translate" [isLoading]="data.loading" [title]="'kpi.tile.malwarescan.title' | translate">
    <div class="flex flex-col gap-3.5" kpi-tile-content>
      <app-malwarescan-status-label
        [loadingError]="data?.loadingError"
        [malwareDetected]="data?.malwarescanInfo?.malwareDetected"
        [status]="data?.malwarescanInfo?.displayStatus"
        (labelClicked)="onSummaryStatusClicked()"
        [labelClickable]="!currentPipelineRunId"
        [pipelineRunId]="currentPipelineRunId"
        (reloadButtonClicked)="reloadInfo()"
      >
      </app-malwarescan-status-label>
    </div>
    <div kpi-tile-footer>
      <span fusionFeatureUsageTracker="kpi-open-malwarescan-wiki">
        According to the scan result provided by<br />
        <a
          href="https://help.sap.com/viewer/b416237f818c4e2e827f6118640079f8/Cloud/en-US/b7c9b86fe724458086a502df3160f380.html"
          target="_blank"
          rel="noopener noreferrer"
          class="clickable"
        >
          <span>SAP Malware Scanning Service</span><fusion-icon name="link-external" size="S"></fusion-icon> </a
        >.</span
      >
    </div>
  </app-list-item-kpis>
</ng-container>
