import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BannerService {
  surveyUrl = 'https://employeeexperience.surveys.sap.com/jfe/form/SV_0MS5UwafHoztDNQ';
  activationDate = new Date('2023-08-29 00:00:00');
  deactivationDate = new Date('2024-03-23T12:00:00.000+01:00');

  maintenanceStartDate = new Date('2024-03-23T10:00:00.000+01:00');
  maintenanceEndDate = new Date('2024-03-23T12:00:00.000+01:00');

  getSurveyUrl(): string {
    return this.surveyUrl;
  }

  getActivationDate(): Date {
    return this.activationDate;
  }

  getDeactivationDate(): Date {
    return this.deactivationDate;
  }

  getMaintenanceStartDate(): Date {
    return this.maintenanceStartDate;
  }

  getMaintenanceEndDate(): Date {
    return this.maintenanceEndDate;
  }

  isBannerActive(): boolean {
    const currentDate = new Date().getTime();
    return currentDate >= this.activationDate.getTime() && currentDate <= this.deactivationDate.getTime();
  }

  isBannerClosed(): boolean {
    return sessionStorage.getItem(`bannerClosed-${this.deactivationDate.getTime()}`) === 'true';
  }

  closeBanner(): void {
    sessionStorage.setItem(`bannerClosed-${this.deactivationDate.getTime()}`, 'true');
  }

  isBannerVisible(): boolean {
    return this.isBannerActive() && !this.isBannerClosed();
  }
}
