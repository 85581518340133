import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import { FosstarRating, FosstarRatingType } from '../../model/fosstar/fosstar-rating';
import { FosstarsSummaryDetail } from '../../ngrx/model/fosstars';
import {
  loadFosstarsGroupDetails,
  loadFosstarsGroupSummary,
  loadFosstarsSingleDetails,
  loadFosstarsSingleSummary,
} from '../../ngrx/store/fosstars/fosstars.actions';
import { selectFosstarsGroupSummary, selectFosstarsSingleSummaryByPipelineRunId } from '../../ngrx/store/fosstars/fosstars.selectors';
import {
  selectIsFosstarsGroupSummaryLoading,
  selectIsFosstarsGroupSummaryLoadingError,
  selectIsFosstarsGroupSummaryLoadingNotFound,
  selectIsFosstarsPipelineRunLoading,
  selectIsFosstarsPipelineRunSummaryLoadingError,
  selectIsFosstarsPipelineRunSummaryLoadingNotFound,
} from '../../ngrx/store/loading-state/loading-state.selectors';
import { GroupComplianceMode } from '../../shared/model/group-compliance-mode';
import { FosstarDetailsDialogComponent } from './fosstar-details-dialog/fosstar-details-dialog.component';

@Component({
  selector: 'app-list-item-kpis-fosstar',
  templateUrl: './list-item-kpis-fosstar.component.html',
  styleUrls: ['./list-item-kpis-fosstar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemKpisFosstarComponent implements OnInit, OnChanges {
  @Input() groupId: string;
  @Input() groupComplianceMode: GroupComplianceMode;
  @Input() currentPipelineRunId: number;

  public fosstarsSummary$!: Observable<FosstarsSummaryDetail[] | FosstarsSummaryDetail>;
  public isLoading$!: Observable<boolean>;
  public isLoadingError$!: Observable<boolean>;
  public isLoadingNotFound$!: Observable<boolean>;
  ratings: FosstarRating[] = [];

  constructor(
    private readonly store$: Store,
    private readonly dialog: MatDialog,
  ) {}
  ngOnInit() {
    if (!this.currentPipelineRunId) {
      this.isLoading$ = this.store$.select(selectIsFosstarsGroupSummaryLoading);
      this.isLoadingNotFound$ = this.store$.select(selectIsFosstarsGroupSummaryLoadingNotFound);
      this.isLoadingError$ = this.store$.select(selectIsFosstarsGroupSummaryLoadingError);
      this.fosstarsSummary$ = this.store$.select(selectFosstarsGroupSummary).pipe(
        map((data: FosstarsSummaryDetail) => {
          this.setRatingsOverview([data]);
          return data;
        }),
      );
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentPipelineRunId) {
      this.loadPipelineRunInfo();
    }
  }

  loadPipelineRunInfo() {
    const pipelineRunId = this.currentPipelineRunId;
    this.fosstarsSummary$ = this.store$.select(selectFosstarsSingleSummaryByPipelineRunId(pipelineRunId)).pipe(
      map((data: FosstarsSummaryDetail[]) => {
        this.setRatingsOverview(data);
        return data;
      }),
    );
    this.isLoading$ = this.store$.select(selectIsFosstarsPipelineRunLoading(pipelineRunId));
    this.isLoadingError$ = this.store$.select(selectIsFosstarsPipelineRunSummaryLoadingError(pipelineRunId));
    this.isLoadingNotFound$ = this.store$.select(selectIsFosstarsPipelineRunSummaryLoadingNotFound(pipelineRunId));
  }

  reload() {
    if (this.currentPipelineRunId) {
      this.store$.dispatch(loadFosstarsSingleSummary({ pipelineRunId: this.currentPipelineRunId }));
    } else {
      this.store$.dispatch(
        loadFosstarsGroupSummary({
          groupComplianceMode: this.groupComplianceMode,
          deliveryId: this.groupId,
        }),
      );
    }
  }

  getRatingAmount(ratingLabel: string, data: FosstarsSummaryDetail[]): number {
    return data.reduce((prev, next) => Number(prev) + Number(next[this.camelize(ratingLabel.toLowerCase())]), 0);
  }

  camelize(text) {
    return text.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
  }

  setRatingsOverview(data: FosstarsSummaryDetail[]) {
    Object.keys(FosstarRatingType).forEach((ratingType) => {
      this.ratings.push(new FosstarRating(ratingType, this.getRatingAmount(ratingType, data)));
    });
  }

  openFosstarDetails(): void {
    if (!this.currentPipelineRunId) {
      this.store$.dispatch(
        loadFosstarsGroupDetails({
          deliveryId: this.groupId,
          groupComplianceMode: this.groupComplianceMode,
        }),
      );
      this.dialog.open(FosstarDetailsDialogComponent, {
        data: { deliveryId: this.groupId, groupComplianceMode: this.groupComplianceMode },
      });
    } else {
      this.store$.dispatch(loadFosstarsSingleDetails({ pipelineRunId: this.currentPipelineRunId }));
      this.dialog.open(FosstarDetailsDialogComponent, { data: { pipelineRunId: this.currentPipelineRunId } });
    }
  }
}
