import { createAction, props } from '@ngrx/store';
import { Delivery } from '../../../model/group/group';
import { RequirementTestcaseResult } from '../../../model/requirement-detail/requirement-detail-testcase';
import { PipelineRunRequirement } from '../../../model/pipeline-run/pipeline-run';
import { ProductStandard } from '../../../shared/model/product-standard';
import { Requirement } from '../../../model/jira/release-item';

export const loadDeliveryRequirements = createAction(
  `[${ProductStandard.fc2}] Trigger loading of ${ProductStandard.fc2} delivery requirements`,
  props<{ delivery: Delivery }>(),
);

export const setDeliveryRequirements = createAction(
  `[${ProductStandard.fc2}] Set ${ProductStandard.fc2} requirements of delivery`,
  props<{ deliveryRequirements: Requirement[] }>(),
);

export const loadExternalDeliveryRequirements = createAction(
  `[${ProductStandard.fc2}] Trigger loading of ${ProductStandard.fc2} External delivery requirements`,
  props<{ delivery: Delivery }>(),
);

export const loadPipelineRunRequirements = createAction(
  `[${ProductStandard.fc3}] Load ${ProductStandard.fc3} requirements for pipeline run`,
  props<{ pipelineRunId: number }>(),
);

export const setPipelineRunRequirements = createAction(
  `[${ProductStandard.fc3}] Set ${ProductStandard.fc3} requirements for pipeline run`,
  props<{ pipelineRunId: number; pipelineRunRequirements: PipelineRunRequirement[] }>(),
);

export const loadPipelineRunRequirementTestcases = createAction(
  `[${ProductStandard.fc2}] Load ${ProductStandard.fc2} requirements testcases for pipeline run`,
  props<{ pipelineRunId: number }>(),
);

export const setPipelineRunRequirementTestcases = createAction(
  `[${ProductStandard.fc2}] Set ${ProductStandard.fc2} requirements testcases for pipeline run`,
  props<{ pipelineRunId: number; pipelineRunRequirementsTestcases: RequirementTestcaseResult[] }>(),
);

export const loadDeliveryRequirementTestcases = createAction(`[${ProductStandard.fc2}] Load ${ProductStandard.fc2} requirement testcases`);

export const setDeliveryRequirementTestcases = createAction(
  `[${ProductStandard.fc2}] Set ${ProductStandard.fc2} requirement testcases`,
  props<{ deliveryTestcases: RequirementTestcaseResult[] }>(),
);

export const loadDeliveryRequirementsError = createAction(`[${ProductStandard.fc2}] Error loading Delivery Requirements`);
export const loadDeliveryRequirementTestcasesError = createAction(`[${ProductStandard.fc2} Error loading Delivery Requirements testcases`);
export const loadPipelineRunRequirementsError = createAction(
  `[${ProductStandard.fc2}] Error loading Pipeline Run requirements`,
  props<{ pipelineRunId: number }>(),
);
export const loadPipelineRunRequirementsNotFound = createAction(
  `[${ProductStandard.fc2}] Loading Pipeline Run requirements not found`,
  props<{ pipelineRunId: number }>(),
);
export const loadPipelineRunRequirementTestcasesError = createAction(
  `[${ProductStandard.fc2}] Error loading Pipeline Run requirements testcases`,
  props<{ pipelineRunId: number }>(),
);
