import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-error-information',
  templateUrl: './error-information.component.html',
})
export class ErrorInformationComponent implements OnInit {
  @Input() errorText;
  @Output() reloadClicked = new EventEmitter();
  @Input() customErrorTextClass: string;
  isReloadSet = true;

  ngOnInit(): void {
    this.isReloadSet = this.reloadClicked.observed;
  }
}
