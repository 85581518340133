import { createReducer, on } from '@ngrx/store';
import { CodeCoverageInfoSlice } from '../app-state';
import { initialCodeCoverageInfoSlice } from '../initial-slices';
import * as CodeCoverageInfoActions from './code-coverage-info.actions';

export const codeCoverageInfoReducer = createReducer(
  initialCodeCoverageInfoSlice,
  on(CodeCoverageInfoActions.loadCodeCoverageGroupSummary, (state): CodeCoverageInfoSlice => {
    return { ...state, aggregationSummary: initialCodeCoverageInfoSlice.aggregationSummary };
  }),
  on(CodeCoverageInfoActions.setCodeCoverageGroupSummary, (state, props): CodeCoverageInfoSlice => {
    return { ...state, aggregationSummary: props.groupSummary };
  }),
  on(CodeCoverageInfoActions.setCodeCoverageGroupDetails, (state, props): CodeCoverageInfoSlice => {
    return { ...state, aggregationDetails: props.groupDetails };
  }),
  on(CodeCoverageInfoActions.setCodeCoverageForPipelineRun, (state, props): CodeCoverageInfoSlice => {
    const selectedPipelineRuns = { ...state.selectedPipelineRuns };
    selectedPipelineRuns[props.pipelineRunId] = props.coverageInfo;
    return { ...state, selectedPipelineRuns };
  }),
);
