import { ComplianceInfoDetails, ComplianceInfoSummary } from '../../model/compliance-information';
import { createAction, props } from '@ngrx/store';
import { ProductStandard } from '../../../shared/model/product-standard';
import { GroupComplianceMode } from '../../../shared/model/group-compliance-mode';

export const loadSlc25GroupSummary = createAction(
  `[${ProductStandard.slc25}] Trigger loading of ${ProductStandard.slc25} compliance information for group-summary from backend`,
  props<{ deliveryId: string; groupComplianceMode: GroupComplianceMode }>(),
);
export const setSlc25GroupSummary = createAction(
  `[${ProductStandard.slc25}] Set ${ProductStandard.slc25} compliance information for group-summary from backend in store`,
  props<{ groupSummary: ComplianceInfoSummary }>(),
);

export const loadSlc25GroupDetails = createAction(
  `[${ProductStandard.slc25}] Trigger loading of ${ProductStandard.slc25} compliance information for group-details from backend`,
  props<{ deliveryId: string; groupComplianceMode: GroupComplianceMode }>(),
);
export const setSlc25GroupDetails = createAction(
  `[${ProductStandard.slc25}] Set ${ProductStandard.slc25} compliance information for group-details from backend in store`,
  props<{ groupDetails: ComplianceInfoDetails[] }>(),
);

export const loadSlc25SingleDetails = createAction(
  `[${ProductStandard.slc25}] Trigger loading of ${ProductStandard.slc25} compliance information for single-details from backend`,
  props<{ pipelineRunId: number }>(),
);
export const setSlc25SingleDetails = createAction(
  `[${ProductStandard.slc25}] Set ${ProductStandard.slc25} compliance information for single-details from backend in store`,
  props<{ pipelineRunId: number; singleDetails: ComplianceInfoDetails }>(),
);

export const loadSlc25GroupSummaryError = createAction(
  `[${ProductStandard.slc25}] Loading of ${ProductStandard.slc25} Group Summary from backend error`,
  props<{ deliveryId: string }>(),
);

export const loadSlc25GroupSummaryNotFound = createAction(
  `[${ProductStandard.slc25}] Loading of ${ProductStandard.slc25} Group Summary from backend not found`,
  props<{ deliveryId: string }>(),
);

export const loadSlc25SingleDetailsError = createAction(
  `[${ProductStandard.slc25}] Loading of ${ProductStandard.slc25} Single Details from backend error`,
  props<{ pipelineRunId: number }>(),
);

export const loadSlc25SingleDetailsNotFound = createAction(
  `[${ProductStandard.slc25}] Loading of ${ProductStandard.slc25} Single Details from backend not found`,
  props<{ pipelineRunId: number }>(),
);

export const loadSlc25GroupDetailsError = createAction(
  `[${ProductStandard.slc25}] Loading of ${ProductStandard.slc25} Group Details from backend error`,
  props<{ deliveryId: string }>(),
);
