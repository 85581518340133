import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Fc1Slice } from '../app-state';

// First selector should be a featureSelector
export const selectFc1Slice = createFeatureSelector<Fc1Slice>('fc1Slice');

export const selectFc1GroupSummary = createSelector(selectFc1Slice, (fc1Slice) => {
  return fc1Slice.aggregationSummary;
});
export const selectFc1GroupDetails = createSelector(selectFc1Slice, (fc1Slice) => {
  return fc1Slice.aggregationDetails;
});
export const selectFc1SingleDetailById = (pipelineRunId: number) =>
  createSelector(selectFc1Slice, (fc1Slice: Fc1Slice) => {
    return fc1Slice.selectedPipelineRuns[pipelineRunId];
  });
