import { createReducer, on } from '@ngrx/store';
import { Fc1Slice } from '../app-state';
import { initialFc1Slice } from '../initial-slices';
import * as Fc1Actions from './fc-1.actions';

export const fc1Reducer = createReducer(
  initialFc1Slice,
  on(Fc1Actions.loadFc1GroupSummary, (state, _): Fc1Slice => {
    return { ...state, aggregationSummary: initialFc1Slice.aggregationSummary };
  }),
  on(Fc1Actions.setFc1GroupSummary, (state, props): Fc1Slice => {
    return { ...state, aggregationSummary: props.groupSummary };
  }),
  on(Fc1Actions.setFc1GroupDetails, (state, props): Fc1Slice => {
    return { ...state, aggregationDetails: [...props.groupDetails] };
  }),
  on(Fc1Actions.setFc1SingleDetails, (state, props): Fc1Slice => {
    const selectedPipelineRuns = { ...state.selectedPipelineRuns };
    selectedPipelineRuns[props.pipelineRunId] = props.singleDetails;
    return { ...state, selectedPipelineRuns };
  }),
);
