<ng-container
  *ngIf="{
    loading: isLoading$ | async,
    loadingError: isLoadingError$ | async,
    loadingNotFound: isLoadingNotFound$ | async,
    complianceInformation: slc25complianceInfo$ | async,
  } as data"
>
  <app-list-item-kpis
    data-test-id="kpi-tile-slc25"
    isDeprecationAvailable="true"
    deprecationAnchor="slc25"
    [flag]="'kpi.flag.deprecated' | translate"
    [isLoading]="data.loading"
    [title]="'kpi.tile.slc25.title' | translate"
  >
    <div class="flex flex-col gap-3.5" kpi-tile-content>
      <app-compliance-status-label
        [complianceInformation]="data.complianceInformation"
        [loadingState]="{
          loadingError: data.loadingError,
          loadingNotFound: data.loadingNotFound,
        }"
        [tooltipText]="currentPipelineRunId ? 'Open Source Code' : undefined"
        [labelClickable]="isValidUrl(data.complianceInformation?.scmsUrl)"
        (labelClicked)="onSummaryStatusClicked(data.complianceInformation)"
        (reloadButtonClicked)="reloadData()"
      ></app-compliance-status-label>
    </div>

    <div kpi-tile-footer>
      <span fusionFeatureUsageTracker="kpi-open-slc25-allowed">
        Your source code management system (SCM) must be found in the
        <a class="clickable" (click)="openListOfAllowedSCMs()"> list of compliant SCMs</a>.
      </span>
    </div>
  </app-list-item-kpis>
</ng-container>
