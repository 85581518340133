import { Component, Input } from '@angular/core';
import { PipelineRunModeIds } from '../../../model/pipeline-run/pipeline-run';
import { ComplianceInfoDetails, ComplianceStatus } from '../../../ngrx/model/compliance-information';

@Component({
  selector: 'app-kpi-column-compliance-status',
  templateUrl: './kpi-column-compliance-status.component.html',
})
export class KpiColumnComplianceStatusComponent {
  @Input() item: ComplianceInfoDetails;
  @Input() dataTestId: string;
  public ComplianceStatus = ComplianceStatus;
  pipelineRunModeIds = PipelineRunModeIds;
}
