<app-list-item-kpis *ngIf="atcInfo$ | async as data" [flag]="'kpi.flag.beta' | translate" [title]="'kpi.tile.atc.title' | translate">
  <div class="issue-text flex flex-col w-full" kpi-tile-content data-test-id="atc-check-result-text">
    <div class="flex flex-row" [class.red-text]="data?.error > threshold">
      <span class="label-text">Error(s)</span>
      <span class="flex flex-grow"></span>
      <span data-test-id="atc-check-result-error-text" class="label-value">{{ data?.error }}</span>
    </div>
    <mat-divider></mat-divider>
    <div class="flex flex-row" [class.red-text]="data?.warning > threshold">
      <span class="label-text">Warning(s)</span>
      <span class="flex flex-grow"></span>
      <span data-test-id="atc-check-result-warning-text" class="label-value">{{ data?.warning }}</span>
    </div>
  </div>
  <div kpi-tile-footer>quality issues in ABAP code found at ABAP Test Cockpit.</div>
</app-list-item-kpis>
