import { RequirementTestcaseResult } from '../../../../model/requirement-detail/requirement-detail-testcase';

export const testcaseWithName = (testcase: RequirementTestcaseResult, name: string): RequirementTestcaseResult => {
  return {
    ...testcase,
    name: name ? [name] : ['empty test name'],
    sort: getTestcaseSort(testcase),
  };
};

export const getTestcaseSort = (testcase: RequirementTestcaseResult) => {
  if (testcase.hasIncorrectVersion) {
    return 3;
  } else if (testcase.untested) {
    return 1;
  } else if (testcase.status) {
    if (testcase.skipped) {
      return 2;
    }
    return 4;
  } else {
    return 0;
  }
};

export const getStatusText = (testcase: RequirementTestcaseResult): string => {
  if (testcase.hasIncorrectVersion) {
    return 'Not Available';
  } else if (testcase.untested) {
    return 'Untested';
  } else if (testcase.status) {
    if (testcase.skipped) {
      return 'Skipped';
    }
    return 'Success';
  } else if (isRequirementsMappingFailed(testcase)) {
    return 'Mapping failed';
  } else {
    return 'Failed';
  }
};

export const isRequirementsMappingFailed = (testcase: RequirementTestcaseResult): boolean => {
  return testcase.status === null && testcase.name && testcase.name[0] === '.' && !!testcase.sourceReference;
};
