<i
  kendoPopoverAnchor
  showOn="click"
  fusionFeatureUsageTracker="deprecation-popover-info-icon-clicked"
  [fusionFeatureUsageTrackerEvents]="['click']"
  [popover]="popoverCallback"
  class="popover-info-icon-anchor clickable fi fi-info-circle-outline"
></i>
<kendo-popover #{{anchor}} [animation]="myAnimation" [width]="350" position="bottom">
  <ng-template kendoPopoverBodyTemplate>
    <mat-tab-group>
      <mat-tab label="Info">
        <div class="popover-content">
          <div
            *ngIf="hasTranslation('deprecation.popover.' + anchorValue + '.checkDate')"
            class="flex flex-row gap-1 justify-start items-center font-size-14"
            fusionFeatureUsageTracker="deprecation-popover-end-date-for-check-text-hovered"
            [fusionFeatureUsageTrackerEvents]="['mouseover']"
          >
            <i class="fi fi-calendar font-size-14"></i>
            <span
              >End of check: {{ 'deprecation.popover.' + anchorValue + '.checkDate' | translate: deprecationAndVisualisationDates }}</span
            >
          </div>
          <div
            class="flex flex-row gap-1 justify-start items-center font-size-14"
            fusionFeatureUsageTracker="deprecation-popover-end-date-for-visualization-text-hovered"
            [fusionFeatureUsageTrackerEvents]="['mouseover']"
          >
            <i class="fi fi-calendar font-size-14"></i>
            <span
              >End of visualization:
              {{ 'deprecation.popover.' + anchorValue + '.visualizationDate' | translate: deprecationAndVisualisationDates }}</span
            >
          </div>
          <div class="flex flex-row gap-1 justify-items-start font-size-14">
            <i class="fi fi-info-circle font-size-14 margin-top-3"></i>
            <span
              >Learn more:
              <a
                href="https://wiki.one.int.sap/wiki/x/TnPX2g"
                rel="noopener noreferrer"
                target="_blank"
                class="font-size-14 vertical-align-middle"
                fusionFeatureUsageTracker="deprecation-popover-wiki-link-clicked"
                [fusionFeatureUsageTrackerEvents]="['click']"
              >
                deprecation of compliance checks and tiles in Cumulus<i class="fi fi-link-external font-size-14 wiki-icon"></i
              ></a>
            </span>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Reason">
        <div
          class="popover-content font-size-14"
          [innerHTML]="'deprecation.popover.' + anchorValue + '.reason' | translate: deprecationAndVisualisationDates"
          fusionFeatureUsageTracker="deprecation-popover-reason-text-hovered"
          [fusionFeatureUsageTrackerEvents]="['mouseover']"
        ></div>
      </mat-tab>
      <mat-tab label="Impact">
        <div
          class="popover-content font-size-14"
          [innerHTML]="'deprecation.popover.' + anchorValue + '.impact' | translate: deprecationAndVisualisationDates"
          fusionFeatureUsageTracker="deprecation-popover-impact-text-hovered"
          [fusionFeatureUsageTrackerEvents]="['mouseover']"
        ></div>
      </mat-tab>
    </mat-tab-group>
  </ng-template>
</kendo-popover>
