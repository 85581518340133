import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { GridDataResult, MultipleSortSettings } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { Observable, map } from 'rxjs';
import { GroupCustomPolicyUsageByPolicyKey } from '../../../ngrx/model/policy-results';
import { loadCustomPolicyUsageGroupDetails } from '../../../ngrx/store/custom-policy-usage/custom-policy-usage.actions';
import { selectCustomPolicyUsageGroupDetails } from '../../../ngrx/store/custom-policy-usage/custom-policy-usage.selectors';
import {
  selectIsCustomPolicyUsageGroupDetailsLoading,
  selectIsCustomPolicyUsageGroupDetailsLoadingError,
} from '../../../ngrx/store/loading-state/loading-state.selectors';
import { GroupComplianceMode } from '../../../shared/model/group-compliance-mode';

@Component({
  selector: 'app-custom-policy-usage-aggregation-details',
  templateUrl: './custom-policy-usage-aggregation-details.component.html',
  styleUrls: ['./custom-policy-usage-aggregation-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomPolicyUsageAggregationDetailsComponent {
  @Input() groupId: string;
  @Input() groupComplianceMode: GroupComplianceMode;
  @Output() selectedPolicy = new EventEmitter<GroupCustomPolicyUsageByPolicyKey>();

  public dataSource$!: Observable<GridDataResult>;
  public isLoading$!: Observable<boolean>;
  public isLoadingError$!: Observable<boolean>;

  policyUsageGroupSorted: GroupCustomPolicyUsageByPolicyKey[];
  dataSource: GridDataResult;

  gridState: State = {
    filter: {
      logic: 'and',
      filters: [],
    },
    sort: [
      {
        field: 'customPolicyText',
        dir: 'asc',
      },
    ],
  };

  public sortSettings: MultipleSortSettings = {
    mode: 'multiple',
    initialDirection: 'asc',
    allowUnsort: true,
    showIndexes: true,
  };

  constructor(private readonly store$: Store) {
    this.dataSource$ = this.store$.select(selectCustomPolicyUsageGroupDetails).pipe(
      map((data) => {
        this.policyUsageGroupSorted = data;
        this.dataStateChanged(this.gridState);
        return this.dataSource;
      }),
    );
    this.isLoading$ = this.store$.select(selectIsCustomPolicyUsageGroupDetailsLoading);
    this.isLoadingError$ = this.store$.select(selectIsCustomPolicyUsageGroupDetailsLoadingError);
  }

  dataStateChanged(state) {
    this.gridState = state;
    this.dataSource = process(this.policyUsageGroupSorted, this.gridState);
  }

  reload() {
    this.store$.dispatch(
      loadCustomPolicyUsageGroupDetails({
        groupId: this.groupId,
        groupComplianceMode: this.groupComplianceMode,
      }),
    );
  }
}
