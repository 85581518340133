<ng-container
  *ngIf="{
    coverageInfo: coverageInfo$ | async,
    isLoading: isLoading$ | async,
  } as data"
>
  <app-list-item-kpis
    [isLoading]="data.isLoading"
    [title]="'kpi.tile.sonarqube.coverage.title' | translate"
    [flag]="'kpi.flag.beta' | translate"
  >
    <div *ngIf="data.coverageInfo" kpi-tile-content>
      <div class="flex flex-col gap-3.5">
        <div class="flex flex-row justify-center items-center gap-2.5" data-test-id="sonarqube-coverage-check-result-text">
          <div>
            <ngx-gauge
              [size]="120"
              [type]="gaugeType"
              [value]="data.coverageInfo?.lineCoverage | number: '1.0-1'"
              [thresholds]="threshold?.lineRate || thresholds"
              [append]="'%'"
            >
              <ngx-gauge-label>
                <a
                  *ngIf="data.coverageInfo.lineCoverageUrl"
                  href="{{ data.coverageInfo.lineCoverageUrl }}"
                  target="_blank"
                  rel="noopener noreferrer"
                  fusionTooltip="Open in SonarQube"
                >
                  <strong class="chart-label" data-test-id="sonarqube-coverage-chart-line-rate">Line Rate</strong>
                  <i class="fi fi-link-external chart-label-icon"></i>
                </a>
                <span *ngIf="!data.coverageInfo.lineCoverageUrl">
                  <strong class="chart-label" data-test-id="sonarqube-coverage-chart-line-rate">Line Rate</strong>
                </span>
              </ngx-gauge-label>
            </ngx-gauge>
          </div>
          <div class="flex flex-col justify-center items-baseline">
            <ngx-gauge
              [size]="120"
              [type]="gaugeType"
              [value]="data.coverageInfo?.branchCoverage | number: '1.0-1'"
              [thresholds]="threshold?.branchRate || thresholds"
              [append]="'%'"
              class="flex flex-col justify-center items-start"
            >
              <ngx-gauge-label>
                <a
                  *ngIf="data.coverageInfo.branchCoverageUrl"
                  href="{{ data.coverageInfo.branchCoverageUrl }}"
                  target="_blank"
                  rel="noopener noreferrer"
                  fusionTooltip="Open in SonarQube"
                >
                  <strong class="chart-label" data-test-id="sonarqube-coverage-chart-branch-rate">Branch Rate</strong>
                  <i class="fi fi-link-external chart-label-icon"></i>
                </a>
                <span *ngIf="!data.coverageInfo.branchCoverageUrl">
                  <strong class="chart-label" data-test-id="sonarqube-coverage-chart-branch-rate">Branch Rate</strong>
                </span>
              </ngx-gauge-label>
            </ngx-gauge>
          </div>
        </div>
        <div class="flex flex-row justify-end items-center" *ngIf="!pipelineRunId">
          <span
            data-test-id="sonarqube-coverage-popup-view-details-link"
            class="open-details"
            (click)="onViewDetailsClicked()"
            fusionFeatureUsageTracker="kpi-group-sonarQube-coverage-view-details"
            [fusionFeatureUsageTrackerEvents]="['mouseover', 'click']"
          >
            View Details
          </span>
        </div>
      </div>
    </div>
  </app-list-item-kpis>
</ng-container>
