<div
  class="status-text-tooltip-container flex flex-row justify-start items-center"
  [fusionTooltip]="optimizedPipelineTooltip"
  [fusionTooltipDisabled]="item.pipelineRunModeId !== pipelineRunModeIds.Optimized_Commit"
>
  <span class="status-circle status-circle-{{ item.complianceStatus.key }}"> </span>
  <span attr.data-test-id="{{ dataTestId }}">{{ item?.complianceStatus.displayKey }}</span>
  <ng-template #optimizedPipelineTooltip>
    <app-compliance-status-tooltip [complianceInformation]="item"></app-compliance-status-tooltip>
  </ng-template>
</div>
