import { createReducer, on } from '@ngrx/store';
import { initialDocumentsSlice } from '../initial-slices';
import * as DocumentsActions from './documents.actions';
import { DocumentsSlice } from '../app-state';

export const documentsReducer = createReducer(
  initialDocumentsSlice,
  on(DocumentsActions.setPipelineRunDocuments, (state, props): DocumentsSlice => {
    const pipelineRunsDetails = { ...state.pipelineRunsDetails };
    pipelineRunsDetails[props.pipelineRunId] = props.documents;
    return { ...state, pipelineRunsDetails: pipelineRunsDetails };
  }),
);
