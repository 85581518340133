import { createReducer, on } from '@ngrx/store';
import { ApiMetadataValidatorSlice } from '../app-state';
import { initialApiMetadataValidatorSlice } from '../initial-slices';
import * as ApiMetadataValidatorActions from './api-metadata-validator.actions';

export const apiMetadataValidatorReducer = createReducer(
  initialApiMetadataValidatorSlice,
  on(ApiMetadataValidatorActions.loadApiMetadataValidatorGroupSummary, (state, _): ApiMetadataValidatorSlice => {
    return { ...state, groupSummary: initialApiMetadataValidatorSlice.groupSummary };
  }),
  on(ApiMetadataValidatorActions.setApiMetadataValidatorGroupSummary, (state, props): ApiMetadataValidatorSlice => {
    return { ...state, groupSummary: props.groupSummary };
  }),
  on(ApiMetadataValidatorActions.setApiMetadataValidatorGroupDetails, (state, props): ApiMetadataValidatorSlice => {
    return { ...state, groupDetails: [...props.groupDetails] };
  }),
  on(ApiMetadataValidatorActions.setApiMetadataValidatorPipelineRunSummary, (state, props): ApiMetadataValidatorSlice => {
    const pipelineRunsSummary = { ...state.pipelineRunsSummary };
    pipelineRunsSummary[props.pipelineRunId] = props.pipelineRunSummary;
    return { ...state, pipelineRunsSummary: pipelineRunsSummary };
  }),
);
