import { createAction, props } from '@ngrx/store';
import { PipelineRunDocument } from '../../../model/documents/pipeline-run-document.model';

export const loadPipelineRunComplianceCertificates = createAction(
  `[Compliance Certificates] Load Compliance Certificates for pipeline run`,
  props<{ pipelineRunId: number }>(),
);

export const setPipelineRunComplianceCertificates = createAction(
  `[Compliance Certificates] Set Compliance Certificates for pipeline run`,
  props<{ pipelineRunId: number; documents: PipelineRunDocument[] }>(),
);

// Errors & NOT FOUND
export const loadPipelineRunComplianceCertificatesError = createAction(
  `[Compliance Certificates] Load Compliance Certificates for pipeline run failed`,
  props<{ pipelineRunId: number }>(),
);

export const loadPipelineRunComplianceCertificatesNotFound = createAction(
  `[Compliance Certificates] Load Compliance Certificates for pipeline run not found`,
  props<{ pipelineRunId: number }>(),
);
