<ng-container
  *ngIf="{
    loading: loading$ | async,
    requirements: requirements$ | async,
    loadingError: loadingError$ | async,
    loadingNotFound: loadingNotFound$ | async,
  } as data"
>
  <app-list-item-kpis [isLoading]="data.loading" [flag]="'kpi.flag.beta' | translate" [title]="'kpi.tile.requirement.title' | translate">
    <div kpi-tile-content class="flex flex-col">
      <span *ngIf="!data.loadingError" class="impressive clickable" (click)="openRequirementDetails(data.requirements)">
        <span data-test-id="requirements-result-text">{{ data.requirements?.length }}</span>
      </span>
      <app-error-information
        *ngIf="data.loadingError"
        errorText="Error loading Requirement information. Please try it again later."
        (reloadClicked)="reloadInfo()"
      >
      </app-error-information>

      <span *ngIf="data.loadingNotFound" class="flex justify-start items-center">
        <span class="status-text-not-available"> No Requirement information available</span>
      </span>
    </div>
    <div kpi-tile-footer *ngIf="!data.loading && !data.loadingError && !data.loadingNotFound">
      <!-- Only show summary when amount has loaded. -->
      <span>{{ 'kpi.tile.requirements.pipeline-run.summary' | translate }}</span>
    </div>
  </app-list-item-kpis>
</ng-container>
