import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CodeCoverageInfo } from '../../model/code-coverage/code-coverage.model';
import { Delivery } from '../../model/group/group';
import { SonarQubeCoverageGroupSummary } from '../../model/sonarqube/sonar-qube.model';
import { ApiMetadataValidatorSummary } from '../../ngrx/model/api-metadata-validator';
import { AtcInfoSummary } from '../../ngrx/model/atc';
import { FosstarsSummaryDetail } from '../../ngrx/model/fosstars';
import { MalwarescanSummary } from '../../ngrx/model/malwarescan';
import { GroupPolicyUsageSummary, PolicyResultsGroupSummary } from '../../ngrx/model/policy-results';
import { selectApiMetadataValidatorGroupSummary } from '../../ngrx/store/api-metadata-validator/api-metadata-validator.selectors';
import { selectAtcGroupSummary } from '../../ngrx/store/atc/atc.selectors';
import { selectCentralPolicyResultsGroupSummary } from '../../ngrx/store/central-policy-results/central-policy-results.selectors';
import { selectCentralPolicyUsageGroupSummary } from '../../ngrx/store/central-policy-usage/central-policy-usage.selectors';
import { selectCodeCoverageGroupSummary } from '../../ngrx/store/code-coverage-info/code-coverage-info.selectors';
import { selectCustomPolicyResultsGroupSummary } from '../../ngrx/store/custom-policy-results/custom-policy-results.selectors';
import { selectCustomPolicyUsageGroupSummary } from '../../ngrx/store/custom-policy-usage/custom-policy-usage.selectors';
import { selectSelectedDelivery } from '../../ngrx/store/deliveries/deliveries.selectors';
import { selectFosstarsGroupSummary } from '../../ngrx/store/fosstars/fosstars.selectors';
import {
  selectIsApiMetadataValidatorGroupSummaryLoading,
  selectIsAtcSummaryLoading,
  selectIsCentralPolicyResultsGroupSummaryLoading,
  selectIsCentralPolicyUsageGroupSummaryLoading,
  selectIsCodeCoverageSummaryLoading,
  selectIsCustomPolicyResultsGroupSummaryLoading,
  selectIsCustomPolicyUsageGroupSummaryLoading,
  selectIsDeliveryMalwarescanSummaryLoading,
  selectIsFosstarsGroupSummaryLoading,
  selectIsSonarQubeCoverageGroupSummaryLoading,
} from '../../ngrx/store/loading-state/loading-state.selectors';
import { selectMalwarescanGroupSummary } from '../../ngrx/store/malwarescan/malwarescan.selectors';
import { selectSonarQubeCoverageGroupSummary } from '../../ngrx/store/sonar-qube-coverage/sonar-qube-coverage.selectors';
import { EnvironmentService } from '../../shared/environment.service';
import { GroupComplianceMode } from '../../shared/model/group-compliance-mode';
import { ProductStandardDeprecationService } from '../../shared/product-standard-deprecation/product-standard-deprecation.service';
import { ProductStandard } from '../../shared/model/product-standard';

@Component({
  selector: 'app-group-compliance-status',
  templateUrl: './group-compliance-status.component.html',
  styleUrls: ['./group-compliance-status.component.scss'],
})
export class GroupComplianceStatusComponent implements OnInit {
  delivery$: Observable<Delivery>;
  selectedDelivery: Delivery;
  fosstarsData$: Observable<FosstarsSummaryDetail>;
  fosstarsLoading$: Observable<boolean>;

  atcLoading$: Observable<boolean>;
  atcData$: Observable<AtcInfoSummary>;
  malwarescanLoading$: Observable<boolean>;
  malwarescanData$: Observable<MalwarescanSummary>;
  apiMetadataValidatorLoading$: Observable<boolean>;
  apiMetadataValidatorData$: Observable<ApiMetadataValidatorSummary>;
  sonarQubeCoverageGroupSummaryIsLoading$: Observable<boolean>;
  sonarQubeCoverageData$: Observable<SonarQubeCoverageGroupSummary>;
  coverageGroupSummaryIsLoading$: Observable<boolean>;
  coverageData$: Observable<CodeCoverageInfo>;
  centralPolicyResultsLoading$: Observable<boolean>;
  centralPolicyResultsData$: Observable<PolicyResultsGroupSummary>;
  customPolicyResultsLoading$: Observable<boolean>;
  customPolicyResultsData$: Observable<PolicyResultsGroupSummary>;
  centralPolicyUsageLoading$: Observable<boolean>;
  centralPolicyUsageData$: Observable<GroupPolicyUsageSummary>;
  customPolicyUsageLoading$: Observable<boolean>;
  customPolicyUsageData$: Observable<GroupPolicyUsageSummary>;

  readOnly: boolean;

  @Input() groupComplianceMode = GroupComplianceMode.LATEST;
  @Input() pipelines: any[];

  constructor(
    public productStandardDeprecationService: ProductStandardDeprecationService,
    private readonly environmentService: EnvironmentService,
    private readonly store$: Store,
  ) {
    this.readOnly = this.environmentService.isReadOnly();
  }

  ngOnInit() {
    this.delivery$ = this.store$.select(selectSelectedDelivery).pipe(
      tap((delivery) => {
        this.selectedDelivery = delivery;
      }),
    );
    this.atcLoading$ = this.store$.select(selectIsAtcSummaryLoading);
    this.atcData$ = this.store$.select(selectAtcGroupSummary);
    this.malwarescanLoading$ = this.store$.select(selectIsDeliveryMalwarescanSummaryLoading);
    this.malwarescanData$ = this.store$.select(selectMalwarescanGroupSummary);
    this.apiMetadataValidatorLoading$ = this.store$.select(selectIsApiMetadataValidatorGroupSummaryLoading);
    this.apiMetadataValidatorData$ = this.store$.select(selectApiMetadataValidatorGroupSummary);
    this.sonarQubeCoverageGroupSummaryIsLoading$ = this.store$.select(selectIsSonarQubeCoverageGroupSummaryLoading);
    this.sonarQubeCoverageData$ = this.store$.select(selectSonarQubeCoverageGroupSummary);
    this.coverageGroupSummaryIsLoading$ = this.store$.select(selectIsCodeCoverageSummaryLoading);
    this.coverageData$ = this.store$.select(selectCodeCoverageGroupSummary);
    this.fosstarsLoading$ = this.store$.select(selectIsFosstarsGroupSummaryLoading);
    this.fosstarsData$ = this.store$.select(selectFosstarsGroupSummary);
    this.centralPolicyResultsLoading$ = this.store$.select(selectIsCentralPolicyResultsGroupSummaryLoading);
    this.centralPolicyResultsData$ = this.store$.select(selectCentralPolicyResultsGroupSummary);
    this.customPolicyResultsLoading$ = this.store$.select(selectIsCustomPolicyResultsGroupSummaryLoading);
    this.customPolicyResultsData$ = this.store$.select(selectCustomPolicyResultsGroupSummary);
    this.centralPolicyUsageLoading$ = this.store$.select(selectIsCentralPolicyUsageGroupSummaryLoading);
    this.centralPolicyUsageData$ = this.store$.select(selectCentralPolicyUsageGroupSummary);
    this.customPolicyUsageLoading$ = this.store$.select(selectIsCustomPolicyUsageGroupSummaryLoading);
    this.customPolicyUsageData$ = this.store$.select(selectCustomPolicyUsageGroupSummary);
  }

  protected readonly ProductStandard = ProductStandard;
}
