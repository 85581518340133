<div class="dialogHeader">
  <p class="dialogTitle">Static Code Checks (FC-1)</p>
  <mat-icon aria-hidden="false" aria-label="cancel" class="closeButton" (click)="closeDialog()" data-test-id="fc-1-detail-popup-close-btn"
    >cancel
  </mat-icon>
</div>

<div
  *ngIf="{
    isLoading: isLoading$ | async,
    isLoadingError: isLoadingError$ | async,
    dataSource: dataSource$ | async,
  } as data"
  class="flex flex-col"
  mat-dialog-content
>
  <div class="flex flex-grow">
    <app-dialog-error-information (reloadClicked)="reload()" *ngIf="data?.isLoadingError"></app-dialog-error-information>
    <kendo-grid
      class="flex flex-grow"
      id="fc1-detail-grid"
      data-test-id="fc-1-detail-popup-grid"
      *ngIf="!data?.isLoadingError"
      [data]="dataSource"
      [loading]="data.isLoading"
      [sortable]="true"
      [sort]="gridState.sort"
      [filterable]="true"
      [filter]="gridState.filter"
      (dataStateChange)="dataStateChanged($event)"
    >
      <kendo-grid-column field="pipelineKey" title="Pipeline" width="44%">
        <ng-template kendoGridCellTemplate kendoTooltip let-dataItem>
          <app-kpi-column-pipeline [item]="dataItem" fusionFeatureUsageTracker="open-fc1-pipeline-url"></app-kpi-column-pipeline>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="sonarQube"
        title="SonarQube"
        width="36%"
        class="text-align-right"
        headerClass="text-align-right"
        [filterable]="false"
        [sortable]="false"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div *ngIf="dataItem.complianceInformationAvailable && dataItem.sonarQube" class="flex flex-col gap-3">
            <div
              class="severity-indicator flex flex-row justify-end items-center"
              [ngClass]="{ clickable: dataItem.sonarQube.blockerUrl }"
              (click)="openSonarIssuesInNewTab(dataItem.sonarQube.blockerUrl)"
            >
              <span class="flex flex-grow"></span>
              <mat-icon svgIcon="sonar_blocker"></mat-icon>
              <span>{{ dataItem.sonarQube.blocker }} Blocker</span>
              <fusion-icon *ngIf="dataItem.sonarQube.blockerUrl" name="link-external" size="S"></fusion-icon>
            </div>
            <div
              class="severity-indicator flex flex-row justify-end items-center"
              [ngClass]="{ clickable: dataItem.sonarQube.criticalUrl }"
              (click)="openSonarIssuesInNewTab(dataItem.sonarQube.criticalUrl)"
            >
              <span class="flex flex-grow"></span>
              <mat-icon svgIcon="sonar_critical"></mat-icon>
              <span>{{ dataItem.sonarQube.critical }} Critical</span>
              <fusion-icon *ngIf="dataItem.sonarQube.criticalUrl" name="link-external" size="S"></fusion-icon>
            </div>
            <div
              class="severity-indicator flex flex-row justify-end items-center"
              [ngClass]="{ clickable: dataItem.sonarQube.majorUrl }"
              (click)="openSonarIssuesInNewTab(dataItem.sonarQube.majorUrl)"
            >
              <span class="flex flex-grow"></span>
              <mat-icon svgIcon="sonar_major"></mat-icon>
              <span>{{ dataItem.sonarQube.major }} Major</span>
              <fusion-icon *ngIf="dataItem.sonarQube.majorUrl" name="link-external" size="S"></fusion-icon>
            </div>
          </div>
          <div *ngIf="!dataItem.complianceInformationAvailable || !dataItem.sonarQube">&mdash;</div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column [hidden]="!hasAtc" field="atc" title="ATC" width="10%" class="text-align-right" headerClass="text-align-right">
      </kendo-grid-column>

      <kendo-grid-column field="complianceStatus.displayKey" title="Status" width="20%">
        <ng-template kendoGridCellTemplate kendoTooltip let-dataItem>
          <app-kpi-column-compliance-status [item]="dataItem"></app-kpi-column-compliance-status>
        </ng-template>
      </kendo-grid-column>

      <ng-template kendoGridLoadingTemplate>
        <app-table-loading-indicator></app-table-loading-indicator>
      </ng-template>
    </kendo-grid>
  </div>
</div>
