<ng-container
  *ngIf="{
    loading: isLoading$ | async,
    policyResults: policyResultsSummary$ | async,
    loadingError: isLoadingError$ | async,
  } as data"
>
  <app-list-item-kpis [isLoading]="data.loading" [minimized]="true" [title]="policyResultTileTitleRef | translate">
    <div class="flex flex-row justify-between items-end w-full" kpi-tile-content>
      <span
        *ngIf="!data.loadingError"
        [ngClass]="
          data.policyResults?.policyCompliantAmount !== data.policyResults?.policyTotalAmount
            ? 'status-text-NOT_COMPLIANT'
            : 'status-text-COMPLIANT'
        "
        class="bold font-size-18"
        data-test-id="policy-results-summary-text-pipeline"
      >
        {{ data.policyResults?.policyCompliantAmount }} of {{ data.policyResults?.policyTotalAmount }} Compliant
      </span>
      <span
        (click)="openDetailsDialog()"
        [fusionFeatureUsageTrackerEvents]="['click']"
        class="open-details clickable"
        attr.data-test-id="{{ dataTestIdForDetailsButton }}"
        attr.fusionFeatureUsageTracker="{{ fusionFeatureTrackerForOpenDetailsDialog }}"
        fusionTooltip="Open Details"
        fusionTooltipPosition="bottom-right"
        >View Details</span
      >
    </div>
  </app-list-item-kpis>
</ng-container>
