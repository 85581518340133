<mat-chip class="mat-chip-small {{ statusType }} {{ status.key }}" [disableRipple]="true" [disabled]="true">
  <div class="flex flex-row gap-1 justify-start items-center">
    <span class="text-overflow" fusionTooltip="{{ showTooltip ? (toolTip | translate) : null }}">{{ status.displayKey }}</span>
    <fusion-icon
      *ngIf="showStatusDate && statusDate"
      name="clock-outline"
      [fusionTooltip]="'since ' + (statusDate | date: 'MMM d, y hh:mm:ss a')"
      size="S"
      data-test-id="pipeline-run-status-date"
      fusionFeatureUsageTracker="headerInfo-pipelineRunExecutionStatus-date-icon"
      [fusionFeatureUsageTrackerEvents]="['mouseover']"
    ></fusion-icon>
  </div>
</mat-chip>
