<div class="dialogHeader">
  <p class="dialogTitle" [innerHTML]="'kpi.tile.fosstar.details.title' | translate"></p>
  <mat-icon aria-hidden="false" aria-label="cancel" class="closeButton" (click)="closeDialog()">cancel</mat-icon>
</div>
<div
  *ngIf="{
    isLoading: isLoading$ | async,
    isLoadingError: isLoadingError$ | async,
    dataSource: dataSource$ | async,
  } as data"
  mat-dialog-content
  class="flex flex-col"
>
  <div class="flex h-full w-full">
    <kendo-grid
      id="fosstar-detail-list-grid"
      class="fosstar-detail-list flex h-full w-full"
      [data]="dataSource"
      [loading]="data.isLoading"
      [sortable]="true"
      [sort]="gridState.sort"
      [filterable]="true"
      [filter]="gridState.filter"
      (dataStateChange)="dataStateChanged($event)"
    >
      <kendo-grid-column field="artifact" title="Artifact">
        <ng-template kendoGridCellTemplate let-dataItem>
          <a
            *ngIf="dataItem.ratingUrl"
            target="_blank"
            class="link flex flex-row justify-start items-center"
            [href]="dataItem.ratingUrl"
            fusionFeatureUsageTracker="open-artifact-detail"
            [fusionFeatureUsageTrackerEvents]="['click']"
            rel="noopener noreferrer"
          >
            <span [fusionTooltip]="'Open Artifact'" fusionTooltipPosition="bottom-right"
              >{{ dataItem.artifact }}
              <fusion-icon id="artifactLink" name="link-external" size="S"></fusion-icon>
            </span>
          </a>
          <span *ngIf="!dataItem.ratingUrl">{{ dataItem.artifact }}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="rating" title="Rating" width="200px">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span *ngIf="dataItem.rating" class="label">{{ dataItem.rating }}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="pipelineKey" title="Pipeline">
        <ng-template kendoGridCellTemplate let-dataItem>
          <a
            *ngIf="dataItem.buildUrl"
            target="_blank"
            class="link flex flex-row justify-start items-center"
            [href]="dataItem.buildUrl"
            fusionFeatureUsageTracker="open-pipeline"
            [fusionFeatureUsageTrackerEvents]="['click']"
            rel="noopener noreferrer"
          >
            <span [fusionTooltip]="'Open Pipeline'" fusionTooltipPosition="bottom-right"
              >{{ dataItem.pipelineKey | pipelineKeyWithoutTimestamp }}
              <fusion-icon name="link-external" size="S"></fusion-icon>
            </span>
          </a>
          <span *ngIf="!dataItem.buildUrl">{{ dataItem.pipelineKey | pipelineKeyWithoutTimestamp }}</span>
        </ng-template>
      </kendo-grid-column>
      <ng-template kendoGridNoRecordsTemplate>No details for FOSStars available.</ng-template>
    </kendo-grid>
  </div>
</div>
