<div class="tile-container flex flex-col justify-between" [class.tile-container-minimized]="minimized">
  <div class="flex flex-row justify-start items-start gap-0.5">
    <span [innerHTML]="safeTitle" class="title"></span>
    <span class="flex flex-grow"></span>
    <span *ngIf="flag">
      <mat-chip [disabled]="true" [disableRipple]="true"
        >{{ flag }}
        <app-deprecation-popover
          class="pointer-events-activated"
          *ngIf="isDeprecationAvailable"
          [anchorValue]="deprecationAnchor"
        ></app-deprecation-popover>
      </mat-chip>
    </span>
  </div>
  <div class="tile-content flex flex-grow justify-start items-end">
    <ng-content *ngIf="!isLoading" select="[kpi-tile-content]"></ng-content>
  </div>
  <app-loading *ngIf="isLoading" [showLoadingText]="false"></app-loading>
  <div class="tile-footer flex">
    <ng-content select="[kpi-tile-footer]"></ng-content>
  </div>
</div>
