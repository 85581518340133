import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, firstValueFrom } from 'rxjs';
import { Delivery, Group } from '../model/group/group';
import { ReleaseStatus, ReleaseStatusKey } from '../model/release-status/release-status';
import { selectSiriusDelivery } from '../ngrx/store/deliveries/deliveries.selectors';
import {
  revertReleaseStatus,
  revertReleaseStatusIfPossible,
  setNewReleaseStatus,
  setNewReleaseStatusIfPossible,
} from '../ngrx/store/pipeline-run/pipeline-run.actions';
import { ChangePipelineRunReleaseStatusDialogComponent } from './change-pipeline-run-release-status-dialog.component';
import { selectDisplayedMicrodeliveries } from '../ngrx/store/microdeliveries/microdeliveries.selectors';

export interface DialogData {
  group: Group;
}

export interface PipelineRunReleaseStatusInGroup {
  pipelineRunId: number;
  pipelineRunKey: string;
  startDateTime: Date;
  latestReleaseStatusKeyInGroup: ReleaseStatus;
  group: Delivery;
  pipelineId: string;
}

@Injectable({
  providedIn: 'root',
})
export class ChangePipelineRunReleaseStatusDialogService {
  dialogClosed$: Observable<any>;

  constructor(
    private readonly matDialog: MatDialog,
    private readonly store$: Store,
  ) {}

  openDialog(options: any) {
    this.dialogClosed$ = this.matDialog.open(ChangePipelineRunReleaseStatusDialogComponent, options).afterClosed();
  }

  async revertReleaseState(
    pipelineRunId: number,
    pipelineRunKey: string,
    pipelineId: string,
    group: Delivery,
    releaseStatus: ReleaseStatusKey,
    justification?: string,
  ) {
    if (releaseStatus === ReleaseStatusKey.RELEASED && group.isMicrodelivery) {
      const siriusDelivery = await firstValueFrom(this.store$.select(selectSiriusDelivery));
      const microdeliveries = await firstValueFrom(this.store$.select(selectDisplayedMicrodeliveries));
      // remove the current microdelivery from the list, as its release status will be reverted later.
      const microdeliveryIds = Array.from(microdeliveries.values())
        .map((md) => md.group.id)
        .filter((groupId) => groupId !== group.id);

      this.store$.dispatch(
        revertReleaseStatusIfPossible({
          pipelineRunId: pipelineRunId,
          pipelineRunKey: pipelineRunKey,
          pipelineId: pipelineId,
          groupId: siriusDelivery.id,
          groupKey: siriusDelivery.key,
          groupName: siriusDelivery.name,
          isMicrodelivery: siriusDelivery.isMicrodelivery,
          microdeliveryIds: microdeliveryIds,
          releaseStatus: releaseStatus,
          justification: justification,
        }),
      );
    }

    this.store$.dispatch(
      revertReleaseStatus({
        pipelineRunId: pipelineRunId,
        pipelineRunKey: pipelineRunKey,
        pipelineId: pipelineId,
        groupId: group.id,
        groupKey: group.key,
        groupName: group.name,
        isMicrodelivery: group.isMicrodelivery,
        releaseStatus: releaseStatus,
        justification: justification,
      }),
    );
  }

  isPipelineRunSpecificReleaseState(pipelineRunReleaseStatusKey: ReleaseStatusKey, releaseStatusKey: ReleaseStatusKey): boolean {
    return pipelineRunReleaseStatusKey == releaseStatusKey;
  }

  async setNewReleaseState(
    pipelineRunId: number,
    pipelineRunKey: string,
    pipelineId: string,
    group: Delivery,
    newReleaseStatus: ReleaseStatusKey,
  ) {
    if (newReleaseStatus === ReleaseStatusKey.RELEASED && group.isMicrodelivery) {
      const siriusDelivery = await firstValueFrom(this.store$.select(selectSiriusDelivery));
      this.store$.dispatch(
        setNewReleaseStatusIfPossible({
          pipelineRunId: pipelineRunId,
          pipelineRunKey: pipelineRunKey,
          pipelineId: pipelineId,
          groupId: siriusDelivery.id,
          groupKey: siriusDelivery.key,
          groupName: siriusDelivery.name,
          isMicrodelivery: group.isMicrodelivery,
          releaseStatus: newReleaseStatus,
        }),
      );
    }

    this.store$.dispatch(
      setNewReleaseStatus({
        pipelineRunId: pipelineRunId,
        pipelineRunKey: pipelineRunKey,
        pipelineId: pipelineId,
        groupId: group.id,
        groupKey: group.key,
        groupName: group.name,
        isMicrodelivery: group.isMicrodelivery,
        releaseStatus: newReleaseStatus,
      }),
    );
  }
}
