import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnimationDirection, AnimationType } from '@progress/kendo-angular-popup';
import { PopoverAnimation, PopoverComponent, PopoverFn } from '@progress/kendo-angular-tooltip';
import { ProductStandardDeprecationService } from '../shared/product-standard-deprecation/product-standard-deprecation.service';

@Component({
  selector: 'app-deprecation-popover',
  templateUrl: './deprecation-popover.component.html',
  styleUrls: ['./deprecation-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeprecationPopoverComponent implements OnInit {
  @Input() public anchorValue = 'default';

  @ViewChild(PopoverComponent, { static: false })
  public popoverRef: PopoverComponent;

  public popoverCallback: PopoverFn = (anchor: HTMLElement) => {
    return this.popoverRef;
  };

  public enabled = true;
  public type: AnimationType = 'zoom';
  public direction: AnimationDirection = 'right';
  public duration = 400;
  public deprecationAndVisualisationDates: Record<string, string>;

  constructor(
    private readonly translateService: TranslateService,
    private readonly productStandardDeprecationService: ProductStandardDeprecationService,
  ) {}

  ngOnInit(): void {
    this.deprecationAndVisualisationDates = this.productStandardDeprecationService.getFormattedDeprecationAndVisualisationDates();
  }

  public get myAnimation(): PopoverAnimation {
    if (this.enabled) {
      return {
        type: this.type,
        direction: this.direction,
        duration: this.duration,
      };
    }

    return false;
  }

  hasTranslation(key: string): boolean {
    return this.translateService.instant(key) !== key;
  }
}
