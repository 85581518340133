import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TestRegressionSlice } from '../app-state';

export const selectTestRegressionSlice = createFeatureSelector<TestRegressionSlice>('testRegressionSlice');

export const selectPipelineRequirements = (pipelineId: string) =>
  createSelector(selectTestRegressionSlice, (testRegressionSlice) => {
    return testRegressionSlice.pipelineRequirements[pipelineId];
  });

export const selectPipelineRunRegressionTests = (pipelineRunId: number) =>
  createSelector(selectTestRegressionSlice, (testRegressionSlice) => {
    return testRegressionSlice.pipelineRunRegressionTests[pipelineRunId];
  });
