<div id="requirementsContainer" class="flex flex-col">
  <div
    *ngIf="(showLightweightTestExport || showExcelExport) && showDetail"
    class="traceability-controls flex flex-row justify-between items-center"
  >
    <div class="flex flex-grow"></div>
    <app-excel-export *ngIf="showExcelExport" [grid]="grid" [loading]="exportingExcel"></app-excel-export>
  </div>
  <div class="flex h-full w-full">
    <kendo-grid
      id="requirement-detail-list-grid"
      #grid
      (dataStateChange)="gridDataStateChange($event)"
      [data]="dataSource"
      [sort]="gridState.sort"
      [sortable]="true"
      [filter]="gridState.filter"
      [filterable]="true"
      (excelExport)="onExcelExport($event)"
      class="requirement-detail-list flex h-full w-full"
      data-test-id="fc-2-requirement-detail-list-grid"
      [resizable]="true"
    >
      <kendo-grid-column field="key" title="Requirement" width="20%">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="flex flex-col">
            <a
              *ngIf="dataItem.link"
              target="_blank"
              class="link"
              [href]="dataItem.link"
              fusionFeatureUsageTracker="requirements-detail-dialog-view-requirement-in-jira"
              rel="noopener noreferrer"
            >
              <span fusionTooltip="Open Issue" fusionTooltipPosition="bottom-right">
                {{ dataItem.key }} <fusion-icon id="jiraRequirement" [name]="'link-external'" size="S"></fusion-icon> </span
            ></a>
            <span *ngIf="!dataItem.link">{{ dataItem.key }}</span>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="status.name" title="Requirement Status" width="20%">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div>
            <span *ngIf="showDetail" [ngClass]="getStatusClasses(dataItem.status.name)">{{ dataItem.status.name ?? '&mdash;' }}</span>
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="summary" title="Requirement Summary" width="20%" [hidden]="!showDetail">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.summary ?? '&mdash;' }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="testStatus" title="Traceability Status" width="20%" [hidden]="!showDetail">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="flex flex-row">
            <span class="dot test-status {{ getTestStatusClass(dataItem.testStatus) }}"></span>
            <span style="margin-left: 5px">{{ dataItem.testStatus }}</span>
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column [filterable]="false" [sortable]="false" width="10%" [hidden]="!showDetail">
        <ng-template kendoGridCellTemplate let-dataItem>
          <a
            *ngIf="dataItem.testStatus !== 'No tests'"
            data-test-id="fc-2-requirement-details-popup-view-details-link"
            class="flex flex-row justify-end items-center"
            (click)="requirementSelect.emit(dataItem)"
            fusionFeatureUsageTracker="requirements-detail-dialog-view-details"
          >
            <span>View Details</span>
            <fusion-icon class="forwardButton" name="chevron-right" size="XL"></fusion-icon>
          </a>
        </ng-template>
      </kendo-grid-column>

      <ng-template kendoGridNoRecordsTemplate> No Requirement details available.</ng-template>
      <kendo-grid-excel></kendo-grid-excel>
    </kendo-grid>
  </div>
</div>
