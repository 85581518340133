<a
  *ngIf="!!item.buildUrl || !!item.pipelineUrl"
  target="_blank"
  class="link"
  [href]="item.buildUrl || item.pipelineUrl"
  fusionTooltip="Open Pipeline"
  fusionTooltipPosition="bottom-right"
  [fusionFeatureUsageTracker]="fusionFeatureUsageTracker"
  [fusionFeatureUsageTrackerEvents]="['click']"
  rel="noopener noreferrer"
>
  <span class="link-text">{{ item.pipelineKey | pipelineKeyWithoutTimestamp }}</span>
  <fusion-icon name="link-external" size="S"></fusion-icon>
</a>
<span *ngIf="!item.buildUrl && !item.pipelineUrl" fusionTooltip="No Url available" fusionTooltipPosition="bottom-right">
  {{ item.pipelineKey | pipelineKeyWithoutTimestamp }}
</span>
