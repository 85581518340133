import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Fc3Slice } from '../app-state';

// First selector should be a featureSelector
export const selectFc3Slice = createFeatureSelector<Fc3Slice>('fc3Slice');

export const selectFc3GroupSummary = createSelector(selectFc3Slice, (fc3Slice) => {
  return fc3Slice.aggregationSummary;
});
export const selectFc3GroupDetails = createSelector(selectFc3Slice, (fc3Slice) => {
  return fc3Slice.aggregationDetails;
});
export const selectFc3SingleDetails = createSelector(selectFc3Slice, (fc3Slice) => {
  return fc3Slice.selectedPipelineRuns;
});
export const selectFc3SingleDetailById = (pipelineRunId: number) =>
  createSelector(selectFc3Slice, (fc3Slice: Fc3Slice) => {
    return fc3Slice.selectedPipelineRuns[pipelineRunId];
  });
