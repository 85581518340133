import { createReducer, on } from '@ngrx/store';
import { Slc25Slice } from '../app-state';
import { initialSlc25Slice } from '../initial-slices';
import * as Slc25Actions from './slc-25.actions';

export const slc25Reducer = createReducer(
  initialSlc25Slice,
  on(Slc25Actions.loadSlc25GroupSummary, (state, _): Slc25Slice => {
    return { ...state, aggregationSummary: initialSlc25Slice.aggregationSummary };
  }),
  on(Slc25Actions.setSlc25GroupSummary, (state, props): Slc25Slice => {
    return { ...state, aggregationSummary: props.groupSummary };
  }),
  on(Slc25Actions.setSlc25GroupDetails, (state, props): Slc25Slice => {
    return { ...state, aggregationDetails: [...props.groupDetails] };
  }),
  on(Slc25Actions.setSlc25SingleDetails, (state, props): Slc25Slice => {
    const selectedPipelineRuns = { ...state.selectedPipelineRuns };
    selectedPipelineRuns[props.pipelineRunId] = props.singleDetails;
    return { ...state, selectedPipelineRuns };
  }),
);
