import { createAction, props } from '@ngrx/store';
import { GroupComplianceMode } from '../../../shared/model/group-compliance-mode';
import { AtcInfoSummary } from '../../model/atc';

export const loadAtcGroupSummary = createAction(
  `[ATC] Trigger loading of ATC information for group-summary from backend`,
  props<{ deliveryId: string; groupComplianceMode: GroupComplianceMode }>(),
);

export const setAtcGroupSummary = createAction(
  `[ATC] Set ATC information for group-summary from backend in store`,
  props<{ groupSummary: AtcInfoSummary }>(),
);

export const loadPipelineRunATCInformation = createAction(
  `[ATC] Trigger loading of ATC information for single-details from backend`,
  props<{ pipelineRunId: number }>(),
);
export const setPipelineRunATCInformation = createAction(
  `[ATC] Set ATC information for single-details from backend in store`,
  props<{ pipelineRunId: number; pipelineRunAtcInformation: AtcInfoSummary }>(),
);

// errors - NOT FOUND
export const loadAtcGroupSummaryError = createAction(`[ATC] Loading of ATC Group Summary error`, props<{ deliveryId: string }>());

export const loadAtcGroupSummaryNotFound = createAction(`[ATC] Loading of ATC Group Summary not found`, props<{ deliveryId: string }>());

export const loadAtcGroupDetailsError = createAction(`[ATC] Loading of ATC Group Details error`, props<{ deliveryId: string }>());

export const loadAtcPipelineRunError = createAction(
  `[ATC] Loading of ATC Pipeline Run Information error`,
  props<{ pipelineRunId: number }>(),
);

export const loadAtcPipelineRunNotFound = createAction(
  `[ATC] Loading of ATC Pipeline Run Information not found`,
  props<{ pipelineRunId: number }>(),
);
