import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ComplianceInfoDetails, ComplianceInfoSummary, ComplianceStatus } from '../../ngrx/model/compliance-information';
import { loadAllowedScmsList } from '../../ngrx/store/allowed-scms/allowed-scms.actions';

import {
  selectIsSLC25GroupSummaryLoading,
  selectIsSLC25GroupSummaryLoadingError,
  selectIsSLC25GroupSummaryLoadingNotFound,
  selectIsSLC25SingleLoading,
  selectIsSLC25SingleLoadingError,
  selectIsSLC25SingleLoadingNotFound,
} from '../../ngrx/store/loading-state/loading-state.selectors';
import { loadSlc25GroupDetails, loadSlc25GroupSummary, loadSlc25SingleDetails } from '../../ngrx/store/slc-25/slc-25.actions';
import { selectSlc25GroupSummary, selectSlc25SingleDetailById } from '../../ngrx/store/slc-25/slc-25.selectors';
import { GroupComplianceMode } from '../../shared/model/group-compliance-mode';
import { Slc25AllowedListDialogComponent } from './slc25-allowed-list-dialog/slc25-allowed-list-dialog.component';
import { Slc25DetailsDialogComponent } from './slc25-details-dialog/slc25-details-dialog.component';

@Component({
  selector: 'app-list-item-kpis-slc25',
  templateUrl: './list-item-kpis-slc25.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemKpisSlc25Component implements OnInit, OnChanges {
  public ComplianceStatus = ComplianceStatus;

  @Input() groupId: string;
  @Input() groupComplianceMode: GroupComplianceMode;
  @Input() currentPipelineRunId: number;

  public slc25complianceInfo$!: Observable<ComplianceInfoDetails | ComplianceInfoSummary>;
  public isLoading$!: Observable<boolean>;
  public isLoadingError$!: Observable<boolean>;
  public isLoadingNotFound$!: Observable<boolean>;

  constructor(
    private readonly store$: Store,
    private readonly dialog: MatDialog,
  ) {}

  ngOnInit() {
    if (!this.currentPipelineRunId) {
      this.isLoading$ = this.store$.select(selectIsSLC25GroupSummaryLoading);
      this.isLoadingError$ = this.store$.select(selectIsSLC25GroupSummaryLoadingError);
      this.isLoadingNotFound$ = this.store$.select(selectIsSLC25GroupSummaryLoadingNotFound);
      this.slc25complianceInfo$ = this.store$.select(selectSlc25GroupSummary);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentPipelineRunId) {
      this.loadPipelineRunInfo();
    }
  }

  loadPipelineRunInfo() {
    const pipelineRunId = this.currentPipelineRunId;
    this.slc25complianceInfo$ = this.store$.select(selectSlc25SingleDetailById(pipelineRunId));
    this.isLoading$ = this.store$.select(selectIsSLC25SingleLoading(pipelineRunId));
    this.isLoadingError$ = this.store$.select(selectIsSLC25SingleLoadingError(pipelineRunId));
    this.isLoadingNotFound$ = this.store$.select(selectIsSLC25SingleLoadingNotFound(pipelineRunId));
  }

  reloadData() {
    if (!this.currentPipelineRunId) {
      this.store$.dispatch(loadSlc25GroupSummary({ deliveryId: this.groupId, groupComplianceMode: this.groupComplianceMode }));
    } else {
      this.store$.dispatch(loadSlc25SingleDetails({ pipelineRunId: this.currentPipelineRunId }));
    }
  }

  public onSummaryStatusClicked(status: ComplianceInfoDetails): void {
    if (!this.currentPipelineRunId) {
      const data = {
        deliveryId: this.groupId,
        groupComplianceMode: this.groupComplianceMode,
      };
      this.store$.dispatch(loadSlc25GroupDetails(data));
      this.dialog.open(Slc25DetailsDialogComponent, { data });
    } else {
      window.open(status.scmsUrl, '_blank', 'noopener,noreferrer');
    }
  }

  // Can be moved to the compliance label component, if we want to only access links
  isValidUrl(url?: string) {
    try {
      new URL(url);
      return true;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      return false;
    }
  }

  async openListOfAllowedSCMs() {
    this.store$.dispatch(loadAllowedScmsList());
    this.dialog.open(Slc25AllowedListDialogComponent);
  }
}
