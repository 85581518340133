<div class="dialogHeader">
  <fusion-icon
    *ngIf="isSelected"
    class="backButton"
    name="chevron-left"
    size="XL"
    (click)="requirementSelected(null)"
    data-test-id="fc-2-requirement-details-popup-back-btn"
    fusionFeatureUsageTracker="requirements-detail-dialog-back"
  >
  </fusion-icon>
  <p class="dialogTitle">{{ isSelected ? 'Detailed test information for ' + selectedRequirement.key : getDialogTitle() }}</p>
  <mat-icon
    aria-hidden="false"
    aria-label="cancel"
    class="closeButton"
    (click)="closeDialog()"
    data-test-id="fc-2-requirement-detail-popup-close-btn"
    >cancel
  </mat-icon>
</div>

<div class="tabContainer flex flex-row">
  <!-- hide the component instead of using ngIf to keep the filter state (only master, not detail page) -->
  <app-requirements
    [requirementDetails]="dialogData.requirementDetails"
    class="w-full"
    [ngClass]="{ hidden: isSelected }"
    [readOnly]="dialogData.readOnly"
    [showDetail]="dialogData.showDetail"
    [showLightweightTestExport]="dialogData.showDetail && !dialogData.isRegression"
    (requirementSelect)="requirementSelected($event)"
  ></app-requirements>
  <app-tests
    *ngIf="isSelected"
    class="w-full"
    [requirement]="selectedRequirement"
    [isPipelineRunView]="dialogData.isPipelineRunView"
  ></app-tests>
</div>
