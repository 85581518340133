import { initialAllowedScmsSlice } from '../initial-slices';
import { createReducer, on } from '@ngrx/store';
import * as AllowedScmsActions from './allowed-scms.actions';
import { AllowedScmsSlice } from '../app-state';

export const allowedScmsReducer = createReducer(
  initialAllowedScmsSlice,
  on(AllowedScmsActions.setAllowedScms, (state, props): AllowedScmsSlice => {
    return { ...state, allowedScms: props.allowedScms };
  }),
  on(AllowedScmsActions.loadFailedAllowedScms, (state, props): AllowedScmsSlice => {
    // Do error handling here.
    return state;
  }),
);
