import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReleaseStatusKey } from '../../model/release-status/release-status';
import {
  ChangePipelineRunReleaseStatusDialogService,
  PipelineRunReleaseStatusInGroup,
} from '../change-pipeline-run-release-status-dialog.service';

@Component({
  selector: 'app-revert-confirmation-dialog',
  templateUrl: './revert-confirmation-dialog.component.html',
  styleUrls: ['./revert-confirmation-dialog.component.scss'],
})
export class RevertConfirmationDialogComponent {
  ReleaseStatus = ReleaseStatusKey;
  @Input() public data: PipelineRunReleaseStatusInGroup;
  @Output() public closeDialog = new EventEmitter<boolean>();
  @Output() public cancelDialog = new EventEmitter<boolean>();

  commentValue = '';
  maxCommentLength = 256;
  constructor(public changePipelineRunReleaseStatusService: ChangePipelineRunReleaseStatusDialogService) {}

  handleRevertReleaseStatus(revertedReleaseStatus: ReleaseStatusKey) {
    if (revertedReleaseStatus === ReleaseStatusKey.RELEASED && this.commentValue) {
      this.changePipelineRunReleaseStatusService.revertReleaseState(
        this.data?.pipelineRunId,
        this.data?.pipelineRunKey,
        this.data?.pipelineId,
        this.data?.group,
        revertedReleaseStatus,
        this.commentValue,
      );
      this.closeDialog.emit(true);
    }
  }

  triggerCancelDialog() {
    this.cancelDialog.emit(true);
  }
}
