<div class="dialogHeader">
  <p class="dialogTitle">{{ policyDialogTitle }}</p>
  <mat-icon
    (click)="closeDialog()"
    aria-hidden="false"
    aria-label="cancel"
    class="closeButton"
    attr.data-test-id="{{ dataTestIdForCloseButton }}"
    >cancel
  </mat-icon>
</div>

<app-policy-results-details
  *ngIf="!isCustomPolicyResults"
  [pipelineRunId]="pipelineRunId"
  (policyAmount)="policyAmountChanged($event)"
></app-policy-results-details>
<app-custom-policy-results-details
  *ngIf="isCustomPolicyResults"
  [pipelineRunId]="pipelineRunId"
  (policyAmount)="policyAmountChanged($event)"
></app-custom-policy-results-details>
