import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Alert, AlertService } from './alert.service';
import { Animations } from '../animations';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  animations: [Animations.fade],
})
export class AlertComponent implements OnInit {
  public alerts$: Observable<Alert[]>;

  constructor(private readonly alertService: AlertService) {}

  public ngOnInit() {
    this.alerts$ = this.alertService.alerts$;
  }
}
