import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PolicyResultsPipelineRunSummary } from '../../ngrx/model/policy-results';
import { loadCentralPolicyResultsSingleDetails } from '../../ngrx/store/central-policy-results/central-policy-results.actions';
import { selectCentralPolicyResultsSingleSummaryById } from '../../ngrx/store/central-policy-results/central-policy-results.selectors';
import { loadCustomPolicyResultsSingleDetails } from '../../ngrx/store/custom-policy-results/custom-policy-results.actions';
import { selectCustomPolicyResultsSingleSummaryById } from '../../ngrx/store/custom-policy-results/custom-policy-results.selectors';
import {
  selectIsCentralPolicyResultsPipelineRunSummaryLoading,
  selectIsCentralPolicyResultsPipelineRunSummaryLoadingError,
  selectIsCentralPolicyResultsPipelineRunSummaryLoadingNotFound,
  selectIsCustomPolicyResultsPipelineRunSummaryLoading,
  selectIsCustomPolicyResultsPipelineRunSummaryLoadingError,
  selectIsCustomPolicyResultsPipelineRunSummaryLoadingNotFound,
} from '../../ngrx/store/loading-state/loading-state.selectors';
import { ListItemKpisPolicyResultsDialogComponent } from './list-item-kpis-policy-results-dialog/list-item-kpis-policy-results-dialog.component';

@Component({
  selector: 'app-list-item-kpis-policy-results',
  templateUrl: './list-item-kpis-policy-results.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemKpisPolicyResultsComponent implements OnChanges {
  @Input() currentPipelineRunId: number;
  @Input() isCustomPolicyResults = false;
  public policyResultsSummary$!: Observable<PolicyResultsPipelineRunSummary>;
  public isLoading$!: Observable<boolean>;
  public isLoadingError$!: Observable<boolean>;
  public isLoadingNotFound$!: Observable<boolean>;
  public policyResultTileTitleRef: string;
  public dataTestIdForDetailsButton: string;
  public fusionFeatureTrackerForOpenDetailsDialog: string;

  constructor(
    private readonly store$: Store,
    private readonly dialog: MatDialog,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentPipelineRunId) {
      this.loadPipelineRunInfo();
    }
  }

  loadPipelineRunInfo() {
    const pipelineRunId = this.currentPipelineRunId;
    if (this.isCustomPolicyResults) {
      this.policyResultsSummary$ = this.store$.select(selectCustomPolicyResultsSingleSummaryById(pipelineRunId));
      this.isLoading$ = this.store$.select(selectIsCustomPolicyResultsPipelineRunSummaryLoading(pipelineRunId));
      this.isLoadingError$ = this.store$.select(selectIsCustomPolicyResultsPipelineRunSummaryLoadingError(pipelineRunId));
      this.isLoadingNotFound$ = this.store$.select(selectIsCustomPolicyResultsPipelineRunSummaryLoadingNotFound(pipelineRunId));
      this.dataTestIdForDetailsButton = 'custom-policy-results-popup-view-details-link';
      this.fusionFeatureTrackerForOpenDetailsDialog = 'kpi-open-custom-policy-results-details';
      this.policyResultTileTitleRef = 'kpi.tile.custom.policy.results.title';
    } else {
      this.policyResultsSummary$ = this.store$.select(selectCentralPolicyResultsSingleSummaryById(pipelineRunId));
      this.isLoading$ = this.store$.select(selectIsCentralPolicyResultsPipelineRunSummaryLoading(pipelineRunId));
      this.isLoadingError$ = this.store$.select(selectIsCentralPolicyResultsPipelineRunSummaryLoadingError(pipelineRunId));
      this.isLoadingNotFound$ = this.store$.select(selectIsCentralPolicyResultsPipelineRunSummaryLoadingNotFound(pipelineRunId));
      this.dataTestIdForDetailsButton = 'policy-results-popup-view-details-link';
      this.fusionFeatureTrackerForOpenDetailsDialog = 'kpi-open-policy-results-details';
      this.policyResultTileTitleRef = 'kpi.tile.policy.results.title';
    }
  }

  public openDetailsDialog(): void {
    const pipelineRun = { pipelineRunId: this.currentPipelineRunId };
    if (this.isCustomPolicyResults) {
      this.store$.dispatch(loadCustomPolicyResultsSingleDetails(pipelineRun));
    } else {
      this.store$.dispatch(loadCentralPolicyResultsSingleDetails(pipelineRun));
    }
    const dialogData = { pipelineRunId: this.currentPipelineRunId, isCustomPolicyResults: this.isCustomPolicyResults };
    this.dialog.open(ListItemKpisPolicyResultsDialogComponent, { data: dialogData });
  }
}
