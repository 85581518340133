export class PipelineRunDocument {
  fileName: string;
  fileUploadedAt: Date;
  isImmutable: boolean;
  message: string;
  pipelineRunId: number;
  pipelineRunKey: string;
  pipelineRunMode: string;
  pipelineRunModeId: number;
  pipelineRunStartDate: Date;
  rootPath: string;
  setImmutableAt: Date;
  setImmutableByProductStandards: string[];
  setImmutableByReleaseStatus: string;
  status: number;
  stepResultId: number;
  stepResultTypeId: number;
  stepResultTypeName: string;
  subPath: string;
  isUnprocessed?: boolean;

  static fromUnprocessedFile(filePath): PipelineRunDocument {
    const unprocessedDocument = new PipelineRunDocument();
    const splitFilePath = filePath.split('/');
    unprocessedDocument.fileName = splitFilePath.slice(splitFilePath.length - 2, splitFilePath.length - 1).join('/');
    unprocessedDocument.rootPath = splitFilePath.length >= 5 ? splitFilePath.slice(2, 3).join('/') : '';
    unprocessedDocument.subPath = splitFilePath.slice(3, splitFilePath.length - 2).join('/');
    unprocessedDocument.stepResultTypeName = 'unknown (unprocessed)';
    unprocessedDocument.isUnprocessed = true;
    return unprocessedDocument;
  }
}
