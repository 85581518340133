import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { distinct, filterBy, FilterDescriptor } from '@progress/kendo-data-query';
import { FilterService } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-multicheck-filter',
  templateUrl: './multicheck-filter.component.html',
  styleUrls: ['./multicheck-filter.component.scss'],
})
export class MultiCheckFilterComponent implements AfterViewInit {
  @Input() public isPrimitive: boolean;
  @Input() public currentFilter: any;
  @Input() public data;
  @Input() public textField;
  @Input() public valueField;
  @Input() public filterService: FilterService;
  @Input() public field: string;
  @Output() public valueChange = new EventEmitter<number[]>();

  public currentData: any;
  public showFilter = true;
  private filteredComponents: string[] = [];

  protected textAccessor = (dataItem: any) => (this.isPrimitive ? dataItem : dataItem[this.textField]);
  protected valueAccessor = (dataItem: any) => (this.isPrimitive ? dataItem : dataItem[this.valueField]);

  public ngAfterViewInit() {
    this.currentData = this.data;
    this.filteredComponents = this.currentFilter.filters.map((filter: FilterDescriptor) => filter.value.text);

    this.showFilter = typeof this.textAccessor(this.currentData[0]) === 'string';
  }

  public isItemSelected(item) {
    return this.filteredComponents.some((component) => component === this.valueAccessor(item));
  }

  public onSelectionChange(selectedComponent, htmlElement) {
    if (this.filteredComponents.some((component) => component === selectedComponent)) {
      this.filteredComponents = this.filteredComponents.filter((component) => component !== selectedComponent);
    } else {
      this.filteredComponents.push(selectedComponent);
    }

    this.filterService.filter({
      filters: this.filteredComponents.map((filterComponent) => ({
        field: this.field,
        operator: (components: string[] | undefined, _) => {
          return !!components?.filter((component) => component === filterComponent).length;
        },
        value: { text: filterComponent },
      })),
      logic: 'or',
    });

    this.onFocus(htmlElement);
  }

  public onInput(inputElement: any) {
    this.currentData = distinct(
      [
        ...this.currentData.filter((dataItem) => this.filteredComponents.some((component) => component === this.valueAccessor(dataItem))),
        ...filterBy(this.data, {
          operator: 'contains',
          field: this.textField,
          value: inputElement.target.value,
        }),
      ],
      this.textField,
    );
  }

  public onFocus(htmlListItem: any) {
    const ul = htmlListItem.parentNode;
    const below = ul.scrollTop + ul.offsetHeight < htmlListItem.offsetTop + htmlListItem.offsetHeight;
    const above = htmlListItem.offsetTop < ul.scrollTop;

    // Scroll to focused checkbox
    if (below || above) {
      ul.scrollTop = htmlListItem.offsetTop;
    }
  }
}
