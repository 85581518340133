<div
  *ngIf="{
    isLoading: isLoading$ | async,
    isLoadingError: isLoadingError$ | async,
    dataSource: dataSource$ | async,
  } as data"
  class="flex flex-col"
  mat-dialog-content
>
  <div class="flex h-full w-full">
    <app-dialog-error-information (reloadClicked)="reload()" *ngIf="data?.isLoadingError"></app-dialog-error-information>
    <kendo-grid
      class="flex h-full w-full"
      id="policy-usage-group-detail-grid"
      data-test-id="policy-usage-aggregated-detail-popup-grid"
      *ngIf="!data?.isLoadingError"
      [data]="dataSource"
      [loading]="data.isLoading"
      [sortable]="sortSettings"
      [sort]="gridState.sort"
      [filterable]="true"
      [filter]="gridState.filter"
      [resizable]="true"
      (dataStateChange)="dataStateChanged($event)"
    >
      <kendo-grid-column
        [minResizableWidth]="37"
        field="policyText"
        title="Policy"
        [width]="37"
        class="vertical-align-initial td-padding-top-20"
      >
        <ng-template kendoGridCellTemplate kendoTooltip let-dataItem>
          <div class="align-middle">
            <a [fusionTooltip]="'Open Policy'" href="{{ dataItem?.policyUrl }}" rel="noopener noreferrer" target="_blank">
              <span data-test-id="policy-usage-policy-key" class="bold">{{ dataItem.policyKey }}</span>
              <i class="fi fi-link-external bold policyExternalLinkIcon"></i>
            </a>
          </div>
          <div class="policyLabel" data-test-id="policy-usage-policy-label">{{ dataItem.policyLabel }}</div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="compliantPipelineCount"
        title="Compliant Pipelines"
        width="23"
        class="text-align-right"
        headerClass="text-align-right"
        [filterable]="true"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div data-test-id="compliantPipelineCount-column">
            {{ dataItem.compliantPipelineCount }}
          </div>
        </ng-template>
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" [spinners]="false" [min]="0" format="#">
          </kendo-grid-numeric-filter-cell>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="notCompliantPipelineCount"
        title="Not Compliant Pipelines"
        width="23"
        class="text-align-right"
        headerClass="text-align-right"
        [filterable]="true"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div data-test-id="notCompliantPipelineCount-column" [ngClass]="{ redColor: dataItem.notCompliantPipelineCount > 0 }">
            {{ dataItem.notCompliantPipelineCount }}
          </div>
        </ng-template>

        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" [spinners]="false" [min]="0" format="#">
          </kendo-grid-numeric-filter-cell>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="noDataAvailablePipelineCount"
        title="Pipelines w/o Information"
        width="23"
        class="text-align-right"
        headerClass="text-align-right"
        [filterable]="true"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div data-test-id="noDataAvailablePipelineCount-column">
            {{ dataItem.noDataAvailablePipelineCount }}
          </div>
        </ng-template>

        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" [spinners]="false" [min]="0" format="#">
          </kendo-grid-numeric-filter-cell>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column [filterable]="false" [sortable]="false" width="25" [minResizableWidth]="25">
        <ng-template kendoGridCellTemplate let-dataItem>
          <a
            data-test-id="policy-usage-group-details-popup-view-details-link"
            class="flex flex-row justify-end items-center"
            (click)="selectedPolicy.emit(dataItem)"
            fusionFeatureUsageTracker="policy-usage-group-detail-dialog-view-details"
          >
            <span>View Details</span>
            <fusion-icon class="forwardButton" name="chevron-right" size="XL"></fusion-icon>
          </a>
        </ng-template>
      </kendo-grid-column>

      <ng-template kendoGridLoadingTemplate>
        <app-table-loading-indicator></app-table-loading-indicator>
      </ng-template>
    </kendo-grid>
  </div>
</div>
