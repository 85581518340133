import { GroupComplianceMode } from '../../shared/model/group-compliance-mode';
import { ProductStandard } from '../../shared/model/product-standard';
import { ComplianceStatus } from '../model/compliance-information';
import { GroupTypeMicrodelivery } from '../model/group-type-microdelivery';
import {
  AllowedScmsSlice,
  ApiMetadataValidatorSlice,
  AtcSlice,
  CentralPolicyResultsSlice,
  CentralPolicyUsageSlice,
  CodeCoverageInfoSlice,
  ComplianceCertificatesSlice,
  ConfigurationSlice,
  CustomPolicyResultsSlice,
  CustomPolicyUsageSlice,
  DeliveriesSlice,
  DocumentsSlice,
  Fc1Slice,
  Fc2Slice,
  Fc3Slice,
  FosstarsSlice,
  MalwarescanSlice,
  MicrodeliveriesSlice,
  PipelineRunSlice,
  PipelineSlice,
  ProcessingStatusSlice,
  Psl1Slice,
  Slc25Slice,
  SonarQubeCoverageSlice,
  TestRegressionSlice,
} from './app-state';

export const initialFosstarsSlice: FosstarsSlice = {
  aggregationSummary: undefined,
  aggregationDetails: [],
  pipelineRunsDetails: {},
  pipelineRunsSummary: {},
};

export const initialCentralPolicyResultsSlice: CentralPolicyResultsSlice = {
  pipelineRunsDetails: {},
  pipelineRunsSummary: {},
  groupSummary: undefined,
  groupDetails: [],
};

export const initialCustomPolicyResultsSlice: CustomPolicyResultsSlice = {
  pipelineRunsDetails: {},
  pipelineRunsSummary: {},
  groupSummary: undefined,
  groupDetails: [],
};

export const initialSlc25Slice: Slc25Slice = {
  aggregationSummary: {
    groupId: '',
    psrKey: ProductStandard.slc25.key,
    complianceStatus: ComplianceStatus.UNKNOWN,
  },
  aggregationDetails: [],
  selectedPipelineRuns: [],
};

export const initialPsl1Slice: Psl1Slice = {
  aggregationSummary: {
    groupId: '',
    psrKey: ProductStandard.psl1.key,
    complianceStatus: ComplianceStatus.UNKNOWN,
  },
  aggregationDetails: [],
  selectedPipelineRuns: {},
};
export const initialFc1Slice: Fc1Slice = {
  aggregationSummary: {
    groupId: '',
    psrKey: ProductStandard.fc1.key,
    complianceStatus: ComplianceStatus.UNKNOWN,
  },
  aggregationDetails: [],
  selectedPipelineRuns: {}, // Map of key-obj
};

export const initialFc2Slice: Fc2Slice = {
  deliveryRequirements: [],
  deliveryTestcases: [],
  groupComplianceMode: GroupComplianceMode.LATEST,
  pipelineRunRequirements: {},
  pipelineRunRequirementsTestcases: {},
};

export const initialFc3Slice: Fc3Slice = {
  aggregationSummary: {
    groupId: '',
    psrKey: ProductStandard.fc3.key,
    complianceStatus: ComplianceStatus.UNKNOWN,
  },
  aggregationDetails: [],
  selectedPipelineRuns: {},
};

export const initialAtcSlice: AtcSlice = {
  aggregationSummary: undefined,
  aggregationDetails: [],
  allPipelineRunAtcInformation: {},
};

export const initialSonarQubeCoverageSlice: SonarQubeCoverageSlice = {
  groupSummary: undefined,
  groupDetails: [],
  selectedPipelineRuns: {},
};

export const initialCodeCoverageInfoSlice: CodeCoverageInfoSlice = {
  aggregationSummary: undefined,
  aggregationDetails: [],
  selectedPipelineRuns: {},
};

export const initialAllowedScmsSlice: AllowedScmsSlice = {
  allowedScms: [],
};

export const initialMicrodeliveriesSlice: MicrodeliveriesSlice = {
  activeMicrodeliveries: new Map<number, GroupTypeMicrodelivery>(),
  displayedMicrodeliveries: new Map<number, GroupTypeMicrodelivery>(),
};

export const initialDeliveriesSlice: DeliveriesSlice = {
  selectedDelivery: undefined,
  siriusDelivery: undefined,
  deliveryFixVersions: undefined,
  groupComplianceMode: GroupComplianceMode.LATEST,
};

export const initialPipelineRunSlice: PipelineRunSlice = {
  pipelineRuns: {},
  selectedPipelineRun: {},
};

export const initialPipelineSlice: PipelineSlice = {
  pipelines: [],
  searchedPipelines: [],
  searchString: '',
};

export const initialConfigurationSlice: ConfigurationSlice = {
  configurations: {},
  jiraComponentMappingsForPipelines: [],
};

export const initialTestRegressionSlice: TestRegressionSlice = {
  pipelineRequirements: {},
  pipelineRunRegressionTests: {},
};

export const initialMalwarescanSlice: MalwarescanSlice = {
  groupSummary: undefined,
  groupDetails: [],
  pipelineRunDetails: {},
};

export const initialDocumentsSlice: DocumentsSlice = {
  pipelineRunsDetails: {},
};

export const initialComplianceCertificatesSlice: ComplianceCertificatesSlice = {
  pipelineRunsDetails: {},
};

export const initialProcessingStatusSlice: ProcessingStatusSlice = {
  pipelineRunProcessingStatus: {},
};

export const initialApiMetadataValidatorSlice: ApiMetadataValidatorSlice = {
  groupSummary: undefined,
  groupDetails: [],
  pipelineRunsSummary: {},
};

export const initialCentralPolicyUsageSlice: CentralPolicyUsageSlice = {
  groupPolicyKeySummary: [],
  groupSummary: undefined,
  groupDetails: [],
};

export const initialCustomPolicyUsageSlice: CustomPolicyUsageSlice = {
  groupPolicyKeySummary: [],
  groupSummary: undefined,
  groupDetails: [],
};
