import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { AllowedScmsEffects } from './store/allowed-scms/allowed-scms.effects';
import { allowedScmsReducer } from './store/allowed-scms/allowed-scms.reducer';
import { AppState } from './store/app-state';
import { AtcEffects } from './store/atc/atc.effects';
import { initialLoadingState, loadingStateReducer } from './store/loading-state/loading-state.reducer';
import { psl1Reducer } from './store/psl-1/psl-1.reducer';
import { Slc25Effects } from './store/slc-25/slc-25.effects';

import { ApiMetadataValidatorEffects } from './store/api-metadata-validator/api-metadata-validator.effects';
import { apiMetadataValidatorReducer } from './store/api-metadata-validator/api-metadata-validator.reducer';
import { atcReducer } from './store/atc/atc.reducer';
import { CentralPolicyResultsEffects } from './store/central-policy-results/central-policy-results.effects';
import { CentralPolicyResultsReducer } from './store/central-policy-results/central-policy-results.reducer';
import { CentralPolicyUsageEffects } from './store/central-policy-usage/central-policy-usage.effects';
import { CentralPolicyUsageReducer } from './store/central-policy-usage/central-policy-usage.reducer';
import { CodeCoverageInfoEffects } from './store/code-coverage-info/code-coverage-info.effects';
import { codeCoverageInfoReducer } from './store/code-coverage-info/code-coverage-info.reducer';
import { ComplianceCertificatesEffects } from './store/compliance-certificates/compliance-certificates.effects';
import { complianceCertificatesReducer } from './store/compliance-certificates/compliance-certificates.reducer';
import { ConfigurationEffects } from './store/configuration/configuration.effects';
import { configurationReducer } from './store/configuration/configuration.reducer';
import { CustomPolicyResultsEffects } from './store/custom-policy-results/custom-policy-results.effects';
import { CustomPolicyResultsReducer } from './store/custom-policy-results/custom-policy-results.reducer';
import { CustomPolicyUsageEffects } from './store/custom-policy-usage/custom-policy-usage.effects';
import { CustomPolicyUsageReducer } from './store/custom-policy-usage/custom-policy-usage.reducer';
import { DeliveriesEffects } from './store/deliveries/deliveries.effects';
import { deliveriesReducer } from './store/deliveries/deliveries.reducer';
import { DocumentsEffects } from './store/documents/documents.effects';
import { documentsReducer } from './store/documents/documents.reducer';
import { Fc1Effects } from './store/fc-1/fc-1.effects';
import { fc1Reducer } from './store/fc-1/fc-1.reducer';
import { Fc2Effects } from './store/fc-2/fc-2.effects';
import { fc2Reducer } from './store/fc-2/fc-2.reducer';
import { Fc3Effects } from './store/fc-3/fc-3.effects';
import { fc3Reducer } from './store/fc-3/fc-3.reducer';
import { FosstarsEffects } from './store/fosstars/fosstars.effects';
import { fosstarsReducer } from './store/fosstars/fosstars.reducer';
import {
  initialAllowedScmsSlice,
  initialApiMetadataValidatorSlice,
  initialAtcSlice,
  initialCentralPolicyResultsSlice,
  initialCentralPolicyUsageSlice,
  initialCodeCoverageInfoSlice,
  initialComplianceCertificatesSlice,
  initialConfigurationSlice,
  initialCustomPolicyResultsSlice,
  initialCustomPolicyUsageSlice,
  initialDeliveriesSlice,
  initialDocumentsSlice,
  initialFc1Slice,
  initialFc2Slice,
  initialFc3Slice,
  initialFosstarsSlice,
  initialMalwarescanSlice,
  initialMicrodeliveriesSlice,
  initialPipelineRunSlice,
  initialPipelineSlice,
  initialProcessingStatusSlice,
  initialPsl1Slice,
  initialSlc25Slice,
  initialSonarQubeCoverageSlice,
  initialTestRegressionSlice,
} from './store/initial-slices';
import { MalwarescanEffects } from './store/malwarescan/malwarescan.effects';
import { malwarescanReducer } from './store/malwarescan/malwarescan.reducer';
import { MicrodeliveriesEffects } from './store/microdeliveries/microdeliveries.effects';
import { microdeliveriesReducer } from './store/microdeliveries/microdeliveries.reducer';
import { PipelineRunEffects } from './store/pipeline-run/pipeline-run.effects';
import { pipelineRunReducer } from './store/pipeline-run/pipeline-run.reducer';
import { PipelineEffects } from './store/pipeline/pipeline.effects';
import { pipelineReducer } from './store/pipeline/pipeline.reducer';
import { ProcessingStatusEffects } from './store/processing-status/processing-status.effects';
import { processingStatusReducer } from './store/processing-status/processing-status.reducer';
import { Psl1Effects } from './store/psl-1/psl-1.effects';
import { slc25Reducer } from './store/slc-25/slc-25.reducer';
import { SonarQubeCoverageEffects } from './store/sonar-qube-coverage/sonar-qube-coverage.effects';
import { sonarQubeCoverageReducer } from './store/sonar-qube-coverage/sonar-qube-coverage.reducer';
import { TestRegressionEffects } from './store/test-regression/test-regression.effects';
import { testRegresionReducer } from './store/test-regression/test-regression.reducer';

/**
 * Is needed to initialize the mockStore
 */
export const initialAppState: AppState = {
  loadingStateSlice: initialLoadingState,
  psl1Slice: initialPsl1Slice,
  slc25Slice: initialSlc25Slice,
  fc1Slice: initialFc1Slice,
  fc2Slice: initialFc2Slice,
  fc3Slice: initialFc3Slice,
  atcSlice: initialAtcSlice,
  sonarQubeCoverageSlice: initialSonarQubeCoverageSlice,
  codeCoverageInfoSlice: initialCodeCoverageInfoSlice,
  allowedScmsSlice: initialAllowedScmsSlice,
  microdeliveriesSlice: initialMicrodeliveriesSlice,
  deliveriesSlice: initialDeliveriesSlice,
  pipelineRunSlice: initialPipelineRunSlice,
  pipelineSlice: initialPipelineSlice,
  configurationSlice: initialConfigurationSlice,
  testRegressionSlice: initialTestRegressionSlice,
  malwarescanSlice: initialMalwarescanSlice,
  documentsSlice: initialDocumentsSlice,
  complianceCertificatesSlice: initialComplianceCertificatesSlice,
  processingStatusSlice: initialProcessingStatusSlice,
  apiMetadataValidatorSlice: initialApiMetadataValidatorSlice,
  fosstarsSlice: initialFosstarsSlice,
  centralPolicyResultsSlice: initialCentralPolicyResultsSlice,
  customPolicyResultsSlice: initialCustomPolicyResultsSlice,
  centralPolicyUsageSlice: initialCentralPolicyUsageSlice,
  customPolicyUsageSlice: initialCustomPolicyUsageSlice,
};

export const metaReducers: Array<MetaReducer<AppState>> = [];

export const reducers: ActionReducerMap<AppState> = {
  loadingStateSlice: loadingStateReducer,
  psl1Slice: psl1Reducer,
  slc25Slice: slc25Reducer,
  fc1Slice: fc1Reducer,
  fc2Slice: fc2Reducer,
  fc3Slice: fc3Reducer,
  atcSlice: atcReducer,
  sonarQubeCoverageSlice: sonarQubeCoverageReducer,
  codeCoverageInfoSlice: codeCoverageInfoReducer,
  allowedScmsSlice: allowedScmsReducer,
  microdeliveriesSlice: microdeliveriesReducer,
  deliveriesSlice: deliveriesReducer,
  pipelineRunSlice: pipelineRunReducer,
  pipelineSlice: pipelineReducer,
  configurationSlice: configurationReducer,
  testRegressionSlice: testRegresionReducer,
  malwarescanSlice: malwarescanReducer,
  documentsSlice: documentsReducer,
  complianceCertificatesSlice: complianceCertificatesReducer,
  processingStatusSlice: processingStatusReducer,
  apiMetadataValidatorSlice: apiMetadataValidatorReducer,
  fosstarsSlice: fosstarsReducer,
  centralPolicyResultsSlice: CentralPolicyResultsReducer,
  customPolicyResultsSlice: CustomPolicyResultsReducer,
  centralPolicyUsageSlice: CentralPolicyUsageReducer,
  customPolicyUsageSlice: CustomPolicyUsageReducer,
};

export const effects = [
  Psl1Effects,
  Slc25Effects,
  Fc1Effects,
  Fc2Effects,
  Fc3Effects,
  AtcEffects,
  SonarQubeCoverageEffects,
  CodeCoverageInfoEffects,
  AllowedScmsEffects,
  MicrodeliveriesEffects,
  DeliveriesEffects,
  PipelineRunEffects,
  PipelineEffects,
  ConfigurationEffects,
  TestRegressionEffects,
  MalwarescanEffects,
  DocumentsEffects,
  ComplianceCertificatesEffects,
  ProcessingStatusEffects,
  ApiMetadataValidatorEffects,
  FosstarsEffects,
  CentralPolicyResultsEffects,
  CustomPolicyResultsEffects,
  CentralPolicyUsageEffects,
  CustomPolicyUsageEffects,
];
