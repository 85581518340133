<ng-container
  *ngIf="{
    loading: loading$ | async,
    testCases: testCasesData$ | async,
    requirements: jiraRequirementsData$ | async,
    loadingError: loadingError$ | async,
  } as data"
>
  <app-list-item-kpis [isLoading]="data.loading" [title]="'kpi.tile.fc2.title' | translate">
    <div kpi-tile-content class="flex flex-col">
      <span
        class="impressive clickable"
        attr.data-test-id="{{ 'fc-2-requirement-test-result-' + (pipelineRunId ? 'pipeline' : 'aggregated') }}"
        (click)="openRequirementDetails(data.requirements, data.testCases)"
      >
        <span *ngIf="!data.loadingError" [ngClass]="{ error: hasNotOkRequirements(data.testCases, data?.requirements) }">
          <span>{{ getAmount(data.testCases) }}</span>
          <span *ngIf="data?.requirements && !pipelineRunId"> of {{ data?.requirements?.length }} </span>
          <span *ngIf="pipelineRunId"> of {{ getAmountOfRequirementsWithTests(data.testCases) }} </span>
        </span>
      </span>
      <div *ngIf="data.loadingError">
        <span class="error">Error loading information from Jira. Please try it again later.</span>
      </div>
    </div>
    <div kpi-tile-footer *ngIf="!data.loading && !data.loadingError">
      <!-- Only show summary when amount has loaded. -->
      <span>{{ (pipelineRunId ? 'kpi.tile.traceability.pipeline-run.summary' : 'kpi.tile.fc2.group.summary') | translate }}</span>
    </div>
  </app-list-item-kpis>
</ng-container>
