import { createAction, props } from '@ngrx/store';
import { GroupComplianceMode } from 'src/app/shared/model/group-compliance-mode';
import {
  CustomPolicyResultsPipelineRunDetail,
  PolicyResultsGroupDetails,
  PolicyResultsGroupSummary,
  PolicyResultsPipelineRunSummary,
} from '../../model/policy-results';

export const loadCustomPolicyResultsSingleSummary = createAction(
  `[Custom Policy Results] Trigger loading of policy results information for single-summary from backend`,
  props<{ pipelineRunId: number }>(),
);
export const setCustomPolicyResultsSingleSummary = createAction(
  `[Custom Policy Results] Set policy results information for single-summary from backend in store`,
  props<{ pipelineRunId: number; singleSummary: PolicyResultsPipelineRunSummary }>(),
);

export const setCustomPolicyResultsGroupSummary = createAction(
  `[Custom Policy Results] Set policy results information for group-summary from backend in store`,
  props<{ groupSummary: PolicyResultsGroupSummary }>(),
);

export const setCustomPolicyResultsGroupDetails = createAction(
  `[Custom Policy Results] Set policy results information for group-details from backend in store`,
  props<{ groupDetails: PolicyResultsGroupDetails[] }>(),
);

export const loadCustomPolicyResultsSingleSummaryError = createAction(
  `[Custom Policy Results] Loading of policy results single-summary from backend error`,
  props<{ pipelineRunId: number }>(),
);

export const loadCustomPolicyResultsGroupSummaryError = createAction(
  `[Custom Policy Results] Loading of policy results group-summary from backend error`,
  props<{ groupId: string }>(),
);

export const loadCustomPolicyResultsGroupDetailsError = createAction(
  `[Custom Policy Results] Loading of policy results group-details from backend error`,
  props<{ groupId: string }>(),
);

export const loadCustomPolicyResultsSingleSummaryNotFound = createAction(
  `[Custom Policy Results] Loading of policy results single-summary from backend not found`,
  props<{ pipelineRunId: number }>(),
);

export const loadCustomPolicyResultsGroupSummaryNotFound = createAction(
  `[Custom Policy Results] Loading of policy results group-summary from backend not found`,
  props<{ groupId: string }>(),
);

export const loadCustomPolicyResultsSingleDetails = createAction(
  `[Custom Policy Results] Trigger loading of policy results information for single-details from backend`,
  props<{ pipelineRunId: number }>(),
);

export const setCustomPolicyResultsSingleDetails = createAction(
  `[Custom Policy Results] Set policy results information for single-details from backend in store`,
  props<{ pipelineRunId: number; singleDetails: CustomPolicyResultsPipelineRunDetail[] }>(),
);

export const loadCustomPolicyResultsSingleDetailsError = createAction(
  `[Custom Policy Results] Loading of policy results single-details from backend error`,
  props<{ pipelineRunId: number }>(),
);

export const loadCustomPolicyResultsSingleDetailsNotFound = createAction(
  `[Custom Policy Results] Loading of policy results single-details from backend not found`,
  props<{ pipelineRunId: number }>(),
);

export const loadCustomPolicyResultsGroupSummary = createAction(
  `[Custom Policy Results] Trigger loading of policy results information for group-summary from backend`,
  props<{ groupId: string; groupComplianceMode: GroupComplianceMode }>(),
);

export const loadCustomPolicyResultsGroupDetails = createAction(
  `[Custom Policy Results] Trigger loading of policy results information for group-details from backend`,
  props<{ groupId: string; groupComplianceMode: GroupComplianceMode }>(),
);
