import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { ComboBoxModule, DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ButtonModule, FeatureUsageTrackerModule, IconModule, TooltipModule } from '@tools/fusion';
import { NgxGaugeModule } from 'ngx-gauge';
import { AlertComponent } from './alert/alert.component';
import { CoverageInfoComponent } from './components/coverage-info/coverage-info.component';
import { DialogErrorInformationComponent } from './components/dialog-error-information/dialog-error-information.component';
import { ErrorInformationComponent } from './components/error-information/error-information.component';
import { LoadingComponent } from './components/loading/loading.component';
import { OptimizedPipelineRunInfoComponent } from './components/optimized-pipeline-run-info/optimized-pipeline-run-info.component';
import { ProcessingStatusWarningBoxComponent } from './components/processing-status-warning-box/processing-status-warning-box.component';
import { ReleaseSelectionComponent } from './components/release-selection/release-selection.component';
import { RunStatusComponent } from './components/run-status/run-status.component';
import { SrsInputComponent } from './components/srs-input/srs-input.component';
import { StatusChipComponent } from './components/status-chip/status-chip.component';
import { WarningBoxComponent } from './components/warning-box/warning-box.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ShowTitleTooltipIfTextIsTruncatedDirective } from './directives/text-truncated-title-tooltip/show-title-tooltip-if-text-is-truncated.directive';
import { PipelineKeyWithoutTimestampPipe } from './pipes/pipeline-key/pipeline-key-without-timestamp.pipe';
import { TableLoadingIndicatorComponent } from './table-loading-indicator/table-loading-indicator.component';

@NgModule({
  declarations: [
    RunStatusComponent,
    CoverageInfoComponent,
    SrsInputComponent,
    ReleaseSelectionComponent,
    AlertComponent,
    LoadingComponent,
    ConfirmationDialogComponent,
    OptimizedPipelineRunInfoComponent,
    PipelineKeyWithoutTimestampPipe,
    ErrorInformationComponent,
    DialogErrorInformationComponent,
    TableLoadingIndicatorComponent,
    ProcessingStatusWarningBoxComponent,
    ShowTitleTooltipIfTextIsTruncatedDirective,
    WarningBoxComponent,
    StatusChipComponent,
  ],
  imports: [
    CommonModule,
    ButtonModule,
    FeatureUsageTrackerModule,
    IconModule,
    TooltipModule,
    MatChipsModule,
    DropDownsModule,
    ComboBoxModule,
    NgxGaugeModule,
    FormsModule,
    MatDialogModule,
    TranslateModule,
    NgOptimizedImage,
  ],
  exports: [
    RunStatusComponent,
    CoverageInfoComponent,
    SrsInputComponent,
    ReleaseSelectionComponent,
    AlertComponent,
    LoadingComponent,
    OptimizedPipelineRunInfoComponent,
    PipelineKeyWithoutTimestampPipe,
    ErrorInformationComponent,
    DialogErrorInformationComponent,
    TableLoadingIndicatorComponent,
    ProcessingStatusWarningBoxComponent,
    ShowTitleTooltipIfTextIsTruncatedDirective,
    WarningBoxComponent,
    StatusChipComponent,
  ],
})
export class SharedModule {}
