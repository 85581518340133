import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CentralPolicyUsageSlice } from '../app-state';

// First selector should be a featureSelector
export const selectCentralPolicyUsageSlice = createFeatureSelector<CentralPolicyUsageSlice>('centralPolicyUsageSlice');

export const selectCentralPolicyKeyGroupDetails = createSelector(selectCentralPolicyUsageSlice, (CentralPolicyUsageSlice) => {
  return CentralPolicyUsageSlice.groupPolicyKeySummary;
});

export const selectCentralPolicyUsageGroupSummary = createSelector(selectCentralPolicyUsageSlice, (CentralPolicyUsageSlice) => {
  return CentralPolicyUsageSlice.groupSummary;
});

export const selectCentralPolicyUsageGroupDetails = createSelector(selectCentralPolicyUsageSlice, (CentralPolicyUsageSlice) => {
  return CentralPolicyUsageSlice.groupDetails;
});
