import { createReducer, on } from '@ngrx/store';
import { FosstarsSlice } from '../app-state';
import { initialFosstarsSlice } from '../initial-slices';
import * as FosstarsActions from './fosstars.actions';

export const fosstarsReducer = createReducer(
  initialFosstarsSlice,
  on(FosstarsActions.loadFosstarsGroupSummary, (state): FosstarsSlice => {
    return { ...state, aggregationSummary: initialFosstarsSlice.aggregationSummary };
  }),
  on(FosstarsActions.setFosstarsGroupDetails, (state, props): FosstarsSlice => {
    return { ...state, aggregationDetails: props.groupDetails };
  }),
  on(FosstarsActions.setFosstarsGroupSummary, (state, props): FosstarsSlice => {
    return { ...state, aggregationSummary: props.groupSummary };
  }),
  on(FosstarsActions.setFosstarsSingleSummary, (state, props): FosstarsSlice => {
    const pipelineRunsSummary = { ...state.pipelineRunsSummary };
    pipelineRunsSummary[props.pipelineRunId] = props.singleDetails;
    return { ...state, pipelineRunsSummary };
  }),
  on(FosstarsActions.setFosstarsSingleDetails, (state, props): FosstarsSlice => {
    const pipelineRunsDetails = { ...state.pipelineRunsDetails };
    pipelineRunsDetails[props.pipelineRunId] = props.singleDetails;
    return { ...state, pipelineRunsDetails };
  }),
);
