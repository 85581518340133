<div
  *ngIf="{
    isLoading: isLoading$ | async,
    isLoadingError: isLoadingError$ | async,
    dataSource: dataSource$ | async,
  } as data"
  class="flex flex-col"
  mat-dialog-content
>
  <div class="flex flex-col h-full w-full">
    <app-dialog-error-information (reloadClicked)="reload()" *ngIf="data?.isLoadingError"></app-dialog-error-information>
    <kendo-grid
      *ngIf="!data?.isLoadingError"
      [filter]="gridState.filter"
      [filterable]="true"
      [loading]="data.isLoading"
      [resizable]="true"
      [sort]="gridState.sort"
      [sortable]="sortSettings"
      data-test-id="custom-policy-results-detail-popup-grid"
      class="flex h-full w-full"
      id="custom-policy-results-grid"
      [data]="dataSource"
      (dataStateChange)="dataStateChanged($event)"
      [trackBy]="trackByResultId"
    >
      <kendo-grid-column field="pipelineKey" title="Pipeline" width="200">
        <ng-template kendoGridCellTemplate kendoTooltip let-dataItem>
          <app-kpi-column-pipeline
            [item]="dataItem"
            fusionFeatureUsageTracker="open-policy-results-group-pipeline-url"
          ></app-kpi-column-pipeline>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [filterable]="false"
        [minResizableWidth]="235"
        field="validationErrorMessages"
        title="Validation Error Messages"
        [width]="400"
        class="vertical-align-initial td-padding-top-20"
      >
        <ng-template kendoGridCellTemplate kendoTooltip let-dataItem let-rowIndex="rowIndex">
          <app-validation-error-message-list
            [messages]="dataItem?.validationErrorMessages"
            messagesTestId="custom-policy-result-validation-error-messages"
            id="textTruncationBlock{{ rowIndex }}"
            data-test-id="custom-policy-result-validation-error-messages"
          >
          </app-validation-error-message-list>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [minResizableWidth]="77"
        [maxResizableWidth]="200"
        field="customPolicyComplianceStatus.displayKey"
        title="Status"
        [width]="200"
        class="vertical-align-initial td-padding-top-20"
      >
        <ng-template kendoGridCellTemplate kendoTooltip let-dataItem>
          <div class="truncate align-middle" appTextTruncateTitleTooltip>
            <span
              *ngIf="dataItem?.customPolicyComplianceStatus?.displayKey"
              class="status-circle status-circle-{{ dataItem?.customPolicyComplianceStatus?.key }}"
            >
            </span>
            <span data-test-id="custom-policy-result-compliance-status">{{
              dataItem?.customPolicyComplianceStatus?.displayKey || '&mdash;'
            }}</span>
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [minResizableWidth]="100"
        [maxResizableWidth]="100"
        [filterable]="false"
        [sortable]="false"
        title=""
        [width]="100"
        class="vertical-align-initial td-padding-top-20"
      >
        <ng-template kendoGridCellTemplate kendoTooltip let-dataItem>
          <div class="flex flex-row justify-center items-baseline"></div>

          <div>
            <div class="flex flex-row gap-2.5 justify-center items-baseline">
              <button
                fusionIconButton
                fusionIcon="mail"
                fusionIconSize="L"
                (click)="mailTo(dataItem)"
                [fusionTooltip]="mailToTooltip"
                class="align-middle"
                data-test-id="mailToPolicyAuthorButton"
                fusionFeatureUsageTracker="custom-policy-results-mail-to-btn"
                [disabled]="!dataItem?.customPolicyAuthor"
                [fusionTooltipDisabled]="!dataItem?.customPolicyAuthor"
              ></button>
              <ng-template #mailToTooltip>
                <div class="action-tooltip-header">Contact Custom Policy Author</div>
                <div class="action-tooltip-text">Author: {{ dataItem?.customPolicyAuthor }}</div>
              </ng-template>
              <button
                fusionIconButton
                fusionIcon="download"
                fusionIconSize="L"
                (click)="downloadFile(dataItem)"
                [fusionTooltip]="downloadCustomPolicyResultFileButton"
                class="align-middle"
                data-test-id="downloadCustomPolicyResultFileButton"
                fusionFeatureUsageTracker="download-custom-policy-results-file-btn"
                [disabled]="!dataItem?.fileName"
                [fusionTooltipDisabled]="!dataItem?.fileName"
              ></button>
              <ng-template #downloadCustomPolicyResultFileButton>
                <div class="action-tooltip-header">Download Custom Policy Result File</div>
                <div class="action-tooltip-text">{{ getDownloadTooltipText(dataItem?.fileName, dataItem?.subPath) }}</div>
              </ng-template>
              <button
                *ngIf="dataItem?.toolUrl"
                fusionIconButton
                fusionIcon="link-external"
                fusionIconSize="L"
                (click)="openToolUrl(dataItem)"
                fusionTooltip="Open Tool"
                class="align-middle"
                data-test-id="openCustomPolicyToolUrlButton"
                fusionFeatureUsageTracker="open-custom-policy-tool-url-btn"
              ></button>
            </div>
          </div>
        </ng-template>
      </kendo-grid-column>
      <ng-template kendoGridLoadingTemplate>
        <app-table-loading-indicator></app-table-loading-indicator>
      </ng-template>
    </kendo-grid>
  </div>
</div>
