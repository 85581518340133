<ng-container
  *ngIf="{
    leadingRunIcon: getIconAndClassName(complianceInformation?.leadingComplianceStatus),
    relatedRunIcon: getIconAndClassName(complianceInformation?.relatedComplianceStatus),
  } as icons"
>
  <div
    class="flex flex-row justify-start items-center gap-1"
    attr.data-test-id="{{ getTooltipTestId(complianceInformation?.psrKey, complianceInformation?.pipelineKey) }}"
  >
    <div class="flex flex-col gap-2.5">
      <strong>Commit Run:</strong>
      <strong>Scheduled Run:</strong>
    </div>
    <div class="flex flex-col gap-1.5">
      <fusion-icon [name]="icons.leadingRunIcon.iconName" size="L" [ngClass]="icons.leadingRunIcon.className"></fusion-icon>
      <fusion-icon [name]="icons.relatedRunIcon.iconName" size="L" [ngClass]="icons.relatedRunIcon.className"></fusion-icon>
    </div>
    <div class="flex flex-col gap-2.5">
      <span data-test-id="leading-compliance-status-pipeline-run">{{ complianceInformation?.leadingComplianceStatus.displayKey }}</span>
      <span data-test-id="related-compliance-status-pipeline-run">{{ complianceInformation?.relatedComplianceStatus.displayKey }}</span>
    </div>
  </div>
</ng-container>
