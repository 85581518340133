import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { isRequirementsMappingFailed, RequirementTestcaseResult } from '../../model/requirement-detail/requirement-detail-testcase';
import { RequirementDetail } from '../../model/requirement-detail/requirement-detail';
import { JiraService } from '../../shared/jira-service/jira.service';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  selectIsPipelineRunRegressionTestsLoading,
  selectIsPipelineRunRegressionTestsLoadingError,
} from '../../ngrx/store/loading-state/loading-state.selectors';
import { RequirementsDetailDialogComponent } from '../list-item-kpis-fc2/dialog/requirements-detail-dialog.component';
import { selectPipelineRequirements, selectPipelineRunRegressionTests } from '../../ngrx/store/test-regression/test-regression.selectors';
import { loadPipelineRequirements } from '../../ngrx/store/test-regression/test-regression.actions';
import { Requirement } from '../../model/jira/release-item';

@Component({
  selector: 'app-list-item-kpis-regression',
  templateUrl: './list-item-kpis-regression.component.html',
  styleUrls: ['./list-item-kpis-regression.component.scss'],
})
export class ListItemKpisRegressionComponent implements OnInit, OnChanges {
  @Input() readOnly = false;
  @Input() pipelineRunId: number;
  @Input() pipelineId: string;

  testCasesData$: Observable<RequirementTestcaseResult[]>;
  jiraRequirementsData$: Observable<Requirement[]>;
  loading$: Observable<boolean>;
  loadingError$: Observable<boolean>;

  constructor(
    private readonly matDialog: MatDialog,
    private readonly jiraService: JiraService,
    private readonly store$: Store,
  ) {}

  ngOnInit() {
    this.jiraRequirementsData$ = this.store$.select(selectPipelineRequirements(this.pipelineId));
    this.store$.dispatch(loadPipelineRequirements({ pipelineId: this.pipelineId }));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.pipelineRunId) {
      this.pipelineRunChanged();
    }
  }

  pipelineRunChanged() {
    const pipelineRunId = this.pipelineRunId;
    this.loading$ = this.store$.select(selectIsPipelineRunRegressionTestsLoading(pipelineRunId, this.pipelineId));
    this.loadingError$ = this.store$.select(selectIsPipelineRunRegressionTestsLoadingError(this.pipelineId, pipelineRunId));
    this.testCasesData$ = this.store$.select(selectPipelineRunRegressionTests(pipelineRunId));
  }

  getAmount(testCases: RequirementTestcaseResult[]): number {
    if (!testCases) {
      return 0;
    }
    /* We want the number of requirements whose test cases are all OK.
    For this, we get the number of requirements with test cases
    and the number of requirements with at least one failed test case
    This approach is of order O(n log n) whereas the trivial approach
    (iterate through all test cases for every requirement) would be of
    order O(n*m) where n is the number of test cases and m the number of requirements */
    const requirementsWithTests = new Set();
    const requirementsNotOk = new Set();
    for (const testcase of testCases) {
      if (!testcase) {
        continue;
      }
      if (this.getOverallTestStatus([testcase]) !== 'OK') {
        requirementsNotOk.add(testcase.requirementKey);
      }
      requirementsWithTests.add(testcase.requirementKey);
    }
    return requirementsWithTests.size - requirementsNotOk.size;
  }

  getRequirementDetails(requirements: Requirement[], testCases: RequirementTestcaseResult[]): RequirementDetail[] {
    if (!requirements || !testCases) {
      console.error('deliveryRequirements or deliveryTestcases not set, cannot get meaningful requirement details');
      return [];
    }
    const requirementDetails: RequirementDetail[] = [];
    requirements.forEach((requirement) => {
      const testcases = this.getTestcases(requirement, testCases);
      const testStatus = this.getOverallTestStatus(testcases);
      requirementDetails.push({
        id: requirement.id.toString(),
        key: requirement.key,
        projectKey: requirement.projectKey,
        testStatus,
        link: requirement.link ?? this.jiraService.getJiraLink(requirement),
        testcases,
        status: {
          name: requirement.statusName,
          sort: this.getStatusSort(requirement.statusName),
        },
        fixVersions: requirement.fixVersions,
        sort: this.getSort(testStatus),
        summary: requirement.summary,
      });
    });
    return requirementDetails;
  }

  getTestcases(jiraElement: any, testCases: RequirementTestcaseResult[]): RequirementTestcaseResult[] {
    return testCases.filter((test) => test?.requirementKey === jiraElement.key);
  }

  getSort(overallTestStatus: string): number {
    switch (overallTestStatus) {
      case 'Not OK':
        return 1;
      case 'No tests':
        return 2;
      case 'OK':
        return 3;
    }
  }

  getOverallTestStatus(tests: RequirementTestcaseResult[]): string {
    if (!tests || !tests.length) {
      return 'No tests';
    }
    for (const test of tests.filter((ts) => !!ts)) {
      if (isRequirementsMappingFailed(test) || !test.status || !test.name.length || test.skipped || test.hasIncorrectVersion) {
        return 'Not OK';
      }
    }
    return 'OK';
  }

  openRequirementDetails(requirements: Requirement[], testCases: RequirementTestcaseResult[]) {
    const requirementDetails = this.getRequirementDetails(requirements, testCases);
    this.matDialog.open(RequirementsDetailDialogComponent, {
      data: {
        showDetail: true,
        readOnly: this.readOnly,
        requirementDetails,
        isPipelineRunView: true,
        isRegression: true,
      },
      panelClass: 'requirement-details-container',
      autoFocus: 'input',
    });
  }

  private getStatusSort(jiraStatus: string): number {
    switch (jiraStatus) {
      case 'Reopened':
      case 'Blocked':
      case 'Open':
        return 1;
      case 'In Progress':
        return 2;
      case 'Consumed':
      case 'Completed':
      case 'Inactive':
        return 3;
    }
  }
}
