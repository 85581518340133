import { createAction, props } from '@ngrx/store';
import { GroupComplianceMode } from 'src/app/shared/model/group-compliance-mode';
import { GroupPolicyUsageByPolicyKey, GroupPolicyUsageSummary, PolicyKeyGroupDetails } from '../../model/policy-results';

export const loadCentralPolicyUsageGroupSummary = createAction(
  `[Central Policy Usage] Trigger loading of policy usage information for group-summary from backend`,
  props<{ groupId: string; groupComplianceMode: GroupComplianceMode }>(),
);

export const loadCentralPolicyUsageGroupSummaryError = createAction(
  `[Central Policy Usage] Loading of policy usage group-summary from backend error`,
  props<{ groupId: string; groupComplianceMode: GroupComplianceMode }>(),
);

export const loadCentralPolicyUsageGroupSummaryNotFound = createAction(
  `[Central Policy Usage] Loading of policy usage group-summary from backend not found`,
  props<{ groupId: string; groupComplianceMode: GroupComplianceMode }>(),
);

export const setCentralPolicyUsageGroupSummary = createAction(
  `[Central Policy Usage] Set policy usage information for group-summary from backend in store`,
  props<{ groupSummary: GroupPolicyUsageSummary }>(),
);

export const loadCentralPolicyUsageGroupDetails = createAction(
  `[Central Policy Usage] Trigger loading of policy usage information for group-details from backend`,
  props<{ groupId: string; groupComplianceMode: GroupComplianceMode }>(),
);

export const loadCentralPolicyUsageGroupDetailsError = createAction(
  `[Central Policy Usage] Loading of policy usage group-details from backend error`,
  props<{ groupId: string; groupComplianceMode: GroupComplianceMode }>(),
);

export const setCentralPolicyUsageGroupDetails = createAction(
  `[Central Policy Usage] Set policy usage information for group-details from backend in store`,
  props<{ groupDetails: GroupPolicyUsageByPolicyKey[] }>(),
);

export const loadCentralPolicyKeyGroupDetails = createAction(
  `[Central Policy Key] Trigger loading of central policy key information for group details from backend`,
  props<{ groupId: string; policyKey: string; groupComplianceMode: GroupComplianceMode }>(),
);

export const loadCentralPolicyKeyGroupDetailsError = createAction(
  `[Central Policy Key] Loading of central policy key information for group details from backend error`,
  props<{ groupId: string; policyKey: string; groupComplianceMode: GroupComplianceMode }>(),
);

export const loadCentralPolicyKeyGroupDetailsNotFound = createAction(
  `[Central Policy Key] Loading of central policy key information for group details from backend not found`,
  props<{ groupId: string; policyKey: string; groupComplianceMode: GroupComplianceMode }>(),
);

export const setCentralPolicyKeyGroupDetails = createAction(
  `[Central Policy Key] Set policy key information for group details from backend in store`,
  props<{ groupPolicyKeySummary: PolicyKeyGroupDetails[] }>(),
);
