import { createReducer, on } from '@ngrx/store';
import { CustomPolicyUsageSlice } from '../app-state';
import { initialCustomPolicyUsageSlice } from '../initial-slices';
import * as CustomPolicyUsageActions from './custom-policy-usage.actions';

export const CustomPolicyUsageReducer = createReducer(
  initialCustomPolicyUsageSlice,
  on(CustomPolicyUsageActions.loadCustomPolicyUsageGroupSummary, (state, _): CustomPolicyUsageSlice => {
    return { ...state, groupSummary: initialCustomPolicyUsageSlice.groupSummary };
  }),
  on(CustomPolicyUsageActions.setCustomPolicyKeyGroupDetails, (state, props): CustomPolicyUsageSlice => {
    return { ...state, groupPolicyKeySummary: props.groupPolicyKeySummary };
  }),
  on(CustomPolicyUsageActions.setCustomPolicyUsageGroupSummary, (state, props): CustomPolicyUsageSlice => {
    return { ...state, groupSummary: props.groupSummary };
  }),
  on(CustomPolicyUsageActions.setCustomPolicyUsageGroupDetails, (state, props): CustomPolicyUsageSlice => {
    return { ...state, groupDetails: props.groupDetails };
  }),
);
