import { AllowedScms } from '../../../ngrx/store/allowed-scms/allowed-scms.model';
import { selectAllowedScmsValues } from '../../../ngrx/store/allowed-scms/allowed-scms.selectors';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

export interface Slc25WhitelistEntry {
  name: string;
  url: string;
}

@Component({
  selector: 'app-slc25-allowed-list-dialog',
  templateUrl: './slc25-allowed-list-dialog.component.html',
  styleUrls: ['./slc25-allowed-list-dialog.component.scss'],
})
export class Slc25AllowedListDialogComponent implements OnInit {
  slc25Whitelist$: Observable<AllowedScms[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private readonly dialogRef: MatDialogRef<Slc25AllowedListDialogComponent>,
    private readonly store$: Store,
  ) {
    this.slc25Whitelist$ = this.store$.select(selectAllowedScmsValues);
  }

  ngOnInit() {
    this.dialogRef.updateSize('25vw', 'auto');
  }

  closeDialog(closeData?: any) {
    this.dialogRef.close(closeData);
  }
}
