import { createAction, props } from '@ngrx/store';
import { Delivery } from '../../../model/group/group';
import { ReleaseDecisionInformation } from '../../../shared/access-api/access-api.service';
import { GroupTypeMicrodelivery } from '../../model/group-type-microdelivery';

export const clearMicrodeliveriesSlice = createAction(`[Microdeliveries] Clear Microdelivery slice`);

export const loadReleaseEntriesFromJira = createAction(
  `[Microdeliveries] Trigger loading of ReleaseEntries (Microdeliveries) from Jira`,
  props<{ releaseConfig: ReleaseDecisionInformation }>(),
);

export const loadReleaseIssuesFromJira = createAction(
  `[Microdeliveries] Trigger loading of ReleaseIssues (Microdeliveries) from Jira`,
  props<{ releaseConfig: ReleaseDecisionInformation }>(),
);

export const addMicrodeliveriesFromJira = createAction(
  `[Microdeliveries] Set Microdeliveries based on the results from jira`,
  props<{ microdeliveries: GroupTypeMicrodelivery[] }>(),
);

export const updateCache = createAction(`[Microdeliveries] Update cache`);
export const updateCacheSuccessful = createAction(
  `[Microdeliveries] Successfully updated cache`,
  props<{ microdeliveries: GroupTypeMicrodelivery[] }>(),
);
export const updateCacheFailed = createAction(`[Microdeliveries] Failed to update cache`);

export const loadMicrodeliveriesFromCache = createAction(
  `[Microdeliveries] Trigger loading of Microdeliveries from Cumulus Cache`,
  props<{ groupId: string }>(),
);

export const setMicrodeliveriesFromCache = createAction(
  `[Microdeliveries] Set Microdeliveries based on the results from Cache`,
  props<{ microdeliveries: GroupTypeMicrodelivery[] }>(),
);

export const jiraRequestFailed = createAction(`[Microdeliveries] Jira Request for Microdeliveries failed`);
export const cacheRequestFailed = createAction(`[Microdeliveries] Cumulus Cache Request for Microdeliveries failed`);

export const loadReleaseDecisionInformation = createAction(
  `[Microdeliveries] Trigger loading of release decision information based on delivery changes`,
  props<{ delivery: Delivery }>(),
);
