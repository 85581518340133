import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PipelineRunSlice } from '../app-state';

export const selectPipelineRunSlice = createFeatureSelector<PipelineRunSlice>('pipelineRunSlice');

export const selectPipelineRunsSortedForPipeline = (pipelineId: string) =>
  createSelector(selectPipelineRunSlice, (pipelineRunSlice) => {
    const runs = pipelineRunSlice.pipelineRuns[pipelineId] ? [...pipelineRunSlice.pipelineRuns[pipelineId]] : [];
    return runs.sort((runA, runB) => new Date(runB.startDateTime).getTime() - new Date(runA.startDateTime).getTime());
  });

export const selectSelectedPipelineRunForPipeline = (pipelineId: string) =>
  createSelector(selectPipelineRunSlice, (pipelineRunSlice) => {
    return pipelineRunSlice.selectedPipelineRun[pipelineId];
  });

export const selectPipelineRunDetailForPipeline = (pipelineId: string, pipelineRunId: number) =>
  createSelector(selectPipelineRunSlice, (pipelineRunSlice) => {
    return pipelineRunSlice.pipelineRuns[pipelineId]?.find((run) => run.id === pipelineRunId);
  });
