import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { GridDataResult, GridItem, MultipleSortSettings } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { Observable, map } from 'rxjs';
import { PolicyKeyGroupDetails } from '../../../../ngrx/model/policy-results';
import { loadCentralPolicyKeyGroupDetails } from '../../../../ngrx/store/central-policy-usage/central-policy-usage.actions';
import { selectCentralPolicyKeyGroupDetails } from '../../../../ngrx/store/central-policy-usage/central-policy-usage.selectors';
import {
  selectIsCentralPolicyKeyGroupDetailsLoading,
  selectIsCentralPolicyKeyGroupDetailsLoadingError,
} from '../../../../ngrx/store/loading-state/loading-state.selectors';
import { AccessApiPolicyService } from '../../../../shared/access-api/policy/access-api-policy.service';
import { GridDataSortService } from '../../../../shared/grid-data-sort-service/grid-data-sort.service';
import { GroupComplianceMode } from '../../../../shared/model/group-compliance-mode';

@Component({
  selector: 'app-central-policy-results',
  templateUrl: './central-policy-results.component.html',
  styleUrls: ['./central-policy-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CentralPolicyResultsComponent {
  @Input() selectedPolicyKey: string;
  @Input() groupId: string;
  @Input() groupComplianceMode: GroupComplianceMode;
  @Output() pipelineAmount = new EventEmitter<number>();

  public dataSource$!: Observable<GridDataResult>;
  public isLoading$!: Observable<boolean>;
  public isLoadingError$!: Observable<boolean>;

  policyResultsInfo: PolicyKeyGroupDetails[];
  dataSource: GridDataResult;
  showToolNameColumn = false;

  gridState: State = {
    filter: {
      logic: 'and',
      filters: [],
    },
    sort: [
      {
        field: 'status.displayKey',
        dir: 'asc',
      },
      {
        field: 'pipelineKey',
        dir: 'asc',
      },
    ],
  };

  public sortSettings: MultipleSortSettings = {
    mode: 'multiple',
    initialDirection: 'asc',
    allowUnsort: true,
    showIndexes: true,
  };

  constructor(
    private readonly store$: Store,
    private readonly accessApiPolicy: AccessApiPolicyService,
    private readonly gridDataSortService: GridDataSortService,
  ) {
    this.dataSource$ = this.store$.select(selectCentralPolicyKeyGroupDetails).pipe(
      map((data) => {
        this.policyResultsInfo = data;
        this.dataStateChanged(this.gridState);
        return this.dataSource;
      }),
    );
    this.isLoading$ = this.store$.select(selectIsCentralPolicyKeyGroupDetailsLoading);
    this.isLoadingError$ = this.store$.select(selectIsCentralPolicyKeyGroupDetailsLoadingError);
  }

  reload() {
    this.store$.dispatch(
      loadCentralPolicyKeyGroupDetails({
        groupId: this.groupId,
        policyKey: this.selectedPolicyKey,
        groupComplianceMode: this.groupComplianceMode,
      }),
    );
  }

  trackByResultId(index: number, item: GridItem) {
    const row = item.data as PolicyKeyGroupDetails;
    return row?.policyResultId;
  }

  mailTo(data: PolicyKeyGroupDetails): void {
    const subject = encodeURIComponent(`Question Regarding Policy: "${data.policyText}"`);
    const lines = [];
    lines.push(`Hello colleagues,`);
    lines.push(``);
    lines.push(`I have a question regarding the following policy: "${data.policyText}".`);
    lines.push(``);
    if (data?.validationErrorMessages && data?.validationErrorMessages.length > 0) {
      lines.push(`The following error messages are shown:`);
      data.validationErrorMessages.forEach((message) => {
        lines.push(`- ${message}`);
      });
      lines.push(``);
    }
    lines.push(`Thank you and best regards,`);
    lines.push(``);

    const body = encodeURIComponent(lines.join('\n'));
    let mailTo = `mailTo:${data?.policyOwner}?subject=${subject}`;
    if (data?.policyOwnerSubstitute) {
      mailTo += `&cc=${data?.policyOwnerSubstitute}`;
    }
    mailTo += `&body=${body}`;
    window.open(mailTo, '_blank', 'noopener,noreferrer');
  }

  downloadFile(data: PolicyKeyGroupDetails): void {
    window.open(this.accessApiPolicy.buildFileLinkUrlForPolicyResult(data.policyResultId), '_blank', 'noopener,noreferrer');
  }

  getDownloadTooltipText(fileName: string, uploadPath: string): string {
    return uploadPath ? `${uploadPath}/${fileName}` : `${fileName}`;
  }

  getToolName(toolName: string, isIgnored: boolean): string {
    if (!toolName || isIgnored) {
      return '&mdash;';
    }
    return toolName;
  }

  dataStateChanged(state) {
    this.gridState = state;
    this.dataSource = process(this.policyResultsInfo, this.gridState);
    this.dataSource.data = this.gridDataSortService.sortPolicyComplianceStateByStatusSortOrder(
      this.gridState.sort,
      this.dataSource.data,
      'status.displayKey',
      'status',
    );
    this.showToolNameColumn = this.policyResultsInfo.some((result) => !!result.toolName);
  }
}
