import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AccessGroupService } from '../../../shared/access-api/access-group.service';
import { AccessPipelineRunService } from '../../../shared/access-api/access-pipeline-run.service';
import { FosstarsDetails, FosstarsSummaryDetail } from '../../model/fosstars';
import * as FosstarsActions from './fosstars.actions';

@Injectable()
export class FosstarsEffects {
  public onLoadFosstarsGroupSummary$ = createEffect(() => {
    return this.action$.pipe(
      ofType(FosstarsActions.loadFosstarsGroupSummary),
      switchMap((action) =>
        this.accessGroupService.getGroupFosstarRatingSummary(action.deliveryId, action.groupComplianceMode).pipe(
          map((groupSummary: FosstarsSummaryDetail) => FosstarsActions.setFosstarsGroupSummary({ groupSummary })),
          catchError((error) => {
            if (error.status === 404) {
              return of(FosstarsActions.loadFosstarsGroupSummaryNotFound({ deliveryId: action.deliveryId }));
            }
            return of(FosstarsActions.loadFosstarsGroupSummaryError({ deliveryId: action.deliveryId }));
          }),
        ),
      ),
    );
  });

  public onLoadFosstarsGroupSummaryDetails$ = createEffect(() => {
    return this.action$.pipe(
      ofType(FosstarsActions.loadFosstarsGroupDetails),
      switchMap((action) =>
        this.accessGroupService.getGroupFosstarRatingDetails(action.deliveryId, action.groupComplianceMode).pipe(
          map((groupDetails: FosstarsDetails[]) => FosstarsActions.setFosstarsGroupDetails({ groupDetails })),
          catchError(() => {
            return of(FosstarsActions.loadFosstarsGroupDetailsError({ deliveryId: action.deliveryId }));
          }),
        ),
      ),
    );
  });

  public onLoadFosstarsSingleSummary$ = createEffect(() => {
    return this.action$.pipe(
      ofType(FosstarsActions.loadFosstarsSingleSummary),
      switchMap((action) => {
        return this.accessApiPipelineRunService.getPipelineRunFosstarRatingSummary(action.pipelineRunId).pipe(
          map((singleDetails) =>
            FosstarsActions.setFosstarsSingleSummary({
              pipelineRunId: action.pipelineRunId,
              singleDetails,
            }),
          ),
          catchError((error) => {
            if (error.status === 404) {
              return of(FosstarsActions.loadFosstarsSingleSummaryNotFound({ pipelineRunId: action.pipelineRunId }));
            }
            return of(FosstarsActions.loadFosstarsSingleSummaryError({ pipelineRunId: action.pipelineRunId }));
          }),
        );
      }),
    );
  });

  public onLoadFosstarsSingleDetails = createEffect(() => {
    return this.action$.pipe(
      ofType(FosstarsActions.loadFosstarsSingleDetails),
      switchMap((action) => {
        return this.accessApiPipelineRunService.getPipelineRunFosstarRatingDetails(action.pipelineRunId).pipe(
          map((singleDetails) =>
            FosstarsActions.setFosstarsSingleDetails({
              singleDetails,
              pipelineRunId: action.pipelineRunId,
            }),
          ),
          catchError(() => {
            return of(FosstarsActions.loadFosstarsSingleDetailsError({ pipelineRunId: action.pipelineRunId }));
          }),
        );
      }),
    );
  });

  constructor(
    private readonly action$: Actions,
    private readonly accessGroupService: AccessGroupService,
    private readonly accessApiPipelineRunService: AccessPipelineRunService,
  ) {}
}
