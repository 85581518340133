import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-list-item-kpis',
  templateUrl: './list-item-kpis.component.html',
  styleUrls: ['./list-item-kpis.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemKpisComponent {
  @Input()
  isLoading: boolean;

  @Input()
  title: string;

  @Input()
  minimized = false;

  @Input()
  flag: string;

  @Input()
  isDeprecationAvailable = false;

  @Input()
  deprecationAnchor: string;

  get safeTitle(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.title);
  }

  constructor(readonly sanitizer: DomSanitizer) {}
}
