<div>
  <app-error-information (reloadClicked)="reloadButtonClicked.emit()" *ngIf="loadingError"></app-error-information>

  <span
    class="status-text status-text-malware-detected-{{ malwareDetected }}"
    [ngClass]="{ clickable: labelClickable }"
    (click)="labelClick()"
    *ngIf="!loadingError"
  >
    <span
      fusionTooltip="Open Details"
      [fusionTooltipDisabled]="!labelClickable"
      fusionTooltipPosition="bottom-right"
      attr.data-test-id="malwarescan-status-text-{{ pipelineRunId ? 'pipeline' : 'aggregated' }}"
      >{{ status }}
    </span>
  </span>
</div>
