import { createReducer, on } from '@ngrx/store';
import { MalwarescanSlice } from '../app-state';
import { initialMalwarescanSlice } from '../initial-slices';
import * as MalwarescanActions from './malwarescan.actions';

export const malwarescanReducer = createReducer(
  initialMalwarescanSlice,
  on(MalwarescanActions.loadMalwarescanGroupSummary, (state): MalwarescanSlice => {
    return { ...state, groupSummary: initialMalwarescanSlice.groupSummary };
  }),
  on(MalwarescanActions.setMalwarescanGroupSummary, (state, props): MalwarescanSlice => {
    return { ...state, groupSummary: props.groupSummary };
  }),
  on(MalwarescanActions.setMalwarescanGroupDetails, (state, props): MalwarescanSlice => {
    return { ...state, groupDetails: [...props.groupDetails] };
  }),
  on(MalwarescanActions.setPipelineRunMalwarescanInformation, (state, props): MalwarescanSlice => {
    const pipelineRunDetails = { ...state.pipelineRunDetails };
    pipelineRunDetails[props.pipelineRunId] = props.pipelineRunDetails;
    return { ...state, pipelineRunDetails };
  }),
);
