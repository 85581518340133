import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { AccessGroupService } from '../../../shared/access-api/access-group.service';
import { AccessPipelineRunService } from '../../../shared/access-api/access-pipeline-run.service';
import * as MalwarescanActions from './malwarescan.actions';
import { MalwarescanDetails, MalwarescanSummary } from '../../model/malwarescan';

@Injectable()
export class MalwarescanEffects {
  public onLoadMalwarescanGroupSummary$ = createEffect(() => {
    return this.action$.pipe(
      ofType(MalwarescanActions.loadMalwarescanGroupSummary),
      switchMap((action) =>
        this.accessGroupService.getGroupSummaryMalwarescanInformation(action.deliveryId, action.groupComplianceMode).pipe(
          map((groupSummary: MalwarescanSummary) => MalwarescanActions.setMalwarescanGroupSummary({ groupSummary })),
          catchError((error) => {
            if (error.status === 404) {
              return of(MalwarescanActions.loadMalwarescanGroupSummaryNotFound({ deliveryId: action.deliveryId }));
            }
            return of(MalwarescanActions.loadMalwarescanGroupSummaryError({ deliveryId: action.deliveryId }));
          }),
        ),
      ),
    );
  });

  public onLoadMalwarescanGroupDetails$ = createEffect(() => {
    return this.action$.pipe(
      ofType(MalwarescanActions.loadMalwarescanGroupDetails),
      switchMap((action) =>
        this.accessGroupService.getGroupDetailsMalwarescanInformation(action.deliveryId, action.groupComplianceMode).pipe(
          map((groupDetails: MalwarescanDetails[]) => MalwarescanActions.setMalwarescanGroupDetails({ groupDetails })),
          catchError(() => {
            return of(MalwarescanActions.loadMalwarescanGroupDetailsError({ deliveryId: action.deliveryId }));
          }),
        ),
      ),
    );
  });

  public onLoadMalwarescanSingle$ = createEffect(() => {
    return this.action$.pipe(
      ofType(MalwarescanActions.loadPipelineRunMalwarescanInformation),
      mergeMap((action) =>
        this.accessPipelineRunService.getPipelineRunMalwarescanInfo(action.pipelineRunId).pipe(
          map((pipelineRunDetails: MalwarescanDetails) =>
            MalwarescanActions.setPipelineRunMalwarescanInformation({
              pipelineRunId: action.pipelineRunId,
              pipelineRunDetails,
            }),
          ),
          catchError((error) => {
            if (error.status === 404) {
              return of(MalwarescanActions.loadMalwarescanPipelineRunNotFound({ pipelineRunId: action.pipelineRunId }));
            }
            return of(MalwarescanActions.loadMalwarescanPipelineRunError({ pipelineRunId: action.pipelineRunId }));
          }),
          takeUntil(
            this.action$.pipe(
              ofType(MalwarescanActions.loadPipelineRunMalwarescanInformation),
              filter((newAction) => newAction.pipelineRunId === action.pipelineRunId),
            ),
          ),
        ),
      ),
    );
  });

  constructor(
    private readonly action$: Actions,
    private readonly accessGroupService: AccessGroupService,
    private readonly accessPipelineRunService: AccessPipelineRunService,
  ) {}
}
