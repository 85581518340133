import { createReducer, on } from '@ngrx/store';
import { initialComplianceCertificatesSlice } from '../initial-slices';
import * as ComplianceCertificatesActions from './compliance-certificates.actions';
import { ComplianceCertificatesSlice } from '../app-state';

export const complianceCertificatesReducer = createReducer(
  initialComplianceCertificatesSlice,
  on(ComplianceCertificatesActions.setPipelineRunComplianceCertificates, (state, props): ComplianceCertificatesSlice => {
    const pipelineRunsDetails = { ...state.pipelineRunsDetails };
    pipelineRunsDetails[props.pipelineRunId] = props.documents;
    return { ...state, pipelineRunsDetails: pipelineRunsDetails };
  }),
);
