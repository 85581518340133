import { createAction, props } from '@ngrx/store';
import { GroupComplianceMode } from '../../../shared/model/group-compliance-mode';
import { FosstarsDetails, FosstarsSummaryDetail } from '../../model/fosstars';

const fosstarsKey = 'Fosstars';

export const loadFosstarsGroupSummary = createAction(
  `[${fosstarsKey}] Trigger loading of ${fosstarsKey} information for group-summary from backend`,
  props<{ deliveryId: string; groupComplianceMode: GroupComplianceMode }>(),
);
export const setFosstarsGroupSummary = createAction(
  `[${fosstarsKey}] Set ${fosstarsKey} information for group-summary from backend in store`,
  props<{ groupSummary: FosstarsSummaryDetail }>(),
);

export const loadFosstarsGroupSummaryNotFound = createAction(
  `[${fosstarsKey}] Trigger loading of ${fosstarsKey} information for group-summary from backend not found`,
  props<{ deliveryId: string }>(),
);

export const loadFosstarsGroupSummaryError = createAction(
  `[${fosstarsKey}] Trigger loading of ${fosstarsKey} information for group-summary from backend error`,
  props<{ deliveryId: string }>(),
);

export const loadFosstarsGroupDetails = createAction(
  `[${fosstarsKey}] Trigger loading of ${fosstarsKey} information for group-details from backend`,
  props<{ deliveryId: string; groupComplianceMode: GroupComplianceMode }>(),
);

export const loadFosstarsGroupDetailsError = createAction(
  `[${fosstarsKey}] Trigger loading of ${fosstarsKey} information for group-details from backend error`,
  props<{ deliveryId: string }>(),
);

export const setFosstarsGroupDetails = createAction(
  `[${fosstarsKey}] Set ${fosstarsKey} information for group-details from backend in store`,
  props<{ groupDetails: FosstarsDetails[] }>(),
);

export const loadFosstarsSingleSummary = createAction(
  `
  [${fosstarsKey}] Load ${fosstarsKey} summary information for pipelinerun from backend in store`,
  props<{ pipelineRunId: number }>(),
);

export const loadFosstarsSingleSummaryError = createAction(
  `
  [${fosstarsKey}] ${fosstarsKey} summary information for pipelinerun from backend error`,
  props<{ pipelineRunId: number }>(),
);

export const loadFosstarsSingleSummaryNotFound = createAction(
  `
  [${fosstarsKey}] ${fosstarsKey} summary information for pipelinerun from backend not found`,
  props<{ pipelineRunId: number }>(),
);

export const setFosstarsSingleSummary = createAction(
  `
  [${fosstarsKey}] Set ${fosstarsKey} summary information for pipelinerun from backend in store`,
  props<{ pipelineRunId: number; singleDetails: FosstarsSummaryDetail[] }>(),
);

export const loadFosstarsSingleDetails = createAction(
  `[${fosstarsKey}] Trigger loading of ${fosstarsKey} information for single-details from backend`,
  props<{ pipelineRunId: number }>(),
);

export const loadFosstarsSingleDetailsError = createAction(
  `[${fosstarsKey}] Trigger loading of ${fosstarsKey} information for single-details from backend error`,
  props<{ pipelineRunId: number }>(),
);

export const setFosstarsSingleDetails = createAction(
  `[${fosstarsKey}] Set ${fosstarsKey} information for single-details from backend in store`,
  props<{ pipelineRunId: number; singleDetails: FosstarsDetails[] }>(),
);
