import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../app/shared/environment.service';

/**
 * HTTP Interceptor: Intercepts the http calls done by ngrx/data to append the x-delivery-guid header for auth. through IF-Proxy
 */
@Injectable({
  providedIn: 'root',
})
export class CumulusAuthInterceptorService implements HttpInterceptor {
  _defaultHeaders: HttpHeaders;

  get defaultHeaders() {
    if (!this._defaultHeaders) {
      const deliveryGuid = this.environmentService.getDeliveryGuid();
      if (deliveryGuid) {
        this._defaultHeaders = new HttpHeaders().append('x-delivery-guid', deliveryGuid);
      }
    }
    return this._defaultHeaders;
  }

  constructor(private readonly environmentService: EnvironmentService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      headers: this.defaultHeaders,
    });
    return next.handle(req);
  }
}
