import { createReducer, on } from '@ngrx/store';
import { Psl1Slice } from '../app-state';
import { initialPsl1Slice } from '../initial-slices';
import * as Psl1Actions from './psl-1.actions';

export const psl1Reducer = createReducer(
  initialPsl1Slice,
  on(Psl1Actions.loadPsl1GroupSummary, (state, _): Psl1Slice => {
    return { ...state, aggregationSummary: initialPsl1Slice.aggregationSummary };
  }),
  on(Psl1Actions.setPsl1GroupSummary, (state, props): Psl1Slice => {
    return { ...state, aggregationSummary: props.groupSummary };
  }),
  on(Psl1Actions.setPsl1GroupDetails, (state, props): Psl1Slice => {
    return { ...state, aggregationDetails: [...props.groupDetails] };
  }),
  on(Psl1Actions.setPsl1SingleDetails, (state, props): Psl1Slice => {
    const selectedPipelineRuns = { ...state.selectedPipelineRuns };
    selectedPipelineRuns[props.pipelineRunId] = props.singleDetails;
    return { ...state, selectedPipelineRuns };
  }),
);
