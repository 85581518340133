import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ComplianceService } from '../../../shared/compliance-service/compliance.service';
import { loadAllowedScmsList, loadFailedAllowedScms, setAllowedScms } from './allowed-scms.actions';
import { selectAllowedScmsSlice } from './allowed-scms.selectors';

import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { AllowedScms } from './allowed-scms.model';
@Injectable()
export class AllowedScmsEffects {
  public onLoadGroupSummary$ = createEffect(() => {
    return this.action$.pipe(
      ofType(loadAllowedScmsList),
      concatLatestFrom(() => this.store$.select(selectAllowedScmsSlice)),
      switchMap(([action, fromStore]) => {
        if (fromStore.allowedScms.length > 0) {
          return of(setAllowedScms({ allowedScms: fromStore.allowedScms }));
        }
        return this.comlianceService.getSlc25Whitelist().pipe(
          map((allowedScms: AllowedScms[]) => {
            return setAllowedScms({ allowedScms });
          }),
          catchError((error) => {
            return of(loadFailedAllowedScms());
          }),
        );
      }),
    );
  });

  constructor(
    private readonly action$: Actions,
    private readonly comlianceService: ComplianceService,
    private readonly store$: Store,
  ) {}
}
