import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { AccessApiPolicyService } from '../../../shared/access-api/policy/access-api-policy.service';
import * as CustomPolicyUsageActions from './custom-policy-usage.actions';

@Injectable()
export class CustomPolicyUsageEffects {
  public onLoadCustomPolicyUsageGroupSummary$ = createEffect(() => {
    return this.action$.pipe(
      ofType(CustomPolicyUsageActions.loadCustomPolicyUsageGroupSummary),
      switchMap((action) =>
        this.accessApiPolicyService.getGroupCustomPolicyUsageSummary(action.groupId, action.groupComplianceMode).pipe(
          map((groupSummary) =>
            CustomPolicyUsageActions.setCustomPolicyUsageGroupSummary({
              groupSummary,
            }),
          ),
          catchError((error: HttpErrorResponse) => {
            const groupId = action.groupId;
            const groupComplianceMode = action.groupComplianceMode;
            if (error.status == 404) {
              return of(CustomPolicyUsageActions.loadCustomPolicyUsageGroupSummaryNotFound({ groupId, groupComplianceMode }));
            }
            return of(CustomPolicyUsageActions.loadCustomPolicyUsageGroupSummaryError({ groupId, groupComplianceMode }));
          }),
        ),
      ),
    );
  });

  public onLoadCustomPolicyUsageGroupDetails$ = createEffect(() => {
    return this.action$.pipe(
      ofType(CustomPolicyUsageActions.loadCustomPolicyUsageGroupDetails),
      switchMap((action) =>
        this.accessApiPolicyService.getGroupCustomPolicyUsageDetails(action.groupId, action.groupComplianceMode).pipe(
          map((groupDetails) =>
            CustomPolicyUsageActions.setCustomPolicyUsageGroupDetails({
              groupDetails,
            }),
          ),
          catchError(() => {
            return of(
              CustomPolicyUsageActions.loadCustomPolicyUsageGroupDetailsError({
                groupId: action.groupId,
                groupComplianceMode: action.groupComplianceMode,
              }),
            );
          }),
        ),
      ),
    );
  });

  public onLoadCustomPolicyKeyGroupDetails$ = createEffect(() => {
    return this.action$.pipe(
      ofType(CustomPolicyUsageActions.loadCustomPolicyKeyGroupDetails),
      mergeMap((action) => {
        return this.accessApiPolicyService
          .getCustomPolicyKeyGroupDetails(action.groupId, action.policyKey, action.groupComplianceMode)
          .pipe(
            map((singleSummary) =>
              CustomPolicyUsageActions.setCustomPolicyKeyGroupDetails({
                groupPolicyKeySummary: singleSummary,
              }),
            ),
            catchError((error) => {
              if (error.status === 404) {
                return of(
                  CustomPolicyUsageActions.loadCustomPolicyKeyGroupDetailsNotFound({
                    groupId: action.groupId,
                    policyKey: action.policyKey,
                    groupComplianceMode: action.groupComplianceMode,
                  }),
                );
              }
              return of(
                CustomPolicyUsageActions.loadCustomPolicyKeyGroupDetailsError({
                  groupId: action.groupId,
                  policyKey: action.policyKey,
                  groupComplianceMode: action.groupComplianceMode,
                }),
              );
            }),
            takeUntil(
              this.action$.pipe(
                ofType(CustomPolicyUsageActions.loadCustomPolicyKeyGroupDetails),
                filter((newAction) => newAction.groupId === action.groupId && newAction.policyKey === action.policyKey),
              ),
            ),
          );
      }),
    );
  });

  constructor(
    private readonly action$: Actions,
    private readonly accessApiPolicyService: AccessApiPolicyService,
  ) {}
}
