<div class="dialogHeader">
  <p class="dialogTitle">Malware Scan Results</p>
  <mat-icon
    aria-hidden="false"
    aria-label="cancel"
    class="closeButton"
    (click)="closeDialog()"
    data-test-id="malwarescan-detail-popup-close-btn"
    >cancel
  </mat-icon>
</div>

<div
  *ngIf="{
    isLoading: isLoading$ | async,
    isLoadingError: isLoadingError$ | async,
    dataSource: dataSource$ | async,
  } as data"
  class="flex flex-col"
  mat-dialog-content
>
  <div class="flex h-full w-full">
    <app-dialog-error-information (reloadClicked)="reload()" *ngIf="data?.isLoadingError"></app-dialog-error-information>
    <kendo-grid
      class="flex h-full w-full"
      id="malwarescan-detail-grid"
      data-test-id="malwarescan-detail-popup-grid"
      *ngIf="!data?.isLoadingError"
      [data]="dataSource"
      [loading]="data.isLoading"
      [sortable]="true"
      [sort]="gridState.sort"
      [filterable]="true"
      [filter]="gridState.filter"
      (dataStateChange)="dataStateChanged($event)"
    >
      <kendo-grid-column field="pipelineKey" title="Pipeline" width="30%">
        <ng-template kendoGridCellTemplate kendoTooltip let-dataItem>
          <app-kpi-column-pipeline [item]="dataItem" fusionFeatureUsageTracker="open-malwarescan-pipeline-url"></app-kpi-column-pipeline>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="documents" title="Documents" width="45%">
        <ng-template kendoGridCellTemplate kendoTooltip let-dataItem>
          <span *ngFor="let link of dataItem.documents; let first = first">
            <br *ngIf="!first" />
            <a
              target="_blank"
              class="link"
              [href]="buildFileLinkUrlForStepResult(link.stepResultId)"
              fusionTooltip="Download document"
              fusionFeatureUsageTracker="open-malwarescan-document"
              [fusionFeatureUsageTrackerEvents]="['click']"
              rel="noopener noreferrer"
              data-test-id="malwarescan-detail-popup-grid-document-links"
            >
              <span class="link-text">{{ link.fileName }}</span>
              <fusion-icon id="malwarescanLink" name="download" size="S"></fusion-icon
            ></a>
          </span>
          <span *ngIf="dataItem?.documents.length === 0" class="no-link">No documents available</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="displayStatus" title="Status" width="25%">
        <ng-template kendoGridCellTemplate kendoTooltip let-dataItem>
          <div class="status-text-tooltip-container flex flex-row justify-start items-center">
            <span class="status-circle status-circle-{{ dataItem?.malwareDetected ? 'NOT_COMPLIANT' : 'COMPLIANT' }}"></span>
            <span>{{ dataItem?.displayStatus }}</span>
          </div>
        </ng-template>
      </kendo-grid-column>

      <ng-template kendoGridLoadingTemplate>
        <app-table-loading-indicator></app-table-loading-indicator>
      </ng-template>
    </kendo-grid>
  </div>
</div>
