<div *ngIf="showFilter" class="filter-components-input">
  <input class="k-textbox k-input k-rounded-md" (input)="onInput($event)" />
</div>
<ul>
  <li
    #itemElement
    *ngFor="let component of currentData; let i = index"
    attr.data-test-id="{{ 'component-label-' + component }}"
    (click)="onSelectionChange(valueAccessor(component), itemElement)"
    [ngClass]="{ 'k-selected': isItemSelected(component) }"
  >
    <input
      type="checkbox"
      id="chk-{{ valueAccessor(component) }}"
      (focus)="onFocus(itemElement)"
      kendoCheckBox
      [checked]="isItemSelected(component)"
      attr.data-test-id="{{ 'checkbox-label-' + component }}"
    />
    <label class="k-multiselect-checkbox k-checkbox-label" for="chk-{{ valueAccessor(component) }}">
      {{ textAccessor(component) }}
    </label>
  </li>
</ul>
