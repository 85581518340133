import { createAction, props } from '@ngrx/store';
import { GroupComplianceMode } from 'src/app/shared/model/group-compliance-mode';
import {
  PolicyResultsGroupDetails,
  PolicyResultsGroupSummary,
  PolicyResultsPipelineRunDetail,
  PolicyResultsPipelineRunSummary,
} from '../../model/policy-results';

export const loadCentralPolicyResultsSingleSummary = createAction(
  `[Central Policy Results] Trigger loading of policy results information for single-summary from backend`,
  props<{ pipelineRunId: number }>(),
);
export const setCentralPolicyResultsSingleSummary = createAction(
  `[Central Policy Results] Set policy results information for single-summary from backend in store`,
  props<{ pipelineRunId: number; singleSummary: PolicyResultsPipelineRunSummary }>(),
);

export const setCentralPolicyResultsGroupSummary = createAction(
  `[Central Policy Results] Set policy results information for group-summary from backend in store`,
  props<{ groupSummary: PolicyResultsGroupSummary }>(),
);

export const setCentralPolicyResultsGroupDetails = createAction(
  `[Central Policy Results] Set policy results information for group-details from backend in store`,
  props<{ groupDetails: PolicyResultsGroupDetails[] }>(),
);

export const loadCentralPolicyResultsSingleSummaryError = createAction(
  `[Central Policy Results] Loading of policy results single-summary from backend error`,
  props<{ pipelineRunId: number }>(),
);

export const loadCentralPolicyResultsGroupSummaryError = createAction(
  `[Central Policy Results] Loading of policy results group-summary from backend error`,
  props<{ groupId: string }>(),
);

export const loadCentralPolicyResultsGroupDetailsError = createAction(
  `[Central Policy Results] Loading of policy results group-details from backend error`,
  props<{ groupId: string }>(),
);

export const loadCentralPolicyResultsSingleSummaryNotFound = createAction(
  `[Central Policy Results] Loading of policy results single-summary from backend not found`,
  props<{ pipelineRunId: number }>(),
);

export const loadCentralPolicyResultsGroupSummaryNotFound = createAction(
  `[Central Policy Results] Loading of policy results group-summary from backend not found`,
  props<{ groupId: string }>(),
);

export const loadCentralPolicyResultsSingleDetails = createAction(
  `[Central Policy Results] Trigger loading of policy results information for single-details from backend`,
  props<{ pipelineRunId: number }>(),
);

export const setCentralPolicyResultsSingleDetails = createAction(
  `[Central Policy Results] Set policy results information for single-details from backend in store`,
  props<{ pipelineRunId: number; singleDetails: PolicyResultsPipelineRunDetail[] }>(),
);

export const loadCentralPolicyResultsSingleDetailsError = createAction(
  `[Central Policy Results] Loading of policy results single-details from backend error`,
  props<{ pipelineRunId: number }>(),
);

export const loadCentralPolicyResultsSingleDetailsNotFound = createAction(
  `[Central Policy Results] Loading of policy results single-details from backend not found`,
  props<{ pipelineRunId: number }>(),
);

export const loadCentralPolicyResultsGroupSummary = createAction(
  `[Central Policy Results] Trigger loading of policy results information for group-summary from backend`,
  props<{ groupId: string; groupComplianceMode: GroupComplianceMode }>(),
);

export const loadCentralPolicyResultsGroupDetails = createAction(
  `[Central Policy Results] Trigger loading of policy results information for group-details from backend`,
  props<{ groupId: string; groupComplianceMode: GroupComplianceMode }>(),
);
