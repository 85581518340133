<ng-container
  *ngIf="{
    loading: isLoading$ | async,
    loadingError: isLoadingError$ | async,
    loadingNotFound: isLoadingNotFound$ | async,
    complianceInformation: fc3complianceInfo$ | async,
  } as data"
>
  <app-list-item-kpis [flag]="'kpi.flag.beta' | translate" [isLoading]="data.loading" [title]="'kpi.tile.fc3.title' | translate">
    <div class="flex flex-col gap-3.5 w-full" kpi-tile-content>
      <div *ngIf="currentPipelineRunId" class="flex h-full w-full" kpi-tile-content>
        <div *ngIf="data.complianceInformation?.count" class="flex flex-col h-full w-full">
          <div class="flex flex-row justify-between items-center">
            <span class="label-text">Success</span>
            <span class="label-value">{{ data.complianceInformation?.success }}</span>
          </div>
          <mat-divider></mat-divider>
          <div class="flex flex-row justify-between items-center">
            <span class="label-text">Skipped</span>
            <span class="label-value">{{ data.complianceInformation?.skipped }}</span>
          </div>
          <mat-divider></mat-divider>
          <div class="flex flex-row justify-between items-center">
            <span class="label-text">Failed</span>
            <span class="label-value">{{ data.complianceInformation?.error }}</span>
          </div>
        </div>
      </div>
      <app-compliance-status-label
        [complianceInformation]="data.complianceInformation"
        [loadingState]="{
          loadingError: data.loadingError,
          loadingNotFound: data.loadingNotFound,
        }"
        [noFilesUploadedCondition]="!data.complianceInformation?.count"
        (labelClicked)="onSummaryStatusClicked()"
        (reloadButtonClicked)="reloadInfo()"
      ></app-compliance-status-label>
    </div>

    <div kpi-tile-footer>
      <span fusionFeatureUsageTracker="kpi-open-fc3-wiki">
        According to the checked
        <a
          href="https://github.tools.sap/P4TEAM/cumulus-compliance-configuration/blob/master/FC-3.yml"
          target="_blank"
          rel="noopener noreferrer"
          class="clickable"
        >
          <span>FC-3/FC-4 compliance <br />rules</span><fusion-icon id="fc3LinkIcon" name="link-external" size="S"></fusion-icon>
        </a> </span
      >.
    </div>
  </app-list-item-kpis>
</ng-container>
