<ng-container
  *ngIf="{
    loading: isPipelineRunProcessingStatusLoading$ | async,
    loadingError: isPipelineRunProcessingStatusLoadingError$ | async,
    loadingNotFound: isPipelineRunProcessingStatusLoadingNotFound$ | async,
    processingStatus: pipelineRunProcessingStatus$ | async,
  } as data"
>
  <app-list-item-kpis [isLoading]="data.loading" [title]="'kpi.tile.documents.title' | translate">
    <div class="flex flex-grow" kpi-tile-content fusionFeatureUsageTracker="kpi-open-documents-details">
      <!-- some loading error handling -->
      <app-error-information
        *ngIf="data.loadingError"
        errorText="Error loading Documents information. Please try it again later."
        (reloadClicked)="reloadInfo()"
      >
      </app-error-information>

      <span *ngIf="data.loadingNotFound" class="flex justify-start items-center">
        <span class="status-text-not-available">No Documents information found for this Pipeline Run.</span>
      </span>

      <!-- actual display logic -->
      <ng-container *ngIf="data.processingStatus && !data.loadingError && !data.loadingNotFound">
        <div class="flex flex-col w-full" *ngIf="data?.processingStatus?.numberOfFiles > 0">
          <div class="flex flex-row justify-start items-center">
            <span class="label-text">Uploaded Documents</span>
            <span class="flex flex-grow"></span>
            <span class="status-text open-details" (click)="openDetailsDialog()" data-test-id="uploaded-documents-count-text">{{
              data?.processingStatus?.numberOfFiles
            }}</span>
          </div>
          <div class="flex flex-row justify-start items-center">
            <span class="label-text">Processed Documents</span>
            <span class="flex flex-grow"></span>
            <span
              (click)="openDetailsDialog()"
              class="status-text open-details"
              [class.error]="data?.processingStatus?.numberOfProcessedFiles < data?.processingStatus?.numberOfFiles"
            >
              {{ data?.processingStatus?.numberOfProcessedFiles }}
            </span>
          </div>
        </div>
        <div class="flex flex-col" *ngIf="data?.processingStatus && data?.processingStatus?.numberOfFiles === 0">
          <span>No files uploaded!</span>
        </div>
      </ng-container>
    </div>
  </app-list-item-kpis>
</ng-container>
