import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { DataStateChangeEvent, GridDataResult, GridItem } from '@progress/kendo-angular-grid';
import { POPUP_CONTAINER, PopupService } from '@progress/kendo-angular-popup';
import { State, process } from '@progress/kendo-data-query';
import { MatomoService } from '@tools/fusion';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PipelineRunDocument } from '../../../model/documents/pipeline-run-document.model';
import { PipelineRunModeIds } from '../../../model/pipeline-run/pipeline-run';
import { loadPipelineRunDocuments } from '../../../ngrx/store/documents/documents.actions';
import { selectPipelineRunCumulusDocuments } from '../../../ngrx/store/documents/documents.selectors';
import {
  selectIsPipelienRunCumulusDocumentsLoading,
  selectIsPipelienRunCumulusDocumentsLoadingError,
} from '../../../ngrx/store/loading-state/loading-state.selectors';
import { AccessApiService } from '../../../shared/access-api/access-api.service';
import { DocumentCoverageService } from './document-coverage/document-coverage.service';

export interface DocumentsDetailsDialogData {
  pipelineId: string;
  pipelineRunId: number;
}

@Component({
  selector: 'app-documents-details-dialog',
  templateUrl: './documents-details-dialog.component.html',
  styleUrls: ['./documents-details-dialog.component.scss'],
  providers: [
    {
      // Provide the current component element as Popup container.
      provide: POPUP_CONTAINER,
      useExisting: ElementRef,
    },
    // Create a new Popup Service that uses the provided container.
    PopupService,
  ],
})
export class DocumentsDetailsDialogComponent implements OnInit {
  gridData$: Observable<GridDataResult>;
  isPipelineRunCumulusDocumentsLoading$!: Observable<boolean>;
  isPipelineRunCumulusDocumentsLoadingError$!: Observable<boolean>;

  pipelineRunCumulusDocuments: PipelineRunDocument[];
  isOptimizedPipeline: boolean;

  gridState: State = {
    skip: 0,
    take: 100000,
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: DocumentsDetailsDialogData,
    private readonly dialogRef: MatDialogRef<DocumentsDetailsDialogComponent>,
    private readonly documentCoverageService: DocumentCoverageService,
    private readonly matomoService: MatomoService,
    private readonly accessApiService: AccessApiService,
    private readonly store$: Store,
  ) {}

  ngOnInit(): void {
    this.dialogRef.updateSize('80vw', 'auto');
    this.loadPipelineRunDocuments();
  }

  loadPipelineRunDocuments() {
    this.gridData$ = this.store$.select(selectPipelineRunCumulusDocuments(this.dialogData.pipelineRunId)).pipe(
      map((pipelineRunDocuments) => {
        if (pipelineRunDocuments) {
          this.pipelineRunCumulusDocuments = pipelineRunDocuments.map((document) =>
            this.documentCoverageService.addCoverageInformation({ ...document }),
          );
          this.isOptimizedPipeline = this.checkForOptimizedPipeline(this.pipelineRunCumulusDocuments);
          return process([...this.pipelineRunCumulusDocuments], this.gridState);
        }
      }),
    );
    this.isPipelineRunCumulusDocumentsLoading$ = this.store$.select(
      selectIsPipelienRunCumulusDocumentsLoading(this.dialogData.pipelineRunId),
    );
    this.isPipelineRunCumulusDocumentsLoadingError$ = this.store$.select(
      selectIsPipelienRunCumulusDocumentsLoadingError(this.dialogData.pipelineRunId),
    );
  }

  gridDataStateChange(gridState: DataStateChangeEvent): void {
    this.gridState = gridState;
    this.gridData$ = of(process([...this.pipelineRunCumulusDocuments], this.gridState));
    this.matomoService.trackEvent('click-filter', 'filter-documents-in-pipeline-run');
  }

  public checkForOptimizedPipeline(documents: PipelineRunDocument[]): boolean {
    return !!documents.find(
      (document) =>
        document.pipelineRunModeId === PipelineRunModeIds.Optimized_Scheduled ||
        document.pipelineRunModeId === PipelineRunModeIds.Optimized_Commit,
    );
  }

  buildFileLinkUrl(document: PipelineRunDocument): string {
    if (
      document.stepResultTypeId === -1 &&
      document.fileName.includes('certificate.json') &&
      this.dialogData.pipelineId &&
      document.pipelineRunId
    ) {
      return this.accessApiService.buildFileLinkForComplianceCertificate(
        document.fileName,
        this.dialogData.pipelineId,
        document.pipelineRunId,
      );
    } else if (document.stepResultId) {
      return this.accessApiService.buildFileLinkUrlForStepResult(document.stepResultId);
    }
  }

  reload() {
    this.store$.dispatch(loadPipelineRunDocuments({ pipelineRunId: this.dialogData.pipelineRunId }));
  }

  trackByStepResultId(index: number, item: GridItem) {
    const row = item.data as PipelineRunDocument;
    return row?.stepResultId;
  }

  closeDialog(data?: any) {
    this.dialogRef.close(data);
  }
}
