import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ComplianceInfoDetails, ComplianceInfoSummary, ComplianceStatus } from '../../ngrx/model/compliance-information';
import { AllowedScms } from '../../ngrx/store/allowed-scms/allowed-scms.model';
import { EnvironmentService } from '../environment.service';
import { ComplianceInfoDetailsRaw, ComplianceInfoSummaryRaw } from '../model/compliance-information';
import { GroupComplianceMode } from '../model/group-compliance-mode';
import { ProductStandard } from '../model/product-standard';

@Injectable({
  providedIn: 'root',
})
export class ComplianceService {
  _baseUrl: string;

  constructor(
    private readonly http: HttpClient,
    private readonly envService: EnvironmentService,
  ) {}

  get baseUrl() {
    if (!this._baseUrl) {
      this._baseUrl = this.envService.getBaseUrl();
    }
    return this._baseUrl;
  }

  // Method to determin which group-summary service should called
  getGroupSummaryComplianceInformationForAction(
    psr: ProductStandard,
    deliveryId: string,
    mode: GroupComplianceMode,
  ): Observable<ComplianceInfoSummary> {
    const params = this.buildHttpParams(mode);
    return this.http
      .get<ComplianceInfoSummaryRaw>(
        `https://${this.baseUrl}/zprs/gcp/access-api/api/v3/groups/${deliveryId}/compliance-info-summary/${psr.key}`,
        { params },
      )
      .pipe(
        map((raw) => {
          return { ...raw, complianceStatus: ComplianceStatus.getByKey(raw.complianceStatus) };
        }),
      );
  }

  // Method to determin which group-details service should called
  getGroupDetailsComplianceInformationForAction<T extends ComplianceInfoDetails>(
    psr: ProductStandard,
    deliveryId: string,
    mode: GroupComplianceMode,
  ): Observable<T[]> {
    const params = this.buildHttpParams(mode);
    return this.http
      .get<
        ComplianceInfoDetailsRaw[]
      >(`https://${this.baseUrl}/zprs/gcp/access-api/api/v3/groups/${deliveryId}/compliance-info/${psr.key}`, { params })
      .pipe(
        map((responses) => {
          return responses.map((raw) => {
            return {
              ...raw,
              complianceStatus: ComplianceStatus.getByKey(raw.complianceStatus),
              leadingComplianceStatus: ComplianceStatus.getByKey(raw.leadingComplianceStatus),
              relatedComplianceStatus: ComplianceStatus.getByKey(raw.relatedComplianceStatus),
            } as T;
          });
        }),
      );
  }

  // Method to determin which single-details service should called
  getSingleDetailsComplianceInformationForAction<T extends ComplianceInfoDetails>(
    psr: ProductStandard,
    pipelineRunId: number,
  ): Observable<T> {
    return this.http
      .get<ComplianceInfoDetailsRaw>(
        `https://${this.baseUrl}/zprs/gcp/access-api/api/v3/pipelineRuns/${pipelineRunId}/compliance-details/${psr.key}`,
      )
      .pipe(
        map((raw) => {
          return {
            ...raw,
            complianceStatus: ComplianceStatus.getByKey(raw.complianceStatus),
            leadingComplianceStatus: ComplianceStatus.getByKey(raw.leadingComplianceStatus),
            relatedComplianceStatus: ComplianceStatus.getByKey(raw.relatedComplianceStatus),
          } as T;
        }),
      );
  }

  getSlc25Whitelist(): Observable<AllowedScms[]> {
    return this.http.get<AllowedScms[]>(`https://${this.baseUrl}/zprs/gcp/compliance-service/api/slc-25/whitelist`);
  }

  private buildHttpParams(mode: GroupComplianceMode) {
    // Use timestamp (t) to avoid getting old (cached) data from IF-Proxy
    return { ...mode.getReleaseStatus().toHttpParam(), t: new Date().getTime().toString() };
  }
}
