import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RequirementDetail } from '../../model/requirement-detail/requirement-detail';
import { Observable } from 'rxjs';
import { selectPipelineRunRequirements } from '../../ngrx/store/fc-2/fc-2.selectors';
import { Store } from '@ngrx/store';
import { loadPipelineRunRequirements } from '../../ngrx/store/fc-2/fc-2.actions';
import { RequirementsDetailDialogComponent } from '../list-item-kpis-fc2/dialog/requirements-detail-dialog.component';
import {
  selectFc2PipelineRunRequirementsIsLoading,
  selectFc2PipelineRunRequirementsLoadingError,
  selectFc2PipelineRunRequirementsLoadingNotFound,
} from '../../ngrx/store/loading-state/loading-state.selectors';
import { PipelineRunRequirement } from '../../model/pipeline-run/pipeline-run';

@Component({
  selector: 'app-list-item-kpis-requirements',
  templateUrl: './list-item-kpis-requirements.component.html',
  styleUrls: ['./list-item-kpis-requirements.component.scss'],
})
export class ListItemKpisRequirementsComponent implements OnChanges {
  @Input() readOnly = false;
  @Input() pipelineRunId: number;

  requirements$: Observable<PipelineRunRequirement[]>;
  loading$: Observable<boolean>;
  loadingError$: Observable<boolean>;
  loadingNotFound$: Observable<boolean>;

  constructor(
    private readonly matDialog: MatDialog,
    private readonly store$: Store,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pipelineRunId) {
      this.pipelineRunChanged();
    }
  }

  pipelineRunChanged() {
    const pipelineRunId = this.pipelineRunId;
    this.loading$ = this.store$.select(selectFc2PipelineRunRequirementsIsLoading(pipelineRunId));
    this.loadingError$ = this.store$.select(selectFc2PipelineRunRequirementsLoadingError(pipelineRunId));
    this.loadingNotFound$ = this.store$.select(selectFc2PipelineRunRequirementsLoadingNotFound(pipelineRunId));
    this.requirements$ = this.store$.select(selectPipelineRunRequirements(pipelineRunId));
  }

  reloadInfo() {
    this.store$.dispatch(loadPipelineRunRequirements({ pipelineRunId: this.pipelineRunId }));
  }

  openRequirementDetails(requirements: PipelineRunRequirement[]) {
    const requirementDetails: RequirementDetail[] = requirements.map((req) => {
      return { key: req.requirementKey };
    }) as RequirementDetail[];

    this.matDialog.open(RequirementsDetailDialogComponent, {
      data: {
        showDetail: false,
        readOnly: this.readOnly,
        requirementDetails,
      },
      panelClass: 'requirement-details-container',
    });
  }
}
