<div class="flex flex-col gap-1" data-test-id="pipeline-run-info-in-dialog">
  <span class="text-overflow" fusionTooltip="{{ optimizedPipelineRun.pipelineRunStartDateTime | date: 'MMM d, y hh:mm:ss a' }}"
    ><strong>{{ optimizedPipelineRun.pipelineRunStartDateTime | date: 'MMM d, y hh:mm:ss a' }}</strong></span
  >
  <span class="text-overflow" fusionTooltip="{{ optimizedPipelineRun.pipelineRunKey }}">{{ optimizedPipelineRun.pipelineRunKey }}</span>
  <div>
    <mat-chip [disabled]="true" [disableRipple]="true">
      <small class="text-overflow">{{ 'pipeline.mode.' + optimizedPipelineRun.pipelineRunModeId + '.flag' | translate }}</small>
    </mat-chip>
  </div>
</div>
