import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { orderBy, process, SortDescriptor, State } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SonarQubeCoverageGroupDetails } from '../../../model/sonarqube/sonar-qube.model';
import {
  selectIsSonarQubeCoverageGroupDetailsLoading,
  selectIsSonarQubeCoverageGroupDetailsLoadingError,
} from '../../../ngrx/store/loading-state/loading-state.selectors';
import { loadSonarQubeCoverageGroupDetails } from '../../../ngrx/store/sonar-qube-coverage/sonar-qube-coverage.actions';
import { selectSonarQubeCoverageGroupDetails } from '../../../ngrx/store/sonar-qube-coverage/sonar-qube-coverage.selectors';
import { isNullOrUndefined } from '../../../shared/utils';
import { KpiDialogsData } from '../../kpis.module';

@Component({
  selector: 'app-sonarqube-coverage-details-dialog',
  templateUrl: './sonarqube-coverage-details-dialog.component.html',
  styleUrls: ['./sonarqube-coverage-details-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SonarqubeCoverageDetailsDialogComponent implements OnInit {
  public dataSource$!: Observable<GridDataResult>;
  public isLoading$!: Observable<boolean>;
  public isLoadingError$!: Observable<boolean>;

  sonarQubeCoverageSorted: SonarQubeCoverageGroupDetails[];
  dataSource: GridDataResult;

  gridState: State = {
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  sort: SortDescriptor[] = [
    {
      field: 'pipelineKey',
      dir: 'asc',
    },
  ];

  isNullOrUndefined = isNullOrUndefined;

  constructor(
    private readonly dialogRef: MatDialogRef<SonarqubeCoverageDetailsDialogComponent>,
    private readonly store$: Store,
    @Inject(MAT_DIALOG_DATA) public dialogData: KpiDialogsData,
  ) {
    this.dataSource$ = this.store$.select(selectSonarQubeCoverageGroupDetails).pipe(
      map((data) => {
        this.sonarQubeCoverageSorted = orderBy(data, this.sort);
        this.dataSource = process(this.sonarQubeCoverageSorted, {});
        return this.dataSource;
      }),
    );
    this.isLoading$ = this.store$.select(selectIsSonarQubeCoverageGroupDetailsLoading);
    this.isLoadingError$ = this.store$.select(selectIsSonarQubeCoverageGroupDetailsLoadingError);
  }

  ngOnInit(): void {
    this.dialogRef.updateSize('70vw', 'auto');
  }

  dataStateChanged(state) {
    this.gridState = state;
    this.dataSource = process(this.sonarQubeCoverageSorted, this.gridState);
  }

  reload() {
    this.store$.dispatch(
      loadSonarQubeCoverageGroupDetails({
        deliveryId: this.dialogData?.deliveryId,
        groupComplianceMode: this.dialogData?.groupComplianceMode,
      }),
    );
  }

  closeDialog(data?: any) {
    this.dialogRef.close(data);
  }
}
