import { selectSelectedPipelineRunForPipeline } from '../pipeline-run/pipeline-run.selectors';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { JiraService } from '../../../shared/jira-service/jira.service';
import { AccessPipelineRunService } from '../../../shared/access-api/access-pipeline-run.service';
import * as TestRegressionActions from './test-regression.actions';
import { catchError, filter, map, mergeMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { selectDeliveryFixVersions } from '../deliveries/deliveries.selectors';
import { selectPipelineRequirements } from './test-regression.selectors';
import { Requirement } from '../../../model/jira/release-item';
import { of } from 'rxjs';
import { selectJiraComponentPipelineMappingForPipeline } from '../configuration/configuration.selectors';
import { selectPipelineById } from '../pipeline/pipeline.selectors';
import { JiraList } from '../../../model/jira/jira-list';

@Injectable()
export class TestRegressionEffects {
  constructor(
    private readonly action$: Actions,
    private readonly store$: Store,
    private readonly jiraService: JiraService,
    private readonly accessPipelineRunService: AccessPipelineRunService,
  ) {}

  onLoadPipelineRequirements = createEffect(() => {
    return this.action$.pipe(
      ofType(TestRegressionActions.loadPipelineRequirements),
      mergeMap((action) => {
        return of(action).pipe(
          withLatestFrom(
            this.store$.select(selectDeliveryFixVersions),
            this.store$.select(selectJiraComponentPipelineMappingForPipeline(action.pipelineId)),
            this.store$.select(selectPipelineById(action.pipelineId)),
            this.store$.select(selectSelectedPipelineRunForPipeline(action.pipelineId)),
          ),
        );
      }),
      mergeMap(([action, deliveryFixVersions, pipelineMapping, pipeline, selectedPipelineRunId]) => {
        const pipelineId = action.pipelineId;
        const pipelineKey = pipeline.pipelineKey;

        // get components from pipeline mapping in release config
        let components: string[] = [];
        if (pipelineMapping?.length) {
          components = pipelineMapping.filter((mapping) => mapping.pipelineKeys.includes(pipelineKey)).map((mapping) => mapping.component);
        }
        if (!deliveryFixVersions) {
          return of(TestRegressionActions.missingDeliveryFixVersionError({ pipelineId }));
        }
        return this.jiraService.getRequirementsByComponents(components, deliveryFixVersions).pipe(
          map((requirements: JiraList) => requirements.issues.map((req) => Requirement.fromJiraIssue(req))),
          mergeMap((requirements) => {
            return [
              TestRegressionActions.setPipelineRequirements({ pipelineId, requirements }),
              TestRegressionActions.loadPipelineRunTestCasesForRegression({
                pipelineId,
                pipelineRunId: selectedPipelineRunId,
              }),
            ];
          }),
          takeUntil(
            // eslint-disable-next-line @ngrx/avoid-cyclic-effects
            this.action$.pipe(
              ofType(TestRegressionActions.loadPipelineRequirements),
              filter((newAction) => newAction.pipelineId === action.pipelineId),
            ),
          ),
          catchError(() => {
            return of(TestRegressionActions.loadPipelineRequirementsError({ pipelineId: pipelineId }));
          }),
        );
      }),
    );
  });

  onLoadPipelineRunRegressionTests = createEffect(() => {
    return this.action$.pipe(
      ofType(TestRegressionActions.loadPipelineRunTestCasesForRegression),
      mergeMap((action) => of(action).pipe(withLatestFrom(this.store$.select(selectPipelineRequirements(action.pipelineId))))),
      mergeMap(([action, pipelineRequirements]) => {
        const pipelineRunId = action.pipelineRunId;

        if (!pipelineRequirements) {
          return of(TestRegressionActions.setPipelineRunTestCasesForRegression({ pipelineRunId, testcases: [] }));
        }

        const requirementKeys = pipelineRequirements.map((requirement: Requirement) => requirement.key);
        return this.accessPipelineRunService.getPipelineRunTestcases(pipelineRunId, requirementKeys).pipe(
          map((testcases) => {
            return TestRegressionActions.setPipelineRunTestCasesForRegression({ pipelineRunId, testcases });
          }),
          takeUntil(
            // eslint-disable-next-line @ngrx/avoid-cyclic-effects
            this.action$.pipe(
              ofType(TestRegressionActions.loadPipelineRunTestCasesForRegression),
              filter((newAction) => newAction.pipelineRunId === action.pipelineRunId),
            ),
          ),
          catchError(() => {
            return of(TestRegressionActions.loadPipelineRunTestCasesForRegressionError({ pipelineRunId }));
          }),
        );
      }),
    );
  });
}
