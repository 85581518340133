import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FosstarsSlice } from '../app-state';

// First selector should be a featureSelector
export const selectFosstarsSlice = createFeatureSelector<FosstarsSlice>('fosstarsSlice');

export const selectFosstarsSingleDetailByPipelineRunId = (pipelineRunId: number) =>
  createSelector(selectFosstarsSlice, (fosstarsSlice: FosstarsSlice) => {
    return fosstarsSlice.pipelineRunsDetails[pipelineRunId] || [];
  });

export const selectFosstarsSingleSummaryByPipelineRunId = (pipelineRunId: number) =>
  createSelector(selectFosstarsSlice, (fosstarsSlice: FosstarsSlice) => {
    return fosstarsSlice.pipelineRunsSummary[pipelineRunId];
  });

export const selectFosstarsGroupDetails = createSelector(selectFosstarsSlice, (fosstarsSlice: FosstarsSlice) => {
  return fosstarsSlice.aggregationDetails;
});

export const selectFosstarsGroupSummary = createSelector(selectFosstarsSlice, (fosstarsSlice: FosstarsSlice) => {
  return fosstarsSlice.aggregationSummary;
});
