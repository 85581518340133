import { createReducer, on } from '@ngrx/store';
import { AtcSlice } from '../app-state';
import { initialAtcSlice } from '../initial-slices';
import * as AtcActions from './atc.actions';

export const atcReducer = createReducer(
  initialAtcSlice,
  on(AtcActions.loadAtcGroupSummary, (state, props): AtcSlice => {
    return {
      ...state,
      aggregationSummary: initialAtcSlice.aggregationSummary,
      aggregationDetails: initialAtcSlice.aggregationDetails,
    };
  }),
  on(AtcActions.setAtcGroupSummary, (state, props): AtcSlice => {
    return { ...state, aggregationSummary: props.groupSummary };
  }),
  on(AtcActions.setPipelineRunATCInformation, (state, props): AtcSlice => {
    const allPipelineRunAtcInformation = { ...state.allPipelineRunAtcInformation };
    allPipelineRunAtcInformation[props.pipelineRunId] = props.pipelineRunAtcInformation;
    return { ...state, allPipelineRunAtcInformation };
  }),
);
