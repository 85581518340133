import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-malwarescan-status-label',
  templateUrl: './malwarescan-status-label.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MalwarescanStatusLabelComponent {
  @Input() status: string;
  @Input() malwareDetected: boolean;
  @Input() pipelineRunId?: number;

  @Input() labelClickable = false;
  @Input() tooltipText: string;

  @Output() labelClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() reloadButtonClicked: EventEmitter<void> = new EventEmitter<void>();

  @Input() loadingError: boolean;

  labelClick() {
    if (this.labelClickable) {
      this.labelClicked?.emit();
    }
  }
}
