<div
  *ngIf="{
    delivery: selectedDelivery$ | async,
    deliveryRequirements: deliveryRequirements$ | async,
    deliveryProcessingStatus: deliveryProcessingStatus$ | async,
    pipelines: pipelines$ | async,
    processingState: processingState$ | async,
    pipelinesLoading: pipelinesLoading$ | async,
    groupComplianceMode: selectedGroupComplianceMode$ | async,
  } as data"
  class="flex h-full w-full"
>
  <div *ngIf="data.pipelines" class="flex flex-col h-full w-full" id="wrapper">
    <div
      [hidden]="selectIsDisplayedMicrodeliveriesEmpty$ | async"
      class="flex flex-row justify-start items-center"
      id="release-selection-container"
    >
      <app-release-selection class="flex h-full w-full"></app-release-selection>
    </div>
    <div class="flex flex-col" id="compliance-wrapper">
      <div id="group-compliance-section">
        <div class="header flex flex-col">
          <div class="flex flex-col" id="view-mode-section">
            <span class="title mb-8">View Mode</span>
            <div>
              <kendo-dropdownlist
                [disabled]="false"
                [itemDisabled]="itemDisabled(data.deliveryProcessingStatus)"
                [value]="data.groupComplianceMode"
                (selectionChange)="onGroupComplianceToggle(data.delivery, $event)"
                [data]="groupComplianceModes"
                [listHeight]="400"
                [popupSettings]="{ width: 'auto' }"
                style="width: 300px"
                [valuePrimitive]="false"
                data-test-id="group-compliance-mode-selection"
                fusionFeatureUsageTracker="group-compliance-mode-selection"
                textField="key"
                valueField="key"
              >
                <ng-template kendoDropDownListItemTemplate let-dataItem>
                  <span data-test-id="group-complience-mode">{{ 'group-compliance-mode.' + dataItem.key | translate }}</span>
                </ng-template>
                <ng-template kendoDropDownListValueTemplate let-dataItem>
                  <span>{{ 'group-compliance-mode.' + dataItem.key | translate }}</span>
                </ng-template>
              </kendo-dropdownlist>
            </div>
          </div>
          <div>
            <span class="title">Aggregated Code Compliance</span>
            <span
              *ngIf="data.processingState.hasLatestRunsUnprocessedFiles"
              [class.error]="data.processingState.hasLatestRunsUnprocessedFiles"
            >
              <fusion-icon
                fusionTooltip="{{ 'pipeline-list.unprocessed-files' | translate }}"
                name="exclamation-triangle-outline"
                size="M"
                id="unprocessed-file-warning"
              ></fusion-icon
            ></span>
          </div>
          <app-processing-status-warning-box
            *ngIf="isProcessingStatusWarningVisible(data.processingState)"
            [restoreButtonVisible]="isRestoreButtonVisible(data.processingState)"
            [singleRun]="false"
            (restore)="restorePipelineRuns(data.processingState.archivedPipelineRunIds)"
          >
            <ng-container warningMessage
              >The current view contains {{ data.processingState.numberOfArchivedPipelineRuns }} archived pipeline runs, which means that
              the displayed information is incomplete.<br />
            </ng-container>
            <ng-container noButtonWarningMessage
              >The current view contains {{ getTextForNumberOfPipelineRuns(data.processingState) }} pipeline runs which means that the
              displayed information is incomplete. <br />The operation should be completed soon, please refresh the page in a few minutes.
              <br />
            </ng-container>
          </app-processing-status-warning-box>
          <app-warning-box
            *ngIf="getRunningPipelines(data.pipelines).length > 0"
            [showTheAdditionalInfo]="true"
            additionalInfoLink="https://wiki.one.int.sap/wiki/x/G-jI_Q"
          >
            <ng-container warningMessage>
              {{ getTextForPipelinesRunningWarning(data.pipelines) }}
            </ng-container>
          </app-warning-box>
        </div>

        <div id="group-compliance-tiles">
          <app-group-compliance-status
            [pipelines]="data.pipelines"
            [groupComplianceMode]="data.groupComplianceMode"
          ></app-group-compliance-status>
        </div>
      </div>
      <div class="flex flex-col" id="pipelines-section">
        <div class="header flex flex-row justify-start items-center">
          <div class="title">Connected Pipelines ({{ gridData.total }})</div>
          <div class="flex flex-grow"></div>
          <div [fusionTooltip]="getAddButtonTooltip(data.delivery)" [fusionTooltipDisabled]="!isManagePipelinesDisabled(data.delivery)">
            <button
              (click)="openPipelineDialog(data.delivery)"
              [disabled]="isManagePipelinesDisabled(data.delivery)"
              fusionButton="primary"
              fusionFeatureUsageTracker="pipeline-list-add-pipeline-button"
              id="addPipelineButton"
              style="white-space: nowrap"
            >
              Add Pipeline(s)
            </button>
          </div>
        </div>
        <div class="flex h-full w-full">
          <kendo-grid
            [(expandedDetailKeys)]="expandedDetailKeys"
            [data]="gridData"
            [kendoGridExpandDetailsBy]="expandDetailsBy"
            [selectable]="false"
            [filter]="filter"
            [loading]="data.pipelinesLoading"
            filterable="menu"
            (filterChange)="filterChange($event, data.pipelines)"
            class="pipeline-list-grid flex h-full w-full"
            data-test-id="connected-pipeline-list"
          >
            <kendo-grid-column
              [title]="'connected-pipelines.column-names.pipeline-key' | translate"
              field="pipelineKey"
              [filterable]="false"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="flex flex-col gap-1">
                  <span class="text-overflow"
                    ><strong>{{ dataItem.pipelineKey | pipelineKeyWithoutTimestamp }}</strong></span
                  >
                  <div *ngIf="dataItem.pipelineRunModeId === pipelineRunModeIds.Optimized_Commit">
                    <mat-chip class="mat-chip-small" [disabled]="true" [disableRipple]="true" data-test-id="optimized-pipeline-chip">
                      <span class="text-overflow">{{ 'pipeline.optimized.flag' | translate }}</span></mat-chip
                    >
                  </div>
                </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              headerClass="kendo-filter-header"
              [title]="'connected-pipelines.column-names.jira-components' | translate"
              field="components"
              *ngIf="isAnyPipelineJiraComponentMapped"
            >
              <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
                <app-multicheck-filter
                  [isPrimitive]="true"
                  [field]="column.field"
                  [filterService]="filterService"
                  [currentFilter]="filter"
                  [data]="distinctPrimitive(data.pipelines)"
                ></app-multicheck-filter>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                <div>
                  <mat-chip
                    class="mat-chip-small"
                    [disableRipple]="true"
                    *ngFor="let component of dataItem.components"
                    [disabled]="true"
                    attr.data-test-id="{{ 'component-label-' + dataItem.pipelineKey + '-' + component }}"
                  >
                    <span class="text-overflow">{{ component }}</span>
                  </mat-chip>
                </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              [title]="'connected-pipelines.column-names.latest-run' | translate: { pipelineRunCountInMode: pipelineRunCountInMode }"
              field="latestRun"
              [filterable]="false"
            >
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="flex flex-row">
                  <app-run-header-info
                    (showDetails)="showPipelineRunDetails(data.pipelines, $event)"
                    [pipeline]="dataItem"
                    warningTooltip="{{ 'pipeline-list.unprocessed-files' | translate }}"
                    class="flex flex-grow w-full"
                  ></app-run-header-info>
                </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column width="70">
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="flex flex-row" [fusionTooltip]="getDeleteButtonTooltip(data.delivery)" fusionTooltipPosition="top-left">
                  <button
                    (click)="removePipeline(data.delivery, dataItem)"
                    [disabled]="isManagePipelinesDisabled(data.delivery)"
                    class="clickable"
                    fusionIcon="x"
                    fusionIconButton
                    fusionIconSize="L"
                  ></button>
                </div>
              </ng-template>
            </kendo-grid-column>
            <ng-template kendoGridDetailTemplate let-dataItem>
              <app-pipeline-list-item-detail
                [delivery]="data.delivery"
                [deliveryProcessingStatus]="data.deliveryProcessingStatus"
                [pipeline]="dataItem"
                class="pipelinelistcontainer"
              >
              </app-pipeline-list-item-detail>
            </ng-template>
            <ng-template kendoGridNoRecordsTemplate>
              <div class="flex h-full w-full justify-items-center">
                <div *ngIf="!data.pipelinesLoading">No Pipelines added yet.</div>
              </div>
            </ng-template>
            <ng-template kendoGridLoadingTemplate>
              <div class="flex h-full w-full justify-center items-start" id="pipeline-list-loading-indicator">
                <app-table-loading-indicator></app-table-loading-indicator>
              </div>
            </ng-template>
          </kendo-grid>
        </div>
      </div>
    </div>
  </div>
</div>
