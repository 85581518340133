import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isNullOrUndefined } from '../../../shared/utils';
import { MalwarescanDetails, MalwarescanStatus, MalwarescanSummary } from '../../model/malwarescan';
import { MalwarescanSlice } from '../app-state';

// First selector should be a featureSelector
export const selectMalwarescanSlice = createFeatureSelector<MalwarescanSlice>('malwarescanSlice');

export const selectMalwarescanGroupSummary = createSelector(selectMalwarescanSlice, (malwarescanSlice) => {
  return setDisplayStatus(malwarescanSlice.groupSummary);
});
export const selectMalwarescanGroupDetails = createSelector(selectMalwarescanSlice, (malwarescanSlice) => {
  return malwarescanSlice.groupDetails.map((detail) => setDisplayStatus(detail));
});
export const selectMalwarescanSingleDetails = (pipelineRunId: number) =>
  createSelector(selectMalwarescanSlice, (malwarescanSlice) => {
    return setDisplayStatus(malwarescanSlice.pipelineRunDetails[pipelineRunId]);
  });

function setDisplayStatus(data: MalwarescanDetails | MalwarescanSummary): MalwarescanDetails | MalwarescanSummary {
  if (isNullOrUndefined(data)) {
    return data;
  } else if (isNullOrUndefined(data.malwareDetected)) {
    return { ...data, displayStatus: null, statusSortOrder: 3 };
  } else if (data.malwareDetected) {
    return { ...data, displayStatus: MalwarescanStatus.MALWARE_DETECTED, statusSortOrder: 2 };
  } else {
    return { ...data, displayStatus: MalwarescanStatus.NO_MALWARE_DETECTED, statusSortOrder: 1 };
  }
}
