<div>
  <span *ngIf="noRelevantFilesUploaded()" class="status-text-not-available"> No compliance relevant files uploaded</span>

  <app-error-information
    *ngIf="loadingState.loadingError"
    errorText="Error loading compliance information for {{ getDisplayKey(complianceInformation?.psrKey) }}. Please try it again later."
    (reloadClicked)="reloadButtonClicked.emit()"
  >
  </app-error-information>

  <span *ngIf="loadingState.loadingNotFound" class="flex justify-start items-center">
    <span class="status-text-not-available"> No compliance information available</span>
  </span>
  <span
    *ngIf="complianceInformation?.complianceStatus && !loadingState.loadingNotFound && !loadingState.loadingError"
    attr.data-test-id="{{ getCheckResultTextTestId(complianceInformation?.psrKey) }}"
    class="status-text status-text-{{ complianceInformation?.complianceStatus.key }}"
    [class.clickable]="isClickable()"
    (click)="labelClick()"
  >
    <fusion-icon
      class="info-icon"
      *ngIf="
        complianceInformation?.complianceStatus.key === ComplianceStatus.UNKNOWN.key ||
        complianceInformation?.complianceStatus.key === ComplianceStatus.IGNORED.key
      "
      [fusionTooltip]="multiline"
      fusionTooltipPosition="bottom-right"
      [name]="'exclamation-circle-outline'"
      size="XL"
    >
    </fusion-icon>
    <ng-container *ngIf="getTooltipSettings(complianceInformation?.pipelineRunModeId, pipelineRunsComplianceInfo) as tooltip">
      <span
        [fusionTooltip]="tooltip.text"
        [fusionTooltipDisabled]="tooltip.isDisabled"
        fusionTooltipPosition="bottom-right"
        attr.data-test-id="{{ getStatusTestId(complianceInformation?.psrKey, complianceInformation?.pipelineKey) }}"
      >
        {{ complianceInformation?.complianceStatus.displayKey }}
      </span>
    </ng-container>
    <em *ngIf="labelClickable && isExternalLink" class="fi fi-link-external"></em>
  </span>
</div>

<ng-template #multiline>
  <div [innerHTML]="'compliance.status.tooltip.' + complianceInformation?.complianceStatus.key | translate"></div>
</ng-template>

<ng-template #pipelineRunsComplianceInfo>
  <app-compliance-status-tooltip [complianceInformation]="complianceInformation"></app-compliance-status-tooltip>
</ng-template>
