import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-excel-export',
  templateUrl: './excel-export.component.html',
})
export class ExcelExportComponent {
  @Input() grid: GridComponent;
  @Input() loading: boolean;

  constructor(private readonly translateService: TranslateService) {}

  exportToExcel(): void {
    this.grid.saveAsExcel();
  }

  getLoadingTooltipMessage(): string {
    return this.translateService.instant('kpi.tile.requirements.excel-export-button.loading-tooltip');
  }

  isLoadingOrNoData(): boolean {
    return this.loading || !this.grid.totalCount;
  }
}
