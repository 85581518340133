import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription, timer } from 'rxjs';

export enum AlertType {
  SUCCESS,
  WARNING,
  ERROR,
}

export class Alert {
  constructor(
    public message: string,
    public type: AlertType,
    private readonly timeout: number,
    public dismiss: () => void,
  ) {
    switch (this.type) {
      case AlertType.SUCCESS:
        this.setTimeout(3000);
        break;
      default:
        if (this.timeout) {
          this.setTimeout(this.timeout);
        }
        break;
    }
  }

  private setTimeout(timeout: number) {
    timer(timeout).subscribe(() => {
      this.dismiss();
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class AlertService implements OnDestroy {
  public alerts$ = new BehaviorSubject<Alert[]>([]);
  public alerts: Alert[] = [];
  subscriptions: Subscription[] = [];

  constructor() {
    this.subscriptions.push(
      this.alerts$.subscribe((alerts) => {
        this.alerts = alerts;
      }),
    );
  }

  public createAlert(message: string, type: AlertType = AlertType.SUCCESS, timeout?: number) {
    const alert = new Alert(message, type, timeout, () => {
      this.alerts$.next(this.alerts.filter((a) => a !== alert));
    });
    this.alerts$.next([alert, ...this.alerts]);
    return alert;
  }

  public clearAlerts() {
    this.alerts$.next([]);
  }

  public ngOnDestroy() {
    this.alerts$.unsubscribe();
  }
}
