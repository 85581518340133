import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import {
  selectIsApiMetadataValidatorGroupSummaryLoading,
  selectIsApiMetadataValidatorGroupSummaryLoadingError,
  selectIsApiMetadataValidatorGroupSummaryLoadingNotFound,
  selectIsApiMetadataValidatorPipelineRunLoading,
  selectIsApiMetadataValidatorPipelineRunLoadingError,
  selectIsApiMetadataValidatorPipelineRunLoadingNotFound,
} from '../../ngrx/store/loading-state/loading-state.selectors';
import { ComplianceStatus } from '../../ngrx/model/compliance-information';
import { ApiMetadataValidatorDetails, ApiMetadataValidatorSummary } from '../../ngrx/model/api-metadata-validator';
import {
  selectApiMetadataValidatorGroupSummary,
  selectApiMetadataValidatorPipelineRunSummary,
} from '../../ngrx/store/api-metadata-validator/api-metadata-validator.selectors';
import {
  loadApiMetadataPipelineRunSummary,
  loadApiMetadataValidatorGroupDetails,
  loadApiMetadataValidatorGroupSummary,
} from '../../ngrx/store/api-metadata-validator/api-metadata-validator.actions';
import { ApiMetadataValidatorDetailsDialogComponent } from './api-metadata-validator-details-dialog/api-metadata-validator-details-dialog.component';
import { GroupComplianceMode } from '../../shared/model/group-compliance-mode';

@Component({
  selector: 'app-list-item-kpis-api-metadata-validator',
  templateUrl: './list-item-kpis-api-metadata-validator.component.html',
  styleUrls: ['./list-item-kpis-api-metadata-validator.component.scss'],
})
export class ListItemKpisApiMetadataValidatorComponent implements OnInit, OnChanges {
  public ComplianceStatus = ComplianceStatus;

  @Input() groupId: string;
  @Input() groupComplianceMode: GroupComplianceMode;
  @Input() currentPipelineRunId: number;

  public apiMetadataValidatorData$!: Observable<ApiMetadataValidatorSummary | ApiMetadataValidatorDetails>;
  public isLoading$!: Observable<boolean>;
  public isLoadingError$!: Observable<boolean>;
  public isLoadingNotFound$!: Observable<boolean>;

  constructor(
    private readonly store$: Store,
    private readonly dialog: MatDialog,
  ) {}

  ngOnInit() {
    if (!this.currentPipelineRunId) {
      this.apiMetadataValidatorData$ = this.store$.select(selectApiMetadataValidatorGroupSummary);
      this.isLoading$ = this.store$.select(selectIsApiMetadataValidatorGroupSummaryLoading);
      this.isLoadingError$ = this.store$.select(selectIsApiMetadataValidatorGroupSummaryLoadingError);
      this.isLoadingNotFound$ = this.store$.select(selectIsApiMetadataValidatorGroupSummaryLoadingNotFound);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentPipelineRunId) {
      this.loadPipelineRunInfo();
    }
  }

  loadPipelineRunInfo() {
    const pipelineRunId = this.currentPipelineRunId;
    this.apiMetadataValidatorData$ = this.store$.select(selectApiMetadataValidatorPipelineRunSummary(pipelineRunId));
    this.isLoading$ = this.store$.select(selectIsApiMetadataValidatorPipelineRunLoading(pipelineRunId));
    this.isLoadingError$ = this.store$.select(selectIsApiMetadataValidatorPipelineRunLoadingError(pipelineRunId));
    this.isLoadingNotFound$ = this.store$.select(selectIsApiMetadataValidatorPipelineRunLoadingNotFound(pipelineRunId));
  }

  reloadInfo() {
    if (!this.currentPipelineRunId) {
      this.store$.dispatch(
        loadApiMetadataValidatorGroupSummary({
          deliveryId: this.groupId,
          groupComplianceMode: this.groupComplianceMode,
        }),
      );
    } else {
      this.store$.dispatch(loadApiMetadataPipelineRunSummary({ pipelineRunId: this.currentPipelineRunId }));
    }
  }

  public openDetailsDialog(): void {
    if (!this.currentPipelineRunId) {
      const data = { deliveryId: this.groupId, groupComplianceMode: this.groupComplianceMode };
      this.store$.dispatch(loadApiMetadataValidatorGroupDetails(data));
      this.dialog.open(ApiMetadataValidatorDetailsDialogComponent, { data });
    }
  }
}
