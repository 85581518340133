import { Component, Input, OnInit } from '@angular/core';
import { PipelineRunExecutionStatus } from '../../../model/pipeline/execution-status/pipeline-run-execution-status';
import { ReleaseStatus } from '../../../model/release-status/release-status';

type Status = PipelineRunExecutionStatus | ReleaseStatus;
type StatusType = 'execution-status' | 'release-status';

@Component({
  selector: 'app-status-chip',
  templateUrl: './status-chip.component.html',
  styleUrl: './status-chip.component.scss',
})
export class StatusChipComponent implements OnInit {
  @Input() statusType: StatusType;
  @Input() status: Status;
  @Input() statusDate?: Date;
  @Input() showStatusDate: boolean;
  @Input() showTooltip: boolean;

  toolTip: string;

  ngOnInit() {
    this.toolTip = `pipeline-list.pipeline-run.${this.statusType}.tooltip`;
  }
}
