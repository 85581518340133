<div class="warning-box flex flex-row gap-2.5">
  <div>
    <fusion-icon name="exclamation-triangle-outline" class="icon-warn"></fusion-icon>
  </div>
  <div *ngIf="restoreButtonVisible; else noRestoreButton">
    <span>
      <ng-content select="[warningMessage]"></ng-content>
      More information can be found
      <a href="https://wiki.one.int.sap/wiki/x/GyJczQ" target="_blank" rel="noopener noreferrer" class="clickable">
        <span>here</span> <fusion-icon name="link-external" size="S"></fusion-icon> </a
      >. Do you want to restore {{ singleRun ? 'it' : 'them' }}?
    </span>
    <div style="margin-top: 8px">
      <button
        [disabled]="loading"
        fusionButton
        fusionButtonSize="slim"
        fusionColor="fusionButtonWarning"
        [attr.data-test-id]="singleRun ? 'restore-pipeline-run-btn' : 'restore-pipeline-runs-btn'"
        [fusionFeatureUsageTracker]="singleRun ? 'restore-pipeline-run-button' : 'restore-pipeline-runs-button'"
        [fusionFeatureUsageTrackerEvents]="['click']"
        (click)="restore.emit()"
      >
        <app-loading class="inline-loading-spinner" *ngIf="loading" size="S" [showLoadingText]="false"></app-loading>
        <fusion-icon *ngIf="!loading" name="arrow-refresh"></fusion-icon>
        Restore
      </button>
    </div>
  </div>

  <ng-template #noRestoreButton>
    <span>
      <ng-content select="[noButtonWarningMessage]"></ng-content>
      More information can be found
      <a href="https://wiki.one.int.sap/wiki/x/GyJczQ" target="_blank" rel="noopener noreferrer" class="clickable">
        <span>here</span> <fusion-icon name="link-external" size="S"></fusion-icon> </a
      >.
    </span>
  </ng-template>
</div>
