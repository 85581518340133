import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-processing-status-warning-box',
  templateUrl: './processing-status-warning-box.component.html',
  styleUrls: ['./processing-status-warning-box.component.scss'],
})
export class ProcessingStatusWarningBoxComponent {
  @Input() restoreButtonVisible: boolean;
  @Input() singleRun: boolean;
  @Input() loading = false;
  @Output() restore = new EventEmitter<any>();
}
