import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-validation-error-message-list',
  templateUrl: './validation-error-message-list.component.html',
  styleUrls: ['./validation-error-message-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationErrorMessageListComponent {
  @Input()
  messages: string[];

  @Input()
  messagesTestId: string;

  @Input()
  isIgnored = false;

  trackByMessageIndex(index: number, message: string) {
    return index;
  }
}
