import { createReducer, on } from '@ngrx/store';
import { CentralPolicyResultsSlice } from '../app-state';
import { initialCentralPolicyResultsSlice } from '../initial-slices';
import * as CentralPolicyResultsActions from './central-policy-results.actions';

export const CentralPolicyResultsReducer = createReducer(
  initialCentralPolicyResultsSlice,
  on(CentralPolicyResultsActions.loadCentralPolicyResultsGroupSummary, (state, _): CentralPolicyResultsSlice => {
    return { ...state, groupSummary: initialCentralPolicyResultsSlice.groupSummary };
  }),
  on(CentralPolicyResultsActions.setCentralPolicyResultsSingleSummary, (state, props): CentralPolicyResultsSlice => {
    const pipelineRunsSummary = { ...state.pipelineRunsSummary };
    pipelineRunsSummary[props.pipelineRunId] = props.singleSummary;
    return { ...state, pipelineRunsSummary };
  }),
  on(CentralPolicyResultsActions.setCentralPolicyResultsSingleDetails, (state, props): CentralPolicyResultsSlice => {
    const pipelineRunsDetails = { ...state.pipelineRunsDetails };
    pipelineRunsDetails[props.pipelineRunId] = props.singleDetails;
    return { ...state, pipelineRunsDetails };
  }),
  on(CentralPolicyResultsActions.setCentralPolicyResultsGroupSummary, (state, props): CentralPolicyResultsSlice => {
    return { ...state, groupSummary: props.groupSummary };
  }),
  on(CentralPolicyResultsActions.setCentralPolicyResultsGroupDetails, (state, props): CentralPolicyResultsSlice => {
    return { ...state, groupDetails: props.groupDetails };
  }),
);
