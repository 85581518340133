import { JiraAllComponentPipelineMapping, ReleaseDecisionInformation } from '../../../shared/access-api/access-api.service';
import { createAction, props } from '@ngrx/store';

export const configurationKey = 'configuration';

export const setConfigurationForPipelinesOfDelivery = createAction(
  `[${configurationKey}] Set configuration for pipelines of delivery`,
  props<{ config: Record<string, ReleaseDecisionInformation> }>(), // it's named config because the props object has a configuration property already
);

export const setJiraComponentMappingsForPipelines = createAction(
  `[${configurationKey}] Set jira component mappings for Pipelines`,
  props<{ jiraComponentMapping: JiraAllComponentPipelineMapping[] }>(),
);
