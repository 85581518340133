import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PolicyResultsGroupSummary } from 'src/app/ngrx/model/policy-results';
import { loadCentralPolicyResultsGroupDetails } from 'src/app/ngrx/store/central-policy-results/central-policy-results.actions';
import { selectCentralPolicyResultsGroupSummary } from 'src/app/ngrx/store/central-policy-results/central-policy-results.selectors';
import {
  selectIsCentralPolicyResultsGroupSummaryLoading,
  selectIsCentralPolicyResultsGroupSummaryLoadingError,
  selectIsCentralPolicyResultsGroupSummaryLoadingNotFound,
  selectIsCustomPolicyResultsGroupSummaryLoading,
  selectIsCustomPolicyResultsGroupSummaryLoadingError,
  selectIsCustomPolicyResultsGroupSummaryLoadingNotFound,
} from 'src/app/ngrx/store/loading-state/loading-state.selectors';
import { GroupComplianceMode } from 'src/app/shared/model/group-compliance-mode';
import { loadCustomPolicyResultsGroupDetails } from '../../ngrx/store/custom-policy-results/custom-policy-results.actions';
import { selectCustomPolicyResultsGroupSummary } from '../../ngrx/store/custom-policy-results/custom-policy-results.selectors';
import { ListItemKpisPolicyResultAggregationDialogComponent } from './list-item-kpis-policy-result-aggregation-dialog/list-item-kpis-policy-result-aggregation-dialog.component';

@Component({
  selector: 'app-list-item-kpis-policy-result-aggregation',
  templateUrl: './list-item-kpis-policy-result-aggregation.component.html',
})
export class ListItemKpisPolicyResultAggregationComponent implements OnInit {
  @Input() groupId: string;
  @Input() groupComplianceMode: GroupComplianceMode;
  @Input() isCustomPolicyResults = false;
  public policyResultsGroupInfo$!: Observable<PolicyResultsGroupSummary>;
  public isLoading$!: Observable<boolean>;
  public isLoadingError$!: Observable<boolean>;
  public isLoadingNotFound$!: Observable<boolean>;
  public policyResultTileTitleRef: string;
  public dataTestIdForDetailsButton: string;
  public fusionFeatureTrackerForOpenDetailsDialog: string;

  constructor(
    private readonly store$: Store,
    private readonly dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    if (this.isCustomPolicyResults) {
      this.policyResultsGroupInfo$ = this.store$.select(selectCustomPolicyResultsGroupSummary);
      this.isLoading$ = this.store$.select(selectIsCustomPolicyResultsGroupSummaryLoading);
      this.isLoadingError$ = this.store$.select(selectIsCustomPolicyResultsGroupSummaryLoadingError);
      this.isLoadingNotFound$ = this.store$.select(selectIsCustomPolicyResultsGroupSummaryLoadingNotFound);
      this.policyResultTileTitleRef = 'kpi.tile.custom.policy.results.title';
      this.dataTestIdForDetailsButton = 'custom-policy-results-aggregated-popup-view-details-link';
      this.fusionFeatureTrackerForOpenDetailsDialog = 'kpi-open-aggregated-custom-policy-results-details';
    } else {
      this.policyResultsGroupInfo$ = this.store$.select(selectCentralPolicyResultsGroupSummary);
      this.isLoading$ = this.store$.select(selectIsCentralPolicyResultsGroupSummaryLoading);
      this.isLoadingError$ = this.store$.select(selectIsCentralPolicyResultsGroupSummaryLoadingError);
      this.isLoadingNotFound$ = this.store$.select(selectIsCentralPolicyResultsGroupSummaryLoadingNotFound);
      this.policyResultTileTitleRef = 'kpi.tile.policy.results.title';
      this.dataTestIdForDetailsButton = 'policy-results-aggregated-popup-view-details-link';
      this.fusionFeatureTrackerForOpenDetailsDialog = 'kpi-open-aggregated-policy-results-details';
    }
  }

  public openDetailsDialog(): void {
    const group = { groupId: this.groupId, groupComplianceMode: this.groupComplianceMode };
    if (this.isCustomPolicyResults) {
      this.store$.dispatch(loadCustomPolicyResultsGroupDetails(group));
    } else {
      this.store$.dispatch(loadCentralPolicyResultsGroupDetails(group));
    }
    const dialogData = {
      groupId: this.groupId,
      groupComplianceMode: this.groupComplianceMode,
      isCustomPolicyResults: this.isCustomPolicyResults,
    };
    this.dialog.open(ListItemKpisPolicyResultAggregationDialogComponent, { data: dialogData, panelClass: 'requirement-details-container' });
  }
}
