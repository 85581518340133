import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { setConfigurationForPipelinesOfDelivery, setJiraComponentMappingsForPipelines } from './configuration.actions';
import { map } from 'rxjs/operators';
import { JiraAllComponentPipelineMapping } from '../../../shared/access-api/access-api.service';

@Injectable()
export class ConfigurationEffects {
  constructor(
    private readonly action$: Actions,
    private readonly store$: Store,
  ) {}

  public onSetConfigurationToJiraComponentMapping = createEffect(() => {
    return this.action$.pipe(
      ofType(setConfigurationForPipelinesOfDelivery),
      map((action) => {
        const allPipelineMappings: JiraAllComponentPipelineMapping[] = [];

        // create a unique entry for each component-pipelineKey combination
        Object.values(action.config).forEach((config) => {
          config.jiraComponentPipelineMappings?.forEach((mapping) =>
            mapping.pipelineKeys.forEach((pipelineKey) => {
              // Add if the same component-pipelineKey entry does not already exist
              if (!allPipelineMappings.find((entry) => entry.pipelineKey === pipelineKey && entry.component === mapping.component)) {
                allPipelineMappings.push({ pipelineKey: pipelineKey, component: mapping.component });
              }
            }),
          );
        });

        return setJiraComponentMappingsForPipelines({ jiraComponentMapping: allPipelineMappings });
      }),
    );
  });
}
