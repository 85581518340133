import { createAction, props } from '@ngrx/store';
import { GroupComplianceMode } from 'src/app/shared/model/group-compliance-mode';
import { CustomPolicyKeyGroupDetails, GroupCustomPolicyUsageByPolicyKey, GroupPolicyUsageSummary } from '../../model/policy-results';

export const loadCustomPolicyUsageGroupSummary = createAction(
  `[Custom Policy Usage] Trigger loading of policy usage information for group-summary from backend`,
  props<{ groupId: string; groupComplianceMode: GroupComplianceMode }>(),
);

export const loadCustomPolicyUsageGroupSummaryError = createAction(
  `[Custom Policy Usage] Loading of policy usage group-summary from backend error`,
  props<{ groupId: string; groupComplianceMode: GroupComplianceMode }>(),
);

export const loadCustomPolicyUsageGroupSummaryNotFound = createAction(
  `[Custom Policy Usage] Loading of policy usage group-summary from backend not found`,
  props<{ groupId: string; groupComplianceMode: GroupComplianceMode }>(),
);

export const setCustomPolicyUsageGroupSummary = createAction(
  `[Custom Policy Usage] Set policy usage information for group-summary from backend in store`,
  props<{ groupSummary: GroupPolicyUsageSummary }>(),
);

export const loadCustomPolicyUsageGroupDetails = createAction(
  `[Custom Policy Usage] Trigger loading of policy usage information for group-details from backend`,
  props<{ groupId: string; groupComplianceMode: GroupComplianceMode }>(),
);

export const loadCustomPolicyUsageGroupDetailsError = createAction(
  `[Custom Policy Usage] Loading of policy usage group-details from backend error`,
  props<{ groupId: string; groupComplianceMode: GroupComplianceMode }>(),
);

export const setCustomPolicyUsageGroupDetails = createAction(
  `[Custom Policy Usage] Set policy usage information for group-details from backend in store`,
  props<{ groupDetails: GroupCustomPolicyUsageByPolicyKey[] }>(),
);

export const loadCustomPolicyKeyGroupDetails = createAction(
  `[Custom Policy Key] Trigger loading of central policy key information for group details from backend`,
  props<{ groupId: string; policyKey: string; groupComplianceMode: GroupComplianceMode }>(),
);

export const loadCustomPolicyKeyGroupDetailsError = createAction(
  `[Custom Policy Key] Loading of central policy key information for group details from backend error`,
  props<{ groupId: string; policyKey: string; groupComplianceMode: GroupComplianceMode }>(),
);

export const loadCustomPolicyKeyGroupDetailsNotFound = createAction(
  `[Custom Policy Key] Loading of central policy key information for group details from backend not found`,
  props<{ groupId: string; policyKey: string; groupComplianceMode: GroupComplianceMode }>(),
);

export const setCustomPolicyKeyGroupDetails = createAction(
  `[Custom Policy Key] Set policy key information for group details from backend in store`,
  props<{ groupPolicyKeySummary: CustomPolicyKeyGroupDetails[] }>(),
);
