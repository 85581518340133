import { createReducer, on } from '@ngrx/store';
import { initialProcessingStatusSlice } from '../initial-slices';
import * as ProcessingStatusActions from './processing-status.actions';
import { ProcessingStatusSlice } from '../app-state';

export const processingStatusReducer = createReducer(
  initialProcessingStatusSlice,
  on(ProcessingStatusActions.setPipelineRunProcessingStatus, (state, props): ProcessingStatusSlice => {
    const pipelineRunsSummary = { ...state.pipelineRunProcessingStatus };
    pipelineRunsSummary[props.pipelineRunId] = props.processingStatus;
    return { ...state, pipelineRunProcessingStatus: pipelineRunsSummary };
  }),
);
