import { DeliveryProcessingStatus } from '../../model/environment/environment-data';
import { Group } from '../../model/group/group';

export function getProcessingStatus(delivery: Group): DeliveryProcessingStatus {
  if (delivery.isLocked) {
    return DeliveryProcessingStatus.FINISHED;
  } else if (!!delivery.activationDate && new Date(delivery.activationDate).getTime() <= new Date().getTime()) {
    // Delivery activationDate is not null and in the past
    return DeliveryProcessingStatus.ACTIVE;
  } else {
    return DeliveryProcessingStatus.NEW;
  }
}
