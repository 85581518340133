import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AtcSlice } from '../app-state';

// First selector should be a featureSelector
export const selectAtcSlice = createFeatureSelector<AtcSlice>('atcSlice');

export const selectAtcGroupSummary = createSelector(selectAtcSlice, (atcSlice) => {
  return atcSlice.aggregationSummary;
});
export const selectAtcGroupDetails = createSelector(selectAtcSlice, (atcSlice) => {
  return atcSlice.aggregationDetails;
});
export const selectAtcSingleDetails = (pipelineRunId: number) =>
  createSelector(selectAtcSlice, (atcSlice) => {
    return atcSlice.allPipelineRunAtcInformation[pipelineRunId];
  });
