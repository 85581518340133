export enum ApiMetadataValidatorStatus {
  NOT_FULFILLED = 'Not Fulfilled',
  FULFILLED = 'Fulfilled',
}

export interface ApiMetadataValidatorSummary {
  groupId?: string;
  isApiMetadataValidatorFulfilled: boolean;
  error: number;
  warning: number;
  info: number;
  displayStatus?: ApiMetadataValidatorStatus;
  statusSortOrder?: number;
}

export interface ApiMetadataValidatorDetails extends ApiMetadataValidatorSummary {
  pipelineKey: string;
  pipelineUrl: string;
  pipelineRunId: number;
  bucketId: string;
  buildUrl: string;
}
