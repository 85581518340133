export enum ReleaseStatusKey {
  RELEASED = 'released',
  RELEASE_CANDIDATE = 'release-candidate',
  DEPLOYED_TO_PRODUCTION = 'deployed-to-production',
  PROMOTED = 'promoted',
}

export class ReleaseStatus {
  static DEPLOYED_TO_PRODUCTION = new ReleaseStatus(ReleaseStatusKey.DEPLOYED_TO_PRODUCTION, 'Deployed to Production');
  static PROMOTED = new ReleaseStatus(ReleaseStatusKey.PROMOTED, 'Promoted');
  static RELEASE_CANDIDATE = new ReleaseStatus(ReleaseStatusKey.RELEASE_CANDIDATE, 'Release Candidate');
  static RELEASED = new ReleaseStatus(ReleaseStatusKey.RELEASED, 'Released');
  // edge case if no status is available
  static NOT_AVAILABLE = new ReleaseStatus(null, 'No state defined for run');

  constructor(
    public key: ReleaseStatusKey,
    public displayKey: string,
  ) {}

  static getByKey(key: string) {
    return Object.values(ReleaseStatus).find((entry) => entry.key == key) ?? ReleaseStatus.NOT_AVAILABLE;
  }

  toString() {
    return this.key;
  }

  toHttpParam() {
    return this.key ? { releaseStatus: this.key } : {};
  }
}

export const ReleaseStatuses: ReleaseStatus[] = Object.keys(ReleaseStatus)
  .filter((key) => ReleaseStatus[key] instanceof ReleaseStatus)
  .map((key) => ReleaseStatus[key]);
