import { PipelineRunModeIds } from '../pipeline-run/pipeline-run';

export function isRequirementsMappingFailed(testcase: RequirementTestcaseResult): boolean {
  return testcase.status === null && testcase.name && testcase.name[0] === '.' && !!testcase.sourceReference;
}

export interface RequirementTestcaseResult {
  buildUrl?: string;
  gitBranch?: string;
  gitCommit?: string;
  gitUrl?: string;
  gitSearchUrl?: string;
  jiraLink?: string;
  name?: Array<string>;
  pipelineKey: string;
  pipelineRunId: number;
  pipelineRunKey?: string;
  pipelineRunMode?: string;
  pipelineRunModeId?: PipelineRunModeIds;
  pipelineUrl?: string;
  requirementKey: string;
  skipped: boolean;
  untested?: boolean;
  hasIncorrectVersion?: boolean;
  source: string;
  sourceReference: string;
  status: boolean;
  stepResultTypeName: string;
  sort?: number;
}
