import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ComplianceStatus } from '../../ngrx/model/compliance-information';
import { MalwarescanDetails, MalwarescanSummary } from '../../ngrx/model/malwarescan';
import {
  selectIsDeliveryMalwarescanSummaryLoading,
  selectIsDeliveryMalwarescanSummaryLoadingError,
  selectIsDeliveryMalwarescanSummaryLoadingNotFound,
  selectIsPipelineRunMalwarescanDetailsLoading,
  selectIsPipelineRunMalwarescanDetailsLoadingError,
  selectIsPipelineRunMalwarescanDetailsLoadingNotFound,
} from '../../ngrx/store/loading-state/loading-state.selectors';
import {
  loadMalwarescanGroupDetails,
  loadMalwarescanGroupSummary,
  loadPipelineRunMalwarescanInformation,
} from '../../ngrx/store/malwarescan/malwarescan.actions';
import { selectMalwarescanGroupSummary, selectMalwarescanSingleDetails } from '../../ngrx/store/malwarescan/malwarescan.selectors';
import { GroupComplianceMode } from '../../shared/model/group-compliance-mode';
import { MalwarescanDetailsDialogComponent } from './malwarescan-details-dialog/malwarescan-details-dialog.component';

@Component({
  selector: 'app-list-item-kpis-malwarescan',
  templateUrl: './list-item-kpis-malwarescan.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemKpisMalwarescanComponent implements OnInit, OnChanges {
  public ComplianceStatus = ComplianceStatus;

  @Input() groupId: string;
  @Input() groupComplianceMode: GroupComplianceMode;
  @Input() currentPipelineRunId: number;

  public malwarescanInfo$!: Observable<MalwarescanDetails | MalwarescanSummary>;
  public isLoading$!: Observable<boolean>;
  public isLoadingError$!: Observable<boolean>;
  public isLoadingNotFound$!: Observable<boolean>;

  constructor(
    private readonly store$: Store,
    private readonly dialog: MatDialog,
  ) {}

  ngOnInit() {
    if (!this.currentPipelineRunId) {
      this.isLoading$ = this.store$.select(selectIsDeliveryMalwarescanSummaryLoading);
      this.isLoadingError$ = this.store$.select(selectIsDeliveryMalwarescanSummaryLoadingError);
      this.isLoadingNotFound$ = this.store$.select(selectIsDeliveryMalwarescanSummaryLoadingNotFound);
      this.malwarescanInfo$ = this.store$.select(selectMalwarescanGroupSummary);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentPipelineRunId) {
      this.loadPipelineRunInfo();
    }
  }

  loadPipelineRunInfo() {
    const pipelineRunId = this.currentPipelineRunId;
    this.malwarescanInfo$ = this.store$.select(selectMalwarescanSingleDetails(pipelineRunId));
    this.isLoading$ = this.store$.select(selectIsPipelineRunMalwarescanDetailsLoading(pipelineRunId));
    this.isLoadingError$ = this.store$.select(selectIsPipelineRunMalwarescanDetailsLoadingError(pipelineRunId));
    this.isLoadingNotFound$ = this.store$.select(selectIsPipelineRunMalwarescanDetailsLoadingNotFound(pipelineRunId));
  }

  reloadInfo() {
    if (!this.currentPipelineRunId) {
      this.store$.dispatch(
        loadMalwarescanGroupSummary({
          deliveryId: this.groupId,
          groupComplianceMode: this.groupComplianceMode,
        }),
      );
    } else {
      this.store$.dispatch(loadPipelineRunMalwarescanInformation({ pipelineRunId: this.currentPipelineRunId }));
    }
  }

  public onSummaryStatusClicked(): void {
    if (!this.currentPipelineRunId) {
      const data = { deliveryId: this.groupId, groupComplianceMode: this.groupComplianceMode };
      this.store$.dispatch(loadMalwarescanGroupDetails(data));
      this.dialog.open(MalwarescanDetailsDialogComponent, { data });
    }
  }
}
