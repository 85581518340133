import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { AccessApiPolicyService } from '../../../shared/access-api/policy/access-api-policy.service';
import * as CentralPolicyResultsActions from './central-policy-results.actions';

@Injectable()
export class CentralPolicyResultsEffects {
  public onLoadCentralPolicyResultsSingleSummary$ = createEffect(() => {
    return this.action$.pipe(
      ofType(CentralPolicyResultsActions.loadCentralPolicyResultsSingleSummary),
      mergeMap((action) => {
        return this.accessApiPolicyService.getPipelineRunCentralPolicyResultsSummary(action.pipelineRunId).pipe(
          map((singleSummary) =>
            CentralPolicyResultsActions.setCentralPolicyResultsSingleSummary({
              pipelineRunId: action.pipelineRunId,
              singleSummary,
            }),
          ),
          catchError((error) => {
            if (error.status === 404) {
              return of(CentralPolicyResultsActions.loadCentralPolicyResultsSingleSummaryNotFound({ pipelineRunId: action.pipelineRunId }));
            }
            return of(CentralPolicyResultsActions.loadCentralPolicyResultsSingleSummaryError({ pipelineRunId: action.pipelineRunId }));
          }),
          takeUntil(
            this.action$.pipe(
              ofType(CentralPolicyResultsActions.loadCentralPolicyResultsSingleSummary),
              filter((newAction) => newAction.pipelineRunId === action.pipelineRunId),
            ),
          ),
        );
      }),
    );
  });

  public onLoadCentralPolicyResultsSingleDetails$ = createEffect(() => {
    return this.action$.pipe(
      ofType(CentralPolicyResultsActions.loadCentralPolicyResultsSingleDetails),
      mergeMap((action) => {
        return this.accessApiPolicyService.getPipelineRunCentralPolicyResultsDetails(action.pipelineRunId).pipe(
          map((singleDetails) =>
            CentralPolicyResultsActions.setCentralPolicyResultsSingleDetails({
              singleDetails,
              pipelineRunId: action.pipelineRunId,
            }),
          ),
          catchError(() => {
            return of(CentralPolicyResultsActions.loadCentralPolicyResultsSingleDetailsError({ pipelineRunId: action.pipelineRunId }));
          }),
          takeUntil(
            this.action$.pipe(
              ofType(CentralPolicyResultsActions.loadCentralPolicyResultsSingleDetails),
              filter((newAction) => newAction.pipelineRunId === action.pipelineRunId),
            ),
          ),
        );
      }),
    );
  });

  public onLoadCentralPolicyResultsGroupSummary$ = createEffect(() => {
    return this.action$.pipe(
      ofType(CentralPolicyResultsActions.loadCentralPolicyResultsGroupSummary),
      switchMap((action) =>
        this.accessApiPolicyService.getGroupCentralPolicyResultsSummary(action.groupId, action.groupComplianceMode).pipe(
          map((groupSummary) =>
            CentralPolicyResultsActions.setCentralPolicyResultsGroupSummary({
              groupSummary,
            }),
          ),
          catchError((error: HttpErrorResponse) => {
            const groupId = action.groupId;
            if (error.status == 404) {
              return of(CentralPolicyResultsActions.loadCentralPolicyResultsGroupSummaryNotFound({ groupId }));
            }
            return of(CentralPolicyResultsActions.loadCentralPolicyResultsGroupSummaryError({ groupId }));
          }),
        ),
      ),
    );
  });

  public onLoadCentralPolicyResultsGroupDetails$ = createEffect(() => {
    return this.action$.pipe(
      ofType(CentralPolicyResultsActions.loadCentralPolicyResultsGroupDetails),
      switchMap((action) =>
        this.accessApiPolicyService.getGroupCentralPolicyResultsDetails(action.groupId, action.groupComplianceMode).pipe(
          map((groupDetails) =>
            CentralPolicyResultsActions.setCentralPolicyResultsGroupDetails({
              groupDetails,
            }),
          ),
          catchError(() => {
            return of(CentralPolicyResultsActions.loadCentralPolicyResultsGroupDetailsError({ groupId: action.groupId }));
          }),
        ),
      ),
    );
  });

  constructor(
    private readonly action$: Actions,
    private readonly accessApiPolicyService: AccessApiPolicyService,
  ) {}
}
