import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import {
  loadApiMetadataPipelineRunSummary,
  loadApiMetadataValidatorGroupDetails,
  loadApiMetadataValidatorGroupDetailsError,
  loadApiMetadataValidatorGroupSummary,
  loadApiMetadataValidatorGroupSummaryError,
  loadApiMetadataValidatorGroupSummaryNotFound,
  loadApiMetadataValidatorPipelineRunSummaryError,
  loadApiMetadataValidatorPipelineRunSummaryNotFound,
  setApiMetadataValidatorGroupDetails,
  setApiMetadataValidatorGroupSummary,
  setApiMetadataValidatorPipelineRunSummary,
} from './api-metadata-validator.actions';
import { ApiMetadataValidatorDetails, ApiMetadataValidatorSummary } from '../../model/api-metadata-validator';
import { AccessGroupService } from '../../../shared/access-api/access-group.service';
import { AccessPipelineRunService } from '../../../shared/access-api/access-pipeline-run.service';

@Injectable()
export class ApiMetadataValidatorEffects {
  public onLoadGroupSummary$ = createEffect(() => {
    return this.action$.pipe(
      ofType(loadApiMetadataValidatorGroupSummary),
      switchMap((action) =>
        this.accessApiGroupService.getApiMetadataValidatorGroupSummary(action.deliveryId, action.groupComplianceMode).pipe(
          map((groupSummary: ApiMetadataValidatorSummary) => setApiMetadataValidatorGroupSummary({ groupSummary })),
          catchError((error: HttpErrorResponse) => {
            const deliveryId = action.deliveryId;
            if (error.status === 404) {
              return of(loadApiMetadataValidatorGroupSummaryNotFound({ deliveryId }));
            }
            return of(loadApiMetadataValidatorGroupSummaryError({ deliveryId }));
          }),
        ),
      ),
    );
  });

  public onLoadGroupDetails$ = createEffect(() => {
    return this.action$.pipe(
      ofType(loadApiMetadataValidatorGroupDetails),
      switchMap((action) =>
        this.accessApiGroupService.getApiMetadataValidatorGroupDetails(action.deliveryId, action.groupComplianceMode).pipe(
          map((groupDetails: ApiMetadataValidatorDetails[]) => setApiMetadataValidatorGroupDetails({ groupDetails })),
          catchError(() => {
            return of(loadApiMetadataValidatorGroupDetailsError({ deliveryId: action.deliveryId }));
          }),
        ),
      ),
    );
  });

  public onLoadSingleDetails$ = createEffect(() => {
    return this.action$.pipe(
      ofType(loadApiMetadataPipelineRunSummary),
      mergeMap((action) =>
        this.accessApiPipelineRunService.getPipelineRunApiMetadataValidatorInfo(action.pipelineRunId).pipe(
          map((apiMetadataValidatorDetails: ApiMetadataValidatorDetails) =>
            setApiMetadataValidatorPipelineRunSummary({
              pipelineRunId: action.pipelineRunId,
              pipelineRunSummary: apiMetadataValidatorDetails,
            }),
          ),
          catchError((error: HttpErrorResponse) => {
            if (error.status === 404) {
              return of(loadApiMetadataValidatorPipelineRunSummaryNotFound({ pipelineRunId: action.pipelineRunId }));
            }
            return of(loadApiMetadataValidatorPipelineRunSummaryError({ pipelineRunId: action.pipelineRunId }));
          }),
          takeUntil(
            this.action$.pipe(
              ofType(loadApiMetadataPipelineRunSummary),
              filter((newAction) => newAction.pipelineRunId === action.pipelineRunId),
            ),
          ),
        ),
      ),
    );
  });

  constructor(
    private readonly action$: Actions,
    private readonly accessApiGroupService: AccessGroupService,
    private readonly accessApiPipelineRunService: AccessPipelineRunService,
  ) {}
}
