import { createAction, props } from '@ngrx/store';
import { SonarQubeCoverage, SonarQubeCoverageGroupDetails, SonarQubeCoverageGroupSummary } from '../../../model/sonarqube/sonar-qube.model';
import { GroupComplianceMode } from '../../../shared/model/group-compliance-mode';

const SONAR_QUBE_COVERAGE_IDENTIFIER = 'SonarQube Coverage';

export const loadSonarQubeCoverageGroupSummary = createAction(
  `[${SONAR_QUBE_COVERAGE_IDENTIFIER}] Trigger loading of SonarQubeCoverage information for group-summary from backend`,
  props<{ deliveryId: string; groupComplianceMode: GroupComplianceMode }>(),
);

export const setSonarQubeCoverageGroupSummary = createAction(
  `[${SONAR_QUBE_COVERAGE_IDENTIFIER}] Set SonarQubeCoverage information for group-summary from backend in store`,
  props<{ groupSummary: SonarQubeCoverageGroupSummary }>(),
);

export const loadSonarQubeCoverageGroupDetails = createAction(
  `[${SONAR_QUBE_COVERAGE_IDENTIFIER}] Trigger loading of SonarQubeCoverage information for group-details from backend`,
  props<{ deliveryId: string; groupComplianceMode: GroupComplianceMode }>(),
);

export const setSonarQubeCoverageGroupDetails = createAction(
  `[${SONAR_QUBE_COVERAGE_IDENTIFIER}] Set SonarQubeCoverage information for group-details from backend in store`,
  props<{ groupDetails: SonarQubeCoverageGroupDetails[] }>(),
);

export const loadPipelineRunSonarQubeCoverage = createAction(
  `[${SONAR_QUBE_COVERAGE_IDENTIFIER}] Load SonarQube coverage for pipeline run`,
  props<{ pipelineRunId: number }>(),
);

export const setPipelineRunSonarQubeCoverage = createAction(
  `[${SONAR_QUBE_COVERAGE_IDENTIFIER}] Set SonarQube coverage for pipeline run`,
  props<{ pipelineRunId: number; coverage: SonarQubeCoverage }>(),
);

// errors - NOT FOUND
export const loadSonarQubeCoverageGroupSummaryError = createAction(
  `[${SONAR_QUBE_COVERAGE_IDENTIFIER}] Loading of SonarQubeCoverage Group Summary error`,
  props<{ deliveryId: string }>(),
);

export const loadSonarQubeCoverageGroupSummaryNotFound = createAction(
  `[${SONAR_QUBE_COVERAGE_IDENTIFIER}] Loading of SonarQubeCoverage Group Summary not found`,
  props<{ deliveryId: string }>(),
);

export const loadSonarQubeCoverageGroupDetailsError = createAction(
  `[${SONAR_QUBE_COVERAGE_IDENTIFIER}] Loading of SonarQubeCoverage Group Details error`,
  props<{ deliveryId: string }>(),
);

export const loadSonarQubeCoverageGroupDetailsNotFound = createAction(
  `[${SONAR_QUBE_COVERAGE_IDENTIFIER}] Loading of SonarQubeCoverage Group Details not found`,
  props<{ deliveryId: string }>(),
);

export const loadPipelineRunSonarQubeCoverageError = createAction(
  `[${SONAR_QUBE_COVERAGE_IDENTIFIER}] Load SonarQube coverage for pipeline run failed`,
  props<{ pipelineRunId: number }>(),
);

export const loadPipelineRunSonarQubeCoverageNotFound = createAction(
  `[${SONAR_QUBE_COVERAGE_IDENTIFIER}] Load SonarQube coverage for pipeline run not found`,
  props<{ pipelineRunId: number }>(),
);
